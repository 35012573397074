import React, { useState } from 'react'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'

import IconButton from './iconButton'

const ToggleField = ({ data, toggleActions, pageName, parentPageName, toggled_field_id }) => {
  const gridData = {
    ...data,
    parentPageName: parentPageName,
    pageName: pageName,
    toggled_field_id: toggled_field_id,
  }
  const renderCallFields = (item, key, data) => {
    switch (item.component_name) {
      case 'icon_button':
        return <IconButton key={key} item={item} toggleActions={toggleActions} gridData={gridData} />
      default:
        return null
    }
  }

  if (data?.field_setting?.component_call_fields) {
    return data?.field_setting?.component_call_fields.map((item, key) => renderCallFields(item, key, data.component_call_data_connection))
  } else {
    return data.component_call_fields.map((item, key) => renderCallFields(item, key, data.component_call_data_connection))
  }
}

export default ToggleField
