import { useEffect, useState } from 'react'
import RedactorX from '../../../Vendor/redactorx/redactorx'
import CMSRichText from '../../Forms/Fields/richText'
import CommonFields from './commonFields'
import { AvForm } from 'availity-reactstrap-validation'

const RichText = ({ item, answers, setAnswers, requiredError }) => {
  // const [enteredValue,setEnteredValue] = useState();
  const [a, setA] = useState([])

  useEffect(() => {
    setA(a)
  }, [answers])
  const current_answer_object = answers?.filter((data) => data?.item_question_id === item?.id)[0] || {}
  const enteredValue = current_answer_object?.response_text
  // useEffect(() => {
  //   if (item.item_answer_responses?.length > 0) {
  //     setEnteredValue(item.item_answer_responses[0].response_text)
  //   }
  // }, [])

  const onHandleChange = (value) => {
    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        return {
          ...question,
          question_type: 'rich-text',
          response_text: value,
        }
      } else {
        return question
      }
    })

    setAnswers(updated_answers)
  }
  // useEffect(() => {
  //   RedactorX('#text_area', {
  //     editor: {
  //       minHeight: '100px',
  //       maxHeight:'300px',
  //       height:'200px'
  //     },
  //     subscribe: {
  //       'editor.change': function (event) {
  //         console.log('app sa', event.target.value)
  //         onHandleChange(event.params.html)
  //         // setEnteredValue(event.params.html)
  //       },
  //     },
  //   })
  // })
  //  useEffect(() => {
  //    // console.log("current object:::::::",current_answer_object)
  //    setEnteredValue(current_answer_object?.response_text)
  //  }, [JSON.stringify(current_answer_object)])

  return (
    <div>
      {/* <label>{item?.question}</label>
      <textarea id={'text_area'} style={{ width: '100%' }}>
        {enteredValue}
      </textarea> */}
      <AvForm>
        <CMSRichText id='text_area' data={{}} value={enteredValue} onChange={(value) => onHandleChange(value)}></CMSRichText>
      </AvForm>

      <CommonFields item={item} selectedAnswer={enteredValue} />
      {item?.validations?.includes('required') &&
        requiredError &&
        (!current_answer_object?.response_text || current_answer_object?.response_text?.length === 0) && (
          <div style={{ color: 'red' }}>Please Complete</div>
        )}
    </div>
  )
}

export default RichText
