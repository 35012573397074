import { cms, tidyName } from '../../helper'
import { request } from '../../Redux/Sagas/requests/api'

const getAddEditFormsData = (forms) => {
  const dataForAllForms = []

  forms?.forEach((form) => {
    let data = {}
    data['connection'] = form?.grid_tab_table || form?.grid_tab_method
    data['grid_tab_parent_required'] = form?.grid_tab_parent_required
    data['grid_tab_parent_table'] = form?.grid_tab_parent_table
    data['type'] = 0
    data['icon'] = form?.icon
    data['default_header'] = form?.cms_header_key ? cms(form?.cms_header_key) : tidyName(form?.name)
    const source_table = form?.grid_tab_table || form?.grid_tab_method
    let cols = getColumnForTabForm(form, source_table)
    data['fields'] = cols

    if (form?.layout && JSON.parse(form?.layout)) {
      data['layout'] = JSON.parse(form?.layout)
    }
    data['repeatable_form'] = form?.repeatable_form ? true : false

    const childFormData = getAddEditFormsData(form?.child_form)

    data['child_forms'] = childFormData
    dataForAllForms?.push(data)
  })

  return dataForAllForms
}

const getColumnsForGrid = (tab) => {
  const cols = []
  let order = 1
  tab?.component_call_form?.[0]?.main_grid?.component_call_cols
    ?.filter((col) => col?.component_name === 'text_data_col')
    ?.forEach((col) => {
      cols?.push({
        title: cms(col?.cms_key),
        column: col?.field_id,
        type: 'colDataAdvancedWithSearch',
        width: '100',
        inline_edit: col?.inline_edit,
        sorting: true,
        order,
      })
      order++
    })

  tab?.component_call_form?.[0]?.main_grid?.component_call_expand_fields &&
    tab?.component_call_form?.[0]?.main_grid?.component_call_expand_fields?.forEach((col) => {
      cols?.push({
        title: cms(col?.cms_key),
        column: col?.field_id,
        type: 'expandDataColumn',
        width: '100',
        inline_edit: false,
        sorting: false,
        order,
      })
      order++
    })
  return cols
}

const getColumnForTabForm = (form, form_source_table) => {
  const cols = []
  form?.component_call_fields?.forEach((item, index) => {
    const requiredValidation = item?.component_call_validation?.find((validation) => validation?.validation_type?.includes('required'))
    let col = {
      form_source_table,
      title: cms(item?.cms_key),
      editable: !item?.disabled,
      type: item?.component_name,
      field: item?.component_name,
      field_id: item?.field_id || item?.name,
      name: item?.name || item?.field_id,
      validation: requiredValidation?.length > 0 ? 'required' : '',
      col: item?.col,
      order: index,
      import: true,
      export: true,
      hidden: item?.hidden,
    }
    if (item?.is_layout) {
      col = {
        ...col,
        is_layout: true,
        row_index: item?.row_index,
        block_index: item?.block_index,
      }
    }

    if (item?.component_name === 'select' || item?.component_name === 'dropdown') {
      col = {
        ...col,
        whrere_clause: col?.component_call_data_connection?.clause_data,
        searchable: col?.format?.searchable,
        is_multi_select: col?.format?.is_multi_select,
      }
      console.log('data connection:::::::::', col, col?.component_call_data_connection)
      const data_connection = item?.component_call_data_connection
      if (data_connection?.component_name === 'template_data') {
        col['table'] = data_connection?.source_table
        col['data_type'] = 'database'
        const is_signle_field = data_connection.fields?.split(',')?.length === 1 ? true : false
        if (is_signle_field) {
          col['data_value'] = 'id'
          col['data_label'] = data_connection?.fields
        } else {
          col['data_value'] = data_connection.fields?.split(',')?.[0]
          col['data_label'] = data_connection.fields?.split(',')?.[1]
        }
        let showAddIcon = false
        item?.component_call_fields?.forEach((item) => {
          item?.component_call_actions?.forEach((action) => {
            if (action?.component_name === 'open_modal') {
              showAddIcon = true
            }
          })
        })
        col['show_add_item'] = showAddIcon
      } else if (data_connection?.component_name === 'dependent_data') {
        col['table'] = data_connection?.source_table
        col['data_type'] = 'dependent'
        col['dependent_data'] = data_connection?.data
        col['dependent_field'] = data_connection?.source_field
      }

      if (data_connection?.clause_data) {
        col['where_clause'] = data_connection?.clause_data
      }
      //toggle field
      let toggleField
      item?.component_call_fields?.forEach((item) => {
        item?.component_call_actions?.forEach((action) => {
          if ((action?.component_name === 'enable_or_disable' && action?.field_id !== item?.field_id) || item?.name) {
            toggleField = action?.field_id
          }
        })
      })

      if (toggleField) {
        col['toggle_field_name'] = toggleField
        col['toggle_field'] = true
      }
    }
    if (item?.field_setting?.depend_fields && item?.field_setting?.depend_fields?.length > 0) {
      col['depend_fields'] = item?.field_setting?.depend_fields
    }
    if (item?.component_name === 'section_heading') {
      col['color'] = item?.background_color
      col['heading'] = cms(item?.cms_key_heading)
      col['sub_heading'] = cms(item?.cms_key_sub_heading)
    }
    cols?.push(col)
  })
  return cols
}

const getTabForm = async (tab) => {
  let data = {}
  data['connection'] = tab?.source_table
  data['type'] = tab?.is_grid
  data['icon'] = tab?.icon
  data['default_header'] = tab?.default_tab_name
  let cols = null
  if (tab?.component_call_form?.[0]?.layout && JSON.parse(tab?.component_call_form?.[0]?.layout)) {
    data['layout'] = JSON.parse(tab?.component_call_form?.[0]?.layout)
  }

  if (tab?.is_grid) {
    cols = getColumnsForGrid(tab)
    data['action_types'] = getActionType(
      tab?.component_call_form?.[0]?.main_grid?.component_call_grid_buttons,
      tab?.component_call_form?.[0]?.main_grid,
      tab?.component_call_form?.[0]
    )

    data['settings'] = getGridSettingPayload(tab?.component_call_form?.[0])
    data['grid_expand_table'] = tab?.component_call_form?.[0]?.main_grid?.grid_expand_table
    data['grid_colors'] = getGridColors(tab?.component_call_form?.[0]?.main_grid?.grid_colors)
    data['grid_filters'] = tab?.component_call_form?.[0]?.main_grid?.grid_filters
    //add form:::::
    const result = await request('get-grid-form', 'POST', { grid_id: tab?.component_call_form?.[0]?.main_grid?.id })
    let add_edit_form = null
    if (result?.data?.length > 0) {
      add_edit_form = getAddEditFormsData(result?.data)
    }
    data['add_forms'] = add_edit_form
  } else {
    cols = getColumnForTabForm(tab?.component_call_form?.[0], tab?.source_table)
  }
  data['columns'] = cols
  return data
}

export const getPartnerPage = async (page) => {
  const partnerTabs = await generatePayloadForSeeder(page)
  return partnerTabs
}

const getActionType = (buttons, mainGrid, content) => {
  const actionTypes = ['ADD', 'EDIT', 'DELETE', 'RESTORE', 'FILTER', 'SEARCH', 'EXPORT', 'Preferences', 'Mail']
  let actions = 'E'
  buttons?.forEach((button) => {
    actionTypes?.forEach((action) => {
      if (button?.name?.toLowerCase()?.includes(action?.toLowerCase())) {
        if (action === 'EXPORT') {
          actions = actions + 'X'
        } else {
          actions = actions + action?.charAt(0)
        }
      }
    })
  })
  if (mainGrid?.component_call_preferences_form.fields_order) {
    actions = actions + 'P'
  }
  if (content?.grid_setting?.sort_all_columns) {
    actions = actions + 'F'
  }
  if (content?.grid_setting?.global_filiter_all_columns && content?.grid_setting?.filiter_all_columns) {
    actions = actions + 'S'
  }
  return actions
}

const getGridColors = (group) => {
  const data = []
  group?.forEach((item) => {
    let groupData = {
      order: parseInt(item?.order),
      color: item?.color,
      name: item?.name,
      note: item?.name,
      conditions: item?.conditions,
    }
    data?.push(groupData)
  })
  return data
  //  [
  //               'order' => 1,
  //               'color' => 'info',
  //               'name' => 'Not Checked',
  //               'note' => '',
  //               'conditions' => [
  //                   [
  //                       'type' => 'dynamic',
  //                       'condition' => 'equals',
  //                       'dynamic_value' => 'Checking……',
  //                       'db_field_name' => 'status_text',
  //                       'order' => 0,
  //                   ],
  //               ]
  //           ],
}
const getGridSettingPayload = (content) => {
  if (!content) return
  const { sort_all_columns, show_add_form_on_edit, select_all_records, group_by, expand_all } = content?.grid_settings || {}

  let data = {
    grid_setting: {
      sort_all_columns,
      show_add_form_on_edit,
      select_all_records,
      group_by,
      expand_all,
    },
    grid_column_setting: content?.main_grid?.grid_column_setting && JSON.parse(content?.main_grid?.grid_column_setting),
    group_by_setting: content?.main_grid?.group_by_setting && JSON.parse(content?.main_grid?.group_by_setting),
    inline_edit_button: content?.main_grid?.inline_edit_button,
    live_refresh: content?.main_grid?.live_refresh,
  }

  return data
}

export const generatePayloadForSeeder = async (currentPageData) => {
  let payload = {
    form_type: 'GRID',
    select_all_records: true,
  }
  payload['page_name'] = currentPageData?.name
  payload['default_header_name'] = cms(currentPageData?.cms_header_name)
  payload['default_sub_header_name'] = cms(currentPageData?.cms_sub_header_name)
  payload['icon'] = currentPageData?.icon

  const hidden_tab = currentPageData?.component_call_buttons?.find((item) => item?.component_name === 'hidden_tab')

  payload['source_table'] = hidden_tab?.source_table
  payload['where_clause'] = hidden_tab?.component_call_form?.[0]?.main_grid?.where_clause

  payload['action_types'] = getActionType(
    hidden_tab?.component_call_form?.[0]?.main_grid?.component_call_grid_buttons,
    hidden_tab?.component_call_form?.[0]?.main_grid,
    hidden_tab?.component_call_form?.[0]
  )

  if (hidden_tab?.component_call_form?.[0]?.layout && JSON.parse(hidden_tab?.component_call_form?.[0]?.layout)) {
    payload['layout'] = JSON.parse(hidden_tab?.component_call_form?.[0]?.layout)
  }
  payload['settings'] = getGridSettingPayload(hidden_tab?.component_call_form?.[0])
  payload['grid_expand_table'] = hidden_tab?.component_call_form?.[0]?.main_grid?.grid_expand_table
  payload['grid_colors'] = getGridColors(hidden_tab?.component_call_form?.[0]?.main_grid?.grid_colors)
  payload['grid_filters'] = hidden_tab?.component_call_form?.[0]?.main_grid?.grid_filters
  payload['grid_email_report'] = hidden_tab?.component_call_form?.[0]?.grid_email_report

  //columns::::
  if (hidden_tab?.component_call_form?.[0]?.main_grid?.component_call_cols) {
    const cols = getColumnsForGrid(hidden_tab)
    payload['columnForm'] = cols
  }

  //add form:::::
  const result = await request('get-grid-form', 'POST', { grid_id: hidden_tab?.component_call_form?.[0]?.main_grid?.id })
  let add_edit_form = null
  if (result?.data?.length > 0) {
    add_edit_form = getAddEditFormsData(result?.data)
  }
  payload['add_forms'] = add_edit_form

  //tab form:::::::::
  // const tabForms = []
  const tabFormsPromise = currentPageData?.component_call_buttons
    ?.filter((item) => item?.component_name === 'show_tabs_on_load')
    ?.map(async (tab) => {
      const data = await getTabForm(tab)
      return data
    })
  const tabForms = await Promise.all(tabFormsPromise)
  payload['tabForm'] = tabForms

  //depend tab::::::
  const dependTabFormsPromise = currentPageData?.component_call_buttons
    ?.filter((item) => item?.component_name === 'depend_tab')
    ?.map(async (tab) => {
      const data = await getTabForm(tab)

      return data
    })
  console.log('depend tabs:::::::::', dependTabFormsPromise)
  const dependTabForms = dependTabFormsPromise && dependTabFormsPromise?.length > 0 && Promise.all(dependTabFormsPromise)
  payload['depend_tabs'] = dependTabForms

  //partner tab:::::::
  if (currentPageData?.tab_near_to_pages && typeof currentPageData?.tab_near_to_pages === 'object') {
    const partnerTabPromise = Object.keys(currentPageData?.tab_near_to_pages)?.forEach(async (key) => {
      const data = await getPartnerPage(currentPageData?.tab_near_to_pages?.[key])
      console.log('partnerTabPromise:::', data, currentPageData, currentPageData?.tab_near_to_pages?.[key])
      return data
    })
    console.log('partnerTabPromise:::', partnerTabPromise)

    const partnerTab = Promise.all(partnerTabPromise)

    payload['partnerTabs'] = partnerTab
  }

  return payload
}
