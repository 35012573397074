import SearchResults from '../../../Components/Sections/searchresults'
import CoupenCode from '../../../Components/Widget/coupenCode'
import SearchFilters from '../../../Components/Widget/filters'
import HeroSearch from '../../../Components/Widget/heroSearch'
import OldBooking from '../../../Components/Widget/oldBooking'
import ProvisionalBooking from '../../../Components/Widget/provisionalBooking'
import PublicForm from '../../../Components/Widget/PublicForm/publicForm'
import ReservationExpire from '../../../Components/Widget/reservationExpire'
import WizardTab from '../../../Components/Widget/wizardTab'
import BookingDetails from '../../../Components/Widget/PaymentForm/BookingDetails'
import PaymentDetails from '../../../Components/Widget/PaymentForm/PaymentDetails'
import AddressWidget from '../../../Components/Widget/PaymentForm/AddressWidget'
import PaymentMethod from '../../../Components/Widget/PaymentForm/Payment'
// import PaymentDetails from '../../../Components/Widget/PaymentSuccess/PaymentDetails'
import Buttons from '../../../Components/Widget/PaymentSuccess/Buttons'
import PublicLogin from '../../../Components/Widget/PubliicAuth/PublicLogin'
import { useEffect, useState } from 'react'
import { request } from '../../../Redux/Sagas/requests/api'
import { getLocalStorageItem } from '../../../Helper/cookieHelper'
import { dummyBookingDetail } from './dummyPage'
import PublicAuth from '../../../Components/Widget/PublicUnAuthAddress'
import PaymentSuccessBanner from '../../../Components/Widget/paymentSuccessBanner'
import PaymentSuccessImage from '../../../Components/Widget/PaymentSuccess/paymentSuccessImage'
import StudentDetails from '../../../Components/Widget/studentDetails'
import PaymentSuccessAction from '../../../Components/Widget/PaymentSuccess/paymentSuccessAction'
import PublicChildButton from './publicChildButton'
import { Col, Row } from 'reactstrap'
import EmailDetails from '../../../Components/Widget/PaymentSuccess/emailDetails'
import ResendEmail from '../../../Components/Widget/PaymentSuccess/resendEmail'
import HeroBanner from '../../../Components/Widget/HeroBanner/HeroBanner'
import PolicyBlock from '../../../Components/Widget/NumberList/PolicyBlock'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import ImageWithDesp from '../../../Components/Widget/ImageWithDesp/ImageWithDesp'
import Content from '../../../Components/Widget/Content/Content'
import ContentImage from '../../../Components/Widget/Content/ContentImage'
export const RenderWidget = ({
  widget,
  isLastWidget,
  searchData,
  setSearchData,
  filterData,
  setFilterData,
  setActivePage,
  activePage,
  parentData,
  setParentData,
  pageData,
  userAddress,
  setUserAddress,
  couponCode,
  setCouponCode,
  onGoBackToHomePage,
  clearSearchData,
  setClearSearchData,
  userAddressValidation,
  setUserAddressValidation,
  searchResultsAvailable,
  setSearchResultsAvailable,
  unitData,
  bookingDetails,
  setBookingDetails,
  formsData,
  setFormData,
  setUnitData,
  searchResults,
  setSearchResults,
}) => {
  const type = widget?.component_name || widget?.name

  switch (type) {
    case 'hero_search':
      return (
        <HeroSearch
          data={widget?.widget_data}
          searchData={searchData}
          setSearchData={setSearchData}
          clearSearchData={clearSearchData}
          setClearSearchData={setClearSearchData}
        />
      )
    case 'search_result':
      return (
        <SearchResults
          data={widget?.widget_data}
          searchData={searchData}
          filterData={filterData}
          setActivePage={setActivePage}
          activePage={activePage}
          parentData={parentData}
          setParentData={setParentData}
          clearSearchData={clearSearchData}
          setClearSearchData={setClearSearchData}
          searchResultsAvailable={searchResultsAvailable}
          setSearchResultsAvailable={setSearchResultsAvailable}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
        />
      )
    case 'search_filters':
      return <SearchFilters searchData={searchData} data={widget?.widget_data} filterData={filterData} setFilterData={setFilterData} />
    case 'booking_timer':
      return (
        <ReservationExpire
          data={widget?.widget_data}
          parentData={parentData}
          widgetUUID={widget?.uuid}
          setParentData={setParentData}
          pageData={pageData}
          setActivePage={setActivePage}
          onGoBackToHomePage={onGoBackToHomePage}
        />
      )
    case 'old_orders':
      return <OldBooking data={widget?.widget_data} searchResults={searchResults} activePage={activePage} />
    case 'provisional_booking':
      return (
        <ProvisionalBooking
          formsData={formsData}
          activePage={activePage}
          unitData={unitData}
          setUnitData={setUnitData}
          setFormData={setFormData}
          data={widget?.widget_data}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
        />
      )
    case 'checkout_form':
      return (
        <PublicForm
          form={widget?.widget_data?.form?.[0]}
          parentData={parentData}
          pageData={pageData}
          setActivePage={setActivePage}
          setParentData={setParentData}
          setSearchData={setSearchData}
          setFilterData={setFilterData}
          unitData={unitData}
          setUnitData={setUnitData}
          formsData={formsData}
          setFormData={setFormData}
          onGoBackToHomePage={onGoBackToHomePage}
        />
      )
    case 'apply_coupon':
      return <CoupenCode couponCode={couponCode} setCouponCode={setCouponCode} isLastWidget={isLastWidget} data={widget?.widget_data} />
    case 'navigation':
      return <WizardTab activePage={activePage} setActivePage={setActivePage} pageData={pageData} />
    case 'booking_details':
      return (
        <BookingDetails
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          data={widget?.widget_data}
          parentData={parentData}
          form={widget?.widget_data?.form?.[0]}
        />
      )
    case 'payment_details':
      return <PaymentDetails data={widget?.widget_data} bookingDetails={bookingDetails} parentData={parentData} couponCode={couponCode} />
    case 'address_dropdown':
      return (
        <AddressWidget
          userAddressValidation={userAddressValidation}
          setUserAddressValidation={setUserAddressValidation}
          form={widget?.widget_data?.form?.[0]}
          data={widget?.widget_data}
          userAddress={userAddress}
          setUserAddress={setUserAddress}
        />
      )
    case 'public_auth':
      return <PublicAuth />
    case 'payment_method':
      return (
        <PaymentMethod
          form={widget?.widget_data?.form?.[0]}
          data={widget?.widget_data}
          userAddress={userAddress}
          parentData={parentData}
          pageData={pageData}
          setActivePage={setActivePage}
          setParentData={setParentData}
          onGoBackToHomePage={onGoBackToHomePage}
          userAddressValidation={userAddressValidation}
          setUserAddressValidation={setUserAddressValidation}
          couponCode={couponCode}
        />
      )

    case 'payment_buttons':
      return <Buttons />
    case 'login_form':
      return <PublicLogin isLastWidget={isLastWidget} form={widget?.widget_data?.form?.[0]} data={widget?.widget_data} />
    case 'payment_success':
      return <PaymentSuccessBanner data={widget?.widget_data} />
    case 'payment_success_image':
      return <PaymentSuccessImage data={widget?.widget_data} />
    case 'student_details':
      return <StudentDetails bookingDetails={bookingDetails} setBookingDetails={setBookingDetails} widgets={widget} data={widget?.widget_data} />
    case 'company_details':
      return <StudentDetails bookingDetails={bookingDetails} setBookingDetails={setBookingDetails} widgets={widget} data={widget?.widget_data} />

    case 'payment_success_action':
      return <PaymentSuccessAction activePage={activePage} setActivePage={setActivePage} data={widget?.widget_data} />

    case 'email_details':
      return <EmailDetails data={widget?.widget_data} />
    case 'resend_email':
      return <ResendEmail data={widget?.widget_data} />
    // case 'search_filters':
    //   return <Filters data={widget?.widget_data} />
    case 'hero_bg':
      return <HeroBanner data={widget?.widget_data} />
    case 'number_list':
      return <PolicyBlock data={widget?.widget_data} />
    case 'content':
      return <Content heading={widget?.widget_data?.heading} description={widget?.widget_data?.text} buttonText={widget?.widget_data?.button_text} />
    case 'content_image' : 
      return <ContentImage image={widget?.widget_data?.image} />
  }
}

const PublicSection = ({
  section,
  searchData,
  setSearchData,
  filterData,
  setFilterData,
  setActivePage,
  activePage,
  parentData,
  setParentData,
  pageData,
  onGoBackToHomePage,
  clearSearchData,
  setClearSearchData,
  searchResultsAvailable,
  setSearchResultsAvailable,
  couponCode,
  setCouponCode,
  bookingDetails,
  setBookingDetails,
  formsData,
  setFormData,
  unitData,
  setUnitData,
  searchResults,
  setSearchResults,
}) => {
  const [userAddress, setUserAddress] = useState({
    address1: '',
    country: '',
    display: 1,
    city: '',
    post_code: '',
  })
  const [userAddressValidation, setUserAddressValidation] = useState(false)
  const isDesktop = useMediaQuery('(min-width:768px)')
  // const [unitData, setUnitData] = useState([])
  // const getChildPageWidgets = (section) => {
  //   if (section?.child_page_buttons && section.child_page_buttons.length) return section?.child_page_buttons
  // }
  // const [bookingDetails, setBookingDetails] = useState()

  return (
    <>
      {section?.child_page_buttons?.length > 0 && (
        <Row style={{ marginLeft: 0, marginRight:0, paddingLeft: section?.container_fluid === 0 && isDesktop ? '6rem' : '15px' , paddingRight: section?.container_fluid === 0 && isDesktop ? '6rem' : '15px'}}>
          {section?.child_page_buttons?.map((button, i) => {
            return (
              <Col md={button?.col ? parseInt(button?.col) : 12} style={{paddingLeft: '15px' , paddingRight : '15px'}}>
                <PublicChildButton
                  key={i}
                  data={button}
                  searchData={searchData}
                  setSearchData={setSearchData}
                  filterData={filterData}
                  setFilterData={setFilterData}
                  setActivePage={setActivePage}
                  activePage={activePage}
                  parentData={parentData}
                  setParentData={setParentData}
                  pageData={pageData}
                  userAddress={userAddress}
                  setUserAddress={setUserAddress}
                  couponCode={couponCode}
                  setCouponCode={setCouponCode}
                  onGoBackToHomePage={onGoBackToHomePage}
                  clearSearchData={clearSearchData}
                  setClearSearchData={setClearSearchData}
                  userAddressValidation={userAddressValidation}
                  setUserAddressValidation={setUserAddressValidation}
                  searchResultsAvailable={searchResultsAvailable}
                  unitData={unitData}
                  setUnitData={setUnitData}
                  setSearchResultsAvailable={setSearchResultsAvailable}
                  bookingDetails={bookingDetails}
                  setBookingDetails={setBookingDetails}
                  searchResults={searchResults}
                  setSearchResults={setSearchResults}
                />
              </Col>
            )
          })}
        </Row>
      )}

      {section?.widgets?.map((widget, index) => {
        return (
        <Row style={{ marginLeft: 0, marginRight:0, paddingLeft: section?.container_fluid === 1 && isDesktop ? '6rem' : '0' , paddingRight: section?.container_fluid === 1 && isDesktop ? '6rem' : '0'}}>
            <Col md={section?.col ? parseInt(section?.col) : 12} style={{padding:0}}>
              <RenderWidget
                key={index}
                isLastWidget={index === section?.widgets.length - 1}
                widget={widget}
                searchData={searchData}
                setSearchData={setSearchData}
                filterData={filterData}
                setFilterData={setFilterData}
                setActivePage={setActivePage}
                activePage={activePage}
                parentData={parentData}
                setParentData={setParentData}
                pageData={pageData}
                userAddress={userAddress}
                setUserAddress={setUserAddress}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
                onGoBackToHomePage={onGoBackToHomePage}
                clearSearchData={clearSearchData}
                setClearSearchData={setClearSearchData}
                userAddressValidation={userAddressValidation}
                setUserAddressValidation={setUserAddressValidation}
                searchResultsAvailable={searchResultsAvailable}
                unitData={unitData}
                setUnitData={setUnitData}
                setSearchResultsAvailable={setSearchResultsAvailable}
                bookingDetails={bookingDetails}
                formsData={formsData}
                setFormData={setFormData}
                setBookingDetails={setBookingDetails}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
              />
          </Col>
          </Row>
        )
      })}
      {/* {section?.child_page_buttons?.map((widget, index) => {
        return (
          <RenderWidget
            key={index}
            widget={widget}
            searchData={searchData}
            setSearchData={setSearchData}
            filterData={filterData}
            setFilterData={setFilterData}
            setActivePage={setActivePage}
            activePage={activePage}
            parentData={parentData}
            setParentData={setParentData}
            pageData={pageData}
            userAddress={userAddress}
            setUserAddress={setUserAddress}
            couponCode={couponCode}
            setCouponCode={setCouponCode}
            onGoBackToHomePage={onGoBackToHomePage}
            clearSearchData={clearSearchData}
            setClearSearchData={setClearSearchData}
            userAddressValidation={userAddressValidation}
            setUserAddressValidation={setUserAddressValidation}
            searchResultsAvailable={searchResultsAvailable}
            unitData={unitData}
            setUnitData={setUnitData}
            setSearchResultsAvailable={setSearchResultsAvailable}
            bookingDetails={bookingDetails}
            setBookingDetails={setBookingDetails}
          />
        )
      })} */}
    </>
  )
}

export default PublicSection
