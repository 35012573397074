import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { setDashboardCMSKey, setDashboardDataInRedux, setDomain, setDropdownOptionsByPage } from '../../../Redux/Reducers/CMS'
import ArTimeByMinute from './Component/Graphs/arTimeByMin'
import CompletedByTaskType from './Component/Graphs/completedByTaskType'
import DailyARTime from './Component/Graphs/dailyARTime'
import DailyARTimeByMonth from './Component/Graphs/dailyARTimeByMonth'
import InfoBox from './Component/Graphs/infoBox'
import TagQuestionJobs from './Component/Graphs/tagQuestionJobs'
import DateTimeRangePicker from '@wojtekmaj/react-datetimerange-picker'
import { useEffect, useState } from 'react'
import { request } from '../../../Redux/Sagas/requests/api'
import { DASHBOARD_DUMMY_DATA } from './dummy-data'
import Loader from 'react-loaders'

import { InputGroup, InputGroupAddon, FormGroup, Label, Form } from 'reactstrap'

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatePicker from 'react-datepicker'
import DashboardLayout from './Component/Layout/dashboardLayoutSetting'
import SelectDomainField from '../../../CMSComponent/Common/selectDomain'
import NoDomain from '../../../CMSComponent/PageVariation/noDomain'

const RenderChart = ({ type, dashboardData }) => {
  switch (type) {
    case 'completed_by_task_types':
      return (
        <div style={{ marginBottom: '4rem', height: '25rem' }}>
          <CompletedByTaskType dashboardData={dashboardData} />
        </div>
      )
    case 'ar_time_by_week':
      return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom: '1rem' }}>
          <ArTimeByMinute dashboardData={dashboardData} />
        </div>
      )
    case 'tags_and_questions_job':
      return (
        <div style={{ marginBottom: '4rem', minHeight: '25rem' }}>
          <TagQuestionJobs dashboardData={dashboardData} />
        </div>
      )

    case 'ar_time_daily':
      return (
        <div style={{ height: '20rem', marginBottom: '4rem', height: '25rem' }}>
          <DailyARTime dashboardData={dashboardData} />
        </div>
      )
    case 'ar_time_months':
      return (
        <div style={{ marginBottom: '4rem', height: '25rem' }}>
          <DailyARTimeByMonth dashboardData={dashboardData} />
        </div>
      )
  }
}

const Dashboard = ({ page }) => {
  const dispatch = useDispatch()

  const domain_id = useSelector((state) => state.CMS.selected_domain_id)
  const coreData = useSelector((state) => state.CMS.coreData)
  const dashboardDataRedux = useSelector((state) => state.CMS.dashboardData)
  const editPageMode = useSelector((state) => state.CMS.editPageMode)

  const [selectedTimeRange, setSelectedTimeRange] = useState([])
  const [dashboardData, setDashboardData] = useState()
  const [loadingData, setLoadingData] = useState(false)
  const [dataRequested, setDataRequested] = useState(false)

  const [isEditingDashboardLayout, setIsEditingDashboardLayout] = useState(false)

  useEffect(() => {
    if (dashboardDataRedux?.data) {
      setSelectedTimeRange([dashboardDataRedux?.start_date, dashboardDataRedux?.end_date])
    } else {
      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth()
      var firstDay = new Date(new Date().setDate(new Date().getDate() - 30))
      var lastDay = new Date().toUTCString()

      setSelectedTimeRange([firstDay, lastDay])
    }
  }, [])

  const checkReduxStartEndDate = () => {
    if (
      new Date(dashboardDataRedux?.start_date).getDate() === new Date(selectedTimeRange?.[0]).getDate() &&
      new Date(dashboardDataRedux?.end_date).getDate() === new Date(selectedTimeRange?.[1]).getDate() &&
      dashboardDataRedux?.domain_id === domain_id
    ) {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    if (domain_id && selectedTimeRange?.length > 0 && !dataRequested) {
      if (dashboardDataRedux?.data && checkReduxStartEndDate()) {
        setDashboardData(dashboardDataRedux?.data)
      } else {
        setLoadingData(true)
        setDataRequested(true)

        var offset = new Date(selectedTimeRange?.[0])?.getTimezoneOffset()
        const start_date = `${new Date(selectedTimeRange?.[0]).getDate()}-${new Date(selectedTimeRange?.[0]).getMonth() + 1}-${new Date(
          selectedTimeRange?.[0]
        ).getFullYear()}`
        const end_date = `${new Date(selectedTimeRange?.[1]).getDate()}-${new Date(selectedTimeRange?.[1]).getMonth() + 1}-${new Date(
          selectedTimeRange?.[1]
        ).getFullYear()}`
        // const start_date = new Date(new Date(selectedTimeRange?.[0])?.getTime() + offset * 60000)
        // const end_date = new Date(new Date(selectedTimeRange?.[1])?.getTime() + offset * 60000)

        const widgets = []
        page?.common_setting?.map((row) => {
          row?.column?.map((col) => {
            col?.items?.map((item) => widgets?.push(item))
          })
        })

        request('get-dashboard-widgets', 'POST', {
          domain_id,
          start_date,
          end_date,
          widgets,
        }).then((res) => {
          // setDashboardData(DASHBOARD_DUMMY_DATA)
          if (res.data) {
            setDashboardData(res.data)
            dispatch(
              setDashboardDataInRedux({
                data: res.data,
                domain_id,
                start_date: selectedTimeRange?.[0],
                end_date: selectedTimeRange?.[1],
              })
            )
            setLoadingData(false)
            setDataRequested(false)
          } else {
            setLoadingData(false)
            setDataRequested(false)
          }
        })
      }
    }
  }, [domain_id, selectedTimeRange])

  console.log('dashboard entereed::::::', dashboardData)

  if (loadingData)
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: 'calc(100vh - 5rem)', alignItems: 'center' }}>
        <Loader type='ball-pulse' color='blue' />
      </div>
    )
  if (!domain_id) return <NoDomain />
  if (!dashboardData) return

  return (
    <div>
      <Row>
        <Col md={6} style={{ paddingLeft: '2rem' }}>
          <div style={{ marginBottom: '1rem' }}>
            <Form>
              <Row form>
                <Col md={6}>
                  <FormGroup>
                    <Label for='exampleEmail' className='mr-sm-2'>
                      Start Date
                    </Label>
                    <InputGroup>
                      <InputGroupAddon addonType='prepend'>
                        <div className='input-group-text'>
                          <FontAwesomeIcon icon={faCalendarAlt} />
                        </div>
                      </InputGroupAddon>
                      <DatePicker
                        selected={selectedTimeRange?.[0] ? new Date(selectedTimeRange?.[0]) : ''}
                        selectsStart
                        className='form-control'
                        onChange={(start) => setSelectedTimeRange([start, selectedTimeRange?.[1]])}
                        maxDate={new Date()}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for='examplePassword' className='mr-sm-2'>
                      End Date
                    </Label>

                    <DatePicker
                      selected={selectedTimeRange?.[1] ? new Date(selectedTimeRange?.[1]) : ''}
                      selectsEnd
                      className='form-control'
                      onChange={(end) => setSelectedTimeRange([selectedTimeRange?.[0], end])}
                      maxDate={new Date()}
                      minDate={selectedTimeRange?.[0] && new Date(selectedTimeRange?.[0])}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
            {/* <DateTimeRangePicker value={selectedTimeRange} onChange={(date) => setSelectedTimeRange(date)} format='y-MM-dd' /> */}
          </div>
        </Col>
        {editPageMode ? (
          <Col md={3}>
            <Button color='primary' onClick={() => setIsEditingDashboardLayout(!isEditingDashboardLayout)} style={{ marginTop: '1.8rem' }}>
              Edit Layout
            </Button>
          </Col>
        ) : (
          <Col md={3}></Col>
        )}

        <Col md={3}>
          <AvForm>
            <SelectDomainField />
          </AvForm>
        </Col>
        <Col md={12}>
          {page?.common_setting?.map((rowItem, key) => {
            return (
              <Row key={key}>
                {rowItem?.column?.map((col, key) => {
                  return (
                    <Col md={col?.width || 6} key={key}>
                      {col?.items?.map((item, key) => {
                        return <RenderChart dashboardData={dashboardData} type={item} key={key} />
                      })}
                    </Col>
                  )
                })}
              </Row>
            )
          })}
        </Col>

        <Modal isOpen={isEditingDashboardLayout} toggle={() => setIsEditingDashboardLayout(!isEditingDashboardLayout)}>
          <ModalHeader toggle={() => setIsEditingDashboardLayout(!isEditingDashboardLayout)}>Dashboard Setting</ModalHeader>
          <ModalBody>
            <DashboardLayout
              initialRows={page?.common_setting}
              isUpdatingLayout={true}
              page={page}
              onCloseModal={() => setIsEditingDashboardLayout(false)}
            />
          </ModalBody>
        </Modal>
      </Row>
    </div>
  )
}

export default Dashboard
