import React from 'react'
import { Button } from 'reactstrap'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'

const Content = ({ heading, subHeading,description, buttonText, buttonIcon ='fa fa-download', buttonAction, buttonType='v1' }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  return (
     <div className={` d-flex flex-column justify-content-center mt-4 mb-4`}>
        <h2 style={{ fontSize: isDesktop ? '30px' : '25px', fontWeight: '600', color: primary_color }}>
          {heading}
        </h2>
        {
          subHeading ? 
          <h4 style={{ fontSize: isDesktop ? '20px' : '20px', fontWeight: '600', color: '#1E1E1E' , marginBottom: '1rem' }}>
            {subHeading}
          </h4> : null
        }
        {
          description ? 
           <div style={{ fontSize: isDesktop ? '20px' : '14px', color: '#8E8E8E', lineHeight: isDesktop ? '40px' : '30px', fontWeight: 400, marginBottom: isDesktop ? '3rem' : '1rem' }}>
             {description}
           </div> : null
        }
       
        {
          buttonText ?
            <Button className='w-100 poppin d-flex align-items-center justify-content-center' style={{  color: buttonType ===  'v1' ? 'white' : primary_color,border: `1px solid ${primary_color}`, maxWidth: isDesktop ? 360 : '100%', height: isDesktop ? 56 : 48, backgroundColor: buttonType ===  'v1' ? primary_color : 'transparent' , borderRadius: 30 }} onClick={buttonAction}>
              <span style={{ fontSize: isDesktop ? '18px' : '15px', fontWeight: 600}}>{buttonText}</span>
              <i className={`${buttonIcon} ml-2`} style={{ fontSize: isDesktop ? '22px' : '18px' }}></i>
            </Button>
            : null
        }
      </div>
  )
}

export default Content

