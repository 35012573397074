import { AvCheckbox, AvCheckboxGroup } from 'availity-reactstrap-validation'
import React, { Fragment, useEffect, useState } from 'react'
import { BiFilter } from 'react-icons/bi'
import { Form } from 'reactstrap'
import { tidyName } from '../../helper'
import MultiCheckBox from './WidgetFilterType/multiCheckBox'
import { primary_color } from '../../Helper/uiHelper'

const RenderFilters = ({ searchData , groupData, form, filterData, setFilterData }) => {
  const type = groupData?.component_name

  switch (type) {
    case 'multi_checkbox':
      return <MultiCheckBox searchData={searchData} data={groupData} form={form} filterData={filterData} setFilterData={setFilterData} />
    default:
      return null
  }
}

const SearchFilters = ({ searchData ,  data, filterData, setFilterData }) => {
  return (
    <Fragment>
      <div className='d-flex flex-column pt-3 pb-3' style={{ flex: '1', background: '#ffffff', minHeight: '0', borderRadius: '10px' }}>
        <div className='d-flex align-items-center mb-2' style={{color : primary_color}}>
          <i className={data?.icon}></i>
          <span className='ml-3' style={{fontSize  : '16px'}}>Filter</span>
        </div>
        <div className='p-1 mb-2'>
          {data?.form?.[0]?.component_call_fields?.map((field, index) => {
            return (
              <RenderFilters
                searchData={searchData}
                groupData={field}
                key={index}
                form={data?.form?.[0]}
                filterData={filterData}
                setFilterData={setFilterData}
              />
            )
          })}
        </div>
      </div>
    </Fragment>
  )
}

export default SearchFilters
