import React from 'react'


const PaymentConfirm = ({ data }) => {
  const { acsUrl, cReq, dsTransId, acsTransId, transactionId } = data
  const formRef = React.useRef(null)

  React.useEffect(() => {
    formRef.current.submit()
  }, [])

  return (
    <>
      <form id='pa-form' ref={formRef} method='post' action={acsUrl}>
        <input type='hidden' name='creq' value={cReq} />
        <input type='hidden' name='acsTransId' value={acsTransId} />
        <input type='hidden' name='dsTransId' value={dsTransId} />
        <input type='hidden' name='transactionId' value={transactionId} />
        <input type='hidden' name='threeDSSessionData' value={transactionId} />
      </form>
      <div>Please wait we are confirming the payment...</div>
    </>
  )
}

export default PaymentConfirm
