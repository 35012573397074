import React, { Fragment } from 'react'

import { InputGroup, InputGroupAddon } from 'reactstrap'

import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import DatePicker from 'react-datepicker'
import { addChangedFieldIdInReduxByFormName, checkFieldExistInSyncToUpdate, cms, editCMS, tidyName, validateForAv } from '../../../helper'
import { connect } from 'react-redux'
import { AvField } from 'availity-reactstrap-validation'
import { checkIsDate } from '../../../helper'
import { checkNeedToUpdateCurrentDate } from './date'

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      startDate: '',
    }
    this.handleChange = this.handleChange.bind(this)
  }
  componentDidMount() {
      let value = ''

      if (this.props.defaultValue && checkIsDate(this.props.defaultValue)) {
        value = new Date(this.props.defaultValue)
      } else if (checkNeedToUpdateCurrentDate(this.props.data?.field_id)) {
        value = new Date()
      }
      this.setState({ startDate: value })

      this.props.setFormFieldValue((formValue) => {
        let updatedValue = {
          ...formValue,
        }
        updatedValue[this.props.data?.field_id] = value

        return updatedValue
      })

    // this.props?.onChange(this.props.data.field_id,new Date(this.props.data.cms_value))
  }
  handleChange(date) {
    this.setState({
      startDate: date,
    })
    this.props?.onChange(this.props.data.field_id, date)
     this.props.setFormFieldValue((formValue) => {
       let updatedValue = {
         ...formValue,
       }
       updatedValue[this.props.data?.field_id] = date
       return updatedValue
     })
    addChangedFieldIdInReduxByFormName(this.props.formName, this.props.currentRow[this.props.currentRow?.length - 1]?.id, this.props.data?.field_id)
  }

  checkSingleCondition = (condition, formValue, compareValue) => {
    switch (condition) {
      case 'equals':
        if (formValue == compareValue) {
          return true
        } else {
          return false
        }
      case 'less_than':
        if (formValue < compareValue) {
          return true
        } else {
          return false
        }
      case 'greater_than_and_equal':
        if (formValue >= compareValue) {
          return true
        } else {
          return false
        }
      case 'greater_than':
        if (formValue >= compareValue) {
          return true
        } else {
          return false
        }
      case 'less_than_and_equal':
        if (formValue <= compareValue) {
          return true
        } else {
          return false
        }
    }
  }

  render() {
    const data = this.props.data

    let isFieldVisible = true

    // applicable to upload table
    if ((data?.field_id === 'valid_too' || data?.field_id === 'valid_from') && this.props.parentPageName === 'contractors') {
      if (this.props.selectedDropdownData?.upload_document_id?.has_expiry === 0 || !this.props.selectedDropdownData) {
        isFieldVisible = false
      }
      if (!this.props.selectedDropdownData?.upload_document_id?.value) {
        isFieldVisible = false
      }
    }

    if (data?.field_setting?.depend_fields && this.props.currentRow[this.props.currentRow?.length - 1]) {
      const dependsData = data?.field_setting?.depend_fields
      const condition_array = dependsData?.map((dependForm) => {
        return this.checkSingleCondition(
          dependForm?.condition,
          this.props.currentRow[this.props.currentRow?.length - 1][dependForm?.dependent_field],
          parseInt(dependForm?.value)
        )
      })
      const final_condition_array = condition_array?.filter((item) => item)
      if (final_condition_array?.length > 0) {
        isFieldVisible = true
      } else {
        isFieldVisible = false
      }
    }

    if (!isFieldVisible) return

    const isFieldInSync = checkFieldExistInSyncToUpdate(
      this.props.formName,
      this.props.currentRow[this.props.currentRow?.length - 1]?.id,
      data?.field_id
    )

    let isRequiredField

    if (
      (this.props.selectedDropdownData?.upload_document_id?.label?.toLowerCase()?.includes('cv') &&
        this.props.selectedDropdownData?.upload_document_id?.label?.toLowerCase()?.includes('required')) ||
      (this.props.selectedDropdownData?.upload_document_id?.name?.toLowerCase()?.includes('cv') &&
        this.props.selectedDropdownData?.upload_document_id?.name?.toLowerCase()?.includes('required'))
    ) {
      isRequiredField = true
    }
      return (
        <div style={{ marginBottom: '1rem' }}>
          <label>{cms(data.cms_key) || tidyName(data?.name)}</label>
          <InputGroup className={isFieldInSync ? 'blue-border' : ''}>
            <DatePicker
              selected={this.state.startDate}
              onChange={this.handleChange}
              showTimeSelect
              className='form-control'
              timeFormat='HH:mm'
              timeIntervals={30}
              dateFormat='d MMMM , yyyy HH:mm'
              timeCaption='Time'
            />
            <InputGroupAddon addonType='append'>
              <div className='input-group-text'>
                <FontAwesomeIcon icon={faCalendarAlt} />
              </div>
            </InputGroupAddon>
          </InputGroup>
          <AvField
            type='hidden'
            value={this.state.startDate}
            name={data?.field_id}
            required={isRequiredField}
            validate={data.component_call_validation ? validateForAv(data.component_call_validation, data.field_id) : {}}
          />
        </div>
      )
  }
}

function mapStateToProps(state) {
  return {
    currentRow: state.CMS.currentRow,
    selectedDropdownData: state.CMS.selectedDropdownData,
  }
}

export default connect(mapStateToProps)(DateTimePicker)
