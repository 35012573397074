import { useEffect, useState } from 'react'
import RoundedInput from './roundedInput'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { useRef } from 'react'
const dummy_question = {
  id: 1,
  item_id: 1,
  order: 0,
  type: 'text',
  question: 'Student Name',
  validations: null,
  score_grade: 'none',
  unique_answer: 0,
  deleted_at: null,
  delete: 0,
  created_at: null,
  updated_at: null,
  has_upload: 0,
  uuid: 'ea2787e6-d768-45bf-96d9-e62db639901c',
  location_id: null,
}
const createDefaultAnswersNew = (questions) => {
  const obj = {}
  questions.forEach((e) => (obj[e.uuid] = e.answer || ''))
  return obj
}
const LockerQuestion = ({ questions, formFieldValue, setFormFieldValue, validationError, setValidationError, formIndex, setFormData }) => {
  const [answer, setAnswer] = useState([createDefaultAnswersNew(questions)])
  const isDesktop = useMediaQuery('(min-width:768px)')
  const isFirstRendered = useRef(false)

  useEffect(()=>{
    setAnswer([createDefaultAnswersNew(questions)])
  },[questions])
  useEffect(() => {
    if (isFirstRendered.current) {
      // let updatedValue = {
      //   ...formFieldValue,
      //   questions: answer,
      // }
      // setFormFieldValue(updatedValue)
      setFormData((prevFormsData) => {
        return prevFormsData.map((item, i) => {
          if (formIndex === i) {
            let newdata = {
              ...item,
            }
            newdata['questions'] = answer?.[0]
            return newdata
          } else {
            return item
          }
        })
      })
    } else isFirstRendered.current = true
  }, [JSON.stringify(answer)])

  return (
    <div style={isDesktop ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem' } : {}}>
      {questions?.map((question, index) => {
        return (
          <div className='mb-2'>
            <RoundedInput
              data={{
                field_id: question?.uuid,
              }}
              validationError={validationError}
              setValidationError={setValidationError}
              formFieldValue={answer?.[0]}
              setFormFieldValue={setAnswer}
              inputPlaceholder={question?.question}
              setFormData={setAnswer}
              formIndex={0}
            />
          </div>
        )
      })}
    </div>
  )
}

export default LockerQuestion
