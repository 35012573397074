import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import { request } from '../../Redux/Sagas/requests/api'
import { MdCheck } from 'react-icons/md'
import { primary_color } from '../../Helper/uiHelper'
const CoupenCode = ({ data, setCouponCode, couponCode, isLastWidget }) => {
  const [couponCodeValue, setCouponCodeValue] = useState('')
  const dispatch = useDispatch()
  useEffect(() => {
    setCouponCodeValue(couponCode)
  }, [couponCode])

  const onVerifyCoupon = () => {
    request('check-coupon', 'POST', { coupon_code: couponCodeValue }).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Coupon code applied!' }))
        setCouponCode(couponCodeValue)
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.data?.message?.coupon_code?.[0] ? res?.data?.message?.coupon_code?.[0] : res?.data?.message,
          })
        )
        setCouponCodeValue('')
      }
    })
  }

  const onChangeValue = (value) => {
    setCouponCodeValue(value)
  }

  return (
    <div className='mt-2' style={{ padding: '2.5rem 0', borderBottom: isLastWidget ? '' : '1px solid lightgrey' }}>
      <div className=' text-left font-weight-bold h4 mb-4' style={{ color: primary_color, fontWeight: '800' }}>
        {data?.heading}
      </div>
      <div className='mb-2 d-flex'>
        <div className='rounded-input-container d-flex align-items-center justify-content-center' style={{ border: '1px solid' }}>
          <input
            placeholder={couponCode ? couponCode : 'Code'}
            className={''}
            type='text'
            value={couponCodeValue ? couponCodeValue : ''}
            onChange={(e) => onChangeValue(e.target.value)}></input>
        </div>

        <button
          className='btn btn-primary rounded-pill ml-1'
          style={{ height: '2rem', width: 'auto', background: primary_color }}
          onClick={() => {
            if (couponCodeValue && couponCodeValue != couponCode) onVerifyCoupon()
          }}>
          {couponCode && couponCodeValue === couponCode ? (
            <MdCheck style={{ marginLeft: '0px', marginBottom: '2px' }} />
          ) : (
            <div className='cursor-pointer' style={{ color: 'white' }}>
              Verify
            </div>
          )}
        </button>
      </div>
      <div>{data?.sub_heading}</div>
    </div>
  )
}

export default CoupenCode
