import React, { useEffect, useState } from 'react'
import { InputGroup, InputGroupAddon } from 'reactstrap'
import { faCalendarAlt, faSpinner, faUpload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DatePicker from 'react-datepicker'

import {
  addChangedFieldIdInReduxByFormName,
  checkFieldExistInSyncToUpdate,
  cms,
  dataValidationForField,
  editCMS,
  validateForAv,
} from '../../../helper'
import { connect, useDispatch, useSelector } from 'react-redux'
import { AvField } from 'availity-reactstrap-validation'
import { request } from '../../../Redux/Sagas/requests/api'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import moment from 'moment'

export const checkNeedToUpdateCurrentDate = (field_id) => {
  const fields = ['termination_date', 'end_date', 'break_end', 'valid_too']
  if (!fields?.includes(field_id)) {
    return true
  } else {
    return false
  }
}

const checkSingleCondition = (condition, formValue, compareValue) => {
  switch (condition) {
    case 'equals':
      if (formValue == compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (formValue < compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than_and_equal':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than_and_equal':
      if (formValue <= compareValue) {
        return true
      } else {
        return false
      }
  }
}

const select = (state) => ({
  cmsEditMode: state.CMS.editMode,
  currentRow: state.CMS.currentRow,
  selectedDropdownData: state.CMS.selectedDropdownData,
})

const CMSDate = ({
  data,
  cmsEditMode,
  id,
  value,
  currentRow,
  selectedDropdownData,
  formName,
  parentPageName,
  formFieldValue,
  setFormFieldValue = () => {},
  source_table,
  formData,
  validationErrorOnFieldValueChange,
  setValidationErrorOnFieldValueChange = () => {},
}) => {
  const [selected, updateSelected] = useState()
  const [isCheckingValidation, setIsCheckingValidation] = useState(false)

  const dispatch = useDispatch()

  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)

  useEffect(() => {
    if (value && value !== '0000-00-00') {
      const dateWithoutHour = new Date(value)
      dateWithoutHour.setHours(0, 0, 0, 0)

      updateSelected(moment(dateWithoutHour).format('YYYY-MM-DD'))
      setFormFieldValue((formValue) => {
        let updatedValue = {
          ...formValue,
        }
        updatedValue[data?.field_id] = value
        return updatedValue
      })
    } else {
      if (checkNeedToUpdateCurrentDate(data?.field_id) || data.default_value === 'now') {
        const dateWithoutHour = new Date()
        dateWithoutHour.setHours(0, 0, 0, 0)

        updateSelected(moment(dateWithoutHour).format('YYYY-MM-DD'))
        setFormFieldValue((formValue) => {
          let updatedValue = {
            ...formValue,
          }
          updatedValue[data?.field_id] = moment(dateWithoutHour).format('YYYY-MM-DD')
          return updatedValue
        })
      }
    }
  }, [value])

  let isFieldVisible = true

  if ((data?.field_id === 'valid_too' || data?.field_id === 'valid_from') && parentPageName === 'contractors') {
    if (selectedDropdownData?.upload_document_id?.has_expiry === 0 || !selectedDropdownData) {
      isFieldVisible = false
    }
    if (!selectedDropdownData?.upload_document_id?.value && !selectedDropdownData?.upload_document_id?.id) {
      isFieldVisible = false
    }
  }

  if (data?.field_setting?.depend_fields && currentRow) {
    const dependsData = data?.field_setting?.depend_fields
    const condition_array = dependsData?.map((dependForm) => {
      return checkSingleCondition(dependForm?.condition, currentRow[currentRow?.length - 1][dependForm?.dependent_field], parseInt(dependForm?.value))
    })
    const final_condition_array = condition_array?.filter((item) => item)
    if (final_condition_array?.length > 0) {
      isFieldVisible = true
    } else {
      isFieldVisible = false
    }
  }

  if (!isFieldVisible) return
  const isFieldInSync = checkFieldExistInSyncToUpdate(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
  let isRequiredField

  if (
    (selectedDropdownData?.upload_document_id?.label?.toString().toLowerCase()?.includes('cv') &&
      selectedDropdownData?.upload_document_id?.label?.toLowerCase()?.includes('required')) ||
    (selectedDropdownData?.upload_document_id?.name?.toString()?.toLowerCase()?.includes('cv') &&
      selectedDropdownData?.upload_document_id?.name?.toLowerCase()?.includes('required'))
  ) {
    isRequiredField = true
  }

  const onChangeDate = async (selectedDate) => {
    updateSelected(selectedDate)
    setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      updatedValue[data?.field_id] = selectedDate
      return updatedValue
    })
    addChangedFieldIdInReduxByFormName(formName, currentRow[currentRow?.length - 1]?.id, data?.field_id)
  }

  return (
    <div className='form-group'>
      <label onClick={() => (cmsEditMode && data.cms_key ? editCMS(data.cms_key) : null)}>{cms(data.cms_key)}</label>
      <InputGroup className={isFieldInSync ? 'blue-border' : ''}>
        <InputGroupAddon addonType='prepend'>
          <div className='input-group-text'>
            <FontAwesomeIcon icon={isCheckingValidation ? faSpinner : faCalendarAlt} spin={isCheckingValidation} />
          </div>
        </InputGroupAddon>

        <DatePicker
          className='form-control'
          selected={selected ? new Date(selected) : null}
          name={data.field_id}
          onFocus={() => (cmsEditMode ? editCMS(data?.cms_key) : null)}
          dateFormat={data.date_format || 'dd MMM yyyy'}
          id={id}
          validate={data.component_call_validation ? validateForAv(data?.component_call_validation, data.field_id) : {}}
          disabled={data.disabled === 1 ? true : false}
          onChange={(date) => {
            let selectedDate = null
            if (date) {
              selectedDate = new Date(date)
              selectedDate.setHours(0, 0, 0, 0)
            }
            onChangeDate(moment(selectedDate).format('YYYY-MM-DD'))
          }}
          popperPlacement='bottom-end'
        />
      </InputGroup>
      <AvField
        type='hidden'
        value={selected}
        name={data.field_id}
        required={isRequiredField}
        validate={data.component_call_validation ? validateForAv(data.component_call_validation, data.field_id) : {}}></AvField>
    </div>
  )
}

export default connect(select)(CMSDate)
