
import React from 'react'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import { cms } from '../../../helper'

const PolicyBlock = ({ data}) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  return (
    <section  style={{marginTop: 10}}>
      <ul>
        {
          data?.map((item, index) => {
            return (
              <li className='d-flex' style={{ gap: 24, marginBottom: 24 }}>
                <span style={{ height: 28, width: 28, backgroundColor: primary_color, fontSize: 16, borderRadius: '50%', display: 'inline-block' }} className='text-white d-flex align-items-center justify-content-center'>{index + 1}</span>
                <p className='w-100 mb-0' style={{ fontSize: isDesktop ? 18 : 16, color: '#000', fontWeight: 300, maxWidth: 'calc(100% - 36px)' , width: '100%' }}>{item?.value}</p>
              </li>
            )
          })
        }
      </ul>
    </section>
  )
}

export default PolicyBlock

