

import { useSelector } from 'react-redux';
import { Row, Col, Card, CardBody, CardTitle, CardHeader, CardFooter, Container } from 'reactstrap'
import { cms, editCMS, tidyName } from '../../../helper';

const SectionHeading = ({data})=>{
  const editMode = useSelector((state) => state.CMS.editMode)

  const editCMSKey = (key)=>{
    if(editMode){
      editCMS(key)
    }
  }

  return (
    <Card className='mb-3' body inverse color={data?.background_color} style={{padding:'0.5rem'}}>
      <div className='text-white' style={{ cursor: editMode ? 'pointer' : '' }} onClick={()=>{editCMSKey(data?.cms_key_heading)}}>
        {cms(data?.cms_key_heading)}
      </div>
      <span style={{ cursor: editMode ? 'pointer' : '' }} onClick={()=>{editCMSKey(data?.cms_key_sub_heading)}}>{cms(data?.cms_key_sub_heading)}</span>
    </Card>
  )
};

export default SectionHeading;