import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'reactstrap'
import Input from '../../CMSComponent/Forms/Fields/input'
import { cms, tidyName } from '../../helper'
import RoundedSearch from '../Form/Fields/roundedSearch'
import { primary_color } from '../../Helper/uiHelper'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import { request } from '../../Redux/Sagas/requests/api'
const HeroSearch = ({ data, searchData, setSearchData, clearSearchData, setClearSearchData }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  return (
    <div
      className='locker-container d-flex justify-content-center'
      // style={{ background: '#F5FCFF' }}
      style={{ background: `url(${process.env.REACT_APP_PUBLIC_STORAGE + data?.hero_search_bg})`, backgroundSize: 'cover' }}>
      <div
        style={{
          padding: isDesktop ? '3rem 2rem' : '1rem',
          marginBlock: '3rem',
          background: 'rgb(255 255 255 / 60%)',
          width: 'fit-content',
          maxWidth: isDesktop ? '40rem' : '',
        }}>
        <div className='d-flex flex-column align-items-center'>
          {data?.hero_search_icon ? (
            <img src={process.env.REACT_APP_PUBLIC_STORAGE + data?.hero_search_icon} width={70} height={70} className='mb-2' />
          ) : (
            <i className='fa-solid fa-school' style={{ fontSize: '3rem', color: primary_color }}></i>
          )}
          {/* <i className='fa-thin fa-school' style={{ fontSize: '3rem', color: primary_color }}></i> */}
          <div class='mt-3' style={isDesktop ? { width: '40rem' } : {}}>
            <p className='text-center' style={{ color: '#8E8E8E' }}>
              {data?.heading}
            </p>
            {/* <p className='text-center'>{data?.sub_heading}</p> */}
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-center row ml-1 mr-1'>
          <Row style={isDesktop ? { width: '40rem' } : { justifyContent: 'center' }}>
            {data?.form?.[0]?.component_call_fields?.map((field) => {
              return (
                <Col md={parseInt(field?.col) || 6} style={isDesktop ? { paddingInline: '5px' } : { marginInline: '5px' }}>
                  <RoundedSearch
                    endIcon={'fa-solid fa-xmark'}
                    startIcon={field?.icon ? field?.icon : 'fa-solid fa-magnifying-glass'}
                    placeholder={tidyName(field?.name)}
                    searchData={searchData}
                    setSearchData={setSearchData}
                    field_id={field?.field_id}
                    search_keyword_exclude={data?.search_keyword_exclude}
                    search_limit_error={data?.search_limit_error}
                    min_length={data?.min_later_count ? parseInt(data?.min_later_count) : 0}
                    clearSearchData={clearSearchData}
                    setClearSearchData={setClearSearchData}
                  />
                </Col>
              )
            })}
          </Row>
        </div>
      </div>
    </div>
  )
}

export default HeroSearch
