import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'

import MainGrid from './Component/MainGrid/main'

const select = (state) => ({
  coreData: state.CMS.coreData,
  page: state.CMS.coreData.page,
})

const AdvancedGrid = ({
  content,
  coreData,
  page,
  gridData,
  pageName,
  isModel,
  currentTabData,
  parentPageName,
  addDataDetail,
  setAddDataDetail,
  setQuestionnaireAction,
  questionnaireAction,
  activeTabNumber,
  isTab,
}) => {
  const [grid, updateGrid] = useState({})
  const [selectedGrid, updateSelectedAdvancedGrid] = useState('main_grid')
  const [refreshGrid, updateRefreshGrid] = useState(false)
  const [clearFilters, updateClearFilters] = useState(false)

  useEffect(() => {
    updateGrid(content)
  }, [content, selectedGrid, coreData, coreData.page, page])

  if (content.main_grid) {
    return (
      <>
        <MainGrid
          mainPageName={parentPageName}
          actionGridData={gridData}
          selectedGrid={selectedGrid}
          updateRefreshGrid={updateRefreshGrid}
          updateClearFilters={updateClearFilters}
          refreshGrid={refreshGrid}
          clearFilters={clearFilters}
          content={content}
          parentPageName={parentPageName}
          pageName={pageName}
          isRepeatingForm={grid.type === 'REPEATING_FORM'}
          updateSelectedAdvancedGrid={updateSelectedAdvancedGrid}
          isModel={isModel}
          currentTabData={currentTabData}
          addDataDetail={addDataDetail}
          setAddDataDetail={setAddDataDetail}
          setQuestionnaireAction={setQuestionnaireAction}
          questionnaireAction={questionnaireAction}
          activeTabNumber={activeTabNumber}
          isTab={isTab}
        />
      </>
    )
  }
  return <h1>Grid In Development</h1>
}
export default connect(select)(AdvancedGrid)
