import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Col, Row } from 'reactstrap'
import Input from '../../CMSComponent/Forms/Fields/input'
import { cms, tidyName } from '../../helper'
import RoundedSearch from '../Form/Fields/roundedSearch'
import './navigationButton.scss'
import { primary_color } from '../../Helper/uiHelper'
import useMediaQuery from '../../CustomHook/useMediaQuery'
const PaymentSuccessBanner = ({ data }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  return (
    <div
      className='locker-container pt-5 pb-5'
      // style={{ background: '#F5FCFF' }}
      style={{
        background: `url(${process.env.REACT_APP_PUBLIC_STORAGE + data?.payment_success_bg})`,
        backgroundSize: 'cover',
        backgroundColor: '#e3f2ff',
      }}>
      <div
        className='d-flex flex-column align-items-center m-auto'
        style={{ width: isDesktop ? 'fit-content' : '90%', padding: isDesktop ? '2rem 5rem' : '2rem', background: 'rgb(255 255 255 / 82%)' }}>
        <div className='wizard-pulse'>
          <i
            className={'position-absolute ' + data?.icon ? data?.icon : 'fa-solid fa-solid-check'}
            style={{ fontSize: '3rem', color: 'white', scale: '0.45' }}></i>
        </div>
        <div class=' mt-3'>
          <p className='text-center h1 font-weight-bold' style={{ color: primary_color }}>
            {cms(data?.heading)}
          </p>
          <p className='text-center '>{data?.text}</p>
        </div>
      </div>
    </div>
  )
}

export default PaymentSuccessBanner
