import React from 'react'
import { primary_color } from '../../../Helper/uiHelper'

const Buttons = () => {
  return (
    <div>
      <div className='pb-3 font-weight-bold'>Lorem ipsum dolor sit amet,</div>
      <p className='font-weight-bold' style={{ color: 'grey' }}>
        Lorem ipsum dolor sit amet, consemod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consecpiscing elit, sed
        do eiusmod tempor inc u Lorem ipsum dolor sit amet, consemod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet,
        consecpiscing elit, sed do eiusmod tempor inc u
      </p>
      <div>
        <button className='btn text-white mr-5' style={{ borderRadius: '20px', background: primary_color, paddingInline: '2.5rem' }}>
          Go To Home
        </button>
        <button className='btn text-white mr-2' style={{ borderRadius: '20px', background: primary_color, paddingInline: '2.5rem' }}>
          Dowload PDF
        </button>
        <button className='btn text-white mr-2' style={{ borderRadius: '20px', background: primary_color, paddingInline: '2.5rem' }}>
          Print
        </button>
        <button className='btn text-white mr-2' style={{ borderRadius: '20px', background: primary_color, paddingInline: '2.5rem' }}>
          Send Via Email
        </button>
        <button className='btn text-white mr-2' style={{ borderRadius: '20px', background: primary_color, paddingInline: '2.5rem' }}>
          Send Via SMS
        </button>
      </div>
    </div>
  )
}

export default Buttons
