export const ADD_PUBLIC_PAGE_DATA = 'ADD_PUBLIC_PAGE_DATA'

const initialSate = {}

export const storePublicPageData = (data) => ({
  type: ADD_PUBLIC_PAGE_DATA,
  data,
})

export default (state = initialSate, action) => {
  switch (action.type) {
    case ADD_PUBLIC_PAGE_DATA:
      const { data } = action
      return {...state,...data}
    default:
  }
  return state
}
