import { Fragment } from 'react'
import { useDispatch } from 'react-redux'
import { Button } from 'reactstrap'
import { clearPageTab } from '../../../Redux/Reducers/page'

function CompleteQuestions({ onFormSubmit, isGroupedQuestionnaire, completed_all }) {
  const dispatch = useDispatch()
  return (
    <Fragment>
      <div className='form-wizard-content'>
        <div className='no-results'>
          {completed_all ? (
            <div className='sa-icon sa-success animate'>
              <span className='sa-line sa-tip animateSuccessTip' />
              <span className='sa-line sa-long animateSuccessLong' />
              <div className='sa-placeholder' />
              <div className='sa-fix' />
            </div>
          ) : (
            <div></div>
          )}

          {completed_all ? (
            <>
              <div className='results-subtitle mt-4'>Finished!</div>
              <div className='results-title'>You have completed questionnaire!</div>
            </>
          ) : (
            <div> </div>
          )}

          <div className='mt-3 mb-2' />
          {!isGroupedQuestionnaire && (
            <div className='text-center'>
              <Button color='success' size='lg' className='btn-shadow btn-wide' onClick={() => onFormSubmit()}>
                {isGroupedQuestionnaire ? <span>Submit Answers</span> : <span>Complete & Go Back To Grid</span>}
              </Button>
            </div>
          )}
        </div>
        {isGroupedQuestionnaire && (
          <div>
            <Button color='success' size='lg' className='btn-shadow btn-wide' onClick={() => onFormSubmit()}>
              <span>Submit Answers</span>
            </Button>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default CompleteQuestions
