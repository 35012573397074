import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { cms, downloadFile, downloadFiles, printPDFByContent } from '../../../helper'
import { primary_color } from '../../../Helper/uiHelper'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import SendSMS from './SendSMS'
import { request } from '../../../Redux/Sagas/requests/api'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { useEffect, useState } from 'react'
import { formatTimeFromSeconds } from '../../../Helper/paymentHelper'
import useMediaQuery from '../../../CustomHook/useMediaQuery'

const getSuccessMessage = (component_name) => {
  switch (component_name) {
    case 'download_button':
      return 'File downloaded successfully!'
    case 'print_button':
      return null
    case 'email_button':
      return 'Email sent successfully!'
    default:
      return null
  }
}
const PaymentSuccessAction = ({ activePage, setActivePage, data }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  // const [emailTimer, setEmailTimer] = useState(180)
  const [showValidation, setShowValidation] = useState(false)
  const buttons = [
    {
      icon: 'fa-solid fa-download mr-1',
      title: 'Download PDF',
      disabled: false,
      component_name: 'download_button',
    },
    {
      icon: 'fa-solid fa-print mr-1',
      title: 'Print',
      disabled: false,
      component_name: 'print_button',
    },
    {
      icon: 'fa-solid fa-envelope-open-text mr-1',
      // title: emailTimer > 0 ? `Resend Email in ${formatTimeFromSeconds(emailTimer)}` : 'Send via Email',
      title: 'Send via Email',
      // disabled: emailTimer > 0 ? true : false,
      component_name: 'email_button',
    },
    {
      icon: 'fa-regular fa-comment-dots',
      title: 'Send Via SMS',
      disabled: false,
      component_name: 'sms_button',
    },
  ]

  // useEffect(() => {
  //   if (emailTimer > 0)
  //     setTimeout(() => {
  //       setEmailTimer((prev) => prev - 1)
  //     }, 1000)
  // }, [emailTimer])

  const [sendSMSModalOpen, setSendSMSModalOpen] = useState(false)
  const [formDetails, setFormDetails] = useState({
    country_code: '+44',
    mobile_number: '',
  })

  const urlParams = new URLSearchParams(location.search)
  const payment_uuid = urlParams.get('payment_uuid')

  const onSendSMS = () => {
    if (!formDetails.mobile_number) return setShowValidation(true)
    let payload = {
      payment_uuid,
      component_name: 'sms_button',
      phone_number: formDetails?.country_code + '-' + formDetails?.mobile_number,
    }
    request('generate-pdf-and-create-message-log', 'POST', payload).then((res) => {
      if (res?.status === 200 || res?.status === 202) {
        dispatch(
          setNotification({
            type: 'success',
            message: 'SMS sent successfully!',
          })
        )
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.data?.message || 'Something went wrong',
          })
        )
      }
      setSendSMSModalOpen(!sendSMSModalOpen)
      setFormDetails({
        country_code: '',
        mobile_number: '',
      })
    })
  }

  const onButtonClick = (item) => {
    if (item?.component_name === 'sms_button') {
      setSendSMSModalOpen(true)
    } else if (item?.component_name === 'download_button') {
      let payload = {
        payment_uuid,
        component_name: item?.component_name,
      }
      downloadFiles('generate-pdf-and-create-message-log', 'invoice.pdf', payload)
    } else {
      let payload = {
        payment_uuid,
        component_name: item?.component_name,
      }
      request('generate-pdf-and-create-message-log', 'POST', payload, {}, {}, {}, { responseType: 'arraybuffer' }).then((res) => {
        if (res?.status === 200 || res?.status === 202) {
          if (item?.component_name === 'print_button') {
            printPDFByContent(res)
          }
          if (getSuccessMessage(item?.component_name)) {
            dispatch(
              setNotification({
                type: 'success',
                message: getSuccessMessage(item?.component_name),
              })
            )
          }
        } else {
          dispatch(
            setNotification({
              type: 'error',
              message: res?.data?.message || 'Something went wrong',
            })
          )
        }
      })
      if (item?.component_name === 'email_button') {
        // setEmailTimer(180)
        setActivePage(activePage.child_pages[0])
      }
    }
  }

  return (
    <div className='mt-4' style={isDesktop ? { padding: isDesktop ? '0 0 0 20%' : '0' } : { paddingInline: '1rem', marginBottom: '2rem' }}>
      <div className='mb-2 font-weight-bold'>{cms(data?.heading)}</div>
      <div className='mb-5 font-weight-bold' style={{ textAlign: 'justify', color: '#8E8E8E' }}>
        {data?.text}
      </div>
      <div style={{ display: 'grid', gridTemplateColumns: isDesktop ? '1fr 1fr' : '1fr' }}>
        {buttons?.map((item, index) => (
          <Button
            key={index}
            disabled={item.disabled}
            className='my-2 mr-2 font-weight-bold primary_button'
            style={{
              // backgroundColor: primary_color,
              // borderColor: primary_color,
              borderRadius: '20px',
              padding: '0.8rem 1.5rem',
              width: isDesktop ? 'auto' : '100%',
            }}
            onClick={() => onButtonClick(item)}>
            <i className={item?.icon}></i> {item?.title}
          </Button>
        ))}
        <Button
          className='mt-5 mr-4 font-weight-bold primary_button'
          onClick={() => {
            history.push('/')
          }}
          style={{
            borderRadius: '30px',
            padding: '0.8rem 1.5rem',
            width: isDesktop ? 'auto' : '100%',
          }}>
          Go to Home <i class='fa-solid fa-arrow-right ml-1'></i>
        </Button>
      </div>

      <Modal
        isOpen={sendSMSModalOpen}
        toggle={() => {
          setSendSMSModalOpen(!sendSMSModalOpen)
          setFormDetails({
            country_code: '',
            mobile_number: '',
          })
          setShowValidation(false)
        }}
        style={{ width: '30rem' }}>
        <ModalHeader
          toggle={() => {
            setSendSMSModalOpen(!sendSMSModalOpen)
            setFormDetails({
              country_code: '',
              mobile_number: '',
            })
            setShowValidation(false)
          }}>
          Send SMS
        </ModalHeader>
        <ModalBody>
          <SendSMS showValidation={showValidation} setShowValidation={setShowValidation} formDetails={formDetails} setFormDetails={setFormDetails} />
        </ModalBody>
        <ModalFooter>
          <Button className='my-2 mr-2 primary_button' style={{ borderRadius: '20px', padding: '0.5rem 1.5rem' }} onClick={() => onSendSMS()}>
            <i className={'fa-regular fa-comment-dots'}></i> Send SMS
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default PaymentSuccessAction
