import React, { useState } from 'react'
import Login from './PubliicAuth/Login'
import Register from './PubliicAuth/Register'
import ForgotPassword from './PubliicAuth/ForgotPassword'
import GuestCheckout from './PubliicAuth/GuestCheckout'
import { primary_color } from '../../Helper/uiHelper'
import useMediaQuery from '../../CustomHook/useMediaQuery'

const PublicUnAuthAddress = ({ userAddress, setUserAddress, userAddressValidation, setUserAddressValidation }) => {
  const [selected, setSelected] = useState(0)
  const isDesktop = useMediaQuery('(min-width:768px)')

  const pages = [
    { name: 'Login', component: <Login showValidation={userAddressValidation} setShowValidation={setUserAddressValidation} /> },
    {
      name: 'Register',
      component: <Register title={'Register now!'} showValidation={userAddressValidation} setShowValidation={setUserAddressValidation} />,
    },
    // { name: 'Forgot Password', component: <ForgotPassword /> },
    {
      name: 'Guest Checkout',
      component: (
        <GuestCheckout
          showValidation={userAddressValidation}
          userAddress={userAddress}
          setUserAddress={setUserAddress}
          setShowValidation={setUserAddressValidation}
        />
      ),
    },
  ]
  return (
    <div className={`col-md-9 ml-auto mr-auto pb-5 ${isDesktop ? 'mt-4' : 'my-3'}`} style={{ borderBottom: '1px solid lightgrey' }}>
      <div className={`d-flex ${isDesktop ? 'mb-5' : 'mb-3'}`} style={{ gap: '1rem' }}>
        {pages.map((element, index) => {
          return (
            <button
              key={index}
              className='btn font-weight-bold'
              onClick={() => {
                setUserAddressValidation(false)
                setSelected(index)
              }}
              style={
                index === selected
                  ? {
                      color: primary_color,
                      borderBottom: `2px solid ${primary_color}`,
                      fontSize: isDesktop ? '1rem' : '0.85rem',
                    }
                  : { color: 'grey', fontSize: isDesktop ? '1rem' : '0.85rem' }
              }>
              {element.name}
            </button>
          )
        })}
      </div>

      <div className=''>{pages[selected].component}</div>
    </div>
  )
}

export default PublicUnAuthAddress
