import React, { useEffect, useState, Fragment } from 'react'

import { cms, dataValidationForField, editCMS } from '../../../helper'
import { connect, useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'

import { Input, InputGroup, InputGroupAddon } from 'reactstrap'
import { faCalendarAlt, faClock, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AvField } from 'availity-reactstrap-validation'

const select = (state) => ({
  cmsEditMode: state.CMS.editMode,
})

const CMSTimer = ({
  data,
  cmsEditMode,
  id,
  defaultValue,
  formFieldValue,
  setFormFieldValue = () => {},
  source_table,
  formData,
  validationErrorOnFieldValueChange,
  setValidationErrorOnFieldValueChange = () => {},
  isTable,
  onChangeAction = () => {},

}) => {
  const [value, setValue] = useState()
  const [inputValue, setInputValue] = useState({
    hour: null,
    minute: null,
  })
  const [isValidatingField, setIsValidatingField] = useState(false)
  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)
  const currentRow = useSelector((state) => state.CMS.currentRow)

  useEffect(() => {
    if (defaultValue) {
      const hour = defaultValue?.split(':')?.[0]
      const minute = defaultValue?.split(':')?.[1]
      setFormFieldValue((formValue) => {
        let updatedValue = {
          ...formValue,
        }
        updatedValue[data?.field_id] = defaultValue
        return updatedValue
      })

      setInputValue({
        hour,
        minute,
      })
    }
  }, [defaultValue, data])

  const onChangeValue = (value, type) => {
    setIsValidatingField(true)
    let newValue = {
      ...inputValue,
    }
    newValue[type] = value
    if (type === 'hour' && !newValue?.minute) {
      newValue['minute'] = '00'
    }
    const valueToSubmit = `${newValue?.hour}${newValue?.minute ? `:${newValue?.minute}` : ''}`

    setIsValidatingField(false)
    setValidationErrorOnFieldValueChange([])
    setInputValue(newValue)
    setValue(valueToSubmit)
    setFormFieldValue((formValue) => {
      let updatedValue = {
        ...formValue,
      }
      updatedValue[data?.field_id] = valueToSubmit
      return updatedValue
    })
    onChangeAction('', '', '', valueToSubmit)
  }

  return (
    <Fragment>
      {!isTable && <label onClick={() => (cmsEditMode && data.cms_key ? editCMS(data.cms_key) : null)}>{cms(data.cms_key)}</label>}

      <InputGroup>
        {!isTable && (
          <InputGroupAddon addonType='prepend'>
            <div className='input-group-text'>
              <FontAwesomeIcon icon={isValidatingField ? faSpinner : faClock} spin={isValidatingField} />
            </div>
          </InputGroupAddon>
        )}

        <Input type='select' value={inputValue?.hour} onChange={(e) => onChangeValue(e.target.value, 'hour')}>
          <option>Hour</option>
          {Array.from({ length: 25 }, (_, i) => {
            if (i < 10) {
              return '0' + i
            } else {
              return `${i}`
            }
          }).map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            )
          })}
        </Input>
        <Input type='select' value={inputValue?.minute} onChange={(e) => onChangeValue(e.target.value, 'minute')}>
          <option>Minute</option>
          {Array.from({ length: 61 }, (_, i) => {
            if (i < 10) {
              return '0' + i
            } else {
              return `${i}`
            }
          }).map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            )
          })}
        </Input>
        {/* <AvField type='select' name='hour' ></AvField>
        <AvField type='select' name='minute'></AvField> */}
        {/* <DatePicker selected={value} onChange={handleChange} showTimeSelect showTimeSelectOnly
          className="form-control" timeIntervals={15} timeCaption="Time" dateFormat="h:mm" /> */}
      </InputGroup>
      <AvField type='hidden' name={data?.field_id || data?.name} value={value}></AvField>
    </Fragment>
  )
}

export default connect(select)(CMSTimer)
