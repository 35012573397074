import DatePicker from 'react-datepicker'

const DateTimeTableFilter = ({value,updateValue,triggerSearch,type}) => {
  

  switch (type) {
    case 'datetime':
    case 'date-time':
      
       return <DatePicker
         selected={value}
         className='header-filter-date remove-outline'
         dateFormat='d MMMM , yyyy HH:mm a'
         placeholderText='Search date...'
         amPmAriaLabel={false}
         showTimeInput
         // readOnly={!inlineEditActive}
         onChange={(e) => {
           updateValue(e)
           triggerSearch(e)
         }}
       />
    case 'date':
       return (
         <DatePicker
           selected={value}
           className='header-filter-date remove-outline'
           dateFormat='d MMMM , yyyy HH:mm a'
           placeholderText='Search date...'
           // readOnly={!inlineEditActive}
           onChange={(e) => {
             updateValue(e)
             triggerSearch(e)
           }}
         />
       )
      default:
         return (
           <DatePicker
             selected={value}
             className='header-filter-date remove-outline'
             dateFormat='d MMMM , yyyy HH:mm a'
             placeholderText='Search date...'
             // readOnly={!inlineEditActive}
             onChange={(e) => {
               updateValue(e)
               triggerSearch(e)
             }}
           />
         )
  }
 
}

export default DateTimeTableFilter
