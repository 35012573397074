export const checkValidationForPayment = (address, cardDetails, isLoggedIn) => {
  if (isLoggedIn) {
    if (!address?.id) {
      return {
        error: true,
        field: 'address_id',
        message: 'Please select any address',
      }
    }
  }
  if (!isLoggedIn) {
    if (!address?.customer_email) {
      return {
        error: true,
        field: 'customer_email',
        message: 'Please enter email',
      }
    }

    if (!address?.address1) {
      return {
        error: true,
        field: 'customer_email',
        message: 'Please enter address',
      }
    }
    if (!address?.city) {
      return {
        error: true,
        field: 'customer_email',
        message: 'Please enter city',
      }
    }
    if (!address?.country) {
      return {
        error: true,
        field: 'customer_email',
        message: 'Please enter country',
      }
    }
    if (!address?.post_code) {
      return {
        error: true,
        field: 'customer_email',
        message: 'Please enter post code',
      }
    }
    if (!address?.customer_email || !address?.address1 || !address?.city || !address?.country || !address?.post_code) {
      return {
        error: true,
        field: 'customer_email',
        message: 'Please fill all required fields',
      }
    }
  }

  // if(!cardDetails?.cardNumber){
  //    return {
  //      error: true,
  //      field: 'cardNumber',
  //      message: 'Please add card number',
  //    }
  // }

  //  if (!cardDetails?.cardholderName) {
  //    return {
  //      error: true,
  //      field: 'cardholderName',
  //      message: 'Please add card holder name',
  //    }
  //  }
  //  if (!cardDetails?.cardNumber) {
  //    return {
  //      error: true,
  //      field: 'cardNumber',
  //      message: 'Please add card number',
  //    }
  //  }
  //  if (!cardDetails?.securityCode || cardDetails?.securityCode?.length > 3) {
  //    return {
  //      error: true,
  //      field: 'securityCode',
  //      message: 'Please add proper CVV',
  //    }
  //  }

  //  if(!cardDetails?.month || !cardDetails?.year){
  //   return {
  //     error: true,
  //     field: 'expiryDate',
  //     message: 'Please add correct expiry date',
  //   }
  //  }

  return {
    error: false,
  }
}
export const formatTimeFromSeconds = (time) => {
  const minutes = Math.floor(time / 60)
  const remainingSeconds = time % 60
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
  return `${formattedMinutes}min:${formattedSeconds}sec`
}
