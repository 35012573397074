import React, { useEffect, useState } from 'react'

import { Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Label, Input } from 'reactstrap'

import { AvField } from 'availity-reactstrap-validation'
import { connect, useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import AvRadio from 'availity-reactstrap-validation/lib/AvRadio'
import AvRadioGroup from 'availity-reactstrap-validation/lib/AvRadioGroup'

import { capitalize, cms, editCMS, getFromRedux, tidyName, validateForAv } from '../../../helper'
import ToggleField from './toggleField'
import { request } from '../../../Redux/Sagas/requests/api'
import { setLoader } from '../../../Redux/Reducers/loader'
import { enabledOrDisabledMethodTabAction, setSelectedDropdownData, setTableDropDown } from '../../../Redux/Reducers/CMS'

const checkSingleCondition = (condition, formValue, compareValue) => {
  switch (condition) {
    case 'equals':
      if (formValue == compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than':
      if (formValue < compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than_and_equal':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'greater_than':
      if (formValue >= compareValue) {
        return true
      } else {
        return false
      }
    case 'less_than_and_equal':
      if (formValue <= compareValue) {
        return true
      } else {
        return false
      }
  }
}

const AddOption = ({ addDropDownOpen }) => {
  return (
    <Dropdown isOpen={addDropDownOpen}>
      {/* <DropdownToggle caret><i class="fa-solid fa-plus-large"></i></DropdownToggle> */}
      <DropdownMenu center>
        <DropdownItem text>
          Some Action <input></input>
        </DropdownItem>
        <DropdownItem text>Dropdown Item Text</DropdownItem>
      </DropdownMenu>
    </Dropdown>
  )
}

const select = (state) => ({
  coreData: state.CMS.coreData,
  cmsEditMode: state.CMS.editMode,
  currentRow: state.CMS.currentRow,
})

const CMSRadio = ({
  data,
  onChangeAction,
  updateKeysForReduxData,
  fieldKey,
  toggleActions,
  cmsEditMode,
  id,
  value,
  isTable,
  index,
  inlineEditActive = true,
  currentRow,
  ...props
}) => {
  const [reduxData, updateReduxData] = useState(undefined)
  const [selectOptions, updateOptions] = useState([])
  const [selectedOption, updateSelectedOption] = useState()
  const tableOptions = useSelector((state) => state.CMS.tableDropDown)
  const dispatch = useDispatch()
  const [addDropDownOpen, setAddDropDownOpen] = useState(false)
  const [selected, setSelected] = useState([])
  const selectedDropdownData = useSelector((state) => state.CMS.selectedDropdownData)

  const enabledOrDisabledMethodTab = useSelector((state) => state.CMS.enabledOrDisabledMethodTab)

  //enable or disabled tab logic

  //enable_or_disable_method_tab_on_submit
  useEffect(() => {
    if (!value && !selectedOption?.value) return
    if (data?.component_call_actions) {
      if (Array.isArray(data?.component_call_actions)) {
        const newTabsToDisabled = [...enabledOrDisabledMethodTab]
        data?.component_call_actions?.forEach((action) => {
          if (action?.component_name === 'enable_or_disable_method_tab' && selectedOption?.value === action?.if_value && action?.enable_field) {
            const index = newTabsToDisabled?.indexOf(action.method_name)

            if (index !== -1) {
              newTabsToDisabled?.splice(index, 1)
            }
          } else if (
            action?.component_name === 'enable_or_disable_method_tab' &&
            selectedOption?.value !== action?.if_value &&
            action?.enable_field
          ) {
            if (!newTabsToDisabled?.includes(action.method_name)) {
              newTabsToDisabled?.push(action.method_name)
            }

            //  dispacth(enabledOrDisabledMethodTabAction(newTabsToDisabled))
          } else if (
            action?.component_name === 'enable_or_disable_method_tab_on_submit' &&
            selectedOption?.value === action?.if_value &&
            action?.enable_field &&
            ((currentRow?.length > 0 && currentRow?.[currentRow?.length - 1]?.[data?.field_id] === action?.if_value) || currentRow?.length === 0)
          ) {
            const index = newTabsToDisabled?.indexOf(action.method_name)

            if (index !== -1) {
              newTabsToDisabled?.splice(index, 1)
            }
          } else if (
            action?.component_name === 'enable_or_disable_method_tab_on_submit' &&
            selectedOption?.value !== action?.if_value &&
            ((currentRow?.length > 0 && currentRow?.[currentRow?.length - 1]?.[data?.field_id] !== action?.if_value) || currentRow?.length === 0) &&
            action?.enable_field
          ) {
            if (!newTabsToDisabled?.includes(action.method_name)) {
              newTabsToDisabled?.push(action.method_name)
            }

            //  dispacth(enabledOrDisabledMethodTabAction(newTabsToDisabled))
          }
        })
        if (JSON.stringify(enabledOrDisabledMethodTab) !== JSON.stringify(newTabsToDisabled)) {
          dispatch(enabledOrDisabledMethodTabAction(newTabsToDisabled))
        }
      }
    }
  }, [value, selectedOption])

  const renderOption = async (data_conection, data) => {
    if (data_conection) {
      if (data_conection.length > 0) {
        data_conection?.map((connection) => {
          if (connection.component_name === 'redux_data') {
            const getReduxData = getFromRedux(connection.redux_data)
            updateReduxData(getReduxData)
            let options = []
            if (getReduxData && typeof getReduxData.length === 'number') {
              getReduxData.map((item) => {
                options.push({
                  value: item[connection.value],
                  label: item[connection.data],
                })
              })
            }
            updateOptions(options)

            const default_value = options?.filter((item) => item.value === value || item.label === value)[0]

            updateSelectedOption(default_value)

            updateKeysForReduxData({ value: connection.value, data: connection.data }, fieldKey)
          }
        })
      } else if (data_conection.component_name === 'template_data') {
        if (!isTable || (isTable && index === 0 && !tableOptions[data?.field_id])) {
          dispatch(setLoader(true))
          const response = await request('connect-template-data', 'post', data_conection)
          dispatch(setLoader(false))
          if (response?.data?.data) {
            let options = []
            const is_signle_field = data_conection.fields?.split(',')?.length === 1 ? true : false
            response?.data?.data.map((item) => {
              if (is_signle_field) {
                options.push({
                  value: item?.id,
                  label: item[data_conection.fields],
                })
              } else {
                options.push({
                  value: item[data_conection.fields?.split(',')[0]],
                  label: item[data_conection.fields?.split(',')[1]],
                })
              }
            })
            updateOptions(options)
            let payload = {}
            payload[data?.field_id] = options
            dispatch(setTableDropDown(payload))
            let default_value
            if (isTable) {
              default_value = options?.filter((item) => item.value === value || item.label === value)[0]
            } else {
              default_value = options?.filter((item) => item.value === value || item.label === value)[0]
              updateSelectedOption(default_value)
            }
            updateSelectedOption(default_value)
          }
        } else {
          updateOptions(tableOptions[data?.field_id])
        }
      } else if (data_conection.component_name === 'manual') {
        if (data_conection?.default_options) {
          let default_options = []

          if (Array.isArray(data_conection?.default_options)) {
            default_options = data_conection?.default_options?.map((item) => {
              return {
                value: item,
                label: tidyName(capitalize(item)),
              }
            })
          } else {
            Object.keys(data_conection?.default_options)?.map((item) => {
              default_options?.push({
                value: item,
                label: tidyName(capitalize(data_conection?.default_options[item])),
              })
            })
          }
          updateOptions(default_options)
          const default_value = default_options?.filter((item) => item.value === value || item.label === value)[0]

          updateSelectedOption(default_value)
        }
      }
    } else if (data.values) {
      let options = []
      let hasKey = true
      for (const property in data.values) {
        if (property == 0) {
          hasKey = false
        }
        options.push({
          value: hasKey ? property : data.values[property],
          label: tidyName(data.values[property]),
        })
      }
      updateOptions(options)
    }
  }

  useEffect(() => {
    renderOption(data.component_call_data_connection, data)
  }, [JSON.stringify(data)])

  useEffect(() => {
    if (data.selectData && data.dataKeys) {
      let options = []
      data.selectData.map((item) => {
        options.push({
          id: item[data.dataKeys.value],
          name: item[data.dataKeys.data],
        })
      })
      updateOptions(options)
    }
    if (isTable || true) {
      const default_value = selectOptions?.filter((item) => item.value === value || item.label === value)[0]

      updateSelectedOption(default_value)
    }
  }, [value, JSON.stringify(selectOptions)])

  useEffect(() => {
    if (isTable && tableOptions[data?.field_id]) {
      updateOptions(tableOptions[data?.field_id])

      const default_value = selectOptions?.filter((item) => item.value === value || item.label === value)[0]
      updateSelectedOption(default_value)
    }
  }, [tableOptions[data?.field_id]])

  //update sel;ected option from selectDrodpdowndata

  useEffect(() => {
    if (!selectedOption && !value) {
      //select default first option when validation required is there. need to add validation condition
      updateSelectedOption(selectOptions?.[0])
    }
  }, [selectedDropdownData])

  if (!inlineEditActive) {
    if (data.field_setting?.type === 'enum') {
      let haveKey = true
      const selectedValue = data.field_setting?.values?.filter((item, key) => {
        if (key == 0) {
          haveKey = false
        }
        if (haveKey) {
          return key === value
        } else {
          return item === value
        }
      })
      return selectedValue.length > 0 ? tidyName(selectedValue[0]) : 'no'
    } else if (selectOptions?.length > 0 && value) {
      const selectedValue = selectOptions.filter((item) => item.value === value)
      return selectedValue.length > 0 ? selectedValue[0].label : 'no'
    } else {
      return value
    }
  }

  let isFieldVisible = true

  if (data?.field_setting?.depend_fields && data?.field_setting?.depend_fields?.length > 0 && currentRow) {
    const dependsData = data?.field_setting?.depend_fields
    const condition_array = dependsData?.map((dependForm) => {
      return checkSingleCondition(dependForm?.condition, currentRow[dependForm?.dependent_field], parseInt(dependForm?.value))
    })
    const final_condition_array = condition_array?.filter((item) => item)
    if (final_condition_array?.length > 0) {
      isFieldVisible = true
    } else {
      isFieldVisible = false
    }
  }

  const onChangeRadio = (value, checked) => {
    if (checked) {
      const selectedOption = selectOptions?.find((option) => option?.value === value)
      updateSelectedOption(selectedOption)

      let newSelectedDropdownData = {
        ...selectedDropdownData,
      }

      newSelectedDropdownData[data?.field_id] = value
      dispatch(setSelectedDropdownData(newSelectedDropdownData))
    }
  }

  if (!isFieldVisible) return

  return (
    <>
      <div>
        <label onClick={() => (cmsEditMode && data.cms_key ? editCMS(data.cms_key) : null)}>
          {cms(data.cms_key)} {data.component_call_fields && <ToggleField data={data} toggleActions={toggleActions} />}
        </label>
        <Row form>
          <Col md={12} style={{ maxHeight: '5rem', overflow: 'auto' }}>
            <Form className='radio-field-wrapper'>
              {/* <FormGroup check> */}
              <AvRadioGroup name={data?.field_id} value={selectedOption?.value}>
                {selectOptions?.map((item, key) => (
                  <>
                    {/* <AvCheckbox label={item?.label} value={item?.value} /> */}
                    <AvRadio
                      key={item?.value}
                      label={item?.label}
                      value={item?.value}
                      onChange={(e) => onChangeRadio(e.target.value, e.target.checked)}
                    />

                    {/* <Input
                      type='checkbox'
                      value={item.value}
                      name='multi_checkbox'
                      id={item.value}
                      onChange={(e) => onCheck(item.value, e.target.checked)}
                    />
                    <Label for={item.value}>{item.label}</Label> */}
                  </>
                ))}
              </AvRadioGroup>
              {/* </FormGroup> */}
            </Form>
          </Col>
        </Row>
      </div>
      {/* <AvField
        type="select"
        name={data.field_id}
        id={id}
        onChange={(e) =>
          changeOption(
            e.target.value,
            data.component_call_actions,
            data.field_id
          )
        }
        validate={
          data.component_call_validation
            ? validateForAv(data.component_call_validation, data.field_id)
            : {}
        }
        disabled={data.disabled === 1 ? true : false}
      >
        {data.selected_value && <option value="">{data.selected_value}</option>}
        {selectOptions?.map((item, key) => (
          <option key={key} value={item.id}>
            {item.name}
          </option>
        ))}
      </AvField> */}
    </>
  )
}

export default connect(select)(CMSRadio)
