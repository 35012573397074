import React, { Fragment, useState } from 'react'
import { Button } from 'reactstrap'
import PrivacyPolicy from '../Sections/privacypolicy'
import TermsConditions from '../Sections/termsconditions'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import LogoWhite from '../../assets/utils/images/logo-white.png'
import { primary_color } from '../../Helper/uiHelper'
const PublicFooter = () => {
  const policies = ['Privacy Policy', 'Terms & Conditions', 'Cookie Policy', 'Contact']
  const policyItems = {
    'Privacy Policy': <PrivacyPolicy />,
    'Terms & Conditions': <TermsConditions />,
    'Cookie Policy': 'Cookie Policy',
    Contact: 'Contact',
  }

  const [selectedData, setSelectedData] = useState('')
  const isDesktop = useMediaQuery('(min-width:768px)')

  const handleItemClick = (item) => {
    setSelectedData(policyItems[item])
  }

  return (
    <Fragment>
      <div
        className={`footer d-flex justify-content-center ${isDesktop ? 'align-items-end' : ''}`}
        style={{
          height: isDesktop ? '11rem' : 'auto',
          background: primary_color,
          color: '#fff',
          position: 'relative',
          bottom: '0',
          left: '0',
          right: '0',
        }}>
        <div className={`row app-footer__inner  ${isDesktop ? 'align-items-end mb-4' : 'mb-3 flex-column'}`} style={{ width: '80%' }}>
          <div className={` app-footer-left text-left ${isDesktop ? 'col-6 d-flex align-items-center' : ''}`}>
            <div className={` ${isDesktop ? ' col-6 d-flex flex-column ml-3' : 'mt-4'} `}>
              <img
                alt='logo'
                style={{ mixBlendMode: 'plus-lighter', marginBottom: isDesktop ? '0' : '1rem' }}
                src={LogoWhite}
                height='50px'
                width='175px'
              />

              <p className={isDesktop ? 'mb-0' : ''}>Copyright 2022 Locker.rental</p>
            </div>
          </div>
          <div className={` ${isDesktop ? 'col-6 d-flex justify-content-end' : ''}`}>
            {policies.map((ele, index) => (
              <div
                key={ele}
                className={`mr-${index === policies.length - 1 ? '0' : '4'} ${isDesktop ? '' : 'mb-4 !mr-0'}`}
                onClick={() => handleItemClick(ele)}
                style={{ cursor: 'pointer' }}>
                <a href='#' style={{ color: '#fff' }}>
                  {ele}
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
      {selectedData && <div className='data'>{selectedData}</div>}
    </Fragment>
  )
}

export default PublicFooter
