import { Fragment, useEffect, useState } from 'react'
import { FormGroup, Label } from 'reactstrap'
import CommonFields from './commonFields'
// import { Multiselect } from 'react-widgets'
import Select from 'react-select'

function MultiSelectBox({ item, answers, setAnswers, requiredError }) {
  const [defaultValue, setValue] = useState([])
  const [selectOptions, setSelectOptions] = useState([])

  const current_answer_object = answers?.filter((data) => data?.item_question_id === item?.id)[0] || {}

  useEffect(() => {
    const options = []
    {
      item.item_answers?.map((item, key) => {
        options.push({ value: item.id, label: item.answer })
      })
    }
    setSelectOptions(options)

    if (item.item_answer_responses?.length > 0) {
      const values = []
      item.item_answer_responses.map((answer) => {
        values.push({ value: answer.item_answer_id, label: answer.response_text })
      })
      setValue(values)
    }
  }, [])

  const onChangeValue = (v) => {
    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        const ans = v?.map((item) => {
          return {
            answer_id: item?.value,
            response_text: item?.label,
          }
        })
        return {
          ...question,
          answer_id: ans,
        }
      } else {
        return question
      }
    })
    setAnswers(updated_answers)
  }

  useEffect(() => {
    // console.log("current object:::::::",current_answer_object)
    const selectedOptionFromAnswersObject = current_answer_object?.answer_id
    const selected_option = selectedOptionFromAnswersObject?.map((item) => {
      return {
        value: item?.answer_id,
        label: item?.response_text,
      }
    })

    setValue(selected_option)
  }, [JSON.stringify(current_answer_object)])
  // console.log("multi value:::::::",defaultValue)
  return (
    <Fragment>
      <div>
        <FormGroup>
          <Label>{item.question}?</Label>
          <Select
            onChange={(e) => onChangeValue(e)}
            value={defaultValue}
            isMulti
            options={[...selectOptions]}
            className='basic-multi-select'
            classNamePrefix='select'
          />
        </FormGroup>
        <CommonFields
          item={item}
          selectedAnswer={defaultValue?.map((item) => item.value)}
          answers={answers}
          setAnswers={setAnswers}
          current_answer_object={current_answer_object}
          isArray={true}
        />
        {item?.validations?.includes('required') &&
          requiredError &&
          (!current_answer_object?.answer_id || current_answer_object?.answer_id?.length === 0) && (
            <div style={{ color: 'red' }}>Please Complete</div>
          )}
      </div>
    </Fragment>
  )
}

export default MultiSelectBox
