import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import BookMyLocker from '../../../Components/Sections/bookmylocker'
import WizardTab from '../../../Components/Widget/wizardTab'
import { cms } from '../../../helper'
import { checkSideBarVisible, getPublicPageData } from '../../../Helper/publicPageHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { DummyPage, dummyPayment, dummyPaymentSuccessPage } from './dummyPage'
// import DummyPage from './dummyPage2'
import PublicPageSidebar from './publicPageSideBar'
import PublicSection from './publicSection'
import Loader from 'react-loaders'
import { primary_color } from '../../../Helper/uiHelper'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useDispatch, useSelector } from 'react-redux'
import { TermsPageData } from './terms'

const getWizardTab = (forms, activePage) => {
  const tabs = []
  let isActiveFound = false
  forms?.map((form) => {
    tabs?.push({
      name: cms(form?.cms_header_name),
      icon: form?.icon,
      isActive: activePage?.name === form?.name ? true : false,
      isVisited: activePage?.name !== form?.name && !isActiveFound,
      uuid: form?.uuid,
    })
    if (activePage?.name === form?.name) {
      isActiveFound = true
    }
  })
  return tabs
}

const PublicPage = ({ page }) => {
  const [pageData, setPageData] = useState()
  const [activePage, setActivePage] = useState()
  const location = useLocation()
  const history = useHistory()
  const searchParams = new URLSearchParams(location.search)

  const isDesktop = useMediaQuery('(min-width:768px)')
  const [parentData, setParentData] = useState()
  const [searchData, setSearchData] = useState({})
  const [filterData, setFilterData] = useState({})
  const [couponCode, setCouponCode] = useState()
  const [clearSearchData, setClearSearchData] = useState([])
  const [searchResultsAvailable, setSearchResultsAvailable] = useState(true)
  const [bookingDetails, setBookingDetails] = useState()
  const [formsData, setFormData] = useState([
    {
      category_id: '',
      item_date_time_id: '',
      location_id: '',
    },
  ])
  const [unitData, setUnitData] = useState([])
  const [searchResults, setSearchResults] = useState(null)

  const publicPageData = useSelector((state) => state.publicPageData)
  const dispatch = useDispatch()

  const fetchPageData = async () => {
    const page_id = searchParams.get('page_id')
    const parent_id = searchParams.get('parent_id')
    try {
      const data = await getPublicPageData(page)
      if (data) {
        setPageData(data)
        if (page_id && parent_id) {
          const child_page = data?.child_pages?.find((page) => page?.uuid === page_id)
          setActivePage(child_page)
          setParentData({ id: parent_id })
        } else {
          setActivePage(data)
          // setActivePage(DummyPage)

        }
      } else {
        console.error('Error: Retrieved data is null or undefined')
      }
    } catch (error) {
      console.error('Error fetching page data:', error)
    }
  }

  useEffect(() => {
    if (page?.page_uuid) {
      fetchPageData()
      // setPageData(TermsPageData)
      // setActivePage(TermsPageData)

      // if (window.location.pathname?.includes('payment')) {
      //   setPageData(dummyPaymentSuccessPage)
      //   setActivePage(dummyPaymentSuccessPage)
      // } else {
      //   // fetchPageData()
      //   setPageData(DummyPage)
      //   setActivePage(DummyPage)
      // }

      // setParentData()
    }
  }, [page?.page_uuid])

  const onGoBackToHomePage = () => {
    history.push(window.location.pathname)
    // setActivePage(pageData)
    // setParentData()

    setSearchData({})
    setFilterData({})
  }
  const wizardForm = pageData ? [pageData, ...pageData?.child_pages] : []
  const wizard_tab = getWizardTab(wizardForm, activePage)

  if (!pageData)
    return (
      <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
        <Loader type='ball-scale-multiple' color={primary_color} style={{ opacity: '0.5', transform: 'scale(1.5)' }} />
      </div>
    )
  const isSidebarVisible = activePage?.sidebar_pages?.length > 0 && checkSideBarVisible(parentData, searchData, filterData, searchResultsAvailable)
  return (
    <div className='d-flex'>
      {isSidebarVisible && (
        <PublicPageSidebar
          pages={activePage?.sidebar_pages}
          parentData={parentData}
          searchData={searchData}
          setSearchData={setSearchData}
          setParentData={setParentData}
          setActivePage={setActivePage}
          filterData={filterData}
          setFilterData={setFilterData}
          activePage={activePage}
          pageData={pageData}
          onGoBackToHomePage={onGoBackToHomePage}
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          bookingDetails={bookingDetails}
          setBookingDetails={setBookingDetails}
          formsData={formsData}
          setFormData={setFormData}
          unitData={unitData}
          setUnitData={setUnitData}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
        />
      )}
      <Row
        style={{
          flex: 1,
          height: 'fit-content',
          paddingBottom: isDesktop ? '2rem' : '0',
          paddingLeft: isDesktop ? '0' : isSidebarVisible ? '2.5rem' : '0',
          width: 'calc(100% - 300px)'
        }}
        className='ml-0 mr-0'>
        {parentData && pageData?.child_pages?.length > 0 && (
          <Col md={9} className='ml-auto mr-auto'>
            <WizardTab activePage={activePage} setActivePage={setActivePage} tabs={wizard_tab} pageData={pageData} />
          </Col>
        )}
        {activePage?.component_call_sections?.map((section, index) => {
          return (
            <Col key={index} md={parseInt(section?.col) || 6} className='pl-0 pr-0' style={isDesktop ? {} : { paddingInline: '0' }}>
              <PublicSection
                section={section}
                searchData={searchData}
                setSearchData={setSearchData}
                filterData={filterData}
                setFilterData={setFilterData}
                setActivePage={setActivePage}
                activePage={activePage}
                parentData={parentData}
                setParentData={setParentData}
                pageData={pageData}
                onGoBackToHomePage={onGoBackToHomePage}
                clearSearchData={clearSearchData}
                setClearSearchData={setClearSearchData}
                searchResultsAvailable={searchResultsAvailable}
                setSearchResultsAvailable={setSearchResultsAvailable}
                couponCode={couponCode}
                setCouponCode={setCouponCode}
                bookingDetails={bookingDetails}
                setBookingDetails={setBookingDetails}
                formsData={formsData}
                setFormData={setFormData}
                unitData={unitData}
                setUnitData={setUnitData}
                searchResults={searchResults}
                setSearchResults={setSearchResults}
              />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default PublicPage
