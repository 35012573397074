import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import '../../../Components/Form/Fields/roundedInput.scss'
import { cms } from '../../../helper'
import { checkValidationForPayment } from '../../../Helper/paymentHelper'
import { useDispatch, useSelector } from 'react-redux'
import { clearNotification, setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'
import { getLocalStorageItem, setLocalStorageItem } from '../../../Helper/cookieHelper'
import PaymentConfirm from './paymentVerification'
import { primary_color } from '../../../Helper/uiHelper'
import { useHistory } from 'react-router-dom'
import TermsConditions from '../../Sections/termsconditions'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import Select from 'react-select'
import { select } from 'redux-saga/effects'

const checkExpiryValidation = (enteredExpiry) => {
  // Example: March 2025
  if (!enteredExpiry) return true
  // Get the current date
  const currentDate = new Date()

  // Extract month and year from entered expiry
  const enteredMonth = parseInt(enteredExpiry.slice(0, 2), 10) // Extracts 'MM' part and converts to integer
  const enteredYear = parseInt(enteredExpiry.slice(2), 10) // Extracts 'YY' part and converts to integer

  // Add 1 to the month because JavaScript months are zero-based (0-January, 1-February, ...)
  const adjustedEnteredDate = new Date(2000 + enteredYear, enteredMonth - 1, 1) // 2000 is added to the year for YY to YYYY conversion

  adjustedEnteredDate.setMonth(adjustedEnteredDate.getMonth() + 1)
  adjustedEnteredDate.setDate(0)
  // Check if entered expiry date is greater than or equal to current date
  if (adjustedEnteredDate > currentDate) {
    return true
  } else {
    return false
  }
  return true
}
const getPayload = (user, userAddress, cardDetails, uuid) => {
  if (!user?.email) {
    return {
      cardNumber: cardDetails?.cardNumber,
      cardholderName: cardDetails?.cardholderName,
      expiryDate: `${cardDetails?.month}${cardDetails?.year?.slice(2)}`,
      securityCode: cardDetails?.securityCode,
      description: 'shopping',
      customerFirstName: userAddress?.customerFirstName,
      customerLastName: userAddress?.customerLastName,
      address1: userAddress?.address1,
      address2: userAddress?.address1,
      city: userAddress?.city,
      // country: userAddress?.country,
      country: 'GB',
      state: 'Devon',
      // postalCode: parseInt(userAddress?.post_code),
      postalCode: userAddress?.post_code,
      customer_email: userAddress?.customer_email,
      booking_id: 2,
      currency_id: 1,
      return_type: 'json',
      //address_id:15
      uuid: uuid,
      //user_id:1
    }
  } else {
    return {
      cardNumber: cardDetails?.cardNumber,
      cardholderName: cardDetails?.cardholderName,
      expiryDate: `${cardDetails?.month}${cardDetails?.year?.slice(2)}`,
      securityCode: cardDetails?.securityCode,
      description: 'shopping',
      state: 'Devon',
      booking_id: 2,
      currency_id: 1,
      return_type: 'json',
      address_id: userAddress?.id,
      uuid: uuid,
      user_id: user?.id,
    }
  }
}

const DUMMY_PAYLOAD = {
  cardNumber: '4929000000006',
  cardholderName: 'CHALLENGE',
  expiryDate: '1223',
  securityCode: '123',
  description: 'shopping',
  customerFirstName: 'CHALLENGE',
  customerLastName: 'sheikh',
  address1: 'Russell St',
  address2: 'Russell St',
  city: 'Tavistock',
  country: 'GB',
  state: 'Devon',
  postalCode: 412,
  customer_email: 'nadim.sheikh.07@gmail.com',
  booking_id: 2,
  currency_id: 1,
  return_type: 'json',
  //address_id:15
  uuid: 'c385890b-cb32-4b34-b270-aa77b48b4874',
  //user_id:1
  amount: 100,
}

const dummy_checkbox_data = [
  { label: 'I would like to receive future communications from Secure Lockers.', value: 1 },
  { label: 'I would like the option to renew a locker.', value: 2 },
  // { label: 'Accept Terms & Conditions.', value: 3 },
]

const PaymentMethod = ({
  form,
  data,
  userAddress,
  parentData,
  pageData,
  setActivePage,
  setParentData,
  onGoBackToHomePage,
  userAddressValidation,
  setUserAddressValidation,
  couponCode,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isDesktop = useMediaQuery('(min-width:768px)')
  const notificationMsg = useSelector((state) => state.NotificationHandling.message)

  const [showTerms, setShowTerms] = useState(false)
  let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
  let current_storage_timer = storage_timer?.[parentData?.id]

  const publicUser = useSelector((state) => state.User.publicUser)
  const [cardDetails, setCardDetails] = useState({
    cardNumber: null,
    cardholderName: null,
    month: null,
    year: null,
    securityCode: null,
  })

  const [years, setYears] = useState([])
  const [months, setMonths] = useState([])
  const [paymentConfirmData, setPaymentConfirmData] = useState()
  const [paymentActionCheck, setPaymentActionCheck] = useState([])
  const [showValidation, setShowValidation] = useState(false)
  const [cardNumber, setCardNumber] = useState('')
  useEffect(() => {
    const years_data = []
    const current_year = new Date().getFullYear()
    for (let year = current_year; year <= 2070; year++) {
      years_data.push(year.toString())
    }
    setYears(years_data)
    const months_data = []
    for (let i = 1; i <= 12; i++) {
      if (i < 10) {
        months_data?.push({
          label: `0${i}`,
          value: `0${i}`,
        })
      } else {
        months_data?.push({
          label: i,
          value: i,
        })
      }
    }
    setMonths(months_data)
  }, [])

  const formChange = (e) => {
     dispatch(clearNotification())
    if (showValidation) setShowValidation(false)
    let { name, value } = e.target
    if (name === 'securityCode' || name === 'cardNumber') {
      value = value.replace(/\D/g, '')
    }

    setCardDetails((prev) => {
      return { ...prev, [name]: value }
    })
  }

  // const cardNumberChange = (e) => {
  //   console.log(e.target.value, e.target.value.length % 4)

  //   let val = e.target.value
  //   console.log('val:sgfdgsd ', val);
  //   if (val.replace(/\s/g, '').length <= 16) {
  //     if (val.replace(/\s/g, '').length % 4 === 0)
  //       if (cardNumber[cardNumber.length - 1] !== ' ' && val[val.length - 1] !== ' ') val = val + ' '
  //       else val = val.slice(0, -1)
  //     setCardNumber(val.replace(/[^0-9\s]/g, ''))
  //     setCardDetails((prev) => {
  //       return { ...prev, cardNumber: val.replace(/\D/g, '') }
  //     })
  //   }
  // }

  useEffect(() => {
     dispatch(clearNotification())
  } , [])
  
  const cardNumberChange = (e) => {
     dispatch(clearNotification())
    let val = e.target.value.replace(/\D/g, '')
    if (val.length <= 16) {
      setCardNumber(val.replace(/(\d{4})(?=\d)/g, '$1 '))
      setCardDetails((prev) => {
        return { ...prev, cardNumber: val }
      })
    }
  }

  const selectFormChange = (e, name) => {
     dispatch(clearNotification())
    if (showValidation) setShowValidation(false)
    let { label, value } = e

    setCardDetails((prev) => {
      return { ...prev, [name]: value }
    })
  }
  const expireSession = () => {
    let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
    delete storage_timer?.[parentData?.id]
    let storage_data = ''
    if (storage_timer) {
      storage_data = JSON.stringify(storage_timer)
    }
    setLocalStorageItem('timer', storage_data)
  }

  const handlePaymentClick = (e) => {
    e.preventDefault()
    setShowValidation(true)
    const validation = checkValidationForPayment(userAddress, cardDetails, publicUser?.email ? true : false)
    if (userAddressValidation !== 'Success') {
      dispatch(setNotification({ type: 'error', message: 'Please fill all the required fields.' }))
      return setUserAddressValidation(true)
    }

    if (!checkExpiryValidation(`${cardDetails?.month}${cardDetails?.year?.slice(2)}`)) {
      return
    }
    // if (validation?.error) {
    //   dispatch(setNotification({ type: 'error', message: validation?.message }))
    //   return
    // }

    let payload = getPayload(publicUser, userAddress, cardDetails, current_storage_timer?.session_uuid)

    if (couponCode) {
      payload = { ...payload, coupon_code: couponCode }
    }
    request('make-payment', 'POST', payload).then((res) => {
      if (res?.data?.status === '2021' || res?.data?.status === 2021) {
        expireSession()
        setPaymentConfirmData(res?.data)
      } else if (res?.data?.status === '0000') {
        setPaymentConfirmData(res?.data)
        expireSession()
        history.push(`/payment-success?uuid=${res?.data?.uuid}&payment_uuid=${res?.data?.payment_uuid}`)
      } else {
        dispatch(setNotification({ type: 'error', message: res?.data?.message }))
      }
      setTimeout(() => {
        dispatch(clearNotification())
      }, 5000);
    })
  }

  const onChangeAction = (e) => {
    dispatch(clearNotification())
    let newData = [...paymentActionCheck]

    if (e.target.checked) {
      newData?.push(e.target.value)
    } else {
      let index = newData.indexOf(e.target.value)
      newData.splice(index, 1)
    }
    setPaymentActionCheck(newData)
  }


  console.log(notificationMsg , "notificationMsg")

  return (
    <div className='col-md-9 mt-3 ml-auto mr-auto'>
      <div className='border-bottom pb-4 ' style={{ marginLeft: 'auto' }}>
        <form className='needs-validation' novalidate onSubmit={handlePaymentClick} style={{ marginBlock: '2rem' }}>
          <div className='h4 font-weight-bold mb-3' style={{ color: primary_color }}>
            {/* {cms(data.heading)} */}
            {'Payment Method'}
          </div>
          <>
            <div className='mb-3'>
              <div className='mb-2 font-weight-bold'>Card Details</div>
              <div className='d-flex flex-column flex-md-row mb-2' style={{ gap: '0.5rem' }}>
                <div className='d-inline w-100'>
                  <input
                    onChange={formChange}
                    className={`form-control rounded-pill ${showValidation && !cardDetails.cardholderName ? 'error-input' : ''}`}
                    placeholder='Name on card'
                    value={cardDetails.cardholderName}
                    name='cardholderName'
                    type='text'
                  />
                  {showValidation && !cardDetails.cardholderName && <div className='text-danger'>Please enter card holder name</div>}
                </div>
                <div className='d-inline w-100'>
                  <input
                    onChange={formChange}
                    className={`form-control rounded-pill ${showValidation && !cardDetails.securityCode ? 'error-input' : ''}`}
                    placeholder='CVV'
                    value={cardDetails.securityCode}
                    maxLength={3}
                    type='text'
                    name='securityCode'
                  />
                  {showValidation && !cardDetails.securityCode && <div className='text-danger'>Please enter cvv</div>}
                </div>
              </div>
              <div className='w-100'>
                <input
                  // onChange={formChange}
                  onChange={cardNumberChange}
                  className={`form-control rounded-pill ${showValidation && !cardDetails.cardNumber ? 'error-input' : ''}`}
                  placeholder='Card Number'
                  // type='password'
                  // value={cardDetails.cardNumber}
                  value={cardNumber}
                  // maxLength={16}
                  name='cardNumber'
                />
                {showValidation && !cardDetails.cardNumber ? <div className='text-danger'>Please enter card number</div> : <></>}
              </div>
            </div>
            <div className='mb-5'>
              <div className='mb-2 font-weight-bold'>Expiry date</div>
              <div className='d-flex flex-column flex-md-row mb-2' style={{ gap: '0.5rem' }}>
                <div className='d-inline w-100'>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: '50px',
                        borderColor: showValidation && !cardDetails.month ? 'red' : baseStyles.borderColor,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: showValidation && !cardDetails.month ? 'red' : 'rgba(0, 0, 0, 0.2)',
                      }),
                    }}
                    placeholder='Month'
                    onChange={(e) => selectFormChange(e, 'month')}
                    options={months}
                  />
                  {showValidation && !cardDetails.month ? <div className='text-danger'>Please enter expiry month</div> : <></>}
                </div>

                <div className='d-inline w-100'>
                  <Select
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: '50px',
                        borderColor: showValidation && !cardDetails.year ? 'red' : baseStyles.borderColor,
                      }),
                      placeholder: (baseStyles, state) => ({
                        ...baseStyles,
                        color: showValidation && !cardDetails.year ? 'red' : 'rgba(0, 0, 0, 0.2)',
                      }),
                    }}
                    placeholder='Year'
                    onChange={(e) => selectFormChange(e, 'year')}
                    options={years.map((e) => {
                      return { label: e, value: e }
                    })}
                  />
                  {showValidation && !cardDetails.year ? <div className='text-danger'>Please enter expiry year</div> : <></>}
                </div>
              </div>
              {showValidation && !checkExpiryValidation(`${cardDetails?.month}${cardDetails?.year?.slice(2)}`) && (
                <div className='text-danger mt-2'>Expiry date is not valid!</div>
              )}
            </div>
          </>

          <div className='mb-3'>
            {dummy_checkbox_data.map((element, index) => (
              <div key={index} className={isDesktop ? '' : 'd-flex align-items-start'}>
                <input
                  required
                  type='checkbox'
                  style={{ transform: 'scale(1.3)', marginTop: isDesktop ? '0' : '0.35rem' }}
                  value={element?.value}
                  onChange={(e) => onChangeAction(e)}
                />
                <label style={{ textTransform: 'none', fontWeight: '600' }} className='ml-3'>
                  {element?.label}
                </label>
              </div>
            ))}
            <div>
              <input required type='checkbox' style={{ transform: 'scale(1.3)' }} value={3} onChange={(e) => onChangeAction(e)} />
              <label style={{ textTransform: 'none', fontWeight: '600' }} className='ml-3'>
                Accept{' '}
                <span style={{ color: primary_color, cursor: 'pointer', fontWeight: '700' }} onClick={() => setShowTerms(!showTerms)}>
                  Terms & Conditions.
                </span>
              </label>
            </div>
            <div
              style={{
                overflow: 'hidden',
                height: showTerms ? '53rem' : '0rem',
                transition: 'height 1s ease',
              }}>
              <TermsConditions handleClose={() => setShowTerms(false)} />
            </div>
          </div>

          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }} className={isDesktop ? 'w-50' : 'w-100'}>
            <button
              className='btn text-danger'
              style={{ borderRadius: '20px', background: 'transparent', border: '1px solid red' }}
              onClick={() => {
                onGoBackToHomePage()
              }}>
              Cancel
            </button>
            <button
              className='btn ml-3 d-flex justify-content-center'
              style={{ color: 'white', borderRadius: '20px', background: primary_color }}
              // onClick={handlePaymentClick}
              type='submit'
              disabled={paymentActionCheck?.length !== 3 || notificationMsg?.length}>
              <span>Pay now</span>
              <i class='fa-sharp fa-solid fa-arrow-right ml-2' style={{ marginTop: 4 }}></i>
            </button>
          </div>
        </form>
      </div>
      {paymentConfirmData && <PaymentConfirm data={paymentConfirmData} />}
    </div>
  )
}

export default PaymentMethod
