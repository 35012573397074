import { AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'
import Wizards from '../../../../../../Components/Form/Wizard/wizard'
import {
  addDropdownOptionRefetchTable,
  addNewRowInGridRedux,
  callInsertRepeatingGridData,
  cms,
  FormDataValidation,
  FormDataValidationOnSubmit,
  getConnectTemplateReduxDataAfterEdit,
  getConnectTemplateReduxDataAfterEditByReactId,
  getConnectTemplateReduxDataAfterEditItems,
  getConnectTemplateReduxDataAfterInsert,
  getConnectTemplateReduxDataAfterInsertItems,
  insertChildTableAddedDataInSync,
  isDirectUpdateConnectTemplate,
  tidyName,
  updateChildTableAddedDataInSync,
  updateSyncRowId,
} from '../../../../../../helper'
import { setNotification } from '../../../../../../Redux/Reducers/notificationHandling'
import { request } from '../../../../../../Redux/Sagas/requests/api'
import RenderFields from '../../../../../Forms/renderFields'
import IconSet from '../../../../../icon'
import { AddFormInGridData } from '../../addGridData'
import {
  clearCurrentRowData,
  clearParentTableData,
  fetchUpdateApiData,
  setConnectTemplateDataRedux,
  setCoreData,
  setCurrentUrl,
  updateSubmittedChangedField,
} from '../../../../../../Redux/Reducers/CMS'
import SubmitPreview from '../../submitPreview'
import { useHistory } from 'react-router-dom'
import TemplateGroupLocation from '../../templateGroupLocation'
import ShowNotes from '../PageSpecificForm/showNotes'
import ShowUploadFiles from '../PageSpecificForm/showUploadFiles'
import UploadFileForm from '../PageSpecificForm/uploadFileForm'
import { setQueue } from '../../../../../../Redux/Reducers/queue'
import { getSignFormSubmitButtonTitle } from './wizardHelper'
import ItemsReportsPreview from '../../../../../../assets/components/item-reports/item-reports-preview'
import moment from 'moment'
import { setCurrentRow } from '../../../../../../Redux/Reducers/CMS'
import { setParentTableData } from '../../../../../../Redux/Reducers/CMS'
import { SetFullPage, setTabFormData } from '../../../../../../Redux/Reducers/page'
import { v4 as uuidv4 } from 'uuid'

const TemplateSchedulesColumn = [
  'template_name',
  'location_name',
  'location_area',
  'days',
  'schedule_frequency',
  'limit_week',
  'limit_month',
  'limit_year',
  'max_display',
  'repeats_mins',
  'time',
]

const checkChildFormVisible = (form, formData) => {
  const showOptionForType = ['select', 'multy-select', 'radio', 'check']
  if (form?.grid_tab_table === 'item_questions') {
    if (showOptionForType?.includes(formData?.type)) {
      return true
    } else {
      return false
    }
  } else {
    if (form?.child_form && form?.child_form?.length > 0) {
      return true
    } else {
      return false
    }
  }
}

export const getTemplateSchedulesValue = (formData, column, currentRowData, parentForm) => {
  switch (column) {
    case 'location_name':
      const a = currentRowData?.template_group_locations_5?.map((item) => {
        return item?.location?.name
      })
      return a?.slice(0, 2)?.join(', ')
    case 'schedule_frequency':
      return formData['schedule_frequency']
    case 'limit_week':
      if (parseInt(formData['limit_week'])) {
        return `Limit ${formData['limit_week']} per week`
      } else {
        return ''
      }
    case 'limit_month':
      if (parseInt(formData['limit_month'])) {
        return `Limit ${formData['limit_month']} per day`
      } else {
        return ''
      }
    case 'limit_year':
      if (parseInt(formData['limit_year'])) {
        return `Limit ${formData['limit_week']} per year`
      } else {
        return ''
      }
    case 'max_display':
      if (parseInt(formData['max_display'])) {
        return `Max ${formData['max_display']}`
      } else {
        return ''
      }
    case 'repeats_mins':
      if (parseInt(formData['repeats_mins'])) {
        return `Max ${formData['repeats_mins']}`
      } else {
        return ''
      }
    case 'location_area':
      const b = formData['template_schedule_resources']?.map((item) => {
        return item?.location_resource_id_label
      })
      return b?.join(', ')
    case 'days':
      const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun']
      if (formData?.mon && formData?.tue && formData?.wed && formData?.thu && formData?.fri && formData?.sat && formData?.sun) {
        return 'Every Day'
      } else {
        const a = []
        days?.map((day) => {
          if (formData[day]) {
            a.push(day)
          }
        })
        return a.join(', ')
      }
    case 'template_name':
      if (parentForm) {
        return parentForm['item_name']
      }
    case 'time':
      return formData['time']
    default:
      return null
  }
}

const getTemplateScheduleResourcesTableValue = (formData, field) => {
  if (field?.field_id === 'template_group_location_id') {
    if (formData?.template_group_location_id_label) {
      return formData?.template_group_location_id_label
    } else {
      return formData?.template_group_location?.location?.name
    }
  }
  if (field?.field_id === 'location_resource_id') {
    return formData?.location_resource_id_label
  }
}

const getTableValue = (type, value, field) => {
  switch (type) {
    case 'toggle_switch':
      if (value) {
        return <span className='badge badge-pill bg-success sidebar-text-light'>Yes</span>
      } else {
        return <span className='badge badge-pill bg-danger sidebar-text-light'>No</span>
      }
    case 'select':
      if (field?.format?.is_multi_select) {
        return value?.map((item) => item?.label).join(', ')
      } else {
        return value
      }
    case 'date':
      return value && moment(value).format('Do MMM, YYYY')
    case 'time':
      return value && moment(value).format('HH:mm')

    default:
      return value
  }
}

const ShowWizardForm = ({ steps, currentStep, onNextStep, navStyles, formResponseData, onPreviousStep, onSetStep }) => {
  const [render, ReRender] = useState(false)

  useEffect(() => {
    ReRender(!render)
  }, [steps])
  if (steps?.length > 1) {
    return (
      <div className='forms-wizard-alt'>
        <Wizards
          showNavigation={true}
          navState={navStyles}
          steps={steps}
          currentStep={currentStep}
          onNextStep={onNextStep}
          formResponseData={formResponseData}
          onPreviousStep={onPreviousStep}
          onSetStep={onSetStep}
        />
      </div>
    )
  }
}

const EditForm = ({ onSubmitForm, index, formData, allForm, content, onValueChange, submittedFormData }) => {
  const [formFieldValue, setFormFieldValue] = useState()

  return (
    <AvForm onValidSubmit={(e, values) => onSubmitForm(values, formData?.id, index)} style={{ marginBottom: '1rem' }}>
      <RenderFields
        allForm={allForm}
        content={content}
        twoCol={true}
        onValueChange={onValueChange}
        defaultFormValue={formData}
        submittedFormData={submittedFormData}
        formFieldValue={formFieldValue}
        setFormFieldValue={setFormFieldValue}
      />
      <Button color='primary'>Save</Button>
    </AvForm>
  )
}

const RenderRepeatableDataForm = ({
  addedForm,
  allForm,
  content,
  onValueChange = () => {},
  setAddedForm,
  parentForm,
  parentTable,
  formResponseData,
  setFormResponseData,
  grid_id,
  parentPageName,
  submittedFormData,
}) => {
  const dispatch = useDispatch()

  const onSubmitForm = (values, id, index) => {
    const updatedAddedForm = [...addedForm]
    updatedAddedForm[index] = {
      ...updatedAddedForm[index],
      ...values,
    }
    setAddedForm(updatedAddedForm)
    dispatch(setNotification({ type: 'success', message: 'Form saved successfully!' }))
  }
  const form = allForm[0]

  const onDelete = (index, id) => {
    if (id) {
      request('connect-template-delete', 'POST', { ids: [id], source_table: allForm[0]?.grid_tab_method }).then((response) => {
        if (response.status === 202 || response.status === 200) {
          dispatch(setNotification({ type: 'success', message: 'Form deleted successfully!' }))
          const newAddedForm = [...addedForm]
          newAddedForm?.splice(index, 1)

          setAddedForm(newAddedForm)
        } else {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        }
      })
    } else {
      const newAddedForm = [...addedForm]
      newAddedForm?.splice(index, 1)

      setAddedForm(newAddedForm)
    }
  }

  if (form?.grid_tab_method === 'notes')
    return (
      <ShowNotes
        addedNotes={addedForm}
        onSubmitForm={onSubmitForm}
        allForm={allForm}
        content={content}
        onValueChange={onValueChange}
        submittedFormData={submittedFormData}
        onDeleteNotes={onDelete}
      />
    )
  if (form?.grid_tab_method === 'upload_files')
    return (
      <ShowUploadFiles
        addedFiles={addedForm}
        onSubmitForm={onSubmitForm}
        allForm={allForm}
        content={content}
        onValueChange={onValueChange}
        submittedFormData={submittedFormData}
        onDeleteFiles={onDelete}
      />
    )

  return (
    <div>
      {addedForm?.map((formData, index) => {
        return (
          <div
            key={index}
            style={{
              padding: '1rem',
              border: '1px dashed #b8b8b8',
              marginBottom: '1rem',
              paddingBottoom: '3rem',
              borderRadius: '5px',
              display: 'flex',
              flexDirection: 'column',
            }}>
            <div>
              <Button
                className='btn-danger float-right'
                style={{ marginTop: '7px' }}
                onClick={
                  () => onDelete(index, formData?.id)
                  // dispatch(
                  //   submitRequest({
                  //     url: 'remove-grid-column',
                  //     data: {
                  //       grid_id: mainGrid?.id,
                  //       field_id: item.field_id,
                  //     },
                  //   })
                  // )
                }>
                <IconSet icon='pe-7s-trash' color='light' />
              </Button>
            </div>
            <div>
              <EditForm
                onSubmitForm={onSubmitForm}
                index={index}
                formData={formData}
                allForm={allForm}
                content={content}
                onValueChange={onValueChange}
                submittedFormData={submittedFormData}
              />
            </div>

            {form?.child_form &&
              form?.child_form?.length > 0 &&
              form?.child_form?.map((form, i) => {
                if (
                  (form?.grid_tab_table === 'template_schedules' || form?.grid_tab_table === 'message_schedules') &&
                  submittedFormData &&
                  submittedFormData['template_groups'] &&
                  !submittedFormData['template_groups']?.add_schedule
                ) {
                  return
                }
                return (
                  <RenderSingleForm
                    key={i}
                    form={form}
                    // content={form?.child_form}
                    twoCol={false}
                    parentForm={formData}
                    parentTable={form?.grid_tab_method}
                    formResponseData={formResponseData}
                    setFormResponseData={setFormResponseData}
                    grid_id={grid_id}
                    parentPageName={parentPageName}
                    updateInParentIndex={index}
                    addedForm={addedForm}
                    setAddedForm={setAddedForm}
                    updateInParent={true}
                    submittedFormData={submittedFormData}
                  />
                  // <RenderRepeatableForm
                  //   allForm={form?.child_form}
                  //   content={form?.child_form}
                  //   twoCol={false}
                  //   parentForm={formData}
                  //   parentTable={allForm[0]?.grid_tab_method}
                  //   formResponseData={formResponseData}
                  //   setFormResponseData={setFormResponseData}
                  // />
                )
              })}
            <hr></hr>
          </div>
        )
      })}
    </div>
  )
}

const RenderRepeatableDataTable = ({
  formData,
  form,
  index,
  setAddedForm,
  addedForm,
  allForm,
  content,
  onValueChange,
  parentForm,
  parentTable,
  formResponseData,
  setFormResponseData,
  grid_id,
  parentPageName,
  submittedFormData,
}) => {
  const [expandForm, setExpandForm] = useState(false)
  const dispatch = useDispatch()

  const onDelete = (index, id) => {
    if (id) {
      request('connect-template-delete', 'POST', { ids: [id], source_table: form?.grid_tab_method }).then((response) => {
        if (response.status === 202 || response.status === 200) {
          const newAddedForm = [...addedForm]
          newAddedForm?.splice(index, 1)

          setAddedForm(newAddedForm)
          dispatch(setNotification({ type: 'success', message: 'Form deleted successfully!' }))
        } else {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        }
      })
    } else {
      const newAddedForm = [...addedForm]
      newAddedForm?.splice(index, 1)

      setAddedForm(newAddedForm)
    }
  }

  const onSubmitForm = (values, id) => {
    const updatedAddedForm = [...addedForm]
    updatedAddedForm[index] = {
      ...updatedAddedForm[index],
      ...values,
    }
    setAddedForm(updatedAddedForm)
    dispatch(setNotification({ type: 'success', message: 'form saved successfully' }))
  }

  let isChildFormVisible = checkChildFormVisible(form, formData)
  let currentRowData
  const currentRow = useSelector((state) => state.CMS.currentRow)
  if (currentRow && currentRow?.length > 0) {
    currentRowData = currentRow[currentRow?.length - 1]
  }
  return (
    <>
      <tr>
        {/* {form?.child_form && form?.child_form?.length > 0 && ( */}
        <td>
          <IconSet
            icon={expandForm ? 'far fa-arrow-down' : 'far fa-arrow-right'}
            color='primary'
            style={{ cursor: 'pointer', marginRight: '0.5rem' }}
            onClick={() => setExpandForm(!expandForm)}
          />
        </td>
        {/* )} */}
        <td>
          <Button
            className='btn-danger float-right'
            style={{ marginTop: '7px' }}
            onClick={
              () => onDelete(index, formData?.id)
              // dispatch(
              //   submitRequest({
              //     url: 'remove-grid-column',
              //     data: {
              //       grid_id: mainGrid?.id,
              //       field_id: item.field_id,
              //     },
              //   })
              // )
            }>
            <IconSet icon='pe-7s-trash' color='light' />
          </Button>
          {/* <IconSet
            icon={expandForm ? 'far fa-arrow-down' : 'far fa-arrow-right'}
            color='primary'
            style={{ cursor: 'pointer', marginRight: '0.5rem' }}
            onClick={() => setExpandForm(!expandForm)}
          /> */}
        </td>
        {form?.grid_tab_table === 'template_schedules' &&
          TemplateSchedulesColumn?.map((item, index) => {
            return <td key={index}>{getTemplateSchedulesValue(formData, item, currentRowData, parentForm)}</td>
          })}
        {form?.grid_tab_table === 'template_schedule_resources' && (
          <>
            {form?.component_call_fields
              ?.filter((item) => item.component_name !== 'section_heading')
              ?.filter((item) => !item?.hide_from_grid)
              ?.map((field, i) => {
                return <td key={i}>{getTemplateScheduleResourcesTableValue(formData, field)}</td>
              })}
          </>
        )}

        {form?.grid_tab_table !== 'template_schedule_resources' &&
          form?.grid_tab_table !== 'template_schedules' &&
          form?.component_call_fields
            ?.filter((item) => item.component_name !== 'section_heading')
            ?.filter((item) => !item?.hide_from_grid)
            ?.map((field, i) => {
              return <td key={i}>{tidyName(getTableValue(field?.component_name, formData[field?.field_id], field, parentForm))}</td>
            })}
      </tr>
      {expandForm && (
        <>
          <tr>
            <td colSpan={form?.component_call_fields?.length + 2}>
              <div style={{ maxWidth: form?.grid_tab_method === 'template_schedules' && '65rem' }}>
                <AvForm onValidSubmit={(e, values) => onSubmitForm(values, formData?.id)} style={{ marginBottom: '1rem' }}>
                  <RenderFields allForm={allForm} content={content} twoCol={true} onValueChange={onValueChange} defaultFormValue={formData} />
                  <Button color='primary'>Save</Button>
                </AvForm>
              </div>
            </td>
          </tr>

          <tr>
            {isChildFormVisible > 0 && (
              <td colSpan={form?.component_call_fields?.length + 2}>
                <div style={{ maxWidth: form?.grid_tab_method === 'template_schedules' && '65rem' }}>
                  <RenderSingleForm
                    form={form?.child_form[0]}
                    // content={form?.child_form}
                    twoCol={false}
                    parentForm={formData}
                    parentTable={allForm[0]?.grid_tab_method}
                    formResponseData={formResponseData}
                    setFormResponseData={setFormResponseData}
                    grid_id={grid_id}
                    parentPageName={parentPageName}
                    updateInParentIndex={index}
                    addedForm={addedForm}
                    setAddedForm={setAddedForm}
                    updateInParent={true}
                    submittedFormData={submittedFormData}
                  />
                </div>
              </td>
            )}
          </tr>
        </>
      )}
    </>
  )
}

const AddForm = ({ onSubmitForm, allForm, content, onValueChange, submittedFormData }) => {
  const [formFieldValue, setFormFieldValue] = useState()
  return (
    <AvForm
      onValidSubmit={(e, values) => onSubmitForm(values)}
      style={{ marginBottom: '1rem', border: '1px dashed', padding: '0.5rem', borderRadius: '5px' }}>
      <RenderFields
        allForm={allForm}
        content={content}
        twoCol={true}
        onValueChange={onValueChange}
        submittedFormData={submittedFormData}
        formFieldValue={formFieldValue}
        setFormFieldValue={setFormFieldValue}
      />
      <Button color='primary'>Save</Button>
    </AvForm>
  )
}
const RenderRepeatableForm = ({
  allForm,
  content,
  onValueChange,
  parentPageName,
  parentForm,
  parentTable,
  formResponseData,
  setFormResponseData,
  grid_id,
  setSubmittedFormData = () => {},
  submittedFormData,
  setParentForms,
  parentForms,
  updateInParent,
  updateInParentIndex,
}) => {
  const [addedForm, setAddedForm] = useState([])
  const [addDataFormOpen, setAddDataFormOpen] = useState(false)
  const dispatch = useDispatch()
  // const submittedFormDataObject = submittedFormData && JSON.parse(submittedFormData)

  const [render, ReRender] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      if (!render) {
        ReRender(true)
      }
    }, 500)
    if (allForm[0]?.grid_tab_method === 'upload_files') {
      setAddDataFormOpen(true)
    }
  }, [])

  useEffect(() => {
    if (
      allForm[0]?.grid_tab_parent_table &&
      submittedFormData &&
      submittedFormData[allForm[0]?.grid_tab_parent_table] &&
      submittedFormData[allForm[0]?.grid_tab_parent_table][allForm[0]?.grid_tab_table || allForm[0]?.grid_tab_method]
    ) {
      setAddedForm(submittedFormData[allForm[0]?.grid_tab_parent_table][allForm[0]?.grid_tab_table || allForm[0]?.grid_tab_method] || [])
      if (submittedFormData[allForm[0]?.grid_tab_parent_table][allForm[0]?.grid_tab_table || allForm[0]?.grid_tab_method]?.length > 0) {
        if (allForm[0]?.grid_tab_method === 'upload_files') {
          setAddDataFormOpen(true)
        } else {
          setAddDataFormOpen(false)
        }
      }
    }
  }, [render])

  useEffect(() => {
    if (parentForm) {
      setAddedForm(parentForm[allForm[0]?.grid_tab_table || allForm[0]?.grid_tab_method] || [])
      if (parentForm[allForm[0]?.grid_tab_table || allForm[0]?.grid_tab_method]?.length > 0) {
        if (allForm[0]?.grid_tab_method === 'upload_files') {
          setAddDataFormOpen(true)
        } else {
          setAddDataFormOpen(false)
        }
      }
    }
  }, [render])

  useEffect(() => {
    if (updateInParent) {
      const parentForm = [...parentForms]
      const currentFormInParentArray = parentForm[updateInParentIndex]

      currentFormInParentArray[allForm[0]?.grid_tab_table || allForm[0]?.grid_tab_method] = addedForm || []
      parentForm[updateInParentIndex] = currentFormInParentArray
      setParentForms(parentForm)
    }
  }, [addedForm])

  useEffect(() => {
    if (submittedFormData && Object.keys(submittedFormData)?.includes(allForm[0]?.grid_tab_parent_table)) {
      const newSubmittedFormData = { ...submittedFormData }
      newSubmittedFormData[allForm[0]?.grid_tab_parent_table][allForm[0]?.grid_tab_table || allForm[0]?.grid_tab_method] = [...addedForm]

      setSubmittedFormData(newSubmittedFormData)
    } else if (!Object.keys(submittedFormData)?.includes(allForm[0]?.grid_tab_parent_table)) {
      const newSubmittedFormData = { ...submittedFormData }
      newSubmittedFormData[allForm[0]?.grid_tab_parent_table] = {}
      newSubmittedFormData[allForm[0]?.grid_tab_parent_table][allForm[0]?.grid_tab_table || allForm[0]?.grid_tab_method] = [...addedForm]
      setSubmittedFormData(newSubmittedFormData)
    }
  }, [addedForm])

  const onSubmitForm = (values) => {
    const payload = {
      data: { ...values },
      source_table: allForm[0]?.grid_tab_method,
    }
    let temporary_id = uuidv4()
    if (!addedForm) {
      setAddedForm([{ ...values, temporary_id }] || [])
    } else {
      setAddedForm([...addedForm, { ...values, temporary_id }] || [])
    }
    dispatch(setNotification({ type: 'success', message: 'Form saved successfully!' }))

    setAddDataFormOpen(false)
  }

  return (
    <div>
      <div style={{ width: '90%' }}>
        {allForm[0]?.grid_tab_method !== 'upload_files' && (
          <Button
            color={addDataFormOpen ? 'danger' : 'success'}
            onClick={() => {
              setAddDataFormOpen(!addDataFormOpen)
            }}
            className='btn m-1'>
            <IconSet icon={addDataFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
            <span style={{ marginLeft: '1rem' }}>Add {tidyName(allForm[0]?.name)}</span>
          </Button>
        )}
      </div>
      {allForm[0]?.grid_tab_method === 'upload_files' && true && <UploadFileForm onSubmitForm={onSubmitForm} />}
      {addDataFormOpen && allForm[0]?.grid_tab_method !== 'upload_files' && (
        <AddForm
          onSubmitForm={onSubmitForm}
          allForm={allForm}
          content={content}
          onValueChange={onValueChange}
          submittedFormData={submittedFormData}
        />
      )}
      {(allForm[0]?.repeatable_form === 1 || allForm[0]?.repeatable_form === true) && (
        <RenderRepeatableDataForm
          addedForm={addedForm}
          setAddedForm={setAddedForm}
          allForm={allForm}
          content={content}
          twoCol={true}
          onValueChange={onValueChange}
          parentForm={parentForm}
          parentTable={parentTable}
          formResponseData={formResponseData}
          setFormResponseData={setFormResponseData}
          grid_id={grid_id}
          parentPageName={parentPageName}
          submittedFormData={submittedFormData}
        />
      )}
      {!(allForm[0]?.repeatable_form === 1 || allForm[0]?.repeatable_form === true) && addedForm && addedForm?.length > 0 && (
        <table
          className='table'
          style={{ width: '100%', overflow: 'auto', display: allForm[0]?.grid_tab_method === 'template_schedules' && 'block' }}>
          <thead>
            <tr>
              <th></th>

              <th style={{ width: '1rem' }}></th>
              {allForm[0]?.grid_tab_table === 'template_schedules' &&
                TemplateSchedulesColumn?.map((item, i) => {
                  return <th key={i}>{tidyName(item)}</th>
                })}
              {allForm[0]?.grid_tab_table !== 'template_schedules' &&
                allForm[0]?.component_call_fields
                  ?.filter((item) => item?.component_name !== 'section_heading')
                  ?.filter((item) => !item?.hide_from_grid)
                  ?.map((field, i) => {
                    return <th key={i}>{tidyName(cms(field?.cms_key))}</th>
                  })}
            </tr>
          </thead>
          <tbody id='grid_setting_row_body'>
            {addedForm?.map((data, index) => {
              return (
                <RenderRepeatableDataTable
                  key={index}
                  formData={data}
                  form={allForm[0]}
                  setAddedForm={setAddedForm}
                  addedForm={addedForm}
                  index={index}
                  allForm={allForm}
                  content={content}
                  twoCol={true}
                  onValueChange={onValueChange}
                  parentForm={parentForm}
                  parentTable={parentTable}
                  formResponseData={formResponseData}
                  setFormResponseData={setFormResponseData}
                  grid_id={grid_id}
                  parentPageName={parentPageName}
                  submittedFormData={submittedFormData}
                />
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

const RenderSingleForm = ({
  form,
  onValueChange,
  parentPageName,
  grid_id,
  setAddFormList,
  parentForm,
  parentTable,
  formResponseData,
  setFormResponseData,
  submittedFormData,
  setSubmittedFormData,
  onNextStep,
  addedForm,
  setAddedForm,
  updateInParent,
  updateInParentIndex,
  formsList,
  addDataDetail,
  setAddGridDataDetail,
  onSubmitForm,
  onCloseForm = () => {},
  setIsSaveButtonClicked = () => {},
}) => {
  const editPageMode = useSelector((state) => state.CMS.editPageMode)
  const [addChildForm, setAddChildForm] = useState(false)
  const [childFormList, setChildFormList] = useState([])
  const coreData = useSelector((state) => state.CMS.coreData)
  const dispatch = useDispatch()
  const history = useHistory()

  const [validationErrorOnFieldValueChange, setValidationErrorOnFieldValueChange] = useState([])
  const [validationErrorOnFormSubmit, setValidationErrorOnFormSubmit] = useState([])

  const [formFieldValue, setFormFieldValue] = useState({})
  const enabledOrDisabledMethodTab = useSelector((state) => state.CMS.enabledOrDisabledMethodTab)
  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const [render, ReRender] = useState(false)

  let defaultSingleFormValue = {}

  if (submittedFormData && submittedFormData[form?.grid_tab_table || form?.grid_tab_method]) {
    defaultSingleFormValue = {
      ...submittedFormData[form?.grid_tab_table || form?.grid_tab_method],
    }
  }
  useEffect(() => {
    const updatedCoreData = {
      ...coreData,
      currentFormData: { ...coreData.currentFormData, ...defaultSingleFormValue },
      currentFormFullData: {},
    }
    dispatch(setCoreData(updatedCoreData))
  }, [JSON.stringify(defaultSingleFormValue)])

  useEffect(() => {
    ReRender(!render)
  }, [form?.component_call_fields, JSON.stringify(submittedFormData)])

  const onSingleFormSubmit = (values) => {
    const new_submitted_form_data = { ...submittedFormData }

    new_submitted_form_data[form?.grid_tab_table || form?.grid_tab_method] = {
      ...new_submitted_form_data[form?.grid_tab_table || form?.grid_tab_method],
      ...values,
    }

    setSubmittedFormData(new_submitted_form_data, values)

    if (form?.child_form?.length === 0 && formsList?.length !== 1) {
      onNextStep()
    } else {
      onSubmitForm(true, new_submitted_form_data)
    }
  }

  useEffect(() => {
    setChildFormList(form?.child_form)
  }, [JSON.stringify(form)])

  useEffect(() => {
    const promises = form?.data_validations?.map(async (validation) => {
      const parent_table = parentTableDataInRedux && parentTableDataInRedux?.[0]?.source_table
      const parent_table_row_id = currentRow[0]?.id || currentRow?.[0]?.temporary_id
      let formValue = {
        ...formFieldValue,
      }
      const result = await FormDataValidation(
        formValue,
        validation,
        parent_table_row_id,
        parent_table,
        addDataDetail?.tableGrid?.source_table,
        currentRow?.[1]?.id || currentRow?.[1]?.temporary_id
      )
      return result
    })
    if (promises) {
      Promise.all(promises).then((results) => {
        let errorCheckArray = []
        results?.forEach((result) => {
          if (Array.isArray(result)) {
            errorCheckArray = [...errorCheckArray, ...result]
          } else {
            errorCheckArray?.push(result)
          }
        })
        const failedValidation = errorCheckArray?.filter((item) => !item?.validation)
        setValidationErrorOnFieldValueChange(failedValidation)
      })
    }
  }, [formFieldValue])

  return (
    <div style={{ position: 'relative', marginBottom: '5rem' }}>
      {editPageMode && form?.grid_tab_repeatable && form?.grid_tab_table !== 'template_group_locations' && (
        <Button color='link' className='float-right' onClick={() => setAddChildForm(true)}>
          Add Child Form
        </Button>
      )}

      {(form?.grid_tab_table === 'template_group_locations' || form?.grid_tab_method === 'template_group_location') && (
        <TemplateGroupLocation submittedFormData={submittedFormData} setSubmittedFormData={setSubmittedFormData} form={form} />
      )}

      {form?.grid_tab_repeatable && form?.grid_tab_table !== 'template_group_locations' ? (
        <RenderRepeatableForm
          allForm={[form]}
          content={[form]}
          twoCol={true}
          onValueChange={onValueChange}
          formResponseData={formResponseData}
          setFormResponseData={setFormResponseData}
          parentForm={parentForm}
          parentTable={parentTable}
          grid_id={grid_id}
          parentPageName={parentPageName}
          submittedFormData={submittedFormData}
          setSubmittedFormData={setSubmittedFormData}
          parentForms={addedForm}
          setParentForms={setAddedForm}
          updateInParent={updateInParent}
          updateInParentIndex={updateInParentIndex}
        />
      ) : form?.component_call_fields && form?.component_call_fields?.length > 0 && form?.grid_tab_table !== 'template_group_locations' ? (
        <div>
          <AvForm
            onValidSubmit={(e, values) => {
           
              const results = FormDataValidationOnSubmit(values, form?.data_validations)

              const error = results?.filter((item) => !item?.validation)
              if (error?.length > 0) {
                setValidationErrorOnFormSubmit(error)
              } else {
                onSingleFormSubmit(values)
              }
            }}>
            <RenderFields
              allForm={[form]}
              content={[form]}
              twoCol={true}
              onValueChange={onValueChange}
              defaultFormValue={defaultSingleFormValue}
              parentPageName={parentPageName}
              formFieldValue={formFieldValue}
              setFormFieldValue={setFormFieldValue}
              source_table={addDataDetail?.tableGrid?.source_table}
              validationErrorOnFieldValueChange={validationErrorOnFieldValueChange}
              setValidationErrorOnFieldValueChange={setValidationErrorOnFieldValueChange}
            />
            {validationErrorOnFieldValueChange?.map((item, index) => {
              return (
                <div class='alert alert-danger mb-1' role='alert' key={index}>
                  {item?.message && <div dangerouslySetInnerHTML={{ __html: item?.message }}></div>}
                </div>
              )
            })}
            {validationErrorOnFormSubmit?.map((item, index) => {
              return (
                <div class='alert alert-danger mb-1' role='alert' key={index}>
                  {item?.message}
                </div>
              )
            })}

            {formsList?.length === 1 ? (
              <div className='wizard-single-form-with-child-submit float-right'>
                <Button
                  color='secondary'
                  disabled={validationErrorOnFieldValueChange?.length > 0}
                  size='lg'
                  className={`btn-shadow  mr-2 `}
                  style={{ marginBottom: '1rem' }}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (!addDataDetail?.isAddNew) {
                      dispatch(clearCurrentRowData())
                      dispatch(clearParentTableData())
                    }

                    onCloseForm()
                  }}>
                  Close
                </Button>
                <Button
                  color='primary'
                  disabled={validationErrorOnFieldValueChange?.length > 0}
                  size='lg'
                  className={`btn-shadow  mr-2 `}
                  style={{ marginBottom: '1rem' }}
                  onClick={(e) => {
                    setIsSaveButtonClicked(true)
                 
                    setTimeout(() => {
                      document.getElementById('submitAndCloseButton').click()
                    }, 1000)
                  }}>
                  Save
                </Button>
                <Button
                  color='primary'
                  disabled={validationErrorOnFieldValueChange?.length > 0}
                  size='lg'
                  className={`btn-shadow mr-2`}
                  style={{ marginBottom: '1rem' }}
                  id='submitAndCloseButton'>
                  {getSignFormSubmitButtonTitle(addDataDetail)}
                </Button>
              </div>
            ) : (
              <Button color='primary'>Save & Next</Button>
            )}
          </AvForm>
          {form?.child_form?.map((form, i) => {
            if (enabledOrDisabledMethodTab?.includes(form?.grid_tab_method)) return
            return (
              <RenderRepeatableForm
                key={i}
                allForm={[form]}
                content={[form]}
                twoCol={true}
                onValueChange={onValueChange}
                formResponseData={formResponseData}
                setFormResponseData={setFormResponseData}
                parentForm={parentForm}
                parentTable={parentTable}
                grid_id={grid_id}
                parentPageName={parentPageName}
                submittedFormData={submittedFormData}
                setSubmittedFormData={setSubmittedFormData}
                parentForms={addedForm}
                setParentForms={setAddedForm}
                updateInParent={updateInParent}
                updateInParentIndex={updateInParentIndex}
              />
            )
          })}
          {addDataDetail?.pageName === 'item_reports' && addDataDetail?.parentPageName === 'approve_jobs' && <ItemsReportsPreview />}

          {form?.child_form &&
            form?.child_form?.length > 0 &&
            Object.keys(submittedFormData)?.includes(form.grid_tab_table || form.grid_tab_method) && (
              <>
                {formsList?.length === 1 ? (
                  <></>
                ) : (
                  // <Button
                  //   color='success'
                  //   size='lg'
                  //   className='btn-shadow btn-wide float-right'
                  //   onClick={() => {
                  //     onSubmitForm(true)

                  //   }}>
                  //   Save & Go Back to Main Page
                  // </Button>
                  <Button className='float-right' onClick={() => onNextStep()} color='primary'>
                    Next
                  </Button>
                )}{' '}
              </>
            )}
        </div>
      ) : (
        <>
          {form?.grid_tab_table !== 'template_group_locations' && (
            <div style={{ textAlign: 'center', marginTop: '3rem', fontSize: '1.3rem' }}>Fields not added yet! </div>
          )}
        </>
      )}
    </div>
  )
}

const ShowAllForms = ({
  forms,
  onValueChange,
  parentPageName,
  grid_id,
  setAddFormList,
  addDataDetail,
  setAddGridDataDetail,
  importedTemplates,
  isModel,
}) => {
  const [steps, setSteps] = useState([])
  const history = useHistory()

  const [formResponseData, setFormResponseData] = useState({})
  const [submittedFormData, setSubmittedFormData] = useState({})
  const [currentStep, setCurrentStep] = useState(0)
  const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false)
  const [isTempIdGenerated, setIsTempIdGenerated] = useState(false)
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)
  const queues = useSelector((state) => state.Queue.queues)

  const [formSubmitStatus, setFormSubmitStatus] = useState(false)
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)

  const currentRow = useSelector((state) => state.CMS.currentRow)

  const enabledOrDisabledMethodTab = useSelector((state) => state.CMS.enabledOrDisabledMethodTab)

  useEffect(() => {
    if (importedTemplates) {
      setSubmittedFormData(importedTemplates)
    }
  }, [importedTemplates])

  useEffect(() => {
    if (addDataDetail?.defaultDataFromCurrentRow && currentRow) {
      let defaultData = {}
      defaultData[addDataDetail?.tableGrid?.source_table] = {
        ...currentRow[currentRow?.length - 1],
      }
      setSubmittedFormData(defaultData)
    }

    if (forms?.length === 1 && currentRow?.length === 1 && !currentRow[1]?.id && forms[0]?.grid_tab_table === 'uploads') {
      let initialData = {}
      initialData[forms[0]?.grid_tab_table || forms[0]?.grid_tab_method] = {}

      setSubmittedFormData(initialData)
    }
  }, [currentRow?.length])

  const getNavStates = (indx, length) => {
    let styles = []
    for (let i = 0; i < length; i++) {
      if (i < indx) {
        styles.push('done')
      } else if (i === indx) {
        styles.push('doing')
      } else {
        styles.push('todo')
      }
    }
    return { current: indx, styles: styles }
  }

  const onNextStep = (forceRedirect) => {
    setCurrentStep(currentStep + 1)
  }

  const onPreviousStep = () => {
    setCurrentStep(currentStep - 1)
  }
  const onSetStep = (step) => {
    setCurrentStep(step)
  }

  const onSubmitForm = (isSingleForm, data) => {
    const source_table = addDataDetail?.tableGrid?.source_table
    const parentPageName = addDataDetail?.parentPageName
    const pageName = addDataDetail?.pageName

    let parent_table, parent_table_row_id, parent_table_temporary_id

    if (source_table === 'users' && data['users']?.password !== data['users']?.password_confirmation) {
      dispatch(setNotification({ type: 'error', message: 'Please confirm password!' }))
      return
    }

    let submitData

    if (data) {
      submitData = { ...data }
    } else {
      submitData = { ...submittedFormData }
    }

    if (submitData && currentRow) {
      if ((addDataDetail?.isAddNew && currentRow?.length > 0) || currentRow?.length > 1) {
        parent_table = parentTableDataInRedux && parentTableDataInRedux?.[0]?.source_table
        parent_table_row_id = currentRow[0]?.id || currentRow[0]?.temporary_id
      }
    }

    //validation for scheduler

    if (submitData?.messages && submitData.messages?.send_schedule_pause === 'schedule') {
      if (!submitData?.message?.message_schedules || submitData?.message?.message_schedules?.length === 0) {
        dispatch(
          setNotification({
            type: 'error',
            message: 'Please add atleast one schedule',
          })
        )
      }
      return
    }

    let submitFormData = {}
    submitFormData[source_table] = {}

    if (addDataDetail?.isAddNew) {
      const id = uuidv4()

      submitFormData[source_table] = {
        ...submitData[source_table],
        temporary_id: isTempIdGenerated || id,
      }
      if (!isTempIdGenerated) {
        setIsTempIdGenerated(id)
      }
    } else {
      submitFormData[source_table] = {
        ...submitData[source_table],
      }
    }

    if (!isDirectUpdateConnectTemplate(addDataDetail?.tableGrid?.source_table, forms, isModel)) {
      const queueData = queues?.filter(
        (queue) => queue.url === 'insert-repeating-grid-data' && queue?.source_table === parent_table && !queue?.parent_table_row_id
      )
      if (
        parent_table &&
        !isModel &&
        ((currentRow?.length === 1 && !currentRow?.[currentRow?.length - 1]?.id && currentRow?.[currentRow?.length - 1]?.temporary_id) ||
          (currentRow?.length === 2 && !currentRow?.[currentRow?.length - 2]?.id && currentRow?.[currentRow?.length - 2]?.temporary_id)) &&
        queueData?.length > 0
      ) {
        
        if (isTempIdGenerated || !addDataDetail?.isAddNew) {
          let id
          if (currentRow?.length === 1) {
            id = currentRow?.[currentRow?.length - 1]?.temporary_id
          } else {
            id = currentRow?.[currentRow?.length - 2]?.temporary_id
          }
          updateChildTableAddedDataInSync(id, parent_table, source_table, submitFormData[source_table])
        } else {
          insertChildTableAddedDataInSync(
            currentRow?.[currentRow?.length - 1]?.temporary_id,
            parent_table,
            source_table,
            submitFormData[source_table]
          )
        }
      } else {
        if (source_table === 'items') {
          dispatch(
            setQueue({
              url: 'insert-repeating-grid-data',
              payload: {
                data: {
                  data: submittedFormData,
                  is_nested: true,
                  parent_table,
                  parent_table_row_id,
                  domain_id,
                  clause_data: isModel && addDataDetail?.tableGrid?.main_grid?.where_clause,
                },
              },
            })
          )
        } else {
          callInsertRepeatingGridData(submitFormData, source_table, addDataDetail, isModel, domain_id, parent_table, parent_table_row_id)
        }
      }

      dispatch(updateSubmittedChangedField())
      let dataToUpdate

      if (addDataDetail?.isAddNew) {
        if (addDataDetail?.parentPageName !== 'task_monitor' && !isTempIdGenerated) {
          dataToUpdate = getConnectTemplateReduxDataAfterInsert(
            { ...connectTemplateDataRedux },
            parentPageName,
            pageName,
            source_table,
            currentRow,
            submitFormData[source_table],
            parent_table ? (isModel ? false : true) : false
          )
        } else if (isTempIdGenerated) {
          dataToUpdate = getConnectTemplateReduxDataAfterEditByReactId(
            { ...connectTemplateDataRedux },
            parentPageName,
            pageName,
            source_table,
            currentRow,
            submitFormData[source_table],
            parent_table ? (isModel ? false : true) : false
          )
        }
      } else {
        if (addDataDetail?.parentPageName !== 'task_monitor') {
          dataToUpdate = getConnectTemplateReduxDataAfterEdit(
            { ...connectTemplateDataRedux },
            parentPageName,
            pageName,
            source_table,
            currentRow,
            submitData[source_table],
            parent_table ? (isModel ? false : true) : false
          )

          updateSyncRowId(parentPageName, pageName, [submitData[source_table]?.id])
        }
      }
      if (!isDirectUpdateConnectTemplate(source_table) && source_table !== 'items') {
        dispatch(setConnectTemplateDataRedux(dataToUpdate))
      }
      if (addDataDetail?.parentPageName === 'task_monitor') {
        if (addDataDetail?.isAddNew) {
          dataToUpdate = getConnectTemplateReduxDataAfterInsertItems(
            { ...connectTemplateDataRedux },
            parentPageName,
            pageName,
            source_table,
            currentRow,
            submitData['item_groups']['items'],
            parent_table ? (isModel ? false : true) : false
          )
        } else {
          dataToUpdate = getConnectTemplateReduxDataAfterEditItems(
            parentPageName,
            pageName,
            source_table,
            currentRow,
            submitData['item_groups']['items'],
            parent_table ? true : false
          )
          const ids = submitData['item_groups']['items']?.map((item) => item?.id)?.filter((id) => id)
          updateSyncRowId(parentPageName, pageName, ids)
        }

        dispatch(setConnectTemplateDataRedux(dataToUpdate))
      }
      setFormSubmitStatus(true)

      if (isSingleForm) {
        if (!addDataDetail?.isAddNew) {
          if (!isSaveButtonClicked) {
            dispatch(clearCurrentRowData())
            dispatch(clearParentTableData())
            setAddGridDataDetail({ open: false })
          }
        } else {
          if (isSaveButtonClicked && !addDataDetail?.tableGrid?.grid_settings?.show_add_form_on_edit) {
            const editButtonInGrid = addDataDetail?.tableGrid?.main_grid?.component_call_cols?.filter((item) => item?.icon === 'far fa-edit')
            if (addDataDetail?.currentTabData?.component_name === 'hidden_tab' && !addDataDetail?.isModel) {
              setTimeout(() => {
                //added after some time as it is affecting enable_or_disable_tab
                dispatch(setCurrentRow(submitFormData[source_table]))
                dispatch(
                  setTabFormData({
                    data: {
                      ...submitFormData[source_table],
                    },
                    main_source_table: source_table,
                  })
                )
                dispatch(
                  setParentTableData({
                    source_table: addDataDetail?.tableGrid?.source_table,
                    parent_page_name: addDataDetail?.tableGrid?.name,
                    grid_id: addDataDetail?.tableGrid?.main_grid?.id,
                    child_table: addDataDetail?.tableGrid?.child_tabs?.component_call_buttons[0]?.source_table,
                  })
                )
              }, 500)

              setAddGridDataDetail({ open: false })

              dispatch(SetFullPage(false))
            }
          } else if (!isSaveButtonClicked) {
            setAddGridDataDetail({ open: false })
          }
        }
      }
      dispatch(setNotification({ type: 'success', message: 'Form saved successfully!' }))
      setIsSaveButtonClicked(false)

      //
    } else {
      const submitData = data || submittedFormData
      let dataInPayload = {}

      if (addDataDetail?.parentPageName === 'task_monitor') {
        dataInPayload['item_groups'] = [{ ...submitData['item_groups'] }]
      } else {
        dataInPayload[source_table] = [{ ...submitData[source_table] }]
      }

      let payload = {
        data: dataInPayload,
        is_nested: true,

        domain_id,
        clause_data: isModel && addDataDetail?.tableGrid?.main_grid?.where_clause,
      }
      if (!isModel) {
        payload = {
          ...payload,
          parent_table,
          parent_table_row_id,
        }
      }
      request('insert-repeating-grid-data', 'POST', {
        ...payload,
      }).then((res) => {
        if (res.status === 202 || res.status === 200) {
          setFormSubmitStatus(true)
          dispatch(setNotification({ type: 'success', message: 'Form added successfully!' }))
          addDropdownOptionRefetchTable(source_table)
          dispatch(fetchUpdateApiData())
          if (isSingleForm) {
            if (!addDataDetail?.isAddNew) {
              dispatch(clearCurrentRowData())
              dispatch(clearParentTableData())
            }

            setAddGridDataDetail({ open: false })

            const a = window.location.hash
            if (a.split('?')?.length > 1) {
              dispatch(setCurrentUrl(`${a.split('?')[0]?.replace('#/', '')}`))
              history.push(`${a.split('?')[0]?.replace('#/', '')}`)
            }
          } else {
          }
        } else {
          dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
        }
      })
    }
  }

  const onCloseForm = () => {
    setAddGridDataDetail({ open: false })
  }

  useEffect(() => {
    const new_steps = []

    forms?.map((form, i) => {
      new_steps?.push({
        name: tidyName(form?.name),
        component: (
          <RenderSingleForm
            key={i}
            form={form}
            twoCol={true}
            onValueChange={onValueChange}
            parentPageName={parentPageName}
            grid_id={grid_id}
            setAddFormList={setAddFormList}
            formResponseData={formResponseData}
            setFormResponseData={setFormResponseData}
            submittedFormData={submittedFormData}
            setSubmittedFormData={setSubmittedFormData}
            onNextStep={onNextStep}
            formsList={forms}
            addDataDetail={addDataDetail}
            setAddGridDataDetail={setAddGridDataDetail}
          />
        ),
      })
    })
    if (forms?.length > 1) {
      new_steps?.push({
        name: 'Finish',
        component: (
          <FinishedWizardStep
            addDataDetail={addDataDetail}
            setAddGridDataDetail={setAddGridDataDetail}
            onSubmitForm={onSubmitForm}
            formSubmitStatus={formSubmitStatus}
            submittedFormData={submittedFormData}
            gridForm={forms}
          />
        ),
      })
    }

    setSteps(new_steps)
  }, [
    JSON.stringify(forms),
    JSON.stringify(formResponseData),
    JSON.stringify(submittedFormData),
    currentStep,
    formSubmitStatus,
    enabledOrDisabledMethodTab,
  ])

  const dispatch = useDispatch()

  if (forms?.length === 1)
    return (
      <RenderSingleForm
        form={forms[0]}
        twoCol={true}
        onValueChange={onValueChange}
        parentPageName={parentPageName}
        grid_id={grid_id}
        setAddFormList={setAddFormList}
        formResponseData={formResponseData}
        setFormResponseData={setFormResponseData}
        submittedFormData={submittedFormData}
        setSubmittedFormData={setSubmittedFormData}
        onNextStep={onNextStep}
        formsList={forms}
        addDataDetail={addDataDetail}
        setAddGridDataDetail={setAddGridDataDetail}
        onSubmitForm={onSubmitForm}
        onCloseForm={onCloseForm}
        setIsSaveButtonClicked={setIsSaveButtonClicked}
      />
    )

  const navStyles = getNavStates(currentStep, steps?.length)
  return (
    <ShowWizardForm
      steps={steps}
      currentStep={currentStep}
      navStyles={navStyles}
      onPreviousStep={onPreviousStep}
      onNextStep={() => onNextStep()}
      formResponseData={formResponseData}
      onSetStep={(step) => onSetStep(step)}
    />
  )
}

const FinishedWizardStep = ({ addDataDetail, setAddGridDataDetail, onSubmitForm, formSubmitStatus, submittedFormData, gridForm }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const redirectToGroupPage = () => {
    const submitted_ids = submittedFormData?.item_groups?.id

    if (!addDataDetail?.isAddNew) {
      dispatch(clearCurrentRowData())
      dispatch(clearParentTableData())
    }
    setAddGridDataDetail({ open: false })
    dispatch(setCurrentUrl(`outstanding_tasks-list?selected_ids=${submitted_ids}`))
    history?.push(`/outstanding_tasks-list?selected_ids=${submitted_ids}`)
  }
  return (
    <div className='form-wizard-content'>
      {!formSubmitStatus && <SubmitPreview submittedFormData={submittedFormData} gridForm={gridForm} />}
      <div className='no-results'>
        {formSubmitStatus ? (
          <>
            <div className='sa-icon sa-success animate'>
              <span className='sa-line sa-tip animateSuccessTip' />
              <span className='sa-line sa-long animateSuccessLong' />
              <div className='sa-placeholder' />
              <div className='sa-fix' />
            </div>
            <div className='results-subtitle mt-4'>Finished!</div>
            {addDataDetail?.isEditForm ? (
              <div className='results-title'>You have updated data!</div>
            ) : (
              <div className='results-title'>You have added data!</div>
            )}

            <div className='mt-3 mb-3' />
            <div className='text-center'>
              <Button
                color='success'
                size='lg'
                className='btn-shadow btn-wide'
                onClick={() => {
                  if (!addDataDetail?.isAddNew) {
                    dispatch(clearCurrentRowData())
                    dispatch(clearParentTableData())
                  }

                  setAddGridDataDetail({ open: false })
                  const a = window.location.hash
                  if (a.split('?')?.length > 1) {
                    dispatch(setCurrentUrl(`${a.split('?')[0]?.replace('#/', '')}`))
                    history.push(`${a.split('?')[0]?.replace('#/', '')}`)
                  }
                }}>
                Go Back to Main Page
              </Button>
            </div>
            {addDataDetail?.tableGrid?.source_table === 'items' && !addDataDetail?.isAddNew && (
              <div className='text-center'>
                <Button
                  color='success'
                  outline
                  size='lg'
                  className='btn-shadow btn-wide'
                  style={{ marginTop: '0.4rem' }}
                  onClick={() => {
                    redirectToGroupPage()
                  }}>
                  Go Back to Outstanding Page
                </Button>
              </div>
            )}
          </>
        ) : (
          <div>
            <Button
              color='success'
              size='lg'
              className='btn-shadow btn-wide float-left'
              style={{ marginLeft: '-1.5rem' }}
              onClick={() => {
                onSubmitForm()
              }}>
              {addDataDetail?.isEditForm ? 'Update Form' : 'Submit Form'}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}
export default ShowAllForms
