import useMediaQuery from '../../CustomHook/useMediaQuery'
import { primary_color } from '../../Helper/uiHelper'
import { cms } from '../../helper'

function _getStudentData(data, component_name) {
  const unitData = data?.units
  const studentData = []
  const companyData = []
  if (data?.domain_name && data?.domain_address) {
    companyData.push(
      {
        label: 'Name',
        value: data.domain_name,
      },
      {
        label: 'Address',
        value: data.domain_address,
      }
    )
  }
  
 
  studentData.push(
    [
      {
        label: 'Name',
        value: data?.user_name,
      },
      {
        label: 'Reservation ID',
        value: data?.booking_id,
      },
    ],
    [
      {
        label: 'Discount Applied',
        value: data?.total_discount,
      },
    ],
    [
      {
        label: 'Total Cost',
        value: data?.total_cost,
      },
    ]
  )

  if (component_name === 'student_details') return studentData
  else  return companyData

}

const StudentDetails = ({ bookingDetails, widgets, data }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')
    
   const reservedData =  _getStudentData(bookingDetails?.bookingReservation, widgets?.component_name)
    return (
      <div className='mt-5' style={widgets?.component_name  === 'company_details' && isDesktop ? {paddingRight : "20%"} : {paddingInline : '1rem'}}>
        <div class='h4 font-weight-bold mb-3' style={{ color: primary_color }}>
          {/* {widgets?.component_name ? cms(widgets?.component_name) : 'Student Details'} */}
          {widgets?.component_name === 'student_details' ? 'Booking Details' : 'Company Info'}
        </div>
        {widgets?.component_name === 'student_details' ? (
          <>
            {reservedData?.map((element) => {
              return (
                <>
                  {element?.map((item) => {
                    return (
                      <div className='mb-3'>
                        <div className='mb-1 font-weight-bold'>{item?.label}</div>
                        <div>{item?.value}</div>
                      </div>
                    )
                  })}
                </>
              )
            })}
          </>
        ) : (
          reservedData?.map((item) => {
            return (
              <div className='mb-3'>
                <div className='mb-1 font-weight-bold'>{item?.label}</div>
                <div>{item?.value}</div>
              </div>
            )
          })
        )}
      </div>
    )
  }

export default StudentDetails
