import React, { useState } from 'react'
import { primary_color } from '../../../Helper/uiHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { setCookie } from '../../../Helper/cookieHelper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { useDispatch } from 'react-redux'
import { setPublicUser } from '../../../Redux/Reducers/user'
const inputIconStyle = {
  position: 'absolute',
  fontSize: '1rem',
  top: '50%',
  left: '15px',
  transform: 'translate(-0%, -50%)',
  color: 'grey',
}
const Register = ({ onRegister=()=>{} , title="", showValidation = false, setShowValidation = () => {} }) => {
  const [isPasswordMatch, setIsPasswordMatch] = useState(true)
  const [userData, setUserData] = useState({
    first_name: '',
    surname: '',
    email: '',
    phone_number: '',
    password: '',
    confirm_password: '',
  })
  const dispatch = useDispatch()
  const _handleChange = (e) => {
    let { name, value } = e.target

    if (name === 'phone_number') {
      value = value.replace(/\D/g, '')
    }

    setShowValidation(false)

    setUserData((prev) => {
      return { ...prev, [name]: value }
    })
  }
  const _handleSubmit = (e) => {
    e.preventDefault()

    if (userData.password === userData.confirm_password) setIsPasswordMatch(true)
    else return setIsPasswordMatch(false)
    // if (userData.password.length < 8) return dispatch(setNotification({ type: 'error', message: 'The password must be at least 8 characters.' }))

    const { first_name, surname, email, phone_number, password, confirm_password } = userData
    if (first_name && surname && email && phone_number && password && confirm_password) {
      setShowValidation('Success')
      const payload = { first_name, surname, email, phone_number, password }

      request('register', 'POST', { ...payload }, { accept: 'Application/json' })
        .then((res) => {
          console.log('register response', res)
          const { data } = res
          if (data.status === 1) {
            setCookie('AUTH_TOKEN', data?.token)
            onRegister()
            dispatch(setNotification({ type: 'success', message: data.message, status: data.status }))
            request('profile').then((res) => {
              if (res?.status === 200 || res?.status === 202) {
                dispatch(setPublicUser(res?.data?.data))
              }
            })
          } else {
            // dispatch(setNotification({ type: 'error', message: data.message, status: data.status }))
            if (res.data?.errors)
              for (let key in res.data?.errors) {
                if (res.data?.errors.hasOwnProperty(key)) {
                  dispatch(setNotification({ type: 'error', message: res.data?.errors[key][0] }))
                  break
                }
              }
          }
        })
        .catch((e) => {
          console.log('register response', e)
        })
    } else setShowValidation(true)
  }
  return (
    <div>
      {title && (
        <div className='h4 font-weight-bold mb-4' style={{ color: primary_color }}>
          {title}
        </div>
      )}
      <form onSubmit={_handleSubmit} className=' d-flex flex-column' style={{ gap: '0.75rem' }}>
        <div className=' position-relative'>
          <input
            className={`form-control rounded-pill border border-black pl-5 ${showValidation && !userData.first_name ? 'error-input' : ''}`}
            onChange={_handleChange}
            name='first_name'
            value={userData.first_name}
            placeholder='First name'
            required
          />
          <i style={inputIconStyle} className='fa-regular fa-user'></i>

          {/* {showValidation && !formValues.password && <div className='text-danger text-left pl-2'>Please enter card holder name</div>} */}
          {showValidation && !userData.first_name && <div className='text-danger text-left pl-2'>Please enter card First name</div>}
        </div>
        <div className=' position-relative'>
          <input
            className={`form-control rounded-pill border border-black pl-5 ${showValidation && !userData.surname ? 'error-input' : ''}`}
            onChange={_handleChange}
            name='surname'
            value={userData.surname}
            placeholder='Surname'
            required
          />
          <i style={inputIconStyle} className='fa-regular fa-user'></i>

          {showValidation && !userData.surname && <div className='text-danger text-left pl-2'>Please enter Surname</div>}
        </div>
        <div className=' position-relative'>
          <input
            className={`form-control rounded-pill border border-black pl-5 ${showValidation && !userData.email ? 'error-input' : ''}`}
            onChange={_handleChange}
            name='email'
            type='email'
            value={userData.email}
            placeholder='Email'
            required
          />
          <i style={inputIconStyle} className='fa-regular fa-envelope'></i>

          {showValidation && !userData.email && <div className='text-danger text-left pl-2'>Please enter Email</div>}
        </div>
        <div className=' position-relative'>
          <input
            className={`form-control rounded-pill border border-black pl-5 ${showValidation && !userData.phone_number ? 'error-input' : ''}`}
            onChange={_handleChange}
            name='phone_number'
            type='text'
            value={userData.phone_number}
            placeholder='Phone'
            required
          />
          <i style={inputIconStyle} className='fa-regular fa-phone'></i>

          {showValidation && !userData.phone_number && <div className='text-danger text-left pl-2'>Please enter phone number</div>}
        </div>
        <div className=' position-relative'>
          <input
            className={`form-control rounded-pill border border-black pl-5 ${showValidation && !userData.password ? 'error-input' : ''}`}
            onChange={_handleChange}
            name='password'
            value={userData.password}
            placeholder='Password'
            type='password'
            required
          />
          <i style={inputIconStyle} className='fa-regular fa-hashtag'></i>

          {showValidation && !userData.password && <div className='text-danger text-left pl-2'>Please enter Password</div>}
        </div>
        <div className=' position-relative'>
          <input
            className={`form-control rounded-pill border border-black pl-5 ${showValidation && !userData.confirm_password ? 'error-input' : ''}`}
            onChange={_handleChange}
            name='confirm_password'
            value={userData.confirm_password}
            placeholder='Confirm password'
            type='password'
            required
          />
          <i style={inputIconStyle} className='fa-regular fa-hashtag'></i>

          {showValidation && !userData.confirm_password && <div className='text-danger text-left pl-2'>Please enter confirm Password</div>}
          {!isPasswordMatch && <div className='text-danger text-left pl-2'>Password and confirm Password is not matching.</div>}
        </div>
        <button className='btn w-100 text-white rounded-pill mt-2' type='submit' style={{ background: primary_color }}>
          Register
        </button>
      </form>
    </div>
  )
}

export default Register
