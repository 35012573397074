import LockerBooking from '../../Form/Fields/lockerBooking'
import RoundedInput from '../../Form/Fields/roundedInput'
import RoundedSelect from '../../Form/Fields/roundedSelect'

const PublicFields = ({
  data,
  setFormFieldValue,
  formFieldValue,
  parentData,
  form,
  validationError,
  setValidationError,
  allFormsData,
  unitData,
  setUnitData,
  formIndex,
  setFormData ,
}) => {
  let fieldType
  if (data?.field_setting?.type === 'pre_time') {
    fieldType = 'pre_time'
  } else if (data?.field_setting?.type === 'post_time') {
    fieldType = 'post_time'
  } else {
    fieldType = data?.field_setting?.field || data?.field || data?.component_name
  }

  switch (fieldType) {
    case 'input':
      return <RoundedInput data={data} setFormFieldValue={setFormFieldValue} formFieldValue={formFieldValue} formIndex={formIndex} setFormData={setFormData}/>
    case 'item_location_booking':
    case 'locker_booking':
      return (
        <LockerBooking
          validationError={validationError}
          setValidationError={setValidationError}
          data={data}
          setFormFieldValue={setFormFieldValue}
          formFieldValue={formFieldValue}
          parentData={parentData}
          form={form}
          allFormsData={allFormsData}
          unitData={unitData}
          setUnitData={setUnitData}
          formIndex={formIndex}
  
          setFormData={setFormData}
        />
      )
    case 'select':
      return (
        <RoundedSelect
          validationError={validationError}
          setValidationError={setValidationError}
          data={data}
          setFormFieldValue={setFormFieldValue}
          formFieldValue={formFieldValue}
          parentData={parentData}
          form={form}
          setFormData={setFormData}
          formIndex={formIndex}
        />
      )
    default:
      return <RoundedInput data={data} />
  }
}

export default PublicFields
