import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getDependDataPayload } from '../../../CMSComponent/Forms/Fields/select'
import { getLocalStorageItem } from '../../../Helper/cookieHelper'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'
import { request } from '../../../Redux/Sagas/requests/api'
import { RiDoorLine } from 'react-icons/ri'
import LockerQuestion from './lockerQuestion'
import { primary_color } from '../../../Helper/uiHelper'
import Loader from 'react-loaders'
import useMediaQuery from '../../../CustomHook/useMediaQuery'

const createDefaultAnswersNew = (questions) => {
  const obj = {}
  questions.forEach((e) => (obj[e.uuid] = e.answer || ''))
  return obj
}

const LockerBooking = ({
  data,
  formFieldValue = {},
  setFormFieldValue,
  parentData,
  form,
  validationError,
  setValidationError,
  allFormsData,
  unitData,
  setUnitData,
  formIndex,
  setFormData
}) => {
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery('(min-width:768px)')

  const [selectedLocker, setSelectedLocker] = useState()
  const [lockers, setLockers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [questions, setQuestions] = useState([])
  const lockerColWidth = isDesktop ? 131.3 : 99.2
  const [lockerColCount , setLocakerCoCount] = useState(0)


  useEffect(() => {
    if (unitData?.length && unitData[formIndex]) setQuestions(unitData[formIndex].questions)
  }, [JSON.stringify(unitData), formIndex])

  const dependent_value = formFieldValue?.[data?.component_call_data_connection?.source_field]

  //* Convert API Data into vertical Array
  const displayVertically = (arr, rowCount) => {
    const output = arr.reduce((rows, item, index) => {
          const rowIndex = index % rowCount;
          rows[rowIndex] = rows[rowIndex] || [];
          rows[rowIndex].push(item);
          return rows;
      }, []);

      return output;
  }

  const fetchData = async (payload) => {
    setIsLoading(true)
    try {
      const response = await request('get-template-data-depend', 'POST', { ...payload })
      if (response?.status === 200 || response?.status === 202) {
        // const new_lockers = []
         const formattedData = displayVertically(response?.data?.data, response?.data?.lockerHeight)
       const new_lockers =  formattedData?.map((item) => {
          return item?.map((dataItem) => {
            return {
            id: dataItem?.id,
            reserved: dataItem?.completed || dataItem?.reserved ? true : false,
            display: dataItem?.display,
            item_name: dataItem?.name,
            price: dataItem?.rate,
            questions: response?.data?.questions
          }
          })
        })
        setLocakerCoCount(formattedData[0]?.length)
        setLockers(new_lockers)
        console.log("new_lockers",new_lockers,response?.data?.data)
      }
    } catch (e) {
      console.error('Error in get-template-data-depend : ', e)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!dependent_value) {
      setLockers([])
      return
    }
    let payload = getDependDataPayload(form?.component_call_fields, data?.component_call_data_connection, dependent_value)
    fetchData(payload)
  }, [dependent_value])
  const onChangeValue = (reserved, value, item) => {
    let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
    let current_storage_timer = storage_timer?.[parentData?.id]
    const selected_items = allFormsData?.map((forms) => forms[data?.field_id])
    if (reserved && !unitData.map((e) => e.item_id).includes(item?.id))
      return dispatch(
        setNotification({
          type: 'error',
          message: 'This locker is already reserved.',
        })
      )
    if (selected_items?.includes(item?.id)) {
      dispatch(
        setNotification({
          type: 'error',
          message: 'This locker is already selected.',
        })
      )
      return
    }
    let payload = {
      items: [value],
      session_id: current_storage_timer?.session_id,
      uuid: formFieldValue?.uuid,
    }
    request('check-item-availability', 'POST', payload).then((res) => {
      if (res.status === 200 || res?.status === 202) {
        setSelectedLocker(value)
        setValidationError(false)
        setQuestions(item?.questions)
        let updatedValue = {
          ...formFieldValue,
        }
        updatedValue[data?.field_id] = value
        if (!item?.questions || item?.questions?.length === 0) {
          delete updatedValue['questions']
        }
        setFormData((prevFormsData) => {
          console.log('prev form data:::::::',prevFormsData, formIndex)
          const newData = prevFormsData.map((ele, i) => {
            if (formIndex === i) {
              let newdata = {
                ...ele,
              }
              newdata[data?.field_id] = item?.id
              newdata['questions'] = createDefaultAnswersNew(item?.questions)
              return newdata
            } else {
              return ele
            }
          })
          console.log('prev form data:::::::', newData, formIndex)
          return newData
        })
        // setFormFieldValue({...updatedValue , questions : createDefaultAnswersNew(item?.questions)})
      } else {
        dispatch(
          setNotification({
            type: 'error',
            message: res?.data?.message,
          })
        )
        let payload = getDependDataPayload(form?.component_call_fields, data?.component_call_data_connection, dependent_value)
        fetchData(payload)
      }
    })
  }
  const getBgColor = (item) => {
    const selected_items = allFormsData?.map((forms) => forms[data?.field_id])
    console.log("item", item)
    if (formFieldValue[data?.field_id] === item?.id) {
      return '#059669CC'
    } else if (item?.display === 0) {
      return '#FFFFFF'
    } else if ((item?.reserved && !unitData.map((e) => e.item_id).includes(item?.id)) || selected_items?.includes(item?.id)) {
      return '#F87171'
    }else {
      return primary_color
    }
  }

  if (isLoading)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ height: '10rem' }}>
        <Loader type='ball-pulse' color={primary_color} />
      </div>
    )
  if (!lockers.length && dependent_value)
    return (
      <div className='d-flex align-items-center justify-content-center' style={{ height: '10rem' }}>
        No lockers found.
      </div>
    )

  return (
    <div className='mt-2'>
      {lockers?.length ? (
        <div className='d-flex align-items-center mb-5 mt-5' style={{ fontWeight: '500' }}>
          <div className='rounded-pill mr-2' style={{ height: '1rem', width: '1rem', background: '#059669CC' }}></div> Selected
          <div className='rounded-pill mr-2 ml-3' style={{ height: '1rem', width: '1rem', background: primary_color }}></div> Available
          <div className='rounded-pill mr-2 ml-3' style={{ height: '1rem', width: '1rem', background: '#F87171' }}></div> Reserved
        </div>
      ) : (
        <></>
      )}
      <div className=' mb-2 !w-100 custom-scrollbar' style={{overflow: 'auto' }}>
       <div style={{ width: lockerColWidth * lockerColCount + "px"}} className='d-flex flex-wrap'>
         {lockers?.map((locakerItem, index) => {
          return locakerItem?.map((item) => {
            const isDisplayZero = item?.display === 0 ? true : false;
            return (
              <div
                key={index}
                onClick={() => {
                  onChangeValue(item?.reserved, item?.id, item)
                }}
                className=' position-relative'
                style={{
                  width: isDesktop ? '8rem' : '6rem',
                  margin: '0.1rem',
                  height: isDesktop ? '10rem' : '9rem',
                  background: getBgColor(item),
                  cursor: (!item?.reserved || !isDisplayZero) ? 'pointer' : 'default',
                }}>
                  {
                    !isDisplayZero ? 
                    <div
                      className='m-1 position-absolute'
                      style={{ left: '0.5rem', top: '0.25rem', background: 'white', padding: '0rem 0.5rem', borderRadius: '14px', fontWeight: '500' }}>
                      <div>£{item?.price}</div>
                    </div> : null
                  }
                
                <div className='position-absolute' style={{ top: '50%', left: '50%', transform: 'translate(-50% , -50%)' }}>
                  {/* <i class='fa-thin fa-door-closed' style={{ fontSize: '5rem' }}></i> */}
                  <RiDoorLine style={{ color: '#DADADA', fontSize: '6rem' }} />
                </div>
                <div
                  className='text-align-center mt-2'
                  style={{
                    color: '#EDF0F7',
                    position: 'absolute',
                    bottom: '1rem',
                    fontWeight: '500',
                    left: '50%',
                    transform: 'translate(-50%)',
                  }}>
                  {item?.item_name}
                </div>
              </div>
            )
          })
        
        })}
       </div>
      </div>
      {questions && questions?.length > 0 && (
        <div className='mb-4 mt-5' style={{ color: primary_color, fontSize: '1.3rem', fontWeight: 700 }}>
          Student Details
        </div>
      )}
      {questions && questions?.length > 0 && (
        <LockerQuestion
          validationError={validationError}
          setValidationError={setValidationError}
          questions={questions}
          formFieldValue={formFieldValue}
          setFormFieldValue={setFormFieldValue}
          formIndex={formIndex}
          setFormData={setFormData}
        />
      )}
    </div>
  )
}

export default LockerBooking
