import React, { Fragment, useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import Slider from 'react-slick'
import { Col, Row } from 'reactstrap'
import cx from 'classnames'

import { getWelcomImages } from '../../Redux/Reducers/slides'
import ErrorAlert from '../errorAlert'
import { renderBlockColorStyle, renderBlockStyle } from '../../helper'

const select = (state) => ({
  slides: state.Slides.slides,
  logo: state.Slides.logo,
})

const getSlidesBasedOnPage = (slides) => {
  if (window.location.hash?.includes('login')) {
    const slides_to_show = slides?.filter((item) => item?.page === 'Log In')
    return slides_to_show
  } else if (window.location.hash?.includes('register')) {
    const slides_to_show = slides?.filter((item) => item?.page === 'Sign Up')
    return slides_to_show
  } else {
    const slides_to_show = slides?.filter((item) => item?.page === 'Forgot Password')
    return slides_to_show
  }
}

const FormClassWrapper = ({ slides, data, children, message, title, titleColor, messageColor, logo, sliderTitle = '', sliderSubTitle = '' }) => {
  const dispatch = useDispatch()

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: true,
    adaptiveHeight: true,
  }
  useEffect(() => {
    if (slides === null) {
      dispatch(getWelcomImages())
    }
  }, [slides])

  const slides_to_show = getSlidesBasedOnPage(slides)

  return (
    <Fragment>
      <div className='h-100'>
        <Row className='h-100 no-gutters'>
          <Col lg='4' className='d-none d-lg-block'>
            <div className='slider-light'>
              <Slider {...settings}>
                {console.log('slides to show::::::', slides_to_show)}
                {slides_to_show &&
                  slides_to_show.length > 0 &&
                  slides_to_show?.map((item, key) => (
                    <div key={key} className='h-100 d-flex justify-content-center align-items-center bg-plum-plate'>
                      <div
                        className='slide-img-bg'
                        style={{
                          backgroundImage: item.image ? 'url(' + process.env.REACT_APP_URL + item.image + ')' : 'url(background/default_slide.jpeg)',
                        }}
                      />
                      <div className='slider-content'>
                        <h3>{item?.title}</h3>
                        <p>{item?.sub_title}</p>
                        {/* <p>{data}</p> */}
                      </div>
                    </div>
                  ))}
                {slides_to_show?.length === 0 && (
                  <div className='h-100 d-flex justify-content-center align-items-center bg-plum-plate'>
                    <div
                      className='slide-img-bg'
                      style={{
                        backgroundImage: 'url(background/default_slide.jpeg)',
                      }}
                    />
                  </div>
                )}
              </Slider>
            </div>
          </Col>

          <Col lg='8' md='12' className='h-100 d-md-flex d-sm-block bg-white justify-content-center align-items-center'>
            <Col lg='9' md='10' sm='12' className='mx-auto app-login-box'>
              {logo && <img alt='logo' src={`${process.env.REACT_APP_STORAGE}/${logo}`} className='auth-page-logo' />}
              <br />
              <span className={cx('logoTextStart', titleColor?.split(' ')[0])} style={renderBlockColorStyle(titleColor)}>
                {title}
              </span>
              <h4 className={messageColor?.split(' ')[0]} style={{ fontSize: '1.1rem', ...renderBlockColorStyle(messageColor) }}>
                {message}
              </h4>
              <Row className='divider' />
              <div>{children}</div>
            </Col>
          </Col>
        </Row>
        <ErrorAlert />
      </div>
    </Fragment>
  )
}

export default connect(select)(FormClassWrapper)
