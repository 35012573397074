
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
const HeroBanner = ({ data, searchData, setSearchData, clearSearchData, setClearSearchData }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  console.log('hero banner::::::::::',data)
  return (
    <div
      className='locker-container d-flex justify-content-center'
      // style={{ background: '#F5FCFF' }}
      style={{ background: `url(${process.env.REACT_APP_PUBLIC_STORAGE + data?.bg_image})`, backgroundSize: 'cover' }}>
      <div
        style={{
          padding: isDesktop ? '3rem 2rem' : '1rem',
          marginBlock: '3rem',
          background: 'rgb(255 255 255 / 60%)',
          width: 'fit-content',
          maxWidth: isDesktop ? '40rem' : '',
        }}>
        <div className='d-flex flex-column align-items-center'>
          {/* {data?.hero_search_icon ? (
            <img src={process.env.REACT_APP_PUBLIC_STORAGE + data?.hero_search_icon} width={70} height={70} className='mb-2' />
          ) : (
            <i className='fa-solid fa-school' style={{ fontSize: '3rem', color: primary_color }}></i>
          )} */}
          {/* <i className='fa-thin fa-school' style={{ fontSize: '3rem', color: primary_color }}></i> */}
          <div class='mt-3' style={isDesktop ? { width: '40rem' } : {}}>
            <p className='text-center' style={{ color: primary_color, fontSize: '40px', fontWeight: '800' }}>
              {data?.heading}
            </p>
            <p className='text-center' style={{ color: '#8E8E8E' }}>
              {data?.text}
            </p>
            {/* <p className='text-center'>{data?.sub_heading}</p> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroBanner
