export const configEnv = {
  dev: {
    grid: 'https://dev-cms.ionicbox.com/v1',
    page: 'https://dev-cms.ionicbox.com/v1',
    cms: 'https://dev-cms.ionicbox.com/v1',
    theme: 'https://dev-cms.ionicbox.com/v1',
    connectTemplate: 'https://dev-cms.ionicbox.com/v1',
    payment: 'https://dev-payment.ionicbox.com/v1',
  },
  test: {
    grid: 'https://test-cms.ionicbox.com/v1',
    page: 'https://test-cms.ionicbox.com/v1',
    cms: 'https://test-cms.ionicbox.com/v1',
    theme: 'https://test-cms.ionicbox.com/v1',
    connectTemplate: 'https://test-cms.ionicbox.com/v1',
    payment: 'https://test-payment.ionicbox.com/v1',
  },
  prod: {
    grid: 'https://beta-cms.ionicbox.com/v1',
    page: 'https://beta-cms.ionicbox.com/v1',
    cms: 'https://beta-cms.ionicbox.com/v1',
    theme: 'https://beta-cms.ionicbox.com/v1',
    connectTemplate: 'https://beta-cms.ionicbox.com/v1',
    payment: 'https://beta-payment.ionicbox.com/api/v1',
  },
}
