import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Button,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "reactstrap";
import { capitalize, cms, editCMS } from "../../../helper";
import IconSet from "../../icon";

const select = (state) => ({
  editMode: state.CMS.editMode,
});

const CMSButton = ({ item, triggerActions = () => {}, editMode, gridSelectedRow, disabled, singleRow = false, noConfirmation, onClickButton=()=>{} }) => {
  const [dropdown, updateDropdown] = useState([])
  const [dropdownOpen, updateDropdownOpen] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  useEffect(() => {
    if (item.drop_down_options) {
      updateDropdown(item.drop_down_options)
    } else {
      updateDropdown([])
    }
  }, [item])

  if (dropdown.length > 0) {
    return (
      <>
        <ButtonDropdown
          isOpen={dropdownOpen}
          direction='left'
          toggle={() => updateDropdownOpen(!dropdownOpen)}
          className='border-0 btn-icon btn-icon-only fsize-1 m-2'
          id={item.name}
          color={item.background_color === 'link' ? 'secondary' : item.background_color || 'secondary'}
          disabled={disabled}>
          <DropdownToggle caret>
            <IconSet icon={item.icon || 'fas fa-file'} type={item.icon_type} color={item.color || 'none'} fontWeight={400} />
          </DropdownToggle>
          <DropdownMenu>
         
            {dropdown.map((item1, key) => {
              return (
                <DropdownItem key={key} onClick={() => (editMode ? editCMS(item1.cms_key) : triggerActions(item1.component_call_actions))}>
                  <IconSet
                    icon={item1.icon || 'fas fa-file'}
                    type={item1.icon_type}
                    color={item1.color || 'light'}
                    fontWeight={400}
                    className='mr-2'
                  />
                  {'  ' + capitalize(cms(item1.cms_key))}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </ButtonDropdown>
        {/* <UncontrolledTooltip placement='bottom' target={item.name}>
          {cms(item.cms_tooltip)}
        </UncontrolledTooltip> */}
      </>
    )
  }
  if (
    item.component_call_actions &&
    item.component_call_actions?.length !== 0 &&
    (item.component_call_actions[0]?.component_name === 'connect_delete_item_template' ||
      item.component_call_actions[0]?.component_name === 'connect_delete_trash_item_template')
  ) {
    return (
      <>
        <Button
          className='border-0 btn-icon btn-icon-only fsize-1 m-2'
          id={item.name}
          color={item.background_color || 'secondary'}
          onClick={() => {
            if (!noConfirmation) setDeleteModal(true)
          }}
          disabled={gridSelectedRow?.length > 0 ? false : singleRow ? false : true}>
          <IconSet icon={item.icon || 'fas fa-file'} type={item.icon_type} color={item.color || 'none'} fontWeight={400} />
          {/* {' ' + cms(item.cms_key)} */}
        </Button>
        <Modal isOpen={deleteModal} toggle={() => setDeleteModal(false)} style={{ width: 300 }}>
          <ModalBody>
            <div>Are you sure you want to delete ?</div>
          </ModalBody>
          <ModalFooter style={{ justifyContent: 'center' }}>
            <Button
              onClick={() => {
                setDeleteModal(false)
                triggerActions(item.component_call_actions)
              }}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </>
    )
  } else {
    return (
      <>
        <Button
          className='border-0 btn-icon btn-icon-only fsize-1 m-2'
          id={item.name}
          color={item.background_color || 'secondary'}
          onClick={() => {
            triggerActions(item.component_call_actions)
            onClickButton()
          }}
          disabled={disabled}>
          <IconSet icon={item.icon || 'fas fa-file'} type={item.icon_type} color={item.color || 'none'} fontWeight={400} />
          {/* {' ' + cms(item.cms_key)} */}
        </Button>
        {/* <UncontrolledTooltip placement='bottom' target={item.name}>
          {cms(item.cms_tooltip)}
        </UncontrolledTooltip> */}
      </>
    )
  }
}

export default connect(select)(CMSButton);
