import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { dummyBookingDetail } from '../../../CMSComponent/PageVariation/PublicPage/dummyPage'
import { cms, tidyName } from '../../../helper'
import { getLocalStorageItem } from '../../../Helper/cookieHelper'
import { request } from '../../../Redux/Sagas/requests/api'
import { primary_color } from '../../../Helper/uiHelper'
import moment from 'moment'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

let dummy_data = [
  { name: 'Child name:', value: 'Nutan Khangar' },
  { name: 'Reservation ID:', value: '96458122' },
  { name: 'Start rent:', value: '01 Sept 2023' },
  { name: 'Locker Block:', value: 'First English school' },
  { name: 'Locker Number:', value: '18' },
  { name: 'End rent:', value: '31 Aug 2023' },
]

const BookingDetails = ({ bookingDetails, setBookingDetails, data, parentData, form }) => {
  const { location } = useHistory()
  const isPaymentPage = location.pathname === '/payment-success'
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const isDesktop = useMediaQuery('(min-width:768px)')

  let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer') || '')
  let current_storage_timer = storage_timer?.[parentData?.id]

  useEffect(() => {
    const fields = form?.component_call_fields?.map((item) => item?.field_id)
    if (!query.get('uuid') && !current_storage_timer?.session_uuid) return
    request('get-booking', 'POST', { uuid: query.get('uuid') ? query.get('uuid') : current_storage_timer?.session_uuid, fields }).then((res) => {
      setBookingDetails(res?.data)
    })
  }, [parentData?.id])

  if (!bookingDetails) return
  const getBookingData = (fields = [], data = {}) => {
    const booking_data = []
    data?.bookingReservation?.units?.map((item) => {
      const data_arr = []
      fields?.forEach((field) => {
        if (field?.field_id === 'pre_time' || field?.field_id === 'post_time') {
          data_arr?.push({
            name: field?.cms_key ? cms(field?.cms_key) : tidyName(field?.name),
            value: item[field?.field_id] && moment(item[field?.field_id]).format('MMM D  YYYY, hh:mm'),
          })
        } else {
          //* hide Field until Featuer ready
          if(field?.field_id !== 'reservation_id'){
            data_arr?.push({
            name: field?.cms_key ? cms(field?.cms_key) : tidyName(field?.name),
            value: item[`${field?.field_id}_label`] || item[field?.field_id],
          }) 
          }
        }
      })
      // Need to change the logic after backend response got chaange for Child Name
      if (item.questions && item?.questions[0]?.answer && item?.questions[1]?.answer) {
        for (let i = 0; i < data_arr.length; i++) {
          if (isPaymentPage)
            if (data_arr[i].name.trim().toLowerCase() === 'pupil name') {
              data_arr[i].value = item.questions[0].answer
            }
          if (data_arr[i].name.trim().toLowerCase() === 'pupil surname') {
            data_arr[i].value = item.questions[1].answer
          } else {
            if (data_arr[i].name.trim().toLowerCase() === 'child name') {
              data_arr[i].value = item.questions[0].answer + ' ' + item.questions[1].answer
              break
            }
          }
        }
      }
      booking_data?.push(data_arr)
    })

    return booking_data
  }
  const booking_data = getBookingData(form?.component_call_fields, bookingDetails)

  return (
    <div
      className={isPaymentPage ? '' : 'col-md-9 ml-auto mr-auto'}
      style={isDesktop && !isPaymentPage ? { marginTop: '1rem' } : { marginTop: '2.5rem', paddingInline: '1rem' }}>
      <div className={isDesktop ? '' : ''} style={{ marginLeft: 'auto' }}>
        <div class={isPaymentPage ? 'mb-5' : 'mb-3'}>
          <div class='h4 font-weight-bold mb-3' style={{ color: primary_color }}>
            {!isPaymentPage ? <>{cms(data?.heading) || 'Booking Details'}</> : <></>}
          </div>

          <div className={!isPaymentPage ? 'row' : ''} style={isPaymentPage ? {} : { maxWidth: '40rem' }}>
            {booking_data.map((item, i) => (
              <>
                {isPaymentPage ? (
                  <div class='h4 font-weight-bold mb-3' style={{ color: primary_color }}>
                    {`Pupil ${i + 1}`}
                  </div>
                ) : (
                  <></>
                )}
                {item?.map((element, index) => {
                  return (
                    <>
                      {isPaymentPage ? (
                        <div className={' p-0'} key={index}>
                          <div
                            className='pt-2 pl-2 pb-2'
                            style={{ background: index % 2 === 0 ? '' : '#049FDB1A', display: 'grid', gridTemplateColumns: '40% 60%' }}>
                            <div className=' font-weight-bold'>{element.name}</div>
                            <div className=''>{element.value}</div>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div className={'col-md-6'} key={index}>
                            <div className='row mb-3'>
                              <div className='ml-3 font-weight-bold' style={{ minWidth: '8rem' }}>
                                {element.name}:{' '}
                              </div>
                              <div className='col-6'>{element.value}</div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )
                })}
                {!isPaymentPage ? (
                  <div className={isPaymentPage ? 'w-100' : 'w-100 mt-3'}>
                    <hr className='mt-0'></hr>
                  </div>
                ) : (
                  <div className='mt-5'></div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BookingDetails
