import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { DropdownToggle, Dropdown, DropdownMenu } from 'reactstrap'

import Icon from '../icon'
import { getColor } from '../../helper'

import ActionCall from '../actionCall'
import NoData from '../PageVariation/noData'
import EditTopNavButton from '../EditCMS/TopNav/editTopNavButton'
import EditTopNavPage from '../EditCMS/TopNav/editTopNavPage'
import { DefaultProfileDropdown } from './profileModalData'

const select = (state) => ({
  CMS: state.CMS,
  cmsData: state.CMS.cmsData,
  user: state.User.user,
})

const HeaderNavButton = ({ CMS, nav, cmsData, user, isProfileDefaultModal }) => {
  const [dropdownOpen, updateDropdownOpen] = useState(false)
  const [bigDropdown, setBigDropdown] = useState(false)

  const dropdown = useRef()
  useEffect(() => {
    if (!nav.component_call_actions || nav.component_call_actions.length === 0) {
      setBigDropdown(false)
    }
  }, [])
  return (
    <div ref={dropdown}>
      <Dropdown
        data-name='name1'
        isOpen={dropdownOpen}
        toggle={(e) => {
          var a = e.target
          var els = []
          while (a) {
            els.unshift(a)
            a = a.parentNode
            if (a && (a.className === 'modal-body' || a.className === 'modal-footer' || a.className === 'modal-header')) {
              return
            }
          }

          const cross_element = document.createElement('span')
          cross_element.setAttribute('area-hidden', true)
          const text_node = document.createTextNode('x')
          cross_element.appendChild(text_node)
          setTimeout(() => {
            if (dropdownOpen && e.target === dropdown.current && e.target?.toString() === cross_element.toString()) return
            if (e.target?.toString() !== cross_element.toString()) {
              if (e.target.childNodes && e.target.childNodes[0] && e.target.childNodes[0]?.toString() === cross_element.toString()) {
              } else {
                updateDropdownOpen(!dropdownOpen)
              }
            }
          }, 100)
        }}>
        <DropdownToggle className='p-0 mr-2' color='link'>
          {nav?.name === 'profile_form' && user?.photo && user?.photo?.length > 0 ? (
            <img
              src={process.env.REACT_APP_URL + user.photo}
              style={{ width: '2.5rem', height: '2.5rem', borderRadius: '50%', cursor: 'pointer', objectFit: 'cover' }}></img>
          ) : (
            <Icon
              data-name='name2'
              type={nav.icon_type}
              icon={nav.icon}
              style={{ color: getColor(nav.color) }}
              color={nav.color}
              navheadericon='true'
            />
          )}
        </DropdownToggle>
        <DropdownMenu
          right
          className={bigDropdown ? 'dropdown-menu-xl rm-pointers dropdown-menu-form' : 'dropdown-menu-xl rm-pointers'}
          style={{ padding: '0 0 0.65rem' }}>
          <EditTopNavButton nav={nav} />
          <EditTopNavPage nav={nav} actions={nav.component_call_actions} />
          {!isProfileDefaultModal && (
            <>
              {!nav.component_call_actions || nav.component_call_actions.length === 0 ? (
                <NoData />
              ) : (
                <ActionCall
                  isOpen={dropdownOpen}
                  dropdownData={true}
                  CMSData={CMS}
                  actions={nav.component_call_actions}
                  setBigDropdown={setBigDropdown}
                />
              )}
            </>
          )}
          {isProfileDefaultModal && <DefaultProfileDropdown user={user} nav={nav} />}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default connect(select)(HeaderNavButton)
