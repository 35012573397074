import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Card, Col, Row } from 'reactstrap'
import { cms } from '../../helper'
import { request } from '../../Redux/Sagas/requests/api'
import { primary_color } from '../../Helper/uiHelper'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import useMediaQuery from '../../CustomHook/useMediaQuery'
const default_image = 'https://test-api.ionicbox.com/storage/files/1/GyexiOYukHj8.png'

const DUMMY_DATA = [
  {
    title: 'Reservation Id',
    value: '12342',
  },
  {
    title: 'Reservation Id',
    value: '12342',
  },
  {
    title: 'Reservation Id',
    value: '12342',
  },
  {
    title: 'Reservation Id',
    value: '12342',
  },
  {
    title: 'Reservation Id',
    value: '12342',
  },
]

const getBookingDetails = (fields, data = {}) => {
  const details = fields?.map((field) => {
    let obj = {
      ...field,
    }
    obj['value'] = data[`${field?.field_id}_label`] || data[field?.field_id]
    return obj
  })
  return details
}

const OldBooking = ({ data, searchResults, activePage }) => {
  const [fields, updateFields] = useState()
  const [bookingData, setBookingData] = useState()
  const publicUser = useSelector((state) => state.User.publicUser)
  const history = useHistory()
  const location = useLocation()
  const isDesktop = useMediaQuery('(min-width:768px)')

  const searchParams = new URLSearchParams(location.search)
  useEffect(() => {
    if (!publicUser?.id) return
    const fields = []
    data?.form?.[0]?.component_call_fields?.map((field) => {
      fields?.push(field?.field_id)
    })
    request('get-old-booking', 'POST', {
      user_id: publicUser?.id,
      fields,
    }).then((res) => {
      if (res.status === 200 || res.status === 202) {
        setBookingData(res?.data)
      }
    })
    const new_fields = []
    data?.form?.[0]?.component_call_fields?.map((field) => {
      new_fields?.push({
        field_id: field?.field_id,
        title: cms(field?.cms_key),
      })
    })
    updateFields(new_fields)
  }, [publicUser?.id])

  if (!publicUser?.email || !bookingData) return

  // Homepage :-
  if (!searchParams.get('parent_id')) {
    if (!searchResults?.length) return

    return (
      <div className='col-md-9 ml-auto mr-auto'>
        <div
          className='fs-4 d-flex align-items-center justify-content-between'
          style={{ margin: isDesktop ? '3rem 3rem 3rem 0.25rem' : '3rem 0.25rem' }}>
          <span className='me-2' style={{ fontSize: '1.2rem', fontWeight: '500' }}>
            {data.heading}
          </span>
        </div>

        <div
          className=' mb-3'
          style={
            isDesktop
              ? { display: 'flex', justifyContent: 'space-between', alignItems: 'center' }
              : { display: 'grid', gridTemplateColumns: 'auto 5.5rem', gap: '0.25rem' }
          }>
          <div className='d-flex align-items-center'>
            <div style={{ padding: '6px', borderRadius: '50%', border: '2px solid #495057' }}>
              <img src={default_image} width='50' height='50' className='me-3' style={{}} />
            </div>
            <div className='ml-3 pt-1 color-black'>
              <h5 className='mb-0' style={isDesktop ? { fontWeight: '700' } : { fontWeight: '600', fontSize: '0.75rem' }}>
                {bookingData.domain_name}
              </h5>
              <div className='d-flex mt-2' style={{ flexWrap: 'wrap' }}>
                <div className='d-flex flex-column  mb-2' style={{ marginRight: '3rem' }}>
                  <div className='font-weight-bold'>Reservation ID</div>
                  <div className='text-muted'>{bookingData?.booking_id}</div>
                </div>
                <div className='d-flex flex-column  mb-2' style={{ marginRight: '3rem' }}>
                  <div className='font-weight-bold'>Date</div>
                  <div className='text-muted'>
                    {bookingData?.start_time}-{bookingData?.end_time}
                  </div>
                </div>
                <div className='d-flex flex-column  mb-2' style={{ marginRight: '3rem' }}>
                  <div className='font-weight-bold'>No of Locker</div>
                  <div className='text-muted'>{bookingData?.units?.length || 0}</div>
                </div>
              </div>

              {/* <span style={{ fontSize: isDesktop ? '0.75rem' : '0.65rem' }}>{bookingData?.address_name}</span> */}
            </div>
          </div>
          <div className='d-flex justify-content-end' style={{ marginRight: isDesktop ? '3rem' : '0', marginBlock: 'auto' }}>
            <button
              className={`btn text-white ${isDesktop ? 'pl-4 pr-4' : 'pl-2 pr-2'}`}
              style={{ borderRadius: '20px', background: primary_color, fontSize: '0.75rem' }}
              onClick={() => {
                history.push(`?parent_id=${bookingData.domain_id}&page_id=${activePage?.child_pages[0]?.uuid}&old_booking=true`)
              }}>
              {data?.button_text}
            </button>
          </div>
        </div>
      </div>
    )
  }

  // Sidebar
  const onBookAgain = () =>{
    const link = `${window.location.pathname}?parent_id=${searchParams.get('parent_id')}&page_id=${searchParams.get('page_id')}&old_booking=true`
    history.push(link)
  }
  const details = getBookingDetails(fields, bookingData)
  return (
    <div className='mt-2' style={{ padding: '2.5rem 0', borderBottom: '1px solid lightgrey' }}>
      <div className=' text-left font-weight-bold h4 mb-4' style={{ color: primary_color, fontWeight: '800' }}>
        {cms(data?.heading) ? cms(data?.heading) : 'Past Bookings'}
      </div>
      {details?.map((item, index) => {
        return (
          <div className='mb-3' key={index}>
            <div className='font-weight-bold'>{item?.title}</div>
            <div>{item?.value || '-'}</div>
          </div>
        )
      })}

      <button
        className='btn btn-primary rounded-pill mt-2 pt-2 pb-2 w-100 font-weight-bold'
        style={{ background: primary_color }}
        onClick={() => onBookAgain()}>
        {data?.button_text || 'Book Again'}
      </button>
    </div>
  )
}

export default OldBooking
