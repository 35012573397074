import { useDispatch, useSelector } from 'react-redux'
import { getConnectTemplateReduxDataAfterInsert } from '../../../helper'
import { addDataStatusClear, addDataStatusSet, setConnectTemplateDataRedux } from '../../../Redux/Reducers/CMS'
import { setQueue } from '../../../Redux/Reducers/queue'

const ReplicateColumn = ({ row, pageName, content, parentPageName, isTab }) => {
  const pages = useSelector((state) => state.CMS.coreData.page)
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const currentRow = useSelector((state) => state.CMS.currentRow)

  const dispatch = useDispatch()

  const onCopy = () => {
    const tabs = pages?.[parentPageName]?.component_call_buttons?.filter((item) => item.component_name !== 'hidden_tab').map((item) => item.name)
    const row_id = row?.row?.original?.id
    const source_table = content?.source_table
    const data = {
      row_id,
      source_table,
      tabs,
    }
    dispatch(
      setQueue({
        url: 'copy-template-data',
        payload: {
          data,
        },
      })
    )
    let { id, ...copied_row } = row?.row?.original
    const new_row = { ...copied_row, name: `${copied_row?.name} (copy)` }
    let dataToUpdate = getConnectTemplateReduxDataAfterInsert(
      connectTemplateDataRedux,
      parentPageName,
      pageName,
      content?.source_table,
      currentRow,
      new_row,
      isTab
    )
    dispatch(setConnectTemplateDataRedux(dataToUpdate))
    dispatch(addDataStatusSet())

    setTimeout(() => {
      dispatch(addDataStatusClear())
    }, 2000)
  }

  return (
    <span className={`badge badge-pill badge-info`} style={{ cursor: 'pointer' }} onClick={() => onCopy()}>
      Copy
    </span>
  )
}

export default ReplicateColumn
