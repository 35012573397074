import useMediaQuery from '../../CustomHook/useMediaQuery'
import { primary_color } from '../../Helper/uiHelper'

const dummy_widgets = [
  {
    img: 'https://dev.ionicbox.com/static/media/payment-success.9d7be726204a3ade5e01.png',
    heading: 'Rent your locker',
    sub_heading: 'Loremor sit amet, consemod tempor incididunt ut l Lorem ipsum dolor sit amet, consemod tempor incididunt ut l',
  },
  {
    img: 'https://dev-api.ionicbox.com/storage/files/20/Securelogin.png',
    heading: 'Receive your code',
    sub_heading: 'Loremor sit amet, consemod tempor incididunt ut l Lorem ipsum dolor sit amet, consemod tempor incididunt ut l',
  },
  {
    img: 'https://dev-api.ionicbox.com/storage/files/21/Mobilepayment.png',
    heading: 'Your locker is ready',
    sub_heading: 'Loremor sit amet, consemod tempor incididunt ut l Lorem ipsum dolor sit amet, consemod tempor incididunt ut l',
  },
]

const NoDataSection = () => {
  const isDesktop = useMediaQuery('(min-width:768px)')
  // const isDesktop = true

  const dummy_text =
    'Lorem ipsum dolor sit amet, consemod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consecpiscing elit, sed do eiusmod tempor inc u'
  return (
    <div className='mt-5'>
      <div
        className=' flex-column align-items-center justify-content-center text-align-center m-auto'
        style={{ display: isDesktop ? 'flex' : 'block', width: isDesktop ? '60%' : '' }}>
        <div style={{ fontSize: isDesktop ? '45px' : '30px', fontWeight: '800', color: primary_color }}>Let us help you book your locker!</div>
        <div style={{ color: '#8E8E8E' }}>{dummy_text}</div>
      </div>
      <div
        className='align-items-center justify-content-between text-align-center m-auto'
        style={{ display: isDesktop ? 'flex' : 'block', width: '80%', gap: '2rem' }}>
        {dummy_widgets.map((element, index) => {
          return (
            <div className='mt-5 mb-5 d-flex justify-content-center flex-column'>
              <div
                className='ml-auto mr-auto mb-4 d-flex align-items-center justify-content-center text-white font-weight-bold'
                style={{
                  background: primary_color,
                  borderRadius: '50%',
                  height: '40px',
                  width: '40px',
                  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                  fontSize: '1.2rem',
                }}>
                {index + 1}
              </div>
              <div>
                <img src={element.img} style={{ objectFit: 'contain', height: '200px', width: '270px' }} />
              </div>
              <div className='mt-5 mb-4' style={{ fontWeight: '800', fontSize: '1.2rem' }}>
                {element.heading}
              </div>
              <div className=' font-weight-light' style={{ color: primary_color }}>
                {element.sub_heading}
              </div>
            </div>
          )
        })}
      </div>
      <div className='ml-auto mr-auto mt-5 mb-5' style={{ width: '60%' }}>
        <hr />
      </div>
      <div
        className='ml-auto mr-auto align-items-center'
        style={isDesktop ? { display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10%', width: '80%' } : { width: '90%', marginBottom: '2rem' }}>
        <div>
          <div className='mb-4' style={{ fontSize: isDesktop ? '45px' : '30px', fontWeight: '800', color: primary_color }}>
            Lorem ipsum dolor
          </div>
          <div className='mb-2' style={{ fontSize: isDesktop ? '30px' : '20px', fontWeight: '400' }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          </div>
          <div style={{ fontSize: '16px', fontWeight: '500', color: '#8E8E8E' }}>
            lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
            minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
            voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia
            deserunt mollit anim id est laborum.
          </div>
        </div>
        <div>
          <img style={{ width: '100%', marginTop: isDesktop ? '0' : '2rem' }} src='https://dev-api.ionicbox.com/storage/files/22/Searching.png' />
        </div>
      </div>
    </div>
  )
}

export default NoDataSection
