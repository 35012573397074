const QR = ({ data }) => {

  let image_src 
  if (data?.includes('base64,')){
    // image_src = process.env.REACT_APP_URL_FILES + data
    image_src = data
    
  } else if(data){
    image_src = process.env.REACT_APP_URL_FILES + data
  }else return
  return <img src={image_src} height={50} style={{ maxWidth: '100%', overflow: 'hidden' }}></img>
}

export default QR
