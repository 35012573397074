import { Card } from 'reactstrap'
import { Progress } from 'react-sweet-progress'
import { cms } from '../../helper'
import { useEffect, useState } from 'react'
import { getLocalStorageItem, setLocalStorageItem } from '../../Helper/cookieHelper'
import { v4 as uuidv4 } from 'uuid'
import { request } from '../../Redux/Sagas/requests/api'
import { useDispatch } from 'react-redux'
import { setNotification } from '../../Redux/Reducers/notificationHandling'
import { primary_color } from '../../Helper/uiHelper'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { setTimerChanged } from '../../Redux/Reducers/timer'

function getDifferenceInSeconds(dateString2) {
  // Convert date strings to Date objects
  const date1 = new Date()
  const date2 = new Date(dateString2)

  // Calculate the difference in milliseconds
  const difference = Math.abs(date1 - date2)

  // Convert milliseconds to seconds
  const differenceInSeconds = Math.floor(difference / 1000)

  return differenceInSeconds
}

const ReservationExpire = ({ data, parentData, widgetUUID, setParentData, pageData, setActivePage, onGoBackToHomePage }) => {
  const [timeLeft, setTimeLeft] = useState(12 * 60)
  const [addTimerDisabled, setAddTimerDisabled] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const dispatch = useDispatch()

  let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
  let current_storage_timer = storage_timer?.[parentData?.id]

  let percent = 100
  if (current_storage_timer?.duration) {
    percent = Math.round(((current_storage_timer?.duration - timeLeft) / current_storage_timer?.duration) * 100)
    percent = 100 - percent // Reverse the percentage
  }

  const resetPage = () => {
    dispatch(setNotification({ type: 'error', message: 'Time is expired!' }))
    onGoBackToHomePage()
  }

  useEffect(() => {
    let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
    let current_storage_timer = storage_timer?.[parentData?.id]

    let interval

    if (!storage_timer) {
      let data = {}
      const new_session_id = 'BOOKING-SESSION-' + uuidv4()
      let payload = {
        session_key: new_session_id,
        reservation_widget: widgetUUID,
        domain_id: searchParams.get('parent_id'),
      }
      let current_row_timer = {
        session_id: new_session_id,
      }
      request('create-reservation', 'POST', payload).then((res) => {
        current_row_timer = {
          ...current_row_timer,
          time: new Date().toString(),
          session_uuid: res?.data?.data?.uuid,
          duration: 720,
        }
        data[parentData?.id] = current_row_timer
        setLocalStorageItem('timer', JSON.stringify(data))
        setTimeLeft(12 * 60)
        interval = setInterval(() => {
          setTimeLeft((prevTimeLeft) => {
            if (prevTimeLeft <= 0) {
              request(`expire-reservation-session/${current_storage_timer?.session_uuid}`, 'GET')
              clearInterval(interval)
              resetPage()
              return null
            }
            return prevTimeLeft - 1
          })
        }, 1000)
      })
    }
    if (parentData?.id && storage_timer && current_storage_timer) {
      if (getDifferenceInSeconds(current_storage_timer?.time) <= parseInt(current_storage_timer?.duration)) {
        setTimeLeft(parseInt(current_storage_timer?.duration) - getDifferenceInSeconds(current_storage_timer?.time))
        interval = setInterval(() => {
          setTimeLeft((prevTimeLeft) => {
            if (prevTimeLeft <= 0) {
              request(`expire-reservation-session/${current_storage_timer?.session_uuid}`, 'GET')
              clearInterval(interval)
              resetPage()
              return null
            }
            return prevTimeLeft - 1
          })
        }, 1000)
      } else {
        request(`expire-reservation-session/${current_storage_timer?.session_uuid}`, 'GET')
        let data = {
          ...storage_timer,
        }
        const new_session_id = 'BOOKING-SESSION-' + uuidv4()
        let payload = {
          session_key: new_session_id,
          reservation_widget: widgetUUID,
          domain_id: searchParams.get('parent_id'),
        }
        let current_row_timer = {
          session_id: new_session_id,
        }
        request('create-reservation', 'POST', payload).then((res) => {
          current_row_timer = {
            ...current_row_timer,
            time: new Date().toString(),
            session_uuid: res?.data?.data?.uuid,
            duration: 720,
          }
          data[parentData?.id] = current_row_timer
          setLocalStorageItem('timer', JSON.stringify(data))
          setTimeLeft(12 * 60)
          interval = setInterval(() => {
            setTimeLeft((prevTimeLeft) => {
              if (prevTimeLeft <= 0) {
                request(`expire-reservation-session/${current_storage_timer?.session_uuid}`, 'GET')
                clearInterval(interval)
                resetPage()
                return null
              }
              return prevTimeLeft - 1
            })
          }, 1000)
        })
      }
    } else if (storage_timer && !current_storage_timer) {
      let data = {
        ...storage_timer,
      }
      const new_session_id = 'BOOKING-SESSION-' + uuidv4()
      let payload = {
        session_key: new_session_id,
        reservation_widget: widgetUUID,
        domain_id: searchParams.get('parent_id'),
      }
      let current_row_timer = {
        session_id: new_session_id,
      }
      request('create-reservation', 'POST', payload).then((res) => {
        current_row_timer = {
          ...current_row_timer,
          time: new Date().toString(),
          session_uuid: res?.data?.data?.uuid,
          duration: 720,
        }
        data[parentData?.id] = current_row_timer
        setLocalStorageItem('timer', JSON.stringify(data))
        setTimeLeft(12 * 60)
        interval = setInterval(() => {
          setTimeLeft((prevTimeLeft) => {
            if (prevTimeLeft <= 0 && prevTimeLeft) {
              request(`expire-reservation-session/${current_storage_timer?.session_uuid}`, 'GET')
              clearInterval(interval)
              resetPage()
              return null
            }
            return prevTimeLeft - 1
          })
        }, 1000)
      })
    }

    return () => clearInterval(interval)
  }, [])

  const onAddMoreTime = () => {
    setAddTimerDisabled(true)
    dispatch(setTimerChanged({isTimerChanged:true}))
    let storage_timer = getLocalStorageItem('timer') && JSON.parse(getLocalStorageItem('timer'))
    let current_storage_timer = storage_timer?.[parentData?.id]
    let payload = {
      timer_uuid: current_storage_timer?.session_uuid,
    }
    request(`reservation/add-time/${current_storage_timer?.session_uuid}`, 'POST', payload)
      .then((res) => {
        if (res.status !== 200 && res.status !== 202) {
          dispatch(setNotification({ type: 'error', message: res?.data?.message }))
          return
        }
        let data = {
          ...storage_timer,
        }
        let current_row_timer = {
          ...current_storage_timer,

          duration: current_storage_timer?.duration + 120,
        }
        data[parentData?.id] = current_row_timer
        setLocalStorageItem('timer', JSON.stringify(data))
        setAddTimerDisabled(false)
        setTimeLeft((prevTimeLeft) => {
          return prevTimeLeft + 120
        })
        dispatch(setTimerChanged({isTimerChanged:false}))
      })
      .catch((e) => {
        console.error('error', e)
        setAddTimerDisabled(false)
        dispatch(setTimerChanged({isTimerChanged:false}))
      })
  }

  let minutes = Math.floor(timeLeft / 60)
  let seconds = timeLeft % 60

  if (minutes < 10) minutes = '0' + minutes
  if (seconds < 10) seconds = '0' + seconds

  const themeColor = minutes === '01' ? '#ffde55' : minutes === '00' ? '#f87171' : primary_color

  let heading = data?.heading || "Reservation expires in"

  if (data?.heading && data?.heading?.toLowerCase().includes(' in')) {
    const indexOfIn = data?.heading?.toLowerCase().indexOf('in')
    let firstPart = data?.heading?.slice(0, indexOfIn).trim()
    let secondPart = data?.heading?.slice(indexOfIn).trim()

    heading = (
      <>
        <span style={{ fontWeight: 800 }}>{firstPart}</span>
        <span style={{ fontWeight: 500 }}> {secondPart}</span>
      </>
    )
  }
  return (
    <div style={{ padding: '2.5rem 0', borderBlock: '1px solid lightgrey' }}>
      <div className=' text-left font-weight-bold h4 ' style={{ color: themeColor }}>
       {heading}
      </div>
      <div style={{ fontWeight: '700' }}>
        <span style={{ color: themeColor, fontSize: '5rem', fontWeight: '900' }}>{minutes !== '00' ? minutes : seconds}</span>{' '}
        {minutes !== '00' ? <span style={{ fontSize: '2rem', color: 'grey' }}>{seconds}</span> : <></>}
      </div>

      <div className='align-center hide-progress-symbole mt-n3' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Progress
          // type='circle'
          percent={percent}
          // width='50%'
          theme={{
            active: {
              symbol: ' ',
              trailColor: '#eaeaea',
              color: themeColor,
            },
            success: {
              symbol: 100 + '%',
              trailColor: '#eaeaea',
              color: themeColor,
            },
          }}
          width='100%'
        />
      </div>
      <div className='h3 mt-2' style={{ color: themeColor, fontWeight: '700' }}>
        {minutes !== '00' ? 'Minutes' : 'Seconds'}
      </div>
      <div className='text-left h5 mt-4 font-weight-bold' style={{ color: themeColor }}>
        {data?.sub_heading}
      </div>
      <button
        disabled={addTimerDisabled}
        className='btn btn-primary rounded-pill font-weight-bold mt-2 pt-2 pb-2 w-100'
        style={{ background: themeColor }}
        onClick={() => onAddMoreTime()}>
        {data?.button_text}
      </button>
    </div>
  )
}

export default ReservationExpire
