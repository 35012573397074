import { useSelector } from 'react-redux'
import QuestionnaireMain from '../../../CMSComponent/Questionnaire/questionnaireMain'

const ApproveJobsQuestionnaire = () => {
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const parentTableReduxData = useSelector((state) => state.CMS.parentTableData)
  const details = {
    source_table: 'item_groups',
    activeRowData: currentRow?.[0],
    grid_id: parentTableReduxData?.[0]?.grid_id,
  }

  if (currentRow?.[0]?.['all_questions_answers']?.length === 0)
    return <div style={{ marginTop: '2rem', textAlign: 'center', fontSize: '2rem' }}>No items available!</div>

  return (
    <div style={{ marginTop: '3rem' }}>
      <QuestionnaireMain questionnaireDetail={details} isInsideTab={true} />
    </div>
  )
}

export default ApproveJobsQuestionnaire
