import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import { setGrid } from '../../Redux/Reducers/queue'
import DatePicker from 'react-datepicker'
import CMSSelect from '../Forms/Fields/select'
import CMSTimer from '../Forms/Fields/timepicker'
import { AvForm } from 'availity-reactstrap-validation'
import {
  checkIsDate,
  dataValidationForField,
  FormDataValidation,
  getColumnData,
  getConnectTemplateReduxDataAfterInlineEdit,
  getConnectTemplateReduxDataAfterInlineEditByReactId,
  isDirectUpdateConnectTemplate,
  updateSyncForInlineUpdateByReactId,
} from '../../helper'
import moment from 'moment'
import { setConnectTemplateDataRedux } from '../../Redux/Reducers/CMS'
import { request } from '../../Redux/Sagas/requests/api'
import { setNotification } from '../../Redux/Reducers/notificationHandling'

const RenderEditableColumn = ({
  type,
  inputValue,
  handleSubmit,
  setValue,
  item,
  index,
  cellValue,
  inlineEditActive = true,
  handleNewValueSubmit,
  inputLabel,
  setInputLabel,
  field,
  data,
  rowData,
  source_table,
  data_validations,
}) => {
  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)
  const currentRow = useSelector((state) => state.CMS.currentRow)

  const dispatch = useDispatch()

  useEffect(() => {
    if (cellValue !== inputValue && inputValue !== undefined && type === 'dropdown') {
      setTimeout(() => {
        // handleSubmit()
      }, 1000)
    }
  }, [inputValue])

  switch (type) {
    case 'input':
      return (
        <input
          onKeyDown={(e) => {
            if (e.key === 'Enter' && inlineEditActive) {
              handleNewValueSubmit(inputValue, false)
            }
          }}
          value={inputValue ? inputValue : ''}
          type='text'
          className='table-input remove-outline'
          onChange={(v) => {
            setValue(v.target.value)
            // handleNewValueSubmit(inputValue, false)
          }}
          readOnly={!inlineEditActive}
          key={'index' + index}
        />
      )
    case 'multi_radio':
    case 'radio':
    case 'dropdown':
      const itemdata = {
        ...item,
        ...item?.field_setting,
      }

      return (
        <AvForm>
          <CMSSelect
            data={itemdata}
            id={item?.field_id + index}
            isTable={true}
            classNamePrefix='select'
            value={inputValue}
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            onChangeAction={(a, b, c, value, label, isInitialUpdate) => {
              if (isInitialUpdate) return
              setValue(value)

              handleNewValueSubmit(value, false, label)
              setInputLabel(label)
            }}
            index={index}
            inlineEditActive={inlineEditActive}
            inputLabel={inputLabel}
            // id={id}
            // data={data}
            // toggleActions={toggleActions}
            // fieldKey={fieldKey}
            // updateValues={() => updateValues()}
            // updateKeysForReduxData={(keys, key) => updateKeysForReduxData(keys, key)}
            // onChangeAction={(data, action, field, value) => fieldAction(data, action, field, value)}
          />
        </AvForm>
      )
    case 'time':
      if (!inlineEditActive) {
        return inputValue
      }

      return (
        <AvForm>
          <CMSTimer
            value={inputValue}
            isTable={true}
            data={{ ...item, ...item?.field_setting }}
            formFieldValue={{ ...rowData }}
            onChangeAction={(a, b, c, value) => {
              setValue(value)
              setTimeout(() => {
                handleNewValueSubmit(value)
              }, 1000)
            }}
            formData={{
              data_validations,
            }}
          />
        </AvForm>
      )

    case 'date':
      const selectedDateValue = !checkIsDate(inputValue) ? '' : new Date(inputValue)

      return (
        <DatePicker
          selected={selectedDateValue}
          className='table-date remove-outline'
          dateFormat='d MMMM , yyyy'
          readOnly={!inlineEditActive}
          onChange={(e) => {
            setValue(e)
            setTimeout(() => {
              handleNewValueSubmit(moment(e).format('YYYY-MM-DD'),true)
            }, 1000)
          }}
        />
      )
    case 'datetime':
    case 'date-time':
      const selectedValue =
        inputValue === undefined ||
        inputValue === '' ||
        inputValue === '0000-00-00 00:00:00' ||
        inputValue === '0000-00-00' ||
        isNaN(Date.parse(inputValue))
          ? ''
          : new Date(inputValue)

      return (
        <DatePicker
          selected={selectedValue}
          className={`table-date remove-outline ${!inlineEditActive ? 'table-date-read-only' : ''}`}
          dateFormat='d MMMM , yyyy HH:mm a'
          readOnly={!inlineEditActive}
          amPmAriaLabel={false}
          showTimeInput
          onChange={(e) => {
            setValue(e)
            setTimeout(() => {
              handleNewValueSubmit(e,true)
            }, 1000)
          }}
        />
      )
    case 'switch':
      if (!inlineEditActive) {
        if (inputValue) {
          return <span className='badge badge-pill bg-success sidebar-text-light'>Yes</span>
        } else {
          return <span className='badge badge-pill bg-danger sidebar-text-light'>No</span>
        }
      } else {
        return (
          <div
            className='switch has-switch ml-2'
            style={{ marginBottom: '-5px' }}
            data-on-label='ON'
            data-off-label='OFF'
            onClick={(e) => {
              if (inlineEditActive) {
                setValue(!inputValue)
                handleNewValueSubmit(!inputValue, false)
              }
            }}>
            <div
              className={cx('switch-animate', {
                'switch-on': inputValue,
                'switch-off': !inputValue,
              })}>
              <input type='checkbox' />
              <span className='switch-left bg-info'>ON</span>
              <label>&nbsp;</label>
              <span className='switch-right bg-info'>OFF</span>
            </div>
          </div>
        )
      }

    case 'rich_text':

      return (
        <input
          onBlur={() => handleSubmit()}
          value={inputValue ? inputValue?.toString()?.replace(/<\/?[^>]+(>|$)/g, '') : ''}
          type='text'
          className='table-input remove-outline'
          onChange={(v) => setValue(v.target.value)}
          readOnly={!inlineEditActive}
          key={index}
        />
      )

    default:
      return (
        <input
          onKeyDown={(e) => {
            if (e.key === 'Enter' && inlineEditActive) {
              handleNewValueSubmit(inputValue, false)
            }
          }}
          onChange={(v) => setValue(v.target.value)}
          value={typeof inputValue === 'string' ? inputValue?.replace(/(<([^>]+)>)/gi, '') : inputValue}
          type='text'
          className='table-input remove-outline'
          readOnly={!inlineEditActive}
          key={index}
        />
      )
  }
}

export const InlineEdit = ({
  cell,
  sourceTable,
  loadTable,
  field,
  item,
  inlineEditActive,
  isEditable,
  pageName,
  parentPageName,
  isModel,
  data_validations,
}) => {
  const dispatch = useDispatch()
  const [inputValue, setValue] = useState()
  const [inputLabel, setInputLabel] = useState()
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const queue = useSelector((state) => state.Queue.grid)
  const currentRow = useSelector((state) => state.CMS.currentRow)
  const domain_id = useSelector((state) => state.CMS.selected_domain_id)
  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)

  let isCellInSync

  queue[sourceTable]?.map((item) => {
    if (item.id === cell.row.original.id && item.name === cell.column.colData.field_id) {
      isCellInSync = true
    }
  })

  const onsubmit = (new_value, convertToString, label) => {
    let newRow = {
      ...cell.row.original,
    }

   
    newRow[cell.column.colData.field_id] = convertToString ? new_value?.toString() : new_value
    if (label) {
      newRow[`${cell.column.colData.field_id}_label`] = label
    }

    if (cell.row.original.id) {
      dispatch(
        setGrid({
          source_table: sourceTable,
          data: {
            id: cell.row.original.id,
            value: convertToString ? new_value?.toString() : new_value,
            name: cell.column.colData.field_id,
          },
          domain_id,
          //child_table:
          //parent_table_id:
          //source_table:#545cd8
        })
      )
      if (!isDirectUpdateConnectTemplate(sourceTable)) {
        const connectTemplateDataReduxNeedToUpdate = getConnectTemplateReduxDataAfterInlineEdit(
          connectTemplateDataRedux,
          parentPageName,
          pageName,
          sourceTable,
          currentRow,
          newRow,
          currentRow && currentRow?.length > 0 && !isModel
        )
        dispatch(setConnectTemplateDataRedux(connectTemplateDataReduxNeedToUpdate))
      }
    } else if (!cell.row.original.id && cell.row.original.temporary_id) {
      const parentTable = parentTableDataInRedux?.[0]?.source_table
      const connectTemplateDataReduxNeedToUpdate = getConnectTemplateReduxDataAfterInlineEditByReactId(
        connectTemplateDataRedux,
        parentPageName,
        pageName,
        sourceTable,
        currentRow,
        newRow,
        currentRow && currentRow?.length > 0 && !isModel
      )
      dispatch(setConnectTemplateDataRedux(connectTemplateDataReduxNeedToUpdate))
      updateSyncForInlineUpdateByReactId(
        cell.row.original.temporary_id,
        sourceTable,
        parentTable,
        currentRow,
        cell.column.colData.field_id,
        convertToString ? new_value?.toString() : new_value
      )
    }
  }
  const handleNewValueSubmit = (new_value, convertToString , label) => {
    if (!inlineEditActive) return
    const promises = data_validations?.map(async (validation) => {
      const parent_table = parentTableDataInRedux && parentTableDataInRedux?.[0]?.source_table
      const parent_table_row_id = currentRow[0]?.id || currentRow?.[0]?.temporary_id
      let formValue = {
        ...cell?.row?.original,
      }
      formValue[cell?.column?.colData] = new_value
      const result = await FormDataValidation(
        formValue,
        validation,
        parent_table_row_id,
        parent_table,
        sourceTable,
        currentRow?.[1]?.id || currentRow?.[1]?.temporary_id
      )
      return result
    })
    if (promises) {
      Promise.all(promises).then((results) => {
        let errorCheckArray = []
        results?.forEach((result) => {
          if (Array.isArray(result)) {
            errorCheckArray = [...errorCheckArray, ...result]
          } else {
            errorCheckArray?.push(result)
          }
        })
        const failedValidation = errorCheckArray?.filter((item) => !item?.validation)
        if (failedValidation?.length > 0) {
          failedValidation?.map((item) => {
            dispatch(setNotification({ type: 'error', message: item?.message }))
          })
          if (cell.row.original[cell.column.id + '_label']) {
            setValue(cell.row.original[cell.column.id + '_label'] || cell.value)
          } else {
            setValue(cell.value || cell.row.original[cell.column.id])
          }
        } else {
          onsubmit(new_value, convertToString, label)
        }
      })
    } else {

      onsubmit(new_value, convertToString, label)
    }
  }

  const handleSubmit = () => {
    let mainValue = ''
    if (cell.value === undefined && cell.row.original.custom_fields) {
      mainValue = getColumnData(cell.row.original.custom_fields, item.field_id)
    } else {
      mainValue = cell.value
    }
    if (sourceTable && inputValue !== mainValue && (inputValue || inputValue === '' || inputValue === 0)) {
      let newRow = {
        ...cell.row.original,
      }
      newRow[cell.column.colData.field_id] = inputValue
      const connectTemplateDataReduxNeedToUpdate = getConnectTemplateReduxDataAfterInlineEdit(
        connectTemplateDataRedux,
        parentPageName,
        pageName,
        sourceTable,
        currentRow,
        newRow,
        currentRow && currentRow?.length > 0 && !isModel
      )
    }
  }
  useEffect(() => {
    if (cell.value === undefined && cell.row.original.custom_fields && cell.row.original.custom_fields?.length !== 0) {
      // setValue(getColumnData(cell.row.original.custom_fields, item.field_id))
    } else {
      if (cell.row.original[cell.column.id + '_label']) {
        setValue(cell.row.original[cell.column.id + '_label'] || cell.value)
      } else {
        setValue(cell.value || cell.row.original[cell.column.id])
      }
      if (field?.field === 'dropdown') {
        setInputLabel(cell.row.original[cell.column.id + '_label'])
        // alert(cell.column.id+'_label')
      }
    }
  }, [sourceTable, cell.value])

  return (
    <td
      {...cell.getCellProps()}
      style={{ ...cell.column.commonStyle }}
      className={`rt-td bg-${cell.background_color} ${isCellInSync ? 'blue-border' : ''}`}
      // className={'rt-td bg-' + cell.background_color + isCellInSync ? ' blue-border' : ''}
    >
      <form
        className='tableForm'
        onSubmit={(e) => {
          e.preventDefault()
          // handleSubmit()
        }}>
        <RenderEditableColumn
          inputValue={inputValue}
          setValue={setValue}
          handleSubmit={handleSubmit}
          type={field?.field}
          item={item}
          index={cell?.row?.index}
          cellValue={cell?.value}
          inlineEditActive={item?.field_id === 'id' ? false : isEditable ? inlineEditActive : false}
          handleNewValueSubmit={handleNewValueSubmit}
          inputLabel={inputLabel}
          setInputLabel={setInputLabel}
          field={field}
          data={cell?.column?.colData}
          rowData={cell?.row?.original}
          source_table={sourceTable}
          data_validations={data_validations}
        />
        {/* <Select options={OPTIONS} value={OPTIONS[0]}/> */}
      </form>
    </td>
  )
}
