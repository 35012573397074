import { FaChevronCircleLeft } from 'react-icons/fa'
import { primary_color } from '../../Helper/uiHelper'
import { useState } from 'react'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import NavLinks from './NavLinks'

function DesktopNavigation({ data , isSidebar = false , isSidebarOpen = false }) {

  const [open, setOpen] = useState(false)
  const isSmallDisplay = useMediaQuery('(max-width:1000px)')

  const sidebarStyle = {
    position: 'absolute',
    right: open ? '5%' : '0%',
    marginRight: open ? '-5%' : '0',
    top: '50%',
    transform: 'translate(0,-50%)',
    transition: 'right 0.3s ease',
    gap: isSmallDisplay ? '1rem' : '3rem',
  }
 
  const backdropStyle = {
    height: '100vh',
    position: 'fixed',
    background: 'rgb(0 0 0 / 39%)',
    zIndex: '3',
    width: '100vw',
  }

   const leftSidebarStyle = {
    position: 'fixed',
    top: 0,
    padding: '1rem',
    paddingTop: '4rem',
    right: isSidebarOpen ? 0 : '-250px',
    height: '100%',
    width: '250px',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
    transition: 'right 0.3s ease',
    zIndex: 500,
  }

  return (
    <>
      {
        isSidebar ? 
        <>
        <div style={isSidebarOpen ? backdropStyle : {}}>
          <div style={leftSidebarStyle}>
              <NavLinks data={data} isSidebar={true} />
          </div>
        </div>
        </> : 
         <div className='d-flex align-items-center' style={sidebarStyle}>
          {open ? (
            <i class='fa-solid fa-xmark' style={{ fontSize: '1.5rem', color: '#8E8E8E', cursor: 'pointer' }} onClick={() => setOpen(!open)}></i>
          ) : (
            <FaChevronCircleLeft onClick={() => setOpen(!open)} style={{ color: primary_color, fontSize: '1.75rem', cursor: 'pointer' }} />
          )}
          <div className='align-items-center' style={{ gap: isSmallDisplay ? '0' : '1rem', display: open ? 'flex' : 'none' }}>
            <NavLinks data={data} />
          </div>
        </div>
      }
   
    </>
  )
}

export default DesktopNavigation
