import React, { Fragment } from 'react'

const PrivacyPolicy = () => {
  const data = [
    {
      title: 'Lorem Ipsum dolor sit amet',
      policies: [
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
      ],
    },
    {
      title: 'Lorem Ipsum dolor sit amet',
      policies: [
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
      ],
    },
  ]

  return (
    <Fragment>
      <div>
        <div className='d-flex flex-column align-items-center h-sm mt-5 mb-5'>
          <h4>Privacy Policies</h4>
          <div class='col-md-7'>
            <p>
              lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsum
              dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua
            </p>
          </div>
        </div>
        <div>
          {data.map((item, index) => (
            <div className='d-flex flex-column m-5' key={index}>
              <h4 className='ml-5'>{item.title}</h4>
              <ol class='d-flex flex-column'>
                {item.policies.map((point, i) => (
                  <li className='col-md-12' key={i}>
                    {point}
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  )
}

export default PrivacyPolicy
