import { TIMER_STATUS } from "./themeOptions"

export const setTimerChanged = (isTimerChanged) => ({
  type: TIMER_STATUS,
  isTimerChanged,
})


const initialState = {
  isTimerChanged: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TIMER_STATUS:
      const { isTimerChanged } = action
      return {
        ...state,
        isTimerChanged
      }
    default:
  }
  return state
}
