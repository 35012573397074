import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import { cms } from '../../../helper'

const dummy_subheading = 'The mail may take some time to be delivered. Please check your spam folder.'
const EmailDetails = ({ data }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  return (
    <div style={{ marginTop: isDesktop ? '8rem' : '2rem', padding: isDesktop ? '0 0 0 6rem' : '1rem' }}>
      <div className=' text-left font-weight-bold h4 mb-4' style={{ color: primary_color }}>
        {data?.heading ? data?.heading : 'Note:'}
      </div>
      <div className='font-weight-bold'>{data?.sub_heading ? data?.sub_heading : dummy_subheading}</div>
    </div>
  )
}

export default EmailDetails
