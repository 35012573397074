import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { primary_color } from '../../../Helper/uiHelper'
import { countryCode } from '../../../assets/utils/data/country-code'

const SendSMS = ({ formDetails, setFormDetails, showValidation, setShowValidation }) => {
  const [showInput, setShowInput] = useState(false)

  const handleChange = (e) => {
    let { name, value } = e.target
    setShowValidation(false)
    if (name === 'mobile_number') {
      value = value.replace(/\D/g, '')
    }
    setFormDetails((prev) => {
      return { ...prev, [name]: value }
    })
  }

  return (
    <div className='d-flex align-items-top'>
      <select
        className='form-control rounded-pill mr-2'
        onChange={handleChange}
        name='country_code'
        value={formDetails.country_code}
        style={{ width: '5rem' }}>
        {countryCode.map((item) => (
          <option value={item.dial_code}>{item.dial_code}</option>
        ))}
      </select>
      <div>
        <input
          className={`form-control rounded-pill ${showValidation && !formDetails.mobile_number ? 'error-input' : ''}`}
          placeholder='Mobile Number'
          value={formDetails.mobile_number}
          name={'mobile_number'}
          onChange={handleChange}
          type='text'
          style={{ width: '20rem' }}
        />
        {showValidation && !formDetails.mobile_number && <div className='text-danger text-left pl-2'>Please enter mobile number</div>}
      </div>
    </div>
  )
}

export default SendSMS
