import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { logTime, resetPublicUser, setDefaultUrl, setIsAuth } from '../../Redux/Reducers/user'
import { reduxLoad } from '../../Redux/Reducers'
import { primary_color } from '../../Helper/uiHelper'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import { setCookie } from '../../Helper/cookieHelper'



const NavLinks = ({ data , isSidebar = false }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const publicUser = useSelector((state) => state.User.publicUser)
  const isDesktop = useMediaQuery('(min-width:768px)')
  const [hover, setHover] = useState('')

   const iconStyle = (name) => {
    return {
      fontSize: hover === name ? '1.4rem' : '1.2rem',

      width: isSidebar ? '2rem' : '4rem',
      color: hover === name ? primary_color : '#8E8E8E',
      transition: 'font-size 0.3s ease',
    }
  }

   const onClickIcon = (item) => {
    if (item?.action === 'redirect-to-cms') {
      dispatch(logTime(new Date()))
      dispatch(setIsAuth(true))
      dispatch(reduxLoad())
      dispatch(setDefaultUrl(publicUser.homepage))
      history.push(publicUser.homepage || '/')
      return
    }
    if(item?.action === 'logout'){
      dispatch(resetPublicUser())
      setCookie('AUTH_TOKEN')
      return
    }
    if(item?.route){
       history.push(item?.route)
    }
  }

  const nameStyle = {
    fontSize: '10px',
    position: 'absolute',
    bottom: '-15px',
    textAlign: 'center',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    transition: 'opacity 0.3s ease', // Add transition property for opacity
    // Set opacity based on hover
  }

 const current_tab = 'Home'
 
  return (
   data?.map((e , index) => {
          if (e.isLoginRequired && !publicUser?.email) return
          if(e.isOnlyMobile && isDesktop ) return 
          return (
            <div
              style={{ textAlign: 'center', position: 'relative', cursor: 'pointer', cursor: 'pointer', position: 'relative' }}
              onMouseOver={() => {
                setHover(e.icon)
              }}
              onMouseOut={() => setHover(false)}
              onClick={() => {
                if (e.action || e.route) {
                  onClickIcon(e)
                }
              }}>
              {
                isSidebar ? 
                 <div
                    key={index}
                    className='d-flex pb-2 align-items-center mt-3'
                    style={{ gap: '0.75rem', color: current_tab === e.name ? primary_color : '#8E8E8E' }}>
                    <i className={e.icon} style={{ ...iconStyle(e.icon) }} />
                    <div>{e.name}</div>
                   </div>
                    : 
                    <>
                     <i className={e.icon} style={{ ...iconStyle(e.icon) }} />
                      <div
                       key={index}
                        style={{
                          ...nameStyle,
                          visibility: hover === e.icon ? 'visible' : 'hidden', // Show/hide the name based on hover
                          opacity: hover === e.icon ? 1 : 0,
                        }}>
                        {e.name}
                      </div>
                    </>
              }
             
            </div>
          )
        })
  )
}

export default NavLinks
