import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useRef, useState } from 'react'
import SignaturePad from 'react-signature-canvas'
import { Button } from 'reactstrap'
import CommonFields from './commonFields'

const ShowExtraDetail = ({ type, value, onChangeValue, requiredError, item }) => {
  const user_option = item?.users?.map((user) => {
    return {
      value: user?.id,
      label: user?.full_name,
    }
  })
  switch (type) {
    case 'signiture_name':
      return <AvField type='text' value={value} name='signiture_name' onChange={(e) => onChangeValue(e.target.value)}></AvField>
    case 'signiture_select':
      return (
        <AvField type='select' name='signiture_select' value={value} onChange={(e) => onChangeValue(e.target.value)}>
          <option value=''>Select Name</option>
          {user_option?.map((option, i) => {
            return (
              <option key={i} value={option?.value}>
                {option?.label}
              </option>
            )
          })}
        </AvField>
      )
    default:
      return
  }
}

const Signature = ({ item, type, answers, setAnswers, requiredError }) => {
  const sigCanvas = useRef({})
  const [savedSignature, setSavedSignature] = useState(null)
  const [value, setValue] = useState()
  const current_answer_object = answers?.filter((data) => data?.item_question_id === item?.id)[0] || {}

  const clear = () => {
    sigCanvas.current.clear()
    setSavedSignature(null)
    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        return {
          ...question,
          image_base64: null,
        }
      } else {
        return question
      }
    })
    if (updated_answers?.length > 0) {
      setAnswers(updated_answers)
    }
  }

  const save = () => {
    // setSavedSignature(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'))

    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        return {
          ...question,
          image_base64: sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'),
        }
      } else {
        return question
      }
    })
    setAnswers(updated_answers)
  }

  useEffect(() => {
    setSavedSignature(null)
    clear()
  }, [item?.id])

  const onChangeValue = (v) => {
    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        return {
          ...question,
          response_text: v,
        }
      } else {
        return question
      }
    })
    setAnswers(updated_answers)
  }

  useEffect(() => {
    setSavedSignature(current_answer_object?.image_base64)
    setValue(current_answer_object?.response_text)
  }, [JSON.stringify(current_answer_object), current_answer_object?.response_text])
  return (
    <div>
      {item?.question && <div style={{ marginBottom: '0.5rem' }}>{item?.question}</div>}
      <SignaturePad ref={sigCanvas} canvasProps={{ className: 'signature-pad' }} onEnd={save} />

      <div style={{ margin: '1rem 0' }}>
        <Button onClick={clear}>Clear</Button>
      </div>
      {savedSignature && (
        <img
          src={savedSignature}
          alt='signature'
          style={{ margin: '0 auto 1rem auto', border: '1px solid black', height: '80px', width: '150px' }}></img>
      )}
      {type !== 'signiture_basic' && (
        <AvForm>
          <ShowExtraDetail type={type} value={value} onChangeValue={onChangeValue} item={item} />
        </AvForm>
      )}

      <CommonFields item={item} selectedAnswer={value} answers={answers} setAnswers={setAnswers} current_answer_object={current_answer_object} />
      {item?.validations?.includes('required') &&
        requiredError &&
        (!current_answer_object?.image_base64 || current_answer_object?.image_base64?.length === 0) && (
          <div style={{ color: 'red' }}>Please Complete</div>
        )}
    </div>
  )
}

export default Signature
