import { store } from './Redux/Config/configureStore'
import { updateProgressDataRedux } from './Redux/Reducers/CMS'

const countTabPercentage = (tab, currentRow, main_source_table) => {
  const fields = tab?.component_call_form?.[0]?.component_call_fields?.filter((item) => item?.field_id !== 'id')
  let valueObject
  if (tab?.source_table === main_source_table) {
    valueObject = currentRow
  } else {
    if (tab?.source_table) {
      valueObject = currentRow[tab?.source_table]
    } else {
      valueObject = currentRow
    }
  }

  let completedFieldCount = 0
  fields?.forEach((field) => {
    if (valueObject && valueObject[field?.field_id]) {
      completedFieldCount += 1
    }
  })

  let percentage = 0
  if (fields?.length > 0) {
    percentage = Math.round((completedFieldCount / fields?.length) * 100)
  }
  return percentage
}

const countChildGridPercentage = (tab, currentRow, main_source_table) => {
  let progress = 0
  if (currentRow) {
    progress = currentRow?.[`${tab?.method_name}_count`]
  }
  return progress
}

const getTabColor = (percentage, isGrid) => {
  if (isGrid) return
  if (percentage <= 20) {
    return 'text-danger'
  } else if (percentage > 20 && percentage <= 50) {
    return 'text-warning'
  } else if (percentage > 50 && percentage <= 80) {
    return 'text-primary'
  } else if (percentage > 80) {
    return 'text-success'
  } else {
    return ''
  }
}

export const updateProgressDataByPagename = (currentRow, pageName) => {
  const oldData = store.getState().CMS.progressData || {}
  const reduxPage = store.getState().CMS.coreData.page
  const pageData = reduxPage[pageName]
  const hidden_tab = pageData?.component_call_buttons?.find((tab) => tab?.component_name === 'hidden_tab')
  const main_source_table = hidden_tab?.source_table

  let newData = {}

  pageData?.component_call_buttons?.map((tab) => {
    if (tab?.component_name === 'show_tabs_on_load') {
      if (tab?.is_grid) {
        const tabPercentage = countChildGridPercentage(tab, currentRow, main_source_table)
        newData[tab?.name] = { count: `( ${tabPercentage} )`, class: getTabColor(tabPercentage, true), count_int: tabPercentage }
      } else {
        const tabPercentage = countTabPercentage(tab, currentRow, main_source_table)
        newData[tab?.name] = { count: `( ${tabPercentage}% )`, class: getTabColor(tabPercentage), count_int: tabPercentage }
      }
    }
  })
  store.dispatch(updateProgressDataRedux(newData))
}

export const increaseProgressDataByTabname = (tabName) => {
  const oldData = store.getState().CMS.progressData || {}
  let newData = {
    ...oldData,
  }

  const old_count = newData[tabName]?.count_int

  if (old_count === undefined) return
  const new_count = old_count + 1

  newData[tabName] = { count: `( ${new_count} )`, class: getTabColor(new_count, true), count_int: new_count }

  store.dispatch(updateProgressDataRedux(newData))
}

export const descreaseProgressDataByTabame = (tabName) => {
  const oldData = store.getState().CMS.progressData || {}
  let newData = {
    ...oldData,
  }

  const old_count = newData[tabName]?.count_int

  if (old_count === undefined) return
  const new_count = old_count - 1

  newData[tabName] = { count: `( ${new_count} )`, class: getTabColor(new_count, true), count_int: new_count }

  store.dispatch(updateProgressDataRedux(newData))
}


export const updateProgressDataByTabame = (tabName,count) => {
  const oldData = store.getState().CMS.progressData || {}
  let newData = {
    ...oldData,
  }


  const new_count = count

  newData[tabName] = { count: `( ${new_count} )`, class: getTabColor(new_count, true), count_int: new_count }

  store.dispatch(updateProgressDataRedux(newData))
}
