import {
  clearCompletedQuestionnaireByGridId,
  dataConnect,
  getConnectTemplateReduxDataAfterDelete,
  getDeletedIdsToUpdateInRedux,
  isDirectUpdateConnectTemplate,
} from '../../../../helper'
import { store } from '../../../../Redux/Config/configureStore'
import {
  addDataStatusSet,
  addGridDataModalOpen,
  callConnectApi,
  callFreeApi,
  clearLastDataConnectionData,
  clearTableDropDown,
  fetchUpdateApiData,
  saveFilter,
  setConnectTemplateDataRedux,
  setDeletedRowIdInRedux,
  setParentTableData,
  submitRequest,
} from '../../../../Redux/Reducers/CMS'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import { SetFullPage, setTabFormData } from '../../../../Redux/Reducers/page'
import { setQueue } from '../../../../Redux/Reducers/queue'
import { request } from '../../../../Redux/Sagas/requests/api'

export const getColumns = (gridRelatedData) => {
  const { page_data } = gridRelatedData || {}

  let columns
  const source_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')
  const page_main_table = source_table && source_table.length > 0 && source_table[0]?.component_call_form[0]?.main_grid
  if (page_main_table?.component_call_cols && page_main_table?.component_call_cols.length > 0) {
    columns = page_main_table?.component_call_cols?.filter((item) => item.field_id).map((item) => item.field_id)
  }
  if (page_main_table?.component_call_expand_fields && page_main_table?.component_call_expand_fields.length > 0) {
    columns = [...columns, ...page_main_table?.component_call_expand_fields?.filter((item) => item.field_id).map((item) => item.field_id)]
  }
  if (page_main_table?.component_call_cols) {
    const aggregate_columns = page_main_table?.component_call_cols?.filter((item) => {
      if (item?.component_name === 'aggregate_column') {
        return true
      }
    })
    if (aggregate_columns?.length > 0) {
      columns = [...columns, 'aggregate_column']
    }
  }

  const method_with_main_source_table = page_data?.component_call_buttons?.filter(
    (item) => item.component_name !== 'hidden_tab' && item.source_table === source_table[0]?.source_table
  )

  const fieldData = method_with_main_source_table?.map((item) => {
    item?.component_call_form &&
      item?.component_call_form?.map((form) => {
        form.component_call_fields?.map((field) => {
          if (!columns.includes(field.field_id)) {
            columns.push(field.field_id)
          }
        })
      })
  })
  const grid_colors = page_main_table?.grid_colors
  let grid_colors_column = []

  if (grid_colors && grid_colors?.length > 0) {
    grid_colors?.map((color) => {
      color?.conditions?.map((color) => {
        if (color?.db_field_name) {
          grid_colors_column?.push(color?.db_field_name)
        }
      })
    })
  }

  if (columns) {
    return [...columns, ...grid_colors_column]
  } else {
    return []
  }
}

export const getChildTableColumn = (mainGrid) => {
  const column_1 = mainGrid.component_call_cols.filter((item) => item.component_name === 'text_data_col').map((item) => item.field_id)
  let column_2 = []

  mainGrid.component_call_cols
    .filter((item) => item.component_name === 'text_data_col')
    ?.forEach((col) => {
      if (
        col?.field_setting?.component_call_data_connection &&
        col?.field_setting?.component_call_data_connection?.clause_data &&
        Array.isArray(col?.field_setting?.component_call_data_connection?.clause_data)
      ) {
        col?.field_setting?.component_call_data_connection?.clause_data?.map((clause) => {
          if (clause?.ref_for_form_field) {
            column_2?.push(clause?.value)
          }
        })
      }
    })
  const column_3 = (mainGrid?.component_call_expand_fields && mainGrid?.component_call_expand_fields?.map((item) => item?.field_id)) || []

  return [...column_1, ...column_2, ...column_3]
}

export const clearConnectTemplateReduxData = (gridRelatedData) => {
  const { pageName, parentPageName } = gridRelatedData
  const connectTemplateDataRedux = store.getState().CMS.connectTemplateDataRedux
  if (connectTemplateDataRedux && connectTemplateDataRedux[parentPageName]?.[pageName]) {
    let connectTemplateDataReduxToUpdate = {
      ...connectTemplateDataRedux,
    }

    connectTemplateDataReduxToUpdate[parentPageName][pageName] = {}
    store.dispatch(setConnectTemplateDataRedux(connectTemplateDataReduxToUpdate))
  }
}

export const getPayloadToLoadGridData = (gridRelatedData, updateInitialPayload) => {
  const domain_id = store.getState().CMS.selected_domain_id
  const {
    tableGrid,
    selectedGrid,
    page_data,
    currentTabData,
    isModel,
    selectedColorBadge,
    filterManage,
    defaultSortColumn,
    selected_ids,
    refreshGrid,
    clearFilters,
    gridInitialPayload,
    triggerGlobalSearchValue,
    initialPayload,
    globalSearchValue,
    parentPageName,
    pageName,
    content,
    activeFilterBy,
    gridFilters,
    savedFilters
  } = gridRelatedData
  const currentRow = store.getState().CMS.currentRow
  const parentTableDataInRedux = store.getState().CMS.parentTableData

  // updateGridFilters({ ...gridFilters, ...search })
  let updatedInitialPayload = initialPayload
  updatedInitialPayload = {
    ...updatedInitialPayload,
    ...{ ...gridFilters },
  }
  updateInitialPayload(updatedInitialPayload)
  if (tableGrid.grid_user_preferences.save_search) {
  }

  // store.dispatch(saveFilter({ ...savedFilters, [tableGrid.name]: updatedInitialPayload }))

  let payload = {
    filter_by: activeFilterBy,
  }

  if(savedFilters && savedFilters[tableGrid?.name]){
    payload = {
      ...savedFilters[tableGrid?.name],
    }
  }

  let recPerPage = tableGrid[selectedGrid].component_call_preferences_form?.rec_per_page

  if (recPerPage) {
    payload = {
      ...payload,
      rec_per_page: recPerPage?.value,
      grid_expand_table: content?.main_grid?.grid_expand_table,
    }
  }

  const mainGrid = tableGrid[selectedGrid]
  const source_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')
  const methods = page_data?.component_call_buttons
    ?.filter((item) => item.component_name !== 'hidden_tab' && item.source_table !== source_table[0]?.source_table)
    .map((item) => item.method_name)
  const countMethods = page_data?.component_call_buttons?.filter((tab) => tab?.method_name)?.map((item) => item?.method_name)

  const columns = getColumns(gridRelatedData)

  if (
    mainGrid?.component_call_data_connection.action === 'connect_template' ||
    mainGrid?.component_call_data_connection.action === 'connect_redux' ||
    true
  ) {
    if ((currentRow[currentRow?.length - 1]?.id || currentRow[currentRow?.length - 1]?.temporary_id) && !isModel) {
      payload = {
        ...payload,
        child_table: currentTabData?.source_table,
        child_table_columns: getChildTableColumn(mainGrid),
        parent_table:
          parentTableDataInRedux && parentTableDataInRedux?.length > 0
            ? parentTableDataInRedux[parentTableDataInRedux.length - 1]?.source_table
            : source_table && source_table[0]?.source_table,
        // parent_table: source_table[0]?.source_table,
        parent_table_row_id: currentRow[currentRow?.length - 1].id || currentRow[currentRow?.length - 1]?.temporary_id,
        childe_table_methods: [],
        page_name: pageName,
      }

      if (selectedGrid === 'restore_grid') {
        payload = { ...payload, child_table_restore: true }
      }
      if (selectedColorBadge?.length > 0) {
        payload = { ...payload, 'filter-group-color': selectedColorBadge }
      }
      if (filterManage?.filter_in || filterManage?.filter_out) {
        payload = { ...payload, 'filter-group-data': filterManage?.filter_in ? 'in' : 'out', grid_id: mainGrid?.id }
      }
      if (mainGrid?.where_clause) {
        payload = { ...payload, clause_data: mainGrid?.where_clause }
      }
      // payload = { ...payload, clause_data: mainGrid?.where_clause }
      if (defaultSortColumn && defaultSortColumn?.length > 0) {
        defaultSortColumn?.map((item) => {
          payload = {
            ...payload,
            ['-sort-' + item.id]: item?.type,
          }
        })
      }
      if (selected_ids) {
        payload = { ...payload, '-filter-id': selected_ids }
      }
    } else {
      if (source_table && source_table.length > 0) {
        payload = {
          ...payload,
          source_table: source_table[0]?.source_table,
          columns,
          methods,
          page_name: parentPageName,
          countMethods,
        }
        if (selectedGrid === 'restore_grid') {
          payload = { ...payload, child_table_restore: true }
        }

        if (selectedColorBadge?.length > 0) {
          payload = { ...payload, 'filter-group-color': selectedColorBadge }
        }
        if (filterManage?.filter_in || filterManage?.filter_out) {
          payload = { ...payload, 'filter-group-data': filterManage?.filter_in ? 'in' : 'out', grid_id: mainGrid?.id }
        }

        let where_clause
        if (mainGrid?.where_clause) {
          where_clause = [...mainGrid?.where_clause]
        }

        if (isModel) {
          let clause = {
            field: 'domain_id',
            type: 'dynamic',
            value: domain_id,
            condition: 'equals',
          }
          where_clause?.push(clause)
        }
        if (mainGrid?.where_clause) {
          payload = { ...payload, clause_data: where_clause }
        }
        // payload = { ...payload, clause_data: mainGrid?.where_clause }

        if (defaultSortColumn && defaultSortColumn?.length > 0) {
          defaultSortColumn?.map((item) => {
            payload = {
              ...payload,
              ['-sort-' + item.id]: item?.type,
            }
          })
        }
        if (selected_ids) {
          payload = { ...payload, '-filter-id': selected_ids }
        }
      }
    }
  } else {
    payload =
      refreshGrid || clearFilters
        ? gridInitialPayload
        : triggerGlobalSearchValue
        ? { ...initialPayload, '-global-search': globalSearchValue }
        : initialPayload
    if (selectedColorBadge?.length > 0) {
      payload = { ...payload, 'filter-group-color': selectedColorBadge, page_name: pageName }
    }
    if (filterManage?.filter_in || filterManage?.filter_out) {
      payload = { ...payload, 'filter-group-data': filterManage?.filter_in ? 'in' : 'out', grid_id: mainGrid?.id }
    }
    if (mainGrid?.where_clause) {
      payload = { ...payload, clause_data: mainGrid?.where_clause }
    }
    // payload = { ...payload, clause_data: mainGrid?.where_clause }

    if (defaultSortColumn && defaultSortColumn?.length > 0) {
      defaultSortColumn?.map((item) => {
        payload = {
          ...payload,
          ['-sort-' + item.id]: item?.type,
        }
      })
    }
    if (selected_ids) {
      payload = { ...payload, '-filter-id': selected_ids }
    }
  }
  return payload
}

export const getDataByPageInGrid = (gridRelatedData, page, updateTableData) => {
  const { page_data, pageName, parentPageName, initialPayload, mainGrid, tableGrid } = gridRelatedData
  const connectTemplateDataRedux = store.getState().CMS.connectTemplateDataRedux

  const a = page
  const nextPage = a?.slice(a?.indexOf('page='))?.split('=')[1]

  if (connectTemplateDataRedux && connectTemplateDataRedux[parentPageName]?.[pageName]?.[parseInt(nextPage)]) {
    updateTableData(connectTemplateDataRedux[parentPageName]?.[pageName]?.[parseInt(nextPage)], false)
  } else {
    const source_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')
    const methods = page_data?.component_call_buttons
      ?.filter((item) => item.component_name !== 'hidden_tab' && item.source_table)
      .map((item) => item.source_table)

    let payload = {
      ...getPayloadToLoadGridData(gridRelatedData),
      ...initialPayload,
    }
    if (mainGrid?.where_clause) {
      payload = { ...payload, clause_data: mainGrid?.where_clause }
    }
    // payload = { ...payload, clause_data: mainGrid?.where_clause }
    store.dispatch(
      callConnectApi({
        url: page,
        data: payload,
        name: tableGrid.name,
      })
    )
  }
}

export const getGridDataBySearch = (gridRelatedData, search, sort, updateGridFilters, selected_ids, currentTabData, updateInitialPayload) => {
  const {
    page_data,
    tableGrid,
    mainGrid,
    gridFilters,
    savedFilters,
    initialPayload,
    selectedColorBadge,
    filterManage,
    isModel,
    parentPageName,
    pageName,
    activeFilterBy,
  } = gridRelatedData
  const currentRow = store.getState().CMS.currentRow
  const parentTableDataInRedux = store.getState().CMS.parentTableData

  updateGridFilters({ ...gridFilters, ...search })
  let updatedInitialPayload = initialPayload
  updatedInitialPayload = {
    ...updatedInitialPayload,
    ...{ ...gridFilters, ...search },
  }
  updateInitialPayload(updatedInitialPayload)
  if (tableGrid.grid_user_preferences.save_search) {
  }

  store.dispatch(saveFilter({ ...savedFilters, [tableGrid.name]: updatedInitialPayload }))
  const source_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')
  const methods = page_data?.component_call_buttons
    ?.filter((item) => item.component_name !== 'hidden_tab' && item.source_table)
    .map((item) => item?.source_table)

  const columns = getColumns(gridRelatedData)

  sort.map((item) => {
    updatedInitialPayload = {
      ...updatedInitialPayload,
      ['-sort-' + item.id]: item.desc === true ? 'DESC' : 'ASC',
    }
  })

  let payload = {}

  if (currentRow && currentRow[currentRow?.length - 1] && !isModel) {
    payload = {
      ...updatedInitialPayload,
      filter_by: activeFilterBy,
      child_table: currentTabData?.source_table,
      child_table_columns: getChildTableColumn(mainGrid),
      parent_table:
        parentTableDataInRedux && parentTableDataInRedux?.length > 0
          ? parentTableDataInRedux[parentTableDataInRedux.length - 1]?.source_table
          : source_table && source_table[0]?.source_table,
      // parent_table: source_table[0]?.source_table,
      parent_table_row_id: currentRow[currentRow?.length - 1].id,
      childe_table_methods: [],
    }
  } else {
    payload = {
      filter_by: activeFilterBy,
      source_table: source_table[0]?.source_table,
      columns: columns,
      methods: methods,
      main: false,
      ...updatedInitialPayload,
    }
  }

  if (selectedColorBadge?.length > 0) {
    payload = { ...payload, 'filter-group-color': selectedColorBadge }
  }
  if (filterManage?.filter_in || filterManage?.filter_out) {
    payload = { ...payload, 'filter-group-data': filterManage?.filter_in ? 'in' : 'out', grid_id: mainGrid?.id }
  }
  if (mainGrid?.where_clause) {
    payload = { ...payload, clause_data: mainGrid?.where_clause }
  }
  // payload = { ...payload, clause_data: mainGrid?.where_clause }
  if (selected_ids) {
    payload = { ...payload, '-filter-id': selected_ids }
  }

  clearConnectTemplateReduxData(gridRelatedData)

  dataConnect(mainGrid.component_call_data_connection, payload, mainGrid.name, false, false, parentPageName, pageName)
  clearCompletedQuestionnaireByGridId(mainGrid?.id)
}

export const getGridDataBySort = (gridRelatedData, sort, selected_ids, currentTabData, updateInitialPayload, updateMainGrid) => {
  if (!sort) return null
  const {
    page_data,
    tableGrid,
    mainGrid,
    gridFilters,
    savedFilters,
    initialPayload,
    selectedColorBadge,
    filterManage,
    isModel,
    parentPageName,
    pageName,
  } = gridRelatedData
  const currentRow = store.getState().CMS.currentRow
  const parentTableDataInRedux = store.getState().CMS.parentTableData

  let updatedInitialPayload = initialPayload
  for (const property in updatedInitialPayload) {
    if (property.search('-sort-') !== -1) {
      delete updatedInitialPayload[property]
    }
  }
  let updatedMainGridCols = mainGrid.component_call_cols
  sort.map((item) => {
    updatedMainGridCols = updatedMainGridCols.map((item1) => {
      if (item.id === item1.field_id) {
        return {
          ...item1,
          sort_priority: 1,
          sort: item.desc === true ? 'DESC' : 'ASC',
        }
      } else {
        return item1
      }
    })
    updatedInitialPayload = {
      ...updatedInitialPayload,
      ['-sort-' + item.id]: item.desc === true ? 'DESC' : 'ASC',
    }
  })
  if (tableGrid.grid_user_preferences.save_search) {

    store.dispatch(saveFilter({ ...savedFilters, [tableGrid.name]: updatedInitialPayload }))
  }
  updateInitialPayload(updatedInitialPayload)
  updateMainGrid({ ...mainGrid, component_call_cols: updatedMainGridCols })

  const source_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')
  const methods = page_data?.component_call_buttons
    ?.filter((item) => item.component_name !== 'hidden_tab' && item.source_table)
    .map((item) => item?.source_table)
  const columns = getColumns(gridRelatedData)
  let payload
  if (currentRow && currentRow[currentRow?.length - 1] && !isModel) {
    payload = {
      ...updatedInitialPayload,
      child_table: currentTabData?.source_table,
      child_table_columns: getChildTableColumn(mainGrid),
      parent_table:
        parentTableDataInRedux && parentTableDataInRedux?.length > 0
          ? parentTableDataInRedux[parentTableDataInRedux.length - 1]?.source_table
          : source_table && source_table[0]?.source_table,
      // parent_table: source_table[0]?.source_table,
      parent_table_row_id: currentRow[currentRow?.length - 1].id,
      childe_table_methods: [],
    }
  } else {
    payload = {
      source_table: source_table[0]?.source_table,
      columns: columns,
      methods: methods,
      ...updatedInitialPayload,
    }
  }

  if (selectedColorBadge?.length > 0) {
    payload = { ...payload, 'filter-group-color': selectedColorBadge }
  }
  if (filterManage?.filter_in || filterManage?.filter_out) {
    payload = { ...payload, 'filter-group-data': filterManage?.filter_in ? 'in' : 'out', grid_id: mainGrid?.id }
  }
  if (mainGrid?.where_clause) {
    payload = { ...payload, clause_data: mainGrid?.where_clause }
  }
  // payload = { ...payload, clause_data: mainGrid?.where_clause }
  if (selected_ids) {
    payload = { ...payload, '-filter-id': selected_ids }
  }
  clearConnectTemplateReduxData(gridRelatedData)
  dataConnect(mainGrid.component_call_data_connection, payload, mainGrid.name)
  clearCompletedQuestionnaireByGridId(mainGrid?.id)
}

export const getExportPayload = (gridRelatedData, currentTabData) => {
  const { page_data, mainGrid } = gridRelatedData
  const currentRow = store.getState().CMS.currentRow

  const source_table = page_data?.component_call_buttons?.filter((item) => item.component_name === 'hidden_tab')

  const methods = page_data?.component_call_buttons
    ?.filter((item) => item.component_name !== 'hidden_tab' && item.source_table !== source_table[0]?.source_table)
    .map((item) => item.source_table)

  const columns = getColumns(gridRelatedData)

  if (currentRow[currentRow?.length - 1]?.id) {
    let payload = {
      child_table: currentTabData?.source_table,
      child_table_columns: getChildTableColumn(mainGrid),
      parent_table: source_table[0]?.source_table,
      parent_table_row_id: currentRow[currentRow?.length - 1].id,
      childe_table_methods: [],
    }
    return payload
  }

  return {
    source_table: source_table[0]?.source_table,
    columns,
    methods,
  }
}

const checkActions = (item, data = null, mainSourceTable = null, mainGrid, row_data, gridRelatedData) => {
  const {
    pages,
    mainPageName,
    isModel,
    parentPageName,
    pageName,
    tableGrid,
    isTab,
    setAddDataDetail,
    activeTabNumber,
    updateRefreshGrid,
    updateClearFilters,
    updateSelectedAdvancedGrid,
    selectedGrid,
    content,
    domain_id,
    setQuestionnaireAction,
    currentTabData,
    initialPayload,
    savedFilters
  } = gridRelatedData
  const currentRow = store.getState().CMS.currentRow
  const deletedRowIdRedux = store.getState().CMS.deletedRowIdRedux
  const parentTableDataInRedux = store.getState().CMS.parentTableData
  const connectTemplateDataRedux = store.getState().CMS.connectTemplateDataRedux

  let cms_key = pages[mainPageName]?.cms_header_name
  if (currentRow[currentRow?.length - 1]?.id && !isModel) {
    cms_key = mainGrid?.cms_tab_key
  }

  if (!item?.url?.includes('add')) {
    switch (item.component_name) {
      case 'open_tab':
        //  store.dispatch(
        //    setParentTableData({
        //      source_table: content?.source_table,
        //      parent_page_name: content?.name,
        //      grid_id: content?.main_grid?.id,
        //      child_table: content?.child_tabs?.component_call_buttons[0]?.source_table,
        //    })
        //  )

        if (isTab && activeTabNumber) {
          if (setAddDataDetail) {
            setAddDataDetail({
              open: true,
              mainGrid: mainGrid,
              tableGrid: tableGrid,
              pageName: pageName,
              parentPageName: parentPageName,
              isTab: isTab,
              activeTabNumber: activeTabNumber,
              defaultDataFromCurrentRow: true,
              currentTabData,
              isModel,
            })
          } else {
            store.dispatch(addGridDataModalOpen(mainGrid))
          }
        } else {
          // if(mainSourceTable === 'item_groups' || mainSourceTable === 'items'){
          if (tableGrid?.grid_settings?.show_add_form_on_edit) {
            store.dispatch(clearTableDropDown())
            let idToLoadData
            if (tableGrid.source_table === 'template_groups') {
              idToLoadData = data?.id
            } else {
              idToLoadData = data?.item_group_id || data?.id
            }

            let addData = {
              open: true,
              mainGrid: mainGrid,
              tableGrid: tableGrid,
              pageName: pageName,
              parentPageName: parentPageName,
              isEditForm: true,
              idToLoadData,
              currentTabData,
              isModel,
            }
            if (tableGrid.source_table === 'template_groups') {
              // addData={...addData,considerCurrentRow:true}
            }

            // dispatch(addGridDataModalOpen(mainGrid))
            setAddDataDetail(addData)
          } else {
            store.dispatch(setTabFormData({ data, main_source_table: mainSourceTable }))
            store.dispatch(SetFullPage(false))
            store.dispatch(clearLastDataConnectionData())
          }
        }

        break
      case 'submit_api':
        if (item.url === 'connect-template-export') {
          store.dispatch(
            submitRequest({
              url: 'connect-template-export',
              data: { ...data, source_table: mainSourceTable, grid_id: mainGrid?.id, cms_key: cms_key, clause_data: mainGrid?.where_clause },
              scheduleApiCall: true,
              noReload: true,
              load: true,
            })
          )
        } else {
          const apiData = data
          store.dispatch(callFreeApi({ url: item.url, data: apiData }))

          updateRefreshGrid(true)
        }
        break
      case 'refresh_grid':
      
        updateRefreshGrid(true)
        clearConnectTemplateReduxData(gridRelatedData)
        break
      case 'clear_grid':
        let newFilters = { ...savedFilters }
        delete newFilters[tableGrid?.name]
        store.dispatch(saveFilter({ ...newFilters }))

        updateClearFilters(true)
        updateRefreshGrid(true)
        break
      case 'switch_grid':
        updateSelectedAdvancedGrid(item.load_grid)
        if (item.load_grid === 'restore_grid' || (selectedGrid === 'restore_grid' && item.load_grid === 'main_grid')) {
          clearConnectTemplateReduxData(gridRelatedData)
        }
        break
      case 'connect_delete_item_template':
        if (isDirectUpdateConnectTemplate(content?.source_table)) {
          store.dispatch(
            submitRequest({
              url: 'connect-template-delete',
              data: { ...data, domain_id },
              noReload: true,
            })
          )
        } else {
          store.dispatch(setQueue({ url: 'connect-template-delete', payload: { data: { ...data, domain_id } } }))
        }

        const dataToUpdate = getConnectTemplateReduxDataAfterDelete(
          connectTemplateDataRedux,
          parentPageName,
          pageName,
          content?.source_table,
          currentRow,
          currentRow?.length > 0 && !isModel,
          data?.ids
        )

        const deletedIdToUpdateInRedux = getDeletedIdsToUpdateInRedux(
          deletedRowIdRedux,
          parentPageName,
          pageName,
          content?.source_table,
          currentRow,
          currentRow?.length > 0 && !isModel,
          data?.ids
        )
        store.dispatch(setDeletedRowIdInRedux(deletedIdToUpdateInRedux))

        setTimeout(() => {
          store.dispatch(addDataStatusSet())
        }, 500)
        if (isDirectUpdateConnectTemplate(content?.source_table)) {
          updateRefreshGrid(true)
        } else {
          store.dispatch(setConnectTemplateDataRedux(dataToUpdate))
        }

        break
      case 'connect_delete_trash_item_template':
        store.dispatch(
          submitRequest({
            url: 'connect-template-delete',
            data: { ...data, is_trash: true },
            noReload: true,
          })
        )

        setTimeout(() => {
          store.dispatch(addDataStatusSet())
        }, 500)
        updateRefreshGrid(true)
        break
      case 'connect_delete_restore_item_template':
        store.dispatch(
          submitRequest({
            url: 'connect-template-delete',
            data: { ...data, is_restore: true },
            noReload: true,
          })
        )

        setTimeout(() => {
          store.dispatch(addDataStatusSet())
        }, 500)
        updateRefreshGrid(true)
        break
      case 'template-connect-export':
        store.dispatch(
          submitRequest({
            url: 'connect-template-export',
            data: { ...data, source_table: mainSourceTable, grid_id: mainGrid?.id, cms_key: cms_key, clause_data: mainGrid?.where_clause },
            scheduleApiCall: true,
            noReload: true,
            load: true,
          })
        )

        setTimeout(() => {
          store.dispatch(addDataStatusSet())
        }, 500)
        break
      case 'ask_questionnaire':
        // setQuestionnaireAction = { setQuestionnaireAction }
        // questionnaireAction = { questionnaireAction }

        if (data?.source_table === 'item_groups') {
          if (row_data.all_questions_answers && row_data.all_questions_answers?.length > 0) {
            let hasQuestionnaire = false
            row_data?.all_questions_answers?.map((item) => {
              // additional_information
              if (item?.item_questions?.length !== 0 || item?.additional_information) {
                hasQuestionnaire = true
              }
            })
            if (hasQuestionnaire) {
              setQuestionnaireAction({
                open: true,
                detail: { ...data, cms_key: mainGrid?.cms_header_key, grid_id: mainGrid?.id, activeRowData: row_data, pageName, parentPageName },
              })
            } else {
              const items = row_data?.all_questions_answers?.map((item, index) => {
                if (item) {
                  // setCompletedQuestionnaireRowByGridId

                  return {
                    q: [],
                    completed_all: true,
                    additional_information: item?.additional_information,
                    item_id: item?.id,
                  }
                }
              })
              request('complete-all-job/web', 'POST', { items: items }).then(() => {
                store.dispatch(setNotification({ type: 'success', message: 'Item Group completed successfully!' }))
                setQuestionnaireAction({ open: false, detail: null })
                updateRefreshGrid(true)
              })
            }
          } else {
            request('complete-job/web', 'POST', {
              item_group_id: row_data?.id,
              q: [],
              completed_all: true,
            }).then(() => {
              store.dispatch(setNotification({ type: 'success', message: 'Item Group completed successfully!' }))
              setQuestionnaireAction({ open: false, detail: null })
              updateRefreshGrid(true)
              store.dispatch(fetchUpdateApiData())
            })
          }
        } else {
          if (!row_data?.item_questions || row_data?.item_questions?.length === 0) {
            let payload = {
              item_id: row_data?.id,
              q: [],
              completed_all: true,
            }
            request('complete-job/web', 'POST', payload).then((res) => {
              store.dispatch(setNotification({ type: 'success', message: 'Task Completed!' }))

              updateRefreshGrid(true)
              store.dispatch(fetchUpdateApiData())
            })
          } else {
            setQuestionnaireAction({
              open: true,
              detail: {
                ...data,
                cms_key: mainGrid?.cms_header_key,
                grid_id: mainGrid?.id,
                activeRowData: row_data,
                item_group_id: row_data?.item_group_id,
                parentPageName,
                pageName,
              },
            })
          }
        }

        // dispatch(setQuestionnaire({ ...data, cms_key: mainGrid?.cms_header_key,grid_id:mainGrid?.id,activeRowData:row_data }))
        break
      default:
        console.log(item, data, 'data')
        break
    }
  } else {
    store.dispatch(clearTableDropDown())

    if (setAddDataDetail) {
      setAddDataDetail({
        open: true,
        mainGrid: mainGrid,
        tableGrid: tableGrid,
        pageName: pageName,
        parentPageName: parentPageName,
        isTab: isTab,
        activeTabNumber: activeTabNumber,
        isAddNew: true,
        currentTabData,
        isModel,
      })
    } else {
      store.dispatch(addGridDataModalOpen(mainGrid))
    }
  }
}

export const triggerButtonActionsInGrid = (actions, row_data, gridSelectedRows, mainSourceTable, mainGrid, gridRelatedData) => {
  const { initialPayload, currentTabData, isModel } = gridRelatedData
  const currentRow = store.getState().CMS.currentRow
  const parentTableDataInRedux = store.getState().CMS.parentTableData

  actions?.map((item) => {
    let data = []
    if (item?.payload?.data) {
      switch (item?.payload?.data) {
        case 'selected_id':
          data = {
            ids: gridSelectedRows.map((i) => i.original.id),
            ...initialPayload,
            ...getExportPayload(gridRelatedData, currentTabData),
            type: item.payload?.type,
          }
          break
        case 'filters':
          const filters = { ...initialPayload, ...getExportPayload(gridRelatedData, currentTabData) }
          delete filters.ids
          delete filters.rec_per_page
          data = { ...filters, type: item.payload?.type }
          break
        default:
          break
      }
    } else if (item?.payload === 'id') {
      if (currentRow[currentRow?.length - 1]?.id) {
        // const currentTabData = page_data?.component_call_buttons
        //   ?.filter((tab) => tab.is_grid === 1 || tab.is_grid == true)
        //   .filter((item) => item.component_call_form[0].main_grid === mainGrid || item.component_call_form[0].restore_grid === mainGrid)

        data = {
          ids: gridSelectedRows.map((i) => parseInt(i.original.id)),
          source_table: currentTabData?.source_table,
          parent_table:
            parentTableDataInRedux && parentTableDataInRedux?.length > 0
              ? parentTableDataInRedux[parentTableDataInRedux.length - 1]?.source_table
              : mainSourceTable,
          parent_table_row_id: currentRow[currentRow?.length - 1]?.id,
        }
      } else {
        data = {
          ids: gridSelectedRows.map((i) => parseInt(i.original.id)),
          source_table: mainSourceTable,
        }
      }
    } else {
      if (currentRow[currentRow?.length - 1]?.id && !isModel) {
        // const currentTabData = page_data?.component_call_buttons
        //   ?.filter((tab) => tab.is_grid === 1 || tab.is_grid == true)
        //   .filter((item) => item.component_call_form[0]?.main_grid === mainGrid || item.component_call_form[0]?.restore_grid === mainGrid)

        data = {
          ...gridSelectedRows,
          source_table: currentTabData?.source_table,
          parent_table:
            parentTableDataInRedux && parentTableDataInRedux?.length > 0
              ? parentTableDataInRedux[parentTableDataInRedux.length - 1]?.source_table
              : mainSourceTable,
          parent_table_row_id: currentRow[currentRow?.length - 1]?.id,
        }
      } else {
        data = {
          ...gridSelectedRows,
          source_table: mainSourceTable,
        }
      }
    }

    checkActions(item, data, mainSourceTable, mainGrid, row_data, gridRelatedData)
  })
}
