import React, { Fragment } from 'react'
import HeroBanner from '../Widget/HeroBanner/HeroBanner'
import PolicyBlock from '../Widget/NumberList/PolicyBlock'
import useMediaQuery from '../../CustomHook/useMediaQuery'

const TermsConditions = ({ handleClose }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  const data = [
    {
      title: 'Lorem Ipsum dolor sit amet',
      policies: [
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
      ],
    },
    {
      title: 'Lorem Ipsum dolor sit amet',
      policies: [
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
        'lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsumdolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua',
      ],
    },
  ]

  return (
    <Fragment>
      <div className='mt-5 position-relative p-4' style={{ border: '1px solid #8E8E8E', borderTopRightRadius:'10px', marginRight:'0.6rem' }}>
        <i
          className='fa fa-times-circle position-absolute'
          onClick={handleClose}
          style={{ color: '#F87171', cursor: 'pointer', fontSize: 18, right: -10, top: -10 }}></i>
        <div className='font-weight-bold mb-1' style={{ fontSize: '16px' }}>
          Terms and Conditions
        </div>
        <div style={{ textAlign: 'left' }} className='mb-4'>
          <p>
            lorem ipsum dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua lorem ipsum
            dolor sit amet consectetur adipisicing elit.sed do eiusmod tempor incidudunt ut labore et dolore magna aliqua
          </p>
        </div>

        <div>
          {data.map((item, index) => (
            <div className='d-flex flex-column' key={index}>
              <div className='font-weight-bold mb-1' style={{ fontSize: '16px' }}>
                {item.title}
              </div>
          
              <ol class='d-flex flex-column' style={{marginLeft:'20px'}}>
                {item.policies.map((point, i) => (
                  <li className='col-md-12' key={i}>
                    {point}
                  </li>
                ))}
              </ol>
            </div>
          ))}
        </div>
      </div>
    </Fragment>
  )
}

export default TermsConditions
