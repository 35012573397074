import { TOGGLE_HEADER_SIDEBAR_VISIBILITY } from "./themeOptions"

export const setToggleHeaderSideBar = (isHeaderSideBarOpen) => ({
  type: TOGGLE_HEADER_SIDEBAR_VISIBILITY,
  isHeaderSideBarOpen,
})


const initialState = {
  isHeaderSideBarOpen: false
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_HEADER_SIDEBAR_VISIBILITY:
      const { isHeaderSideBarOpen } = action
      return {
        ...state,
        isHeaderSideBarOpen
      }
    default:
  }
  return state
}
