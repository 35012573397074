import React, { lazy, Fragment, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import { can, checkPermission, getNavFilterList } from '../../helper'

import Login from '../Auth/login'
import HandleLogout from '../Auth/logout'
import Register from '../Auth/register'
import ResetPassword from '../Auth/resetPassword'
import AuthRoute from './authRoute'
import WebRoute from './webRoute'
import CMSPage from '../../CMSComponent/page'
import { Redirect, useHistory } from 'react-router-dom/cjs/react-router-dom'
import EditSidebarNav from '../../CMSComponent/EditCMS/SidebarNavForms/editSidebarNav'
import { ErrorBoundary } from '../ErrorPage/errorBoundry'
import useNetworkStatus from '../../CustomHook/useNetworkStatus'
import { closeOpenModal, setCoreData, setCurrentUrl, updatePublicPage } from '../../Redux/Reducers/CMS'
import AccessDenied from '../AccessDenied/accessDenied'
import Wrapper from '../../Components/wrapper'
import { fetchPublicPagesNav } from '../../Helper/publicPageHelper'
import PublicPage from '../../CMSComponent/PageVariation/PublicPage/publicPage'
import PublicPageWrapper from '../../Components/publicPageWrapper'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'

const ForgotPassword = lazy(() => import('../Auth/forgotPassword'))

const select = (state) => ({
  navMenu: state.CMS.coreData.nav,
  user: state.CMS.User,
  defaultUrl: state.User.defaultUrl,
  coreData: state.CMS.coreData,
  currentUrl: state.CMS.currentUrl,
  Permissions: state.Permissions,
  publicPage: state.CMS.publicPage,
  publicPageFetched: state.CMS.publicPageFetched,
})

const Routes = (props) => {
  const { navMenu, defaultUrl, coreData, Permissions } = props
  const [list, updateList] = useState([])
  const { isOnline } = useNetworkStatus()
  const isAuth = useSelector((state) => state?.User?.isAuth)

  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const renderNav = () => {
    if (!navMenu) return null
    updateList(getNavFilterList(navMenu))
  }

  useEffect(() => {
    fetchPublicPagesNav()
    // setTimeout(() => {
    //   dispatch(updatePublicPage(DUMMY_ROUTE))
    // }, 2000)
  }, [])

  useEffect(() => {
    renderNav()
  }, [navMenu, coreData])

  useEffect(() => {
    const availableList = list?.map((item) => item.path)
    const accessedList = list?.filter((item) => checkPermission('view', item?.page, null, true))
    if (navMenu && Permissions && props.publicPageFetched) {
      if (availableList?.length > 0 && isAuth) {
        console.log('called::::::::::', navMenu, Permissions, props.publicPageFetched)
        if (accessedList?.length === 0 && coreData?.nav) {
          history.push('/access-denied')
        } else if (availableList?.includes(props.currentUrl)) {
          history.push(props.currentUrl)
        } else {
          history?.push(accessedList?.[0]?.path)
          dispatch(setCurrentUrl(accessedList?.[0]?.path))
        }
      } else if (availableList?.length === 0 && can('site_setup') && isAuth) {
        history.push('/site_setup')
      }
    } else if (props.publicPage?.length > 0 && !window.location.pathname?.includes('payment')) {
      const currentPage = props.publicPage.find((item) => { return `/${item.route}` == window.location.pathname })
      if(currentPage){
          history.push('/'+currentPage?.route + location.search)
      }
      else {
        history.push('/'+props.publicPage?.[0]?.route + location.search)
      }
    }
  }, [coreData, navMenu, list, Permissions, isAuth])

  useEffect(() => {
    dispatch(
      setCoreData({
        ...coreData,
        currentFormData: {},
        currentFormFullData: {},
      })
    )
  }, [])

  useEffect(() => {
    dispatch(closeOpenModal())
  }, [])

  return (
    <Fragment>
      <Switch>
        {/* Auth Routes */}
        <WebRoute path='/welcome/login' component={Login} />
        <WebRoute path='/welcome/register' component={Register} />
        <WebRoute path='/forgot-password' component={ForgotPassword} />
        <WebRoute path='/reset/:token' component={ResetPassword} />

        {/* User Route */}
        <AuthRoute path='/logout' component={HandleLogout} />
        <ErrorBoundary>
          {props?.publicPageFetched && (
            <>
              {props?.publicPage?.length > 0 && !isAuth ? (
                <>
                  {console.log('publicpage data:::::::', props?.publicPage, props?.publicPageFetched)}
                  {props?.publicPage?.map((page) => (
                    <Route
                      path={'/' + page?.route}
                      component={() => (
                        <PublicPageWrapper>
                          <PublicPage page={page} />
                        </PublicPageWrapper>
                      )}
                    />
                  ))}
                </>
              ) : (
                <>
                  {list.map((item, key) => {
                    return <AuthRoute exact key={key} path={'/' + item.path} page={item.page} component={CMSPage} />
                  })}
                </>
              )}
            </>
          )}

          <Route
            component={() => {
              if (defaultUrl) {
                return <p>default url..</p>
              }
              // return <ErrorPage />
            }}
          />
          {/* 
          <Route
            component={() => {
              if (defaultUrl) {
                return list.length > 0 ? (
                  <Redirect to={false ? defaultUrl : props.currentUrl ? props.currentUrl : list[0].path} />
                ) : can('site_setup') ? (
                  <SiteSetup />
                ) : coreData?.nav && coreData?.nav?.length > 0 ? (
                  <></> //access denies
                ) : (
                  // <AccessDenied />
                  <p>Loading...</p>
                )
              }
              return
              // return <ErrorPage />
            }}
          /> */}
          <Route path='/access-denied' exact component={() => <AccessDenied />} />
          <Route
            path='/'
            exact
            component={() => {
              return (
                <DefaultComponent
                  currentUrl={props.currentUrl}
                  list={list}
                  publicPage={props.publicPage}
                  publicPageFetched={props.publicPageFetched}
                />
              )
            }}></Route>
        </ErrorBoundary>
      </Switch>
      <ToastContainer />
    </Fragment>
  )
}

const SiteSetup = () => {
  const history = useHistory()

  useEffect(() => {
    console.log('route:::::::::::')
    history?.push('site_setup')
  }, [])
  return (
    <div className='text-center'>
      <Wrapper newNav={true}>
        <EditSidebarNav newNav={true} />
      </Wrapper>
    </div>
  )
}

const DefaultComponent = ({ currentUrl, list, publicPage, publicPageFetched }) => {
  const history = useHistory()
  const isAuth = useSelector((state) => state?.User?.isAuth)
  const coreData = useSelector((state) => state.CMS.coreData)
  let accessedList

  accessedList = list?.filter((item) => checkPermission('view', item?.page, null, true))

  useEffect(() => {
    if (!publicPageFetched) return
    if (isAuth && coreData?.nav) {
      if (currentUrl && currentUrl !== '' && currentUrl !== '/') {
        history.push(currentUrl)
      } else if (accessedList && accessedList?.length > 0 && accessedList[0]?.path && accessedList[0]?.path !== '') {
        history.push(accessedList[0]?.path)
      } else if (accessedList?.length === 0) {
        history.push('/access-denied')
      } else {
        history.push('/welcome/login')
      }
    } else {
      if (publicPage?.length > 0) {
        history.push(publicPage?.[0]?.route)
      } else {
        history.push('/')
      }
    }
  }, [])

  return <div>Loading....!</div>
}

export default connect(select)(Routes)
