import React, { useEffect } from 'react'
import AddressForm from '../PaymentForm/AddressForm'
import { primary_color } from '../../../Helper/uiHelper'
  const inputIconStyle = {
    position: 'absolute',
    fontSize: '1rem',
    top: '50%',
    left: '15px',
    transform: 'translate(-0%, -50%)',
    color: 'grey',
  }
const GuestCheckout = ({ userAddress, setUserAddress, showValidation, setShowValidation }) => {

  useEffect(() => {
    if (
      userAddress.customerFirstName &&
      userAddress.customerLastName &&
      userAddress.customer_email &&
      userAddress.address1 &&
      userAddress.city &&
      userAddress.country &&
      userAddress.post_code
    )
      setShowValidation('Success')
  }, [userAddress])

  const formChange = (e) => {
    setShowValidation(false)
    setUserAddress((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }
  return (
    <div>
      <div className='h4 font-weight-bold mb-4' style={{ color: primary_color }}>
        Guest Checkout now!
      </div>
      <form className=' d-flex flex-column mb-5 pb-5' style={{ gap: '0.75rem', borderBottom: '1px solid lightgrey' }} onChange={formChange}>
        <div className=' position-relative'>
          <input
            value={userAddress?.customerFirstName}
            className={`form-control rounded-pill border border-black pl-5 ${showValidation && !userAddress?.customerFirstName ? 'error-input' : ''}`}
            placeholder='First name'
            name='customerFirstName'
            onChange={formChange}
          />
          <i style={inputIconStyle} className='fa-regular fa-user'></i>
          {showValidation && !userAddress?.customerFirstName && <div className='text-danger text-left pl-2'>Please enter First name</div>}
        </div>
        <div className=' position-relative'>
          <input
            className={`form-control rounded-pill border border-black pl-5 ${showValidation && !userAddress?.customerLastName ? 'error-input' : ''}`}
            value={userAddress?.customerLastName}
            placeholder='Surname'
            name='customerLastName'
            onChange={formChange}
          />
          <i style={inputIconStyle} className='fa-regular fa-user'></i>
          {showValidation && !userAddress?.customerLastName && <div className='text-danger text-left pl-2'>Please enter Surname</div>}
        </div>
        <div className=' position-relative'>
          <input
            value={userAddress?.customer_email}
            className={`form-control rounded-pill border border-black pl-5 ${showValidation && !userAddress?.customer_email ? 'error-input' : ''}`}
            placeholder='Email'
            name='customer_email'
            onChange={formChange}
          />
          <i style={inputIconStyle} className='fa-regular fa-envelope'></i>
          {showValidation && !userAddress?.customer_email && <div className='text-danger text-left pl-2'>Please enter Email</div>}
        </div>
      </form>
      <div className='h4 font-weight-bold mb-4' style={{ color: primary_color }}>
        Address
      </div>
      <AddressForm
        userAddress={userAddress}
        setUserAddress={setUserAddress}
        showValidation={showValidation}
        setShowValidation={setShowValidation}
        isGuestCheckout={true}
      />
    </div>
  )
}

export default GuestCheckout
