import React, { Fragment, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import NavHeaderBtn from '../../../CMSComponent/NavHeaderBtn'
import Icon from '../../../CMSComponent/icon'
import AddTopNavButton from '../../../CMSComponent/EditCMS/TopNav/addTopNavButton'

const select = (state) => ({
  editPageMode: state.CMS.editPageMode,
  coreData: state.CMS.coreData,
})

const TopNavButtons = ({ coreData, editPageMode }) => {
  const [navHeader, updateNavHeader] = useState([])
  const [dropdownOpen, updateDropdownOpen] = useState(false)

  useEffect(() => {
    if (coreData.header_navigation) {
      updateNavHeader(coreData.header_navigation)
    }
  }, [coreData.header_navigation])

  if (!navHeader) return null
  return (
    <Fragment>
      <div className='header-dots ml-2'>
        {editPageMode && (
          <Dropdown isOpen={dropdownOpen} toggle={() => updateDropdownOpen(!dropdownOpen)}>
            <DropdownToggle className='p-0 mr-2' color='link'>
              <Icon bgcolor='success' color='primary' type='Pe7' icon='pe-7s-plus' navheadericon='true' style={{ fontWeight: '600' }} />
            </DropdownToggle>
            <DropdownMenu right className={'dropdown-menu-xl rm-pointers dropdown-menu-form'} style={{ padding: '0 0 0.65rem' }}>
              <AddTopNavButton close={() => updateDropdownOpen(false)} />
            </DropdownMenu>
          </Dropdown>
        )}
        <NavHeaderBtn navHeader={navHeader} />
      </div>
    </Fragment>
  )
}

export default connect(select)(TopNavButtons)
