import React, { useState } from 'react'
import NavigationButton from './navigationButton'
import useMediaQuery from '../../CustomHook/useMediaQuery'

const grey_dot = <div className='rounded-circle' style={{ height: '4px', width: '4px', background: '#A8AEBB' }}></div>
const WizardTab = ({ tabs, setActivePage, activePage, pageData }) => {
  const isDesktop = useMediaQuery('(min-width:768px)')

  let tabsToShow = tabs
  if(!isDesktop){
    tabsToShow = tabs.slice(0,tabs?.length-1)
  }
  return (
    <div className='d-flex justify-content-between' style={{ flex: 1, height: 'fit-content', margin: isDesktop ? '5rem auto' : '5rem auto 0 auto' }}>
      <div className='locker-container py-2 d-flex justify-content-between w-100' style={{ height: 'fit-content' }}>
        {tabsToShow?.map((item, index) => (
          <>
            <div key={index} className='d-flex align-items-center flex-column'>
              <NavigationButton
                setActivePage={setActivePage}
                activePage={activePage}
                index={index}
                icon={item.icon}
                title={item.name}
                pageData={pageData}
                isActive={item?.isActive}
                isVisited={item?.isVisited}
                page_uuid={item?.uuid}
              />
            </div>
            {index < tabs.length - 1  && isDesktop && (
              <div
                className='d-flex align-items-center justify-content-between'
                style={{
                  width: isDesktop ? '40px' : '20px',
                  height: '50px',
                  marginBottom: '50px',
                }}>
                {Array(isDesktop ? 4 : 3).fill(grey_dot)}
              </div>
            )}
          </>
        ))}
      </div>
    </div>
  )
}

export default WizardTab
