import { Fragment, useEffect, useState } from 'react'
import { CustomInput, FormGroup, Label } from 'reactstrap'
import CommonFields from './commonFields'

function Checkbox({ item, answers, setAnswers, requiredError }) {
  const [defaultValue, setValue] = useState([])
  const [selectOptions, setSelectOptions] = useState([])

  const current_answer_object = answers?.filter((data) => data?.item_question_id === item?.id)[0] || {}

  useEffect(() => {
    const options = []
    {
      item.item_answers?.map((item, key) => {
        options.push({ value: item.id, label: item.answer })
      })
    }
    setSelectOptions(options)

    if (item.item_answer_responses?.length > 0) {
      const values = []
      item.item_answer_responses.map((answer) => {
        values.push(answer.item_answer_id)
      })
      setValue(values)
    }
  }, [])

  const onChangeValue = (v) => {
    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        const ans = v?.map((item) => {
          const selectedOption = selectOptions?.filter((data) => data?.value === item)
          return {
            answer_id: selectedOption[0]?.value,
            response_text: selectedOption[0]?.label,
          }
        })
        return {
          ...question,
          answer_id: ans,
        }
      } else {
        return question
      }
    })
    setAnswers(updated_answers)
  }

  useEffect(() => {
    const selectedOptionFromAnswersObject = current_answer_object?.answer_id
    const selected_option = selectedOptionFromAnswersObject?.map((item) => item?.answer_id)
    if (selected_option) {
      setValue(selected_option)
    }
  }, [JSON.stringify(current_answer_object)])

  return (
    <Fragment>
      <div>
        <Label>{item.question}?</Label>
        <FormGroup>
          <div>
            {selectOptions?.map((i, key) => {
              return (
                <CustomInput
                  key={key}
                  id={i.value}
                  checked={defaultValue?.find((f) => f === i.value) || false}
                  type='checkbox'
                  name='checkbox_question'
                  label={i.label}
                  onChange={(e) => {
                    if (defaultValue?.find((f) => f === i.value)) {
                    }
                    if (e.target.checked) {
                      onChangeValue([...defaultValue, i.value])
                      // setValue([...defaultValue,i.value])
                    } else {
                      const prevValue = [...defaultValue]
                      const index = prevValue?.indexOf(i.value)
                      prevValue.splice(index, 1)
                      // setValue(prevValue)
                      onChangeValue(prevValue)
                    }
                  }}
                />
              )
            })}
          </div>
        </FormGroup>
        <CommonFields
          item={item}
          selectedAnswer={defaultValue}
          answers={answers}
          setAnswers={setAnswers}
          current_answer_object={current_answer_object}
          isArray={true}
        />
        {item?.validations?.includes('required') &&
          requiredError &&
          (!current_answer_object?.answer_id || current_answer_object?.answer_id?.length === 0) && (
            <div style={{ color: 'red' }}>Please Complete</div>
          )}
      </div>
    </Fragment>
  )
}

export default Checkbox
