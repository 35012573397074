import React, { Fragment, useEffect, useState } from 'react'
import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'
import { Card, CardBody, Row, Col, Button, FormGroup, Label } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'

import MultiStep from '../../Components/wizard'
import PageTitle from '../../Layout/AppMain/pageTitle'
import { checkQuestionnaireValidations, cms, editCMS, setCompletedQuestionnaireRowByGridId } from '../../helper'
import { request } from '../../Redux/Sagas/requests/api'
// types
import CompleteQuestions from './QuestionTypes/completeQuestions'
import Select from './QuestionTypes/select'
import Text from './QuestionTypes/text'
import MultiSelect from './QuestionTypes/multiSelect'
import Radio from './QuestionTypes/radio'
// import Checkbox from './QuestionTypes/Checkbox'
import DateTime from './QuestionTypes/dateTime'
import FileUpload from './QuestionTypes/fileUpload'
import RichText from './QuestionTypes/richText'
import Signature from './QuestionTypes/signaturePage'
import Checkbox from './QuestionTypes/checkbox'
import Time from './QuestionTypes/time'

import { setNotification } from '../../Redux/Reducers/notificationHandling'

import AdditionalInfo from './additionalInfo'
import { clearConnectTemplateReduxData } from '../Grid/Component/MainGrid/gridHelper'
import { fetchUpdateApiData } from '../../Redux/Reducers/CMS'

const checkAnswerBasedOnQuestionType = (answer) => {
  if (
    answer?.question_type === 'text' ||
    answer?.question_type === 'date-time' ||
    answer?.question_type === 'date' ||
    answer?.question_type === 'time' ||
    answer?.question_type === 'rich-text'
  ) {
    if (answer?.response_text && answer?.response_text !== '') {
      return true
    } else {
      return false
    }
  }
  if (answer?.question_type === 'select' || answer?.question_type === 'radio') {
    if (answer?.response_text && answer?.answer_id) {
      return true
    } else {
      return false
    }
  }
  if (answer?.question_type === 'multy-select' || answer?.question_type === 'check') {
    if (answer?.answer_id && answer?.answer_id?.length > 0) {
      return true
    } else {
      return false
    }
  }

  if (answer?.question_type === 'signiture_select' || answer?.question_type === 'signiture_name' || answer?.question_type === 'signiture_basic') {
    if (answer?.image_base64 && answer?.image_base64?.length > 0) {
      return true
    } else {
      return false
    }
  }
  if (answer?.question_type === 'upload') {
    if (answer?.upload_base64 && answer?.upload_base64?.length > 0) {
      return true
    } else {
      return false
    }
  }
}

export const checkAllAnswerCompleted = (answers) => {
  const answerCompletedArray = answers?.map((answer) => {
    if (answer?.validation?.includes('required')) {
      return checkAnswerBasedOnQuestionType(answer)
    } else {
      return true
    }
  })

  const finalAnsArray = answerCompletedArray?.filter((item) => !item)
  if (finalAnsArray?.length === 0) {
    return true
  } else {
    return false
  }
}

const GetComponent = ({ item, answers, setAnswers, requiredError }) => {
  switch (item.type) {
    case 'select':
      return <Select item={item} answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'multy-select':
      return <MultiSelect item={item} answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'radio':
      return <Radio item={item} answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'check':
      return <Checkbox item={item} answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
      return
    case 'date':
      return <DateTime item={item} type='date' answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'time':
      return <Time item={item} type='time' answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'date-time':
      return <DateTime item={item} type='date-time' answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'text':
      return <Text item={item} answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'rich-text':
      return <RichText item={item} answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'upload':
      return <FileUpload item={item} answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'signiture_basic':
      // return
      return <Signature item={item} type='signiture_basic' answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'signiture_name':
      return <Signature item={item} type='signiture_name' answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'signiture_select':
      return <Signature item={item} type='signiture_select' answers={answers} setAnswers={setAnswers} requiredError={requiredError} />
    case 'divider':
      return <hr></hr>
    default:
      break
  }
}

const completeQuestion = { name: 'Finish Wizard', component: <CompleteQuestions /> }
const blank = { name: 'Loading..', component: <></> }

const Questionnaire = ({
  questionnaireDetail,
  questionDetail,
  item_id,
  isGroupedQuestionnaire,
  allAnswers,
  setAllAnswers,
  onAnswerChangeInMain,
  showFullQuestion,
  submitAllAnswerStatus,
  questionnaireIndex,
  setQuestionnaireAction,
  questionnaireName,
  setAllAdditionalInformation,
  allAdditionalInformation,
}) => {
  const [steps, setSteps] = useState([blank])
  // const [question, setQuestion] = useState([])
  // const [showFullQuestion, setShowFullQuestion] = useState(false)
  // const [answers, setAnswers] = useState([])
  const answers = allAnswers[questionnaireIndex] || []

  const setAnswers = (data) => {
    setAllAnswers((allAnswers) => {
      const newAnswers = [...allAnswers]
      newAnswers[questionnaireIndex] = data
      return newAnswers
    })
  }

  const [requiredError, setRequiredError] = useState(false)
  // const [additional_information, set_additional_information] = useState(questionDetail?.additional_information)
  const additional_information = allAdditionalInformation[questionnaireIndex]

  const set_additional_information = (data) => {
    setAllAdditionalInformation((allAdditionalInformation) => {
      const a = [...allAdditionalInformation]
      a[questionnaireIndex] = data
      return a
    })
  }

  const dispatch = useDispatch()

  const onFormSubmit = () => {
    let payload = {
      item_id: item_id,
      q: answers,
      additional_information: allAdditionalInformation[questionnaireIndex],
    }
    const isValidated = checkQuestionnaireValidations(questionDetail?.item_questions, answers)

    payload = {
      ...payload,
      completed_all: checkAllAnswerCompleted(answers),
    }
    clearConnectTemplateReduxData({ pageName: questionnaireDetail?.pageName, parentPageName: questionnaireDetail?.parentPageName })

    if (isValidated) {
      request('complete-job/web', 'POST', payload).then((res) => {
        if (!(!isGroupedQuestionnaire || submitAllAnswerStatus)) {
          dispatch(setNotification({ type: 'success', message: 'Answer Saved Successfully' }))
        }
        if (!isGroupedQuestionnaire) {
          setCompletedQuestionnaireRowByGridId(questionnaireDetail?.grid_id, questionnaireDetail?.activeRowData?.id)
        }
        if (!isGroupedQuestionnaire || submitAllAnswerStatus) {
          // dispatch(clearPageTab())
          setQuestionnaireAction({ open: false, detail: null })
        }
        dispatch(fetchUpdateApiData())
      })
    } else {
      dispatch(setNotification({ type: 'error', message: 'Please Complete Required Question' }))
      setRequiredError(true)
    }
  }

  useEffect(() => {
    if (submitAllAnswerStatus) {
      onFormSubmit()
    }
  }, [submitAllAnswerStatus])

  useEffect(() => {
    if (questionDetail) {
      // setQuestion(response.data)
      const answer_default_data = questionDetail?.item_questions
        ?.filter((question) => question?.type !== 'divider')
        ?.map((question) => {
          let obj = {
            item_question_id: question?.id,
            question_type: question?.type,
            validation: question?.validations,
          }
          if (question?.item_answer_responses?.length > 0) {
            let comment = '',
              upload_base64
            question?.item_answer_responses?.map((answer) => {
              if (answer?.comment) {
                comment = answer?.comment
              }
              if (answer?.upload_base64 && answer?.upload_base64?.length > 0 && !upload_base64) {
                upload_base64 = answer?.upload_base64
              }
            })

            obj = {
              ...obj,
              comment: comment,
              upload_base64: upload_base64,
            }
          }
          if (
            (question?.type === 'text' ||
              question?.type === 'date' ||
              question?.type === 'time' ||
              question?.type === 'date-time' ||
              question?.type === 'rich-text') &&
            question?.item_answer_responses &&
            question?.item_answer_responses?.length > 0
          ) {
            obj = {
              ...obj,
              question_type: question?.type,
              response_text: question?.item_answer_responses[0]?.response_text,
            }
          }

          if (
            (question?.type === 'select' || question?.type === 'radio') &&
            question?.item_answer_responses &&
            question?.item_answer_responses?.length > 0
          ) {
            obj = {
              ...obj,
              answer_id: question?.item_answer_responses[0]?.answer_id || question?.item_answer_responses[0]?.item_answer_id,
              response_text: question?.item_answer_responses[0]?.response_text,
              // comment: question?.item_answer_responses?.comment,
            }
          }

          if (question?.type === 'multy-select' || question?.type === 'check') {
            if (question?.item_answer_responses && question?.item_answer_responses?.length > 0) {
              const ans = question?.item_answer_responses?.map((answer) => {
                return {
                  answer_id: answer?.item_answer_id,
                  response_text: answer?.response_text,
                }
              })
              obj = {
                ...obj,
                answer_id: ans,
              }
            } else {
              obj = {
                ...obj,
                answer_id: null,
              }
            }
          }

          if (
            (question?.type === 'signiture_select' || question?.type === 'signiture_name' || question?.type === 'signiture_basic') &&
            question?.item_answer_responses &&
            question?.item_answer_responses?.length > 0
          ) {
            obj = {
              ...obj,
              image_base64: question?.item_answer_responses[0]?.image_base64,
              response_text: question?.item_answer_responses[0]?.response_text,
            }
          }
          if (question?.type === 'upload' && question?.item_answer_responses && question?.item_answer_responses?.length > 0) {
            obj = {
              ...obj,
              upload_base64: question?.item_answer_responses[0]?.upload_base64,
            }
          }
          return obj
        })

      setAnswers(answer_default_data)
      // if (answer_default_data?.length > 0) {
      //   setAllAnswers((allAnswers) => [...allAnswers, { item_id: item_id, q: answer_default_data }])
      // }
    }
  }, [])

  useEffect(() => {
    // setAllAnswers(new_answers_from_main)
    onAnswerChangeInMain(answers, item_id)
  }, [JSON.stringify(answers)])

  useEffect(() => {
    let updatedSteps = []

    if (questionDetail?.item_questions) {
      questionDetail?.item_questions?.map((item, key) => {
        const mark = item.question.charAt(item.question.length - 1) !== '?' && '?'
        updatedSteps.push({
          name: item.question + mark,
          component: (
            <div className={showFullQuestion ? '' : ''}>
              <GetComponent
                item={item}
                key={key}
                showFullQuestion={showFullQuestion}
                answers={answers}
                setAnswers={setAnswers}
                requiredError={requiredError}
              />
            </div>
          ),
          questionId: item?.id,
        })
      })
      if (questionDetail?.add_additional_information) {
        updatedSteps = [
          ...updatedSteps,
          {
            name: 'Additional Information',

            component: (
              <AdditionalInfo additional_information={additional_information} set_additional_information={set_additional_information} id={item_id} />
            ),
          },
        ]

        // set_additional_information(questionDetail?.additional_information)
      }

      updatedSteps = [
        ...updatedSteps,
        {
          name: 'Finish Wizard',
          component: (
            <CompleteQuestions
              onFormSubmit={onFormSubmit}
              isGroupedQuestionnaire={isGroupedQuestionnaire}
              completed_all={questionDetail?.completed}
            />
          ),
        },
      ]
      // setQuestion(response.data)
    }
    setSteps(updatedSteps)
  }, [showFullQuestion, JSON.stringify(answers), requiredError, additional_information, questionDetail?.additional_information])

  if (questionDetail?.item_questions?.length === 0) return

  return (
    <Fragment>
      <CSSTransitionGroup
        component='div'
        transitionName='TabsAnimation'
        transitionAppear={true}
        transitionAppearTimeout={0}
        transitionEnter={false}
        transitionLeave={false}
        style={{ padding: '2rem', paddingTop: '0rem', paddingBottom: '0rem' }}>
        <div>
          <Row className='mt-4'>
            <Col md='12'>
              <div style={{ marginLeft: '15px', fontWeight: 'bold', fontSize: '17px', marginBottom: '7px' }}>{questionnaireName}</div>
              <Card className='main-card mb-3' style={{ border: questionDetail?.completed ? '1px solid green' : 'none', marginLeft: '15px' }}>
                <CardBody>
                  <div className='forms-wizard-vertical'>
                    {/* <div className='forms-wizard-vertical' style={{ maxHeight: '400px', overflow: 'hidden' }}> */}
                    {steps?.length > 1 && (
                      <MultiStep
                        showNavigation={true}
                        steps={steps}
                        showAllSteps={showFullQuestion}
                        questionnaireIndex={questionnaireIndex}
                        answers={answers}
                        hideNavigation={true}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </CSSTransitionGroup>
    </Fragment>
  )
}

export default Questionnaire
