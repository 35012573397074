import { AvField, AvForm } from 'availity-reactstrap-validation'
import { useEffect, useState } from 'react'
import { Button, FormGroup } from 'reactstrap'
import { tidyName } from '../../../../helper'
import RenderFields from '../../../Forms/renderFields'
import IconSet from '../../../icon'

const TemplateGroupLocation = ({ submittedFormData, setSubmittedFormData, form }) => {
  const [addedData, setAddedData] = useState([])
  const [addDataFormOpen, setAddDataFormOpen] = useState(false)

  const [searchValue, setSearchValue] = useState('')

  useEffect(() => {
    if (submittedFormData) {
      if (submittedFormData?.template_groups?.template_group_locations) {
        setAddedData(submittedFormData?.template_groups?.template_group_locations)
      }
    }
  }, [submittedFormData.template_groups?.template_group_locations])

  const onDelete = (id) => {
    const newAddedData = []
    for (let i = 0; i < addedData?.length; i++) {
      if (addedData[i]?.id !== id) {
        newAddedData?.push(addedData[i])
      }
    }

    // const newAddedData = [...addedData]
    // newAddedData?.splice(index,1)
    setSubmittedFormData({ ...submittedFormData, template_groups: { ...submittedFormData?.template_groups, template_group_locations: newAddedData } })
  }

  const onFormSubmit = (values) => {
    const new_location = {
      location_id: values?.location_id,
      location: {
        id: values?.location_id,
        name: values?.location_id_label,
      },
      template_group_id: submittedFormData?.template_groups?.id,
    }
    const newAddedData = [new_location, ...addedData]
    setSubmittedFormData({ ...submittedFormData, template_groups: { ...submittedFormData?.template_groups, template_group_locations: newAddedData } })

    setAddDataFormOpen(false)
  }

  let filteredList = addedData
  if (searchValue?.length > 0) {
    filteredList = addedData?.filter((item) => item?.location?.name?.toLowerCase()?.includes(searchValue?.toLowerCase()))
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Button
          color={addDataFormOpen ? 'danger' : 'success'}
          onClick={() => {
            setAddDataFormOpen(!addDataFormOpen)
          }}
          className='btn m-1'>
          <IconSet icon={addDataFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
          <span style={{ marginLeft: '1rem' }}>Add {tidyName(form?.name)}</span>
        </Button>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ width: '10rem' }}>Search By : </span>
          <input
            className='is-untouched is-pristine av-valid form-control'
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}></input>
        </div>
      </div>
      {addDataFormOpen && (
        <AvForm onValidSubmit={(e, values) => onFormSubmit(values)}>
          <RenderFields allForm={[form]} content={[form]} twoCol={true} />
          <Button color='primary'>Save & Next</Button>
        </AvForm>
      )}

      {filteredList && filteredList?.length > 0 && (
        <table className='table' style={{ maxHeight: '16rem', overflow: 'auto', display: 'block', marginTop: '1rem' }}>
          <thead>
            <tr>
              <th style={{ width: '1rem' }}></th>
              <th style={{ width: '100%' }}>Name</th>
            </tr>
          </thead>
          <tbody>
            {filteredList?.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <Button className='btn-danger float-right' style={{ marginTop: '7px' }} onClick={() => onDelete(item?.id)}>
                      <IconSet icon='pe-7s-trash' color='light' />
                    </Button>
                  </td>
                  <td>{item?.location?.name}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default TemplateGroupLocation
