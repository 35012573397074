import moment from 'moment'
import { ListGroup, ListGroupItem, UncontrolledCollapse } from 'reactstrap'
import { tidyName } from '../../../../helper'
import { getTemplateSchedulesValue } from './Component/WizardForm/showAllForm'

const getParentTableName = (data, table) => {
  switch (table) {
    case 'item_groups':
      return data['group_name']
    case 'uploads':
      return data['title']
    default:
      return data['name']
  }
}
const getTitle = (val) => {
  return val?.item_name || val?.title || val?.file_name
}

const RenderChildFormPreview = ({ form, data }) => {
  const child_forms = [...form?.child_form]

  return (
    <>
      {/* <span style={{fontWeight:'bold'}}>{tidyName(form?.grid_tab_method)}</span> */}
      {/* <ol> */}
      <div style={{ fontWeight: 'bold', marginBottom: '0.5rem', marginTop: '0.5rem' }}>{tidyName(form?.name)}</div>
      <ListGroup>
        {data?.map((val, index) => {
          return (
            // <li>
            <div style={{ background: '#afb3b3' }} key={index}>
              <ListGroupItem id={form?.grid_tab_method + index}>
                <span style={{ marginRight: '1rem' }}>{index + 1}.</span>
                <span>{val?.question || val?.answer || getTemplateSchedulesValue(val, 'days')}</span>
                {form?.grid_tab_method === 'template_schedules' && <span>&nbsp;({getTemplateSchedulesValue(val, 'schedule_frequency')})</span>}
              </ListGroupItem>

              {/* <ul> */}
              <ListGroup style={{ padding: '1rem', paddingTop: '0rem', paddingBottom: '0rem' }}>
                {child_forms?.map((child_form, i) => {
                  const child_table_value = val[child_form?.grid_tab_table || child_form?.grid_tab_method]
                  if (!child_table_value || child_table_value?.length === 0) return

                  return (
                    // <li>
                    <RenderChildFormPreview key={i} form={child_form} data={val[child_form?.grid_tab_table || child_form?.grid_tab_method]} />
                    // </li>
                  )
                })}
              </ListGroup>

              {/* </ul> */}
            </div>

            // </li>
          )
        })}
      </ListGroup>

      {/* </ol> */}
    </>
  )
}
const RenderDependentFormPreview = ({ form, data }) => {
  const child_forms = [...form?.child_form]

  return (
    <>
      {/* <ListGroupItem>
        <span style={{ fontWeight: 'bold' }}>{tidyName(form?.grid_tab_table)}</span>
      </ListGroupItem> */}

      {/* <ol> */}
      <div style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>{tidyName(form?.name)}</div>
      {data?.map((val, index) => {
        return (
          <div style={{ background: '#d0d8d9' }} key={index}>
            <ListGroupItem id={'task' + index} style={{ display: 'flex', justifyContent: 'space-between' }}>
              {' '}
              <span>
                <span style={{ marginRight: '1rem' }}>{index + 1}.</span>
                <span>{getTitle(val)}</span>
              </span>
              <span>{val?.date_time && val?.date_time !== '0000-00-00 00:00:00' && moment(val?.date_time).format('D MMM YYYY h:mm a')}</span>
            </ListGroupItem>

            {/* <ul> */}

            <UncontrolledCollapse toggler={'#' + 'task' + index}>
              <ListGroup style={{ margin: '1rem', marginTop: '0rem' }}>
                {child_forms?.map((child_form, key) => {
                  return (
                    // <li>
                    <RenderChildFormPreview key={key} form={child_form} data={val[child_form?.grid_tab_table || child_form?.grid_tab_method]} />
                    // </li>
                  )
                })}
              </ListGroup>
            </UncontrolledCollapse>
            {/* </ul> */}
          </div>
        )
      })}
      {/* </ol> */}
    </>
  )
}
const RenderMainFormPreview = ({ data, forms, currentForm }) => {
  let child_forms = []
  if (currentForm?.child_form && currentForm?.child_form?.length > 0) {
    child_forms = [...currentForm?.child_form]
  }
  const dependent_forms = []
  forms?.forEach((form) => {
    if (form?.grid_tab_parent_required && form?.grid_tab_parent_table === currentForm?.grid_tab_table) {
      dependent_forms?.push(form)
    }
  })
  if (currentForm?.grid_tab_table === 'template_group_locations') {
    return
  }

  return (
    <div>
      <ListGroup>
        <div style={{ fontWeight: 'bold', marginBottom: '0.5rem' }}>{tidyName(currentForm?.name)}</div>
        <div style={{ background: '#e1e3e3' }}>
          <ListGroupItem id='mainDetails'>
            {data[currentForm?.grid_tab_table] && getParentTableName(data[currentForm?.grid_tab_table], currentForm?.grid_tab_table)}
          </ListGroupItem>
          {/* <UncontrolledCollapse toggler='#mainDetails'> */}
          <ListGroup style={{ margin: '1rem' }}>
            {dependent_forms?.length > 0 &&
              dependent_forms?.map((dependent_form, key) => {
                return (
                  <RenderDependentFormPreview
                    key={key}
                    form={dependent_form}
                    data={data[currentForm?.grid_tab_table][dependent_form?.grid_tab_table]}
                  />
                )
              })}
          </ListGroup>
          {/* </UncontrolledCollapse> */}
        </div>
      </ListGroup>
      {/* <div>
        <span style={{ fontWeight: 'bold', fontSize: '1.1rem' }}>{data[currentForm?.grid_tab_table]['group_name']}</span>{' '}
        <span style={{ fontSize: '0.7rem' }}>({tidyName(currentForm?.grid_tab_table)})</span>
      </div> */}
      <ul></ul>
    </div>
  )
}

const SubmitPreview = ({ submittedFormData, gridForm }) => {
  return (
    <div>
      {gridForm?.map((form, i) => {
        return (
          <>
            {!form?.grid_tab_parent_required ? (
              <RenderMainFormPreview data={submittedFormData} forms={gridForm} currentForm={form} key={i} />
            ) : (
              <div key={i}></div>
            )}
          </>
        )
      })}
    </div>
  )
}
export default SubmitPreview
