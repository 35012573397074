import React, { Fragment } from "react";

class AppFooter extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="app-footer">
          <div className="app-footer__inner">
            <div className="app-footer-left">
              Powered by xweb4u
            </div>
            <div className="app-footer-right">

            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AppFooter;
