import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import ReactSelect from 'react-select'
import { Button, Col } from 'reactstrap'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import { request } from '../../../../Redux/Sagas/requests/api'

const ImportTemplate = ({ importedTemplates, setImportedTemplates, setImportTemplateModalOpen }) => {
  const [templateGroups, setTemplateGroups] = useState()
  const [selectedTemplateGroup, setSelectedTemplateGroup] = useState(null)
  const [templatesData, setTemplatesData] = useState(null)

  const dispatch = useDispatch()

  useEffect(() => {
    request('get-template-groups', 'GET').then((data) => {
      if (data?.data?.data) {
        setTemplateGroups(data?.data?.data)
      }
    })
  }, [])

  const templateGroupsOptions = templateGroups?.map((group) => {
    return {
      value: group?.id,
      label: group?.name,
    }
  })

  const onSelectTemplateGroup = (e) => {
    setTemplatesData(null)
    setSelectedTemplateGroup(e)
    const payload = {
      template_group_id: e?.value,
    }
    if (e.value) {
      request('get-templates', 'POST', payload).then((data) => {
        if (data?.status === 200 || data?.status === 202) {
          setTemplatesData(data?.data)
        } else {
          dispatch(setNotification({ type: 'error', message: 'Error in getting templates' }))
        }
      })
    }
  }

  const onImportData = () => {
    if (templatesData) {
      const { success, ...rest } = templatesData
      setImportedTemplates(rest)
      setImportTemplateModalOpen(false)
    }
  }

  return (
    <div>
      <div>
        <lable style={{ marginBottom: '1rem' }}>Select Templates</lable>
        <ReactSelect
          options={templateGroupsOptions}
          // id={id}
          onChange={(e) => {
            onSelectTemplateGroup(e)
          }}
          value={selectedTemplateGroup ? selectedTemplateGroup : null}
          styles={{ menu: (base) => ({ zIndex: 15 }) }}
          placeholder=''
        />
      </div>
      {templatesData && (
        <Button color='primary' style={{ marginTop: '1rem' }} onClick={() => onImportData()}>
          Import
        </Button>
      )}
    </div>
  )
}

export default ImportTemplate
