import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'

import Nav from '../AppNav/verticalNavWrapper'

import CSSTransitionGroup from 'react-transition-group/CSSTransitionGroup'

import PerfectScrollbar from 'react-perfect-scrollbar'
import HeaderLogo from '../AppLogo'

import { setEnableMobileMenu } from '../../Redux/Reducers/themeOptions'
import EditSidebarNav from '../../CMSComponent/EditCMS/SidebarNavForms/editSidebarNav'
import { renderBlockColorClass, renderBlockStyle } from '../../helper'
import DynamicMenu from './MetisMenu/metisMenu'

class AppSidebar extends Component {
  state = {}

  toggleMobileSidebar = () => {
    let { enableMobileMenu, setEnableMobileMenu } = this.props
    setEnableMobileMenu(!enableMobileMenu)
  }

  render() {
    let { backgroundColor, enableBackgroundImage, enableSidebarShadow, backgroundImage, backgroundImageOpacity } = this.props

    return (
      <Fragment>
        <div className='sidebar-mobile-overlay' onClick={this.toggleMobileSidebar} />
        <CSSTransitionGroup
          component='div'
          className={cx('app-sidebar no-print', renderBlockColorClass(backgroundColor), {
            'sidebar-shadow': enableSidebarShadow,
          })}
          transitionName='SidebarAnimation'
          transitionAppear={true}
          transitionAppearTimeout={1500}
          transitionEnter={false}
          transitionLeave={false}
          style={renderBlockStyle(backgroundColor)}>
          <HeaderLogo />
          <PerfectScrollbar>
            <div className='app-sidebar__inner'>
              <div className='text-center'>
                <EditSidebarNav />
              </div>
              {console.log('edit menu enable:::::::', this.props.enableMobileMenu)}
              <DynamicMenu enableMobileMenu={this.props.enableMobileMenu} toggleMobileSidebar={this.toggleMobileSidebar} />
              {/* <Nav /> */}
            </div>
          </PerfectScrollbar>
          <div
            className={cx('app-sidebar-bg', backgroundImageOpacity)}
            style={{
              backgroundImage: enableBackgroundImage ? 'url(' + process.env.REACT_APP_URL + backgroundImage + ')' : null,
            }}></div>
        </CSSTransitionGroup>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  enableBackgroundImage: state.ThemeOptions.enableBackgroundImage,
  enableSidebarShadow: state.ThemeOptions.enableSidebarShadow,
  enableMobileMenu: state.ThemeOptions.enableMobileMenu,
  backgroundColor: state.ThemeOptions.backgroundColor,
  backgroundImage: state.ThemeOptions.backgroundImage,
  backgroundImageOpacity: state.ThemeOptions.backgroundImageOpacity,
})

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppSidebar)
