import { AvField } from 'availity-reactstrap-validation'
import { Col, Row } from 'reactstrap'
import CMSRichText from '../../../../Forms/Fields/richText'
import { tidyName } from '../../../../../helper'
import { useState } from 'react'

const BodyHeader = ({ bodyHeaderTitle, bodyHeaderDescription, setBodyHeaderTitle, setBodyHeaderDescription, columns, isAddingNew, reportIndex }) => {
  const [seletcedField, updateSelectedField] = useState()
  const [selectedFieldForDescription, setSelectedFieldForDescription] = useState()

  const updateDynamicValue = () => {
    if (!seletcedField) return
    let title = bodyHeaderTitle

    title = title + ' [' + seletcedField + ']'
    setBodyHeaderTitle(title)
    updateSelectedField()
  }
  const updateDynamicValueForHeaderDescription = () => {
    if (!selectedFieldForDescription) return
    let description = bodyHeaderDescription
    //const b = a.slice(0,a.lastIndexOf('</p>'))
    description = description.slice(0, description.lastIndexOf('</p>'))

    description = description + '[' + selectedFieldForDescription + ']' + '</p>'

    setBodyHeaderDescription(description)
    setSelectedFieldForDescription()
  }

  return (
    <Row>
      <Col md={6}>
        <AvField
          name='body_title'
          label='title'
          type='text'
          value={bodyHeaderTitle}
          onChange={(e) => {
            setBodyHeaderTitle(e.target.value)
          }}></AvField>
      </Col>
      <Col
        md={0}
        style={{ flexGrow: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => updateDynamicValue()}>
        <i className='fa-solid fa-circle-arrow-left fa-2x'></i>
      </Col>
      <Col md={5}>
        <AvField type='select' name='row_group_field' label='Select Placeholder' onChange={(e) => updateSelectedField(e.target.value)}>
          <option value=''>Select column</option>
          <option value='title'>Title</option>
          <option value='report_date'>Report Date</option>
          <option value='todays_date'>Today's Date</option>
          <option value='current_week'>This Week</option>
          <option value='current_month'>This Month</option>
          <option value='current_year'>This Year</option>
          <optgroup label='Others'>
            {columns?.map((col, i) => {
              return (
                <option key={i} value={col?.name || col?.field_id}>
                  {tidyName(col?.title) || tidyName(col?.name)}
                </option>
              )
            })}
          </optgroup>
        </AvField>
      </Col>
      <Col md={6}>
        <label>Body header description</label>
        <CMSRichText
          id={`body_header_description${isAddingNew ? '_new_' : reportIndex}`}
          data={{}}
          value={bodyHeaderDescription}
          onChange={(value) => {
            setBodyHeaderDescription(value)
          }}></CMSRichText>
        {/* <RichTextWithoutAvField id='body_header_title' data={{}} value={bodyHeaderData?.title} onChange={(value) => setBodyHeaderData({...bodyHeaderData,title:value})}></RichTextWithoutAvField> */}
      </Col>
      <Col
        md={0}
        style={{ flexGrow: '0', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
        onClick={() => updateDynamicValueForHeaderDescription()}>
        <i className='fa-solid fa-circle-arrow-left fa-2x'></i>
      </Col>
      <Col md={5} style={{ display: 'flex', alignItems: 'center' }}>
        <AvField type='select' name='row_group_field' label='Select Placeholder' onChange={(e) => setSelectedFieldForDescription(e.target.value)}>
          <option value=''>Select column</option>
          <option value='title'>Title</option>
          <option value='report_date'>Report Date</option>
          <option value='todays_date'>Today's Date</option>
          <option value='current_week'>This Week</option>
          <option value='current_month'>This Month</option>
          <option value='current_year'>This Year</option>
          <optgroup label='Others'>
            {columns?.map((col, i) => {
              return (
                <option key={i} value={col?.name || col?.field_id}>
                  {tidyName(col?.title) || tidyName(col?.name)}
                </option>
              )
            })}
          </optgroup>
        </AvField>
      </Col>
    </Row>
  )
}
export default BodyHeader
