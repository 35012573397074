import React from 'react'
import { Button } from 'reactstrap'
import { checkSingleAnswerValidation } from '../helper'
 const getNavStatesInitial = (indx, length) => {
    let styles = []
    for (let i = 0; i < length; i++) {
      if (i < indx) {
        styles.push('done')
      } else if (i === indx) {
        styles.push('doing')
      } else {
        styles.push('todo')
      }
    }
    return { current: indx, styles: styles }
  }

const checkNavState = (currentStep, stepsLength) => {
  if (currentStep > 0 && currentStep < stepsLength - 1) {
    return {
      showPreviousBtn: true,
      showNextBtn: true,
    }
  } else if (currentStep === 0) {
    return {
      showPreviousBtn: false,
      showNextBtn: true,
    }
  } else {
    return {
      showPreviousBtn: true,
      showNextBtn: false,
    }
  }
}

export default class MultiStep extends React.Component {
  state = {
    showPreviousBtn: false,
    showNextBtn: true,
    compState: 0,
    navState: { current: 0, styles: [] },
  }

  getNavStates = (indx, length,steps) => {
    let styles = []
    steps?.map((step,i)=>{
      if(indx === i){
        styles.push('doing')
      }else if(this.isAnswerCompleted(step.questionId)){
        styles.push('done')
      }else{
        styles.push('todo')
      }
      
    })
    // for (let i = 0; i < length; i++) {
    //   if (i < indx) {
    //     styles.push('done')
    //   } else if (i === indx) {
    //     styles.push('doing')
    //   } else {
    //     styles.push('todo')
    //   }
    // }
    return { current: indx, styles: styles }
  }
  componentDidMount(){
     this.setState({
       navState: this.getNavStates(0, this.props.steps.length,this.props.steps),
     })
  }
  setNavState = (next) => {
    this.setState({
      navState: this.getNavStates(next, this.props.steps.length,this.props.steps),
    })
    if (next < this.props.steps.length) {
      this.setState({ compState: next })
    }
    this.setState(checkNavState(next, this.props.steps.length))
  }

  handleKeyDown = (evt) => {
    if (evt.which === 13) {
      this.next()
    }
  }
  

  handleOnClick = (evt, i) => {
    evt.stopPropagation()
    evt.preventDefault()
    if (evt.currentTarget.value === this.props.steps.length - 1 && this.state.compState === this.props.steps.length - 1) {
      this.setNavState(this.props.steps.length)
    } else {
      this.setNavState(evt.currentTarget.value)
    }


    if (this.props.showAllSteps) {
      const section = document.querySelector('#' + 'question' + i + this.props.questionnaireIndex)
      section.scrollIntoView({ block: 'nearest', behavior: 'smooth' })
      evt.preventDefault()

      // section.scrollTop = 0
    }
  }

  next = () => {
    this.setNavState(this.state.compState + 1)
  }

  previous = () => {
    if (this.state.compState > 0) {
      this.setNavState(this.state.compState - 1)
    }
  }
  isAnswerCompleted = (questionId) => {
    
    const currentAnswerData = this.props?.answers?.filter((answer) => answer?.item_question_id === questionId)
    if (currentAnswerData && currentAnswerData?.length > 0) {
      return checkSingleAnswerValidation('required', currentAnswerData[0])
    } else {
      return false
    }
  }

  getClassName = (className, i) => {
    return className + '-' + this.state.navState.styles[i]
  }

  renderSteps = () => {
    return this.props.steps.map((s, i) => (
      <li className={this.getClassName('form-wizard-step', i)} onClick={(e) => this.handleOnClick(e, i)} key={i} value={i}>
        <em style={{ width: '2.5rem',height:'2.5rem' }}>{i + 1}</em>
        <span style={{ flex: '1' }}>{this.props.steps[i].name}</span>
      </li>
    ))
  }

  render() {
    const isShowAllSteps = this.props.showAllSteps
    return (
      <div onKeyDown={this.handleKeyDown}>
        <ol className='forms-wizard' style={{ overflow: 'auto',height:'55vh' }}>
          {/* <ol className='forms-wizard' style={{ maxHeight: '400px', overflow: 'auto' }}> */}
          {this.renderSteps()}
        </ol>
        <div style={{ height: '55vh', overflow: 'auto' }}>
          {/* <div style={{ height: '400px', overflow: 'auto' }}> */}
          {!isShowAllSteps ? (
            <> {this.props.steps[this.state.compState].component}</>
          ) : (
            <>
              {this.props.steps?.map((step, index) => {
                return (
                  <React.Fragment key={'question' + index + this.props.questionnaireIndex}>
                    <div id={'question' + index + this.props.questionnaireIndex} style={{ display: 'flex' }}>
                      {this.props.steps.length !== index + 1 && <span style={{ marginLeft: '0.5rem' }}>{index + 1}.</span>}

                      <div style={{ flex: 1 }}>{step.component}</div>
                      {this.isAnswerCompleted(step?.questionId) && (
                        <span
                          style={{ background: '#3AC47D', height: 'fit-content', padding: '0.1rem 0.35rem', borderRadius: '1rem', color: 'white' }}>
                          <i className='fa-duotone fa-check' color='light'></i>
                        </span>
                      )}
                    </div>
                    <hr></hr>
                  </React.Fragment>
                )
              })}
            </>
          )}
        </div>
        {!this.props.hideNavigation && (
          <>
            <div className='divider' />
            <div className='clearfix' style={{ marginTop: '5rem' }}>
              <div style={this.props.showNavigation ? {} : { display: 'none' }}>
                <Button
                  color='secondary'
                  className='btn-shadow float-left btn-wide btn-pill'
                  outline
                  style={this.state.showPreviousBtn ? {} : { display: 'none' }}
                  onClick={this.previous}>
                  Previous
                </Button>

                <Button
                  color='primary'
                  className='btn-shadow btn-wide float-right btn-pill btn-hover-shine'
                  style={this.state.showNextBtn ? {} : { display: 'none' }}
                  onClick={this.next}>
                  Next
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}

MultiStep.defaultProps = {
  showNavigation: true,
}
