import React, { useEffect, useState } from 'react'
import { Button, Nav, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import Home from '../Sections/home'
import BookMyLocker from '../Sections/bookmylocker'
import ContactUs from '../Sections/contactus'
import FAQs from '../Sections/faqs'
import Admin from '../Sections/admin'
import Footer from '../Footer/footer'
import AboutUs from '../Sections/aboutus'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { cms } from '../../helper'
import { resetPublicUser } from '../../Redux/Reducers/user'
import { setCookie } from '../../Helper/cookieHelper'
import { primary_color } from '../../Helper/uiHelper'
import HeaderSidebar from './HeaderSidebar'
import useMediaQuery from '../../CustomHook/useMediaQuery'
import { BiArrowToLeft, BiArrowToRight } from 'react-icons/bi'
import DesktopNavigation from './DesktopNavigation'
import { LoginForm } from '../Widget/PubliicAuth/PublicLogin'
import Register from '../Widget/PubliicAuth/Register'
import { FaUserCircle } from 'react-icons/fa'
import { setToggleHeaderSideBar } from '../../Redux/Reducers/sideBar'

const dummy_data = [
  {
    name: 'Home',
    icon: 'fa-solid fa-house',
    route: 'book-my-locker',
  },
  {
    name: 'Locker',
    icon: 'fa-solid fa-door-closed',
  },
  {
    name: 'About',
    icon: 'fa-solid fa-address-card',
  },
  {
    name: 'Terms',
    icon: 'fas fa-file-contract',
    route: 'terms-and-condition',
  },
  {
    name: 'Privacy policy',
    icon: 'fas fa-shield-alt',
    route: 'privacy-policies',
  },
  {
    name: 'Dashboard',
    icon: 'fa-solid fa-compass',
    action: 'redirect-to-cms',
    isLoginRequired: true,
  },
  {
    name: 'Logout',
    icon: 'fa-solid fa-sign-out',
    action: 'logout',
    isLoginRequired: true,
    isOnlyMobile: true,
  },
  // {
  //   name: 'More',
  //   icon: 'fa-sharp fa-solid fa-arrow-down-to-line',
  // },
]

const PublicHeader = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isDesktop = useMediaQuery('(min-width:768px)')
  const [collapsed, setCollapsed] = useState(false)
  const headerBackgroundColor = useSelector((state) => state.ThemeOptions.headerBackgroundColor)
  const publicUser = useSelector((state) => state.User.publicUser)
  const publicPage = useSelector((state) => state.CMS.publicPage)
  const [showLogin, setShowLogin] = useState(false)
  const [showRegister, setShowRegister] = useState(false)
  const handleItemClick = (item) => {
    history.push('/' + item?.route)
  }
  const isSideBarOpen = useSelector((state) => state?.SideBar?.isHeaderSideBarOpen)
  
  useEffect(() => {
     dispatch(setToggleHeaderSideBar(false))
  } , [])

  const toggleHamburger = () => {
    dispatch(setToggleHeaderSideBar(!isSideBarOpen))
    setCollapsed(!collapsed)
  }
  return (
     <>
        <div
          className={` header d-flex justify-content-between align-items-center ${isDesktop ? 'pl-4' : ''} ${headerBackgroundColor}`}
          style={{
            height: '60px',
            position: 'sticky',
            top: 0,
            background: 'white',
            zIndex: 1000,
            boxShadow: '0px 0px 25px 0px rgba(4,159,219,0.18)',
          }}>
          <div className='logo ml-2'>
            <img
              alt='logo'
              style={{ cursor: 'pointer' }}
              onClick={() => history.push('/')}
              src={`https://test-api.ionicbox.com/storage//logo/1lr.png`}
              height={isDesktop ? 50 : 27}
            />
          </div>
          
         {
          isDesktop ? 
          <div className='position-relative w-100'>
            <DesktopNavigation data={dummy_data} />
          </div> : null
         }
          
          <Nav className='header-items align-items-center'>
            {/* {publicPage.map((item) => {
              if (item?.route?.includes('payment')) return
              return (
                <div
                  key={item?.uuid}
                  className={`mx-2 align-self-center cursor-pointer ${
                    window.location.pathname === `/${item?.route}` ? 'border-bottom font-weight-bold' : ''
                  }`}
                  style={{ color: primary_color }}
                  onClick={() => handleItemClick(item)}>
                  {cms(item?.cms_key)}
                </div>
              )
            })} */}
            <div className='pr-2 d-flex align-items-center' style={{ width: 'max-content' }}>
              {
                !isDesktop ? 
                 <div>
                <button className={`btn h2 p-0`} style={{ color: primary_color, fontSize: '1.5rem' }} onClick={toggleHamburger}>
                      {collapsed ? <BiArrowToRight /> : <BiArrowToLeft />}
                    </button>
              </div> : null
              }
             
              {!publicUser?.email && (
                <>
                  <Button
                    outline
                    className='ml-3'
                    variant='outline-primary' // Change the variant to the desired color
                    onClick={() => {
                      setShowRegister(true)
                    }}
                    style={{ borderColor: primary_color, padding: isDesktop ? '0.5rem 2rem' : '0.3rem 1rem', color: primary_color, borderRadius: '20px' }}>
                    Register
                  </Button>
                  <Button
                    className='mx-2'
                    style={{ backgroundColor: primary_color, padding: isDesktop ? '0.5rem 2rem' : '0.3rem 1rem', borderColor: primary_color, borderRadius: '20px' }}
                    onClick={() => {
                      history.push('/welcome/login')
                    }}
                  >
                    Login
                  </Button>
                </>
              )}
              {publicUser?.email && (
                <div className='d-flex'>
                  <div className='d-flex align-items-center ml-3'>
                    <FaUserCircle style={{ color: primary_color, fontSize: '1.50rem' }} />
                    <span className='ml-2 mr-2 text-overflow' style={{maxWidth: 70}}>{publicUser?.full_name}</span>
                  </div>
                  {
                    isDesktop ? 
                     <Button
                    className='mx-2'
                    style={{ backgroundColor: primary_color, borderColor: primary_color, borderRadius: '20px' }}
                    onClick={() => {
                      dispatch(resetPublicUser())
                      setCookie('AUTH_TOKEN')
                    }}>
                    Logout
                  </Button>: null
                  }
                </div>
              )}
            </div>
          </Nav>
        </div>
        {
          !isDesktop ? 
          <div>
               <DesktopNavigation data={dummy_data} isSidebarOpen={collapsed} isSidebar={true} />
            </div> : null
        }
         
        <Modal
          isOpen={showLogin}
          toggle={() => {
            setShowLogin(false)
          }}
          style={{ width: isDesktop ? '30rem' : '95%' }}>
          <ModalHeader toggle={() => { setShowLogin(false) }}>
            <div className='h4 font-weight-bold' style={{ color: primary_color }}>
              Login now!
            </div>
          </ModalHeader>
          <ModalBody>
            <LoginForm onLogin={() => setShowLogin(false)} />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={showRegister}
          toggle={() => {
            setShowRegister(false)
          }}

          style={{ width: isDesktop ? '30rem' : '95%' }}>
          <ModalHeader toggle={() => { setShowRegister(false) }}>
            <div className='h4 font-weight-bold' style={{ color: primary_color }}>
              Register now!
            </div>
          </ModalHeader>
          <ModalBody>
            <Register onRegister={() => setShowRegister(false)} />
          </ModalBody>
        </Modal>
      </>
  )
}

export default PublicHeader
