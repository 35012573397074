import { useEffect, useState } from 'react'
import useMediaQuery from '../../../CustomHook/useMediaQuery'
import { primary_color } from '../../../Helper/uiHelper'
import { cms } from '../../../helper'
import { Button } from 'reactstrap'
import { formatTimeFromSeconds } from '../../../Helper/paymentHelper'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { request } from '../../../Redux/Sagas/requests/api'
import { setNotification } from '../../../Redux/Reducers/notificationHandling'

const dummy_subheading = 'You can resend mail after '
const ResendEmail = ({ data }) => {
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const payment_uuid = urlParams.get('payment_uuid')

  const onButtonClick = (item) => {
    let payload = {
      payment_uuid,
      component_name: 'email_button',
    }
    request('generate-pdf-and-create-message-log', 'POST', payload, {}, {}, {}, { responseType: 'arraybuffer' }).then((res) => {
      if (res?.status === 200 || res?.status === 202) {
        setNotification({
          type: 'success',
          message: res?.data?.message || 'Email sent succeessfully',
        })
        setEmailTimer(180)
        // setActivePage(activePage.child_pages[0])
      }
    })
  }

  const isDesktop = useMediaQuery('(min-width:768px)')
  const [emailTimer, setEmailTimer] = useState(180)
  useEffect(() => {
    if (emailTimer > 0)
      setTimeout(() => {
        setEmailTimer((prev) => prev - 1)
      }, 1000)
  }, [emailTimer])
  return (
    <div className='' style={{ marginTop: '3rem', padding: isDesktop ? '0 0 0 6rem' : '1rem' }}>
      <div className=' text-left font-weight-bold h4 mb-4' style={{ color: primary_color, fontSize: '24px' }}>
        {data?.heading ? cms(data?.heading) : 'Still didn’t receive email ?'}
      </div>
      <div className='' style={{ color: '#8E8E8E' }}>
        {data?.sub_heading ? cms(data?.sub_heading) : dummy_subheading}{' '}
        <span className='font-weight-bold' style={{ color: primary_color }}>
          {formatTimeFromSeconds(emailTimer)}
        </span>
      </div>
      <Button
        className='my-2 mt-4 mr-2 font-weight-bold primary_button'
        disabled={emailTimer > 0 ? true : false}
        style={{
          // backgroundColor: primary_color,
          // borderColor: primary_color,
          borderRadius: '30px',
          padding: '0.8rem 3.5rem',
          fontSize: '14px',
          // width: isDesktop ? 'auto' : '95%',
        }}
        onClick={() => onButtonClick('email')}>
        <i className='fa-solid mr-2 fa-envelope'></i> Resend email
      </Button>
    </div>
  )
}

export default ResendEmail
