import React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import IconSet from '../../icon'
import { addDataStatusSet, setConnectTemplateDataRedux, setCurrentRow, setDomain, setParentTableData } from '../../../Redux/Reducers/CMS'
import { cms, editCMS, getConnectTemplateReduxDataAfterDeleteClientRow, removeInsertDataQueueByReactId, tidyName } from '../../../helper'
import { updateProgressDataByPagename } from '../../../progressHelper'

const deleteActions = ['connect_delete_item_template', 'connect_delete_trash_item_template', 'connect_delete_restore_item_template', 'open_tab']

const select = (state) => ({
  editMode: state.CMS.editMode,
})

const EditButton = ({ item, triggerButtonActions = () => {}, row, content, pageName, parentPageName, isModel }) => {
  const dispatch = useDispatch()

  const currentRow = useSelector((state) => state.CMS.currentRow)
  const connectTemplateDataRedux = useSelector((state) => state.CMS.connectTemplateDataRedux)
  const editMode = useSelector((state) => state.CMS.editMode)
  const parentTableDataInRedux = useSelector((state) => state.CMS.parentTableData)

  let classes = 'btn-icon btn-icon-only'
  if (item.field_id === 'questionnaire' && row.original?.completed === 1) {
    classes = classes + ' bg-success'
  }
  if (item.component_name === 'action_button') {
    classes = classes + ' btn-pill'
  }

  let isDisabledButton = false
  if (deleteActions.includes(item.component_call_actions[0].component_name)) {
    if (!row.original.id) {
      if (!row.original.temporary_id) {
        isDisabledButton = true
        return
      }
    }
  }
  return (
    <Button
      size='sm'
      className={classes}
      color={item.background_color || 'primary'}
      disabled={isDisabledButton}
      onClick={() => {
        if (item.cms_key && editMode) {
          editCMS(item.cms_key)
        } else {
          let apiData = ''
          switch (item.component_call_actions[0].component_name) {
            case 'open_tab':
              updateProgressDataByPagename(row?.original, parentPageName)
              if (row?.original?.domain_id) {
                dispatch(setDomain(parseInt(row?.original?.domain_id)))
              }
              if (!content?.grid_settings?.show_add_form_on_edit || true) {
                dispatch(setCurrentRow(row?.original))
              }

              if (item.component_call_actions[0]?.payload?.data === 'selected_id' || item.component_call_actions[0]?.payload === 'selected_id') {
                apiData = { id: [row.row.original.id] }
              }
              if (item.component_call_actions[0]?.payload === 'id') {
                apiData = [row.row]
              } else {
                apiData = row.original
              }
              dispatch(
                setParentTableData({
                  source_table: content?.source_table,
                  parent_page_name: content?.name,
                  grid_id: content?.main_grid?.id,
                  child_table: content?.child_tabs?.component_call_buttons[0]?.source_table,
                })
              )

              triggerButtonActions(item.component_call_actions, [], apiData)

              break
            case 'submit_api':
              if (item.component_call_actions[0]?.payload?.data === 'selected_id' || item.component_call_actions[0]?.payload === 'selected_id') {
                apiData = { id: [row.original.id] }
              } else if (item.component_call_actions[0]?.payload === 'id') {
                apiData = [row]
              }
              triggerButtonActions(item.component_call_actions, [], apiData)
              break
            case 'connect_delete_item_template':
              if (!row.original.id && row.original.temporary_id) {
                const parentTable = parentTableDataInRedux?.[0]?.source_table
                removeInsertDataQueueByReactId(row.original.temporary_id, content.source_table, parentTable, currentRow)
                const dataToUpdate = getConnectTemplateReduxDataAfterDeleteClientRow(
                  connectTemplateDataRedux,
                  parentPageName,
                  pageName,
                  content?.source_table,
                  currentRow,
                  currentRow?.length > 0 && !isModel,
                  [row?.original?.temporary_id]
                )

                dispatch(setConnectTemplateDataRedux(dataToUpdate))
                dispatch(addDataStatusSet())
              } else {
                if (item.component_call_actions[0]?.payload?.data === 'selected_id' || item.component_call_actions[0]?.payload === 'selected_id') {
                  apiData = { ids: [row.original.id] }
                } else if (item.component_call_actions[0]?.payload === 'id') {
                  apiData = [row]
                }

                triggerButtonActions(item.component_call_actions, row.original, apiData, item.cms_key)
              }

              break
            case 'connect_delete_trash_item_template':
            case 'connect_delete_restore_item_template':
            case 'ask_questionnaire':
              if (item.component_call_actions[0]?.payload?.data === 'selected_id' || item.component_call_actions[0]?.payload === 'selected_id') {
                apiData = { ids: [row.original.id] }
              } else if (item.component_call_actions[0]?.payload === 'id') {
                apiData = [row]
              }
              triggerButtonActions(item.component_call_actions, row.original, apiData, item.cms_key)
              break
            default:
              console.log(item.component_call_actions[0], 'item')
              break
          }
        }
      }}
      style={{ textAlign: 'center', marginTop: '0.1rem' }}>
      {!item.icon && item.cms_key ? (
        item.cms_key === 'field.complete_group' ? (
          row?.original?.items && row?.original?.items?.length > 0 ? (
            tidyName(cms(item.cms_key))
          ) : (
            'Add Task'
          )
        ) : item?.cms_key === 'field.complete_task' ? (
          row?.original?.item_questions?.length === 0 ? (
            'Complete'
          ) : (
            tidyName(cms(item.cms_key))
          )
        ) : (
          tidyName(cms(item.cms_key))
        )
      ) : (
        <IconSet icon={item.icon || 'fas fa-file'} type={item.icon_type} fontWeight={400} />
      )}
    </Button>
  )
}

export default connect(select)(EditButton)
