import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import CommonFields from './commonFields'

const RenderComponent = ({ type, selectedValue, setSelectedValue, onHandleChange }) => {
  switch (type) {
    case 'date':
      return (
        <DatePicker
          popperPlacement='bottom-start'
          className='question-date-picker'
          onChange={(value) => onHandleChange(value)}
          selected={selectedValue}
          dateFormat='d MMMM , yyyy'
        />
      )
    case 'date-time':
      return (
        <DatePicker
          popperPlacement='bottom-start'
          className='question-date-picker'
          onChange={(value) => onHandleChange(value)}
          selected={selectedValue}
          dateFormat='d MMMM , yyyy HH:mm a'
          amPmAriaLabel={false}
          showTimeInput
        />
      )
    case 'time':
      return <input type='time' onChange={(e) => onHandleChange(e.target.value)} style={{ width: '30%' }}></input>

    default:
      return (
        <DatePicker
          popperPlacement='bottom-start'
          className='question-date-picker'
          onChange={(value) => setSelectedValue(value)}
          selected={selectedValue}
          dateFormat='d MMMM , yyyy HH:mm a'
          amPmAriaLabel={false}
          showTimeInput
        />
      )
  }
}

const DateTime = ({ type, item, answers, setAnswers, requiredError }) => {
  const [selectedValue, setSelectedValue] = useState()
  const current_answer_object = answers?.filter((data) => data?.item_question_id === item?.id)[0] || {}

  const onHandleChange = (v) => {
    setSelectedValue(v)

    const old_answers = [...answers]
    const updated_answers = old_answers?.map((question) => {
      if (question?.item_question_id === item?.id) {
        return {
          ...question,
          question_type: type,
          response_text: v,
        }
      } else {
        return question
      }
    })
    setAnswers(updated_answers)
  }

  useEffect(() => {
    if (current_answer_object?.response_text?.length > 0) {
      setSelectedValue(new Date(current_answer_object?.response_text))
    }
  }, [JSON.stringify(current_answer_object), item?.id])

  return (
    <div>
      <div>{item?.question}</div>
      <div style={{ marginTop: '1rem' }}>
        <RenderComponent type={type} selectedValue={selectedValue} setSelectedValue={setSelectedValue} onHandleChange={onHandleChange} />
      </div>
      <CommonFields
        item={item}
        selectedAnswer={selectedValue}
        answers={answers}
        setAnswers={setAnswers}
        current_answer_object={current_answer_object}
      />
      {item?.validations?.includes('required') &&
        requiredError &&
        (!current_answer_object?.response_text || current_answer_object?.response_text?.length === 0) && (
          <div style={{ color: 'red' }}>Please Complete</div>
        )}
    </div>
  )
}

export default DateTime
