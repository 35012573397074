import ThemeOptions from './themeOptions'
import StaticThemeOptions from './staticThemeOptions'
import User from './user'
import NotificationHandling from './notificationHandling'
import Loader from './loader'
import Permissions from './permissions'
import publicPageData from './publicPageData'
import Slides from './slides'
import CMS from './CMS'
import Page from './page'
import Queue from './queue'
import SideBar from './sideBar'
import TimerStatus from './timer'

export const REDUX_LOAD = 'REDUX_LOAD'
export const REDUX_UNLOAD = 'REDUX_UNLOAD'

export const reduxLoad = () => ({
  type: REDUX_LOAD,
})

export const reduxUnload = () => ({
  type: REDUX_UNLOAD,
})

const rootReducer = {
  Queue,
  Page,
  ThemeOptions,
  StaticThemeOptions,
  CMS,
  User,
  Permissions,
  publicPageData,
  NotificationHandling,
  Loader,
  Slides,
  SideBar,
  TimerStatus
}

export default rootReducer
