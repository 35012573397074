import cx from 'classnames'
import { useEffect, useState } from 'react'
import ReactTable from 'react-table'
import AvForm from 'availity-reactstrap-validation/lib/AvForm'
import AvField from 'availity-reactstrap-validation/lib/AvField'
import { Row, Col, Card, CardBody, Button, Label, Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import Switch from 'react-switch'
import { ListGroup } from 'reactstrap'
import { ListGroupItem } from 'reactstrap'

import IconSet from '../../../icon'
import GridSettingTable from './gridColors'
import { checkCMSPermission, editCMS, getPageNameForCoreDataFromUrl, tidyName } from '../../../../helper'
import { removePageDataRedux, submitRequest, updatePageDataRedux } from '../../../../Redux/Reducers/CMS'
import { cms } from '../../../../helper'
import EditColumn from '../../../EditCMS/Grid/editColumn'
import EditTab from '../../../EditCMS/Grid/editTab'
import GridButtonSetting from './gridButtonSetting'
import RowGroupSetting from './rowGroupSetting'
import WhereClause from '../../../EditCMS/Grid/whereClause'
import ConditionCommonField from '../../../EditCMS/Grid/conditionCommonField'
import GridFilterTable from './gridInOutFilterTable'
import { RenderCommonFields } from '../../../EditCMS/ContentForms/editTabPage'
import { request } from '../../../../Redux/Sagas/requests/api'
import { setLoader } from '../../../../Redux/Reducers/loader'
import { reduxLoad } from '../../../../Redux/Reducers'
import { setNotification } from '../../../../Redux/Reducers/notificationHandling'
import AggregateCheckBox from '../../../EditCMS/Grid/aggregateCheckBox'
import ExpandRowWithTable from './expandRowWithTable'
import FilterBy from './filterBy'
import CopyTab from './CopyTab/copyTab'
import ScheduleEmailReports, { ScheduleEmailReportsAccordian } from './schedule-email-report/scheduleEmailReport'
import { generatePayloadForSeeder } from '../../../../Services/Helper/createSeederHelper'

const RenderColumnRow = ({
  item,
  source_table,
  start,
  dragover,
  index,
  preferanceForm,
  updatePreferanceForm,
  updateGridColumnSettingTitle,
  mainGrid,
  isButtonSettingOpen,
  setIsButtonSettingOpen,
  pageName,
  isRepeatingForm,
  parentPageName,
  isAggregateColumn,
}) => {
  const dispatch = useDispatch()
  const [isEditColumnOpen, setIsEditColumnOpen] = useState(false)

  const [columnEditForm, setColumnEditForm] = useState({
    sort: '',
    width: '',
    inline_edit: '',
    title: '',
  })
  const [aggregate_column, set_aggregate_column] = useState([])

  useEffect(() => {
    const updated_form = {
      title: cms(item?.cms_key),
      sort: item.sort !== 'none' ? item?.sort : '',
      width: item?.width,
      inline_edit: item?.inline_edit ? item?.inline_edit : 0,
    }
    setColumnEditForm(updated_form)

    if (isAggregateColumn && item?.options?.columns) {
      set_aggregate_column(item?.options?.columns)
    }
  }, [])

  const updateColumn = () => {
    const { sort, ...rest } = columnEditForm
    const pageNameFormCoreData = getPageNameForCoreDataFromUrl()
    dispatch(setLoader(true))
    if (isAggregateColumn) {
      request('submit-grid-column', 'POST', {
        columnForm: [
          {
            ...rest,
            type: 'aggregate_column',
            column: 'aggregate_column',
            options: {
              columns: aggregate_column,
            },
          },
        ],
        grid_id: mainGrid?.id,
        source_table,
        page_name: pageName,
        parent_page_name: window.location.hash.split('/')[1]?.split('-')[0],
        tab: true,
      }).then((res) => {
        request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[parentPageName] = data

          dispatch(updatePageDataRedux(pageData))
          dispatch(setLoader(false))
        })
      })
    } else {
      request('submit-grid-column', 'POST', {
        columnForm: [{ ...rest, type: 'colDataAdvancedWithSearch', column: item?.field_id, sorting: columnEditForm?.sort }],
        grid_id: mainGrid?.id,
        source_table,
        page_name: pageName,
        parent_page_name: window.location.hash.split('/')[1]?.split('-')[0],
        tab: true,
      }).then((res) => {
        request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[parentPageName] = data

          dispatch(updatePageDataRedux(pageData))
          dispatch(setLoader(false))
        })
      })
    }

    // dispatch(
    //   submitRequest({
    //     url: 'submit-grid-column',
    //     data: {
    //       columnForm: [{ ...rest, type: 'colDataAdvancedWithSearch', column: item?.field_id, sorting: columnEditForm?.sort }],
    //       grid_id: mainGrid?.id,
    //       source_table,
    //       page_name: pageName,
    //       parent_page_name: window.location.hash.split('/')[1]?.split('-')[0],
    //       tab: true,
    //     },
    //   })
    // )
  }

  return (
    <>
      <tr
        key={index}
        draggable={isRepeatingForm ? 'false' : 'true'}
        id={item?.field_id}
        onDragStart={(e) => start(e)}
        onDragOver={(e) => dragover(e)}>
        <td> {!isRepeatingForm && <i className='fa-sharp fa-solid fa-grid-horizontal' style={{ cursor: 'pointer' }}></i>}</td>
        <td> {isAggregateColumn ? 'Aggregate Column' : tidyName(cms(item.cms_key))}</td>
        <td>
          <Switch
            checked={preferanceForm[item.field_id]?.print ? true : false}
            onChange={(value) =>
              updatePreferanceForm({
                ...preferanceForm,
                [item.field_id]: {
                  ...preferanceForm[item.field_id],
                  print: value,
                },
              })
            }
            uncheckedIcon={<i className='fa-thin fa-xmark switch-icon'></i>}
            checkedIcon={<i className='fa-thin fa-check switch-icon'></i>}
            className='d-inline'
          />
        </td>
        <td>
          <Switch
            checked={preferanceForm[item.field_id]?.export ? true : false}
            onChange={(value) =>
              updatePreferanceForm({
                ...preferanceForm,
                [item.field_id]: {
                  ...preferanceForm[item.field_id],
                  export: value,
                },
              })
            }
            uncheckedIcon={<i className='fa-thin fa-xmark switch-icon'></i>}
            checkedIcon={<i className='fa-thin fa-check switch-icon'></i>}
            className='d-inline'
          />
        </td>
        <td>
          <Switch
            checked={preferanceForm[item.field_id]?.title ? true : false}
            onChange={(value) => updateGridColumnSettingTitle(value, item)}
            uncheckedIcon={<i className='fa-thin fa-xmark switch-icon'></i>}
            checkedIcon={<i className='fa-thin fa-check switch-icon'></i>}
            className='d-inline'
          />
        </td>
        <td>
          <Button
            className='btn-danger'
            onClick={() => {
              const pageNameFormCoreData = getPageNameForCoreDataFromUrl()
              const data = {
                grid_id: mainGrid?.id,
                field_id: item.field_id,
              }
              dispatch(setLoader(true))
              request('remove-grid-column', 'POST', data).then((res) => {
                request(`get-page/${pageNameFormCoreData}`, 'GET').then(({ data }) => {
                  let pageData = {}
                  pageData[pageNameFormCoreData] = data
                  dispatch(updatePageDataRedux(pageData))
                  dispatch(setLoader(false))
                })
              })
              // dispatch(
              //   submitRequest({
              //     url: 'remove-grid-column',
              //     data: {
              //       grid_id: mainGrid?.id,
              //       field_id: item.field_id,
              //     },
              //   })
              // )
            }}>
            <IconSet icon='pe-7s-trash' color='light' />
          </Button>
        </td>
        {!isRepeatingForm && (
          <td>
            <IconSet
              icon={isEditColumnOpen ? 'far fa-arrow-down' : 'far fa-arrow-right'}
              color='primary'
              style={{ cursor: 'pointer', marginRight: '0.5rem' }}
              onClick={() => setIsEditColumnOpen(!isEditColumnOpen)}
            />
          </td>
        )}
      </tr>
      {isEditColumnOpen && !isAggregateColumn && (
        <tr>
          <td colSpan={7}>
            <AvForm>
              <Row>
                <Col md={6}>
                  <AvField
                    type='select'
                    label='Sorting'
                    name={'sorting'}
                    value={columnEditForm?.sort}
                    onChange={(e) => setColumnEditForm({ ...columnEditForm, sort: e.target.value })}>
                    <option value={''}>Select sorting</option>
                    <option value={'ASC'}>ASC</option>
                    <option value={'DESC'}>DESC</option>
                  </AvField>
                </Col>
                <Col md={6}>
                  <AvField
                    type='number'
                    label='Width'
                    name={'width'}
                    max='100'
                    min='10'
                    value={columnEditForm.width}
                    onChange={(e) => setColumnEditForm({ ...columnEditForm, width: e.target.value })}
                  />
                </Col>
                <Col md={6}>
                  <AvField
                    type='title'
                    label='Title'
                    name='Title'
                    value={columnEditForm.title}
                    onChange={(e) => setColumnEditForm({ ...columnEditForm, title: e.target.value })}
                  />
                </Col>

                <Col md={6}>
                  <AvField
                    type='select'
                    label='Inline Edit'
                    name={'inline_edit'}
                    value={columnEditForm.inline_edit}
                    onChange={(e) => setColumnEditForm({ ...columnEditForm, inline_edit: e.target.value })}
                    required
                    // disabled={isInlineEditDisabled}
                  >
                    {/* <option value="">Select Inline Type</option> */}
                    <option value={0}>Read Only</option>
                    <option value={1}>Edit</option>
                  </AvField>
                </Col>
              </Row>
            </AvForm>
          </td>
        </tr>
      )}
      {isEditColumnOpen && isAggregateColumn && (
        <tr>
          <td colSpan={8}>
            <AggregateCheckBox aggregate_column={aggregate_column} set_aggregate_column={set_aggregate_column} />
          </td>
        </tr>
      )}
      {isEditColumnOpen && (
        <tr>
          <td colSpan={7}>
            <Button color='primary' onClick={() => updateColumn()}>
              Save
            </Button>
          </td>
        </tr>
      )}
    </>
  )
}

const getColorName = (type) => {
  switch (type) {
    case 'primary':
      return 'Blue'
    case 'secondary':
      return 'Gray'
    case 'danger':
      return 'Red'
    case 'success':
      return 'Green'
    case 'warning':
      return 'Yellow'
    case 'info':
      return 'Light Blue'
    case 'db':
      return 'Dynamic value'
    default:
      return cms(type)
  }
}

export const AddColorGroup = ({ source_table, content, isFilter = false, isUpdate = false, row }) => {
  const gridColors = useSelector((state) => state.CMS.coreData.grid_colors)

  const initialColorForm = {
    name: '',
    color: gridColors[0] || '',
    order: 0,
    note: '',
    filter_type: '',
    type: '',
    condition: 'equals',
    column: '',
    value: '',
  }
  const [form, updateForm] = useState(initialColorForm)
  const dispatch = useDispatch()
  const grid_selector = useSelector((state) => state.CMS.coreData.grid_selector)

  const select_column_option = grid_selector[source_table]?.columns

  const grid_id = content?.main_grid?.id

  const handleSubmit = () => {
    const { filter_type, type, condition, value, column, ...color_payload } = form

    if (isFilter) {
      const pageNameFormCoreData = getPageNameForCoreDataFromUrl()
      const data = {
        id: form?.id,
        name: form?.name,
        type: form?.type,
        condition: form?.condition,
        db_field_name: form?.column,
        add_or_sub: form?.add_or_sub,
        add_or_sub_type: form?.add_or_sub_type,
        add_or_sub_value: form?.add_or_sub_value,
        is_filter_out: form?.filter_type === 'filter_out' ? true : false,
        dynamic_value: form?.type === 'dynamic' && form?.value,
        numeric_value: (form?.type === 'fixed_numeric_value' || form?.type === 'between_from_current_date_time') && form?.value,
        between_period: (form?.type === 'between_from_current_date_time' || form?.type === 'between_from_current_date_time') && form?.between_period,
        between_type: form?.type === 'between_from_current_date_time' && form?.between_type,
        multiple_values: form?.type === 'select_multiple_values' && form?.value,

        grid_id,
      }
      dispatch(setLoader(true))
      request('save-grid-data-filter', 'POST', data).then((res) => {
        request(`get-page/${pageNameFormCoreData}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[pageNameFormCoreData] = data

          dispatch(updatePageDataRedux(pageData))
          dispatch(setLoader(false))
        })
      })
      // dispatch(
      //   submitRequest({
      //     url: 'save-grid-data-filter',
      //     data: {
      //       id: form?.id,
      //       name: form?.name,
      //       type: form?.type,
      //       condition: form?.condition,
      //       db_field_name: form?.column,
      //       add_or_sub: form?.add_or_sub,
      //       add_or_sub_type: form?.add_or_sub_type,
      //       add_or_sub_value: form?.add_or_sub_value,
      //       is_filter_out: form?.filter_type === 'filter_out' ? true : false,
      //       dynamic_value: form?.type === 'dynamic' && form?.value,
      //       numeric_value: (form?.type === 'fixed_numeric_value' || form?.type === 'between_from_current_date_time') && form?.value,
      //       between_period:
      //         (form?.type === 'between_from_current_date_time' || form?.type === 'between_from_current_date_time') && form?.between_period,
      //       between_type: form?.type === 'between_from_current_date_time' && form?.between_type,

      //       grid_id,
      //     },
      //   })
      // )
    } else {
      dispatch(setLoader(true))
      const pageNameFormCoreData = getPageNameForCoreDataFromUrl()
      request('save-grid-color-group', 'POST', { ...color_payload, grid_id }).then((res) => {
        request(`get-page/${pageNameFormCoreData}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[pageNameFormCoreData] = data

          dispatch(updatePageDataRedux(pageData))
          dispatch(setLoader(false))
        })
      })
      // dispatch(
      //   submitRequest({
      //     url: 'save-grid-color-group',
      //     data: { ...color_payload, grid_id },
      //   })
      // )
    }
  }

  useEffect(() => {
    if (isUpdate) {
      updateForm({
        id: row?.id,
        name: row?.name,
        color: row?.color,
        order: row?.order,
        note: row?.note,
        filter_type: row?.is_filter_out ? 'filter_out' : 'filter_in',
        type: row?.type,
        condition: row?.condition,
        column: row?.db_field_name,
        value: row?.numeric_value || row?.dynamic_value || row?.date_value,
        between_period: row?.between_period,
        between_type: row?.between_type,
      })
    }
  }, [])

  return (
    <AvForm>
      <Row style={{ margin: '0.5rem' }}>
        <Col md={6}>
          <AvField type='text' name='name' label='Name' value={form?.name} onChange={(e) => updateForm({ ...form, name: e.target.value })} />
        </Col>
        {!isFilter && (
          <Col md={6}>
            <AvField type='select' name='color' label='Color' onChange={(e) => updateForm({ ...form, color: e.target.value })}>
              {gridColors?.map((item, i) => (
                <option value={item} key={i}>
                  <div>{getColorName(item)}</div>
                </option>
              ))}
            </AvField>
          </Col>
        )}

        {isFilter && (
          <Col md={6}>
            <AvField
              type='select'
              name='filter_type'
              label='Filter Type'
              value={form?.filter_type}
              onChange={(e) => updateForm({ ...form, filter_type: e.target.value })}>
              <option value=''>Select Filter Type</option>
              <option value='filter_in'>Include</option>
              <option value='filter_out'>Exclude</option>
            </AvField>
          </Col>
        )}

        {isFilter && (
          <ConditionCommonField
            source_table={source_table}
            columnValue={select_column_option?.filter((column) => column?.name === form?.column)[0]?.name}
            onColumnChange={(value) => updateForm({ ...form, column: value })}
            type={form?.type}
            onChangeAddOrSub={(value) => updateForm({ ...form, add_or_sub: value })}
            onChangeAddOrSubType={(value) => updateForm({ ...form, add_or_sub_type: value })}
            onChangeAddOrSubValue={(value) => updateForm({ ...form, add_or_sub_value: value })}
            onTypeChange={(value) => updateForm({ ...form, type: value })}
            condition={form?.condition}
            onConditionChange={(value) => updateForm({ ...form, condition: value })}
            onValueChange={(value) => updateForm({ ...form, value: value })}
            value={form?.value}
            onChangeBetweenType={(value) => updateForm({ ...form, between_type: value })}
            between_type={form?.between_type}
            onChangeBetweenPeriod={(value) => updateForm({ ...form, between_period: value })}
            between_period={form?.between_period}
            between_value={form?.between_value}
            onChangeBetweenValue={(value) => updateForm({ ...form, between_value: value })}
          />
        )}
        {!isFilter && (
          <Col md={6}>
            <AvField type='number' name='order' label='Order' required value='0' onChange={(e) => updateForm({ ...form, order: e.target.value })} />
          </Col>
        )}

        <Col md={6}>
          <AvField type='text' name='note' label='Note' onChange={(e) => updateForm({ ...form, note: e.target.value })} />
        </Col>
        <Col md={12}>
          <Button onClick={() => handleSubmit()}>Save</Button>
        </Col>
      </Row>
    </AvForm>
  )
}

const GridSetting = ({ content, source_table, pageName, activeTabData, parentPageName, gridData }) => {
  const currentRowData = useSelector((state) => state.CMS.currentRow)
  const pages = useSelector((state) => state.CMS.coreData.page)
  const currentPageData = pages[parentPageName]

  const [buttonData, updateButtonData] = useState({
    live_refresh_button: false,
    inline_edit_button: false,
  })
  const [widgetForm, setWidgetForm] = useState(false)
  const [showAddFormOnEdit, setShowAddFormOnEdit] = useState(false)
  const [selectAllRow, setSelectAllRow] = useState(false)
  const [expandAll, setExpandAll] = useState(false)
  const [group_by, set_group_by] = useState(false)
  const [preferanceForm, updatePreferanceForm] = useState({})
  const grid_id = content?.main_grid?.id

  const [isColorFormOpen, setIsColorFormOpen] = useState(false)
  const [addColumnFormOpen, setAddColumnFormOpen] = useState(false)
  const [addColumnForRepeatingFormOpen, setAddColumnForRepeatingForm] = useState(false)

  const [columnForm, updateColumnForm] = useState()

  const [tabForm, updateTabForm] = useState()

  const [gridButtonList, setGridButtonList] = useState() //button above grid
  const [buttonForm, setButtonForm] = useState({})
  const [tableButtonList, setTableButtonList] = useState([]) //button inside grid
  const [tableButtonForm, setTableButtonForm] = useState({})
  const [isButtonSettingOpen, setIsButtonSettingOpen] = useState(false)
  const [isGridRowSettingOpen, setIsGridRowSettingOpen] = useState(false)
  const [isColumnSettingOpen, setIsColumnSettingOpen] = useState(true)
  const [whereClauseOpen, setWhereClauseOpen] = useState(false)
  const [whereClauseForm, updateWhereClauseForm] = useState([])
  const [groupBy, setGroupBy] = useState({
    Column: '',
    text: '',
  })

  const [gridSettingAccordianOpen, setGridSettingAccoridanOpen] = useState({
    tableSetting: false,
    tabSetting: false,
    gridColors: false,
    filterSetting: false,
    gridFilterSetting: false,
  })

  const [grid_expand, set_grid_expand] = useState()
  const [filter_by, set_filter_by] = useState()

  const editMode = useSelector((state) => state.CMS?.editMode)

  const dispatch = useDispatch()
  const mainGrid = content?.main_grid
  let childTabs

  if (currentRowData && currentRowData?.length > 0) {
    childTabs = content?.child_tabs?.component_call_buttons
  } else {
    childTabs = pages[parentPageName]?.component_call_buttons?.filter((tab) => tab?.component_name === 'show_tabs_on_load')
  }

  useEffect(() => {
    const mainGrid = content?.main_grid
    const mainGridButton = content?.main_grid?.component_call_grid_buttons
    const grid_settings = content?.grid_settings
    const tableButton = mainGrid?.component_call_cols?.filter((item) => item.component_name === 'icon_col_button')
    const grid_button_setting = mainGrid?.grid_button_setting
    let default_button_form
    if (grid_button_setting) {
      default_button_form = JSON.parse(grid_button_setting)
    }

    setGridButtonList(mainGridButton)
    setTableButtonList(tableButton)

    let initialButtonForm = {}
    if (mainGridButton) {
      mainGridButton?.map((button) => {
        initialButtonForm[button.name] = false
      })
    }

    let initialTableButtonForm = {}
    tableButton?.map((item) => {
      initialTableButtonForm[item.name] = false
    })

    if (default_button_form) {
      setButtonForm({ ...initialButtonForm, ...default_button_form['mainGrid'] })
      setTableButtonForm({ ...initialTableButtonForm, ...default_button_form['table'] })
    } else {
      setTableButtonForm(initialTableButtonForm)
      setButtonForm(initialButtonForm)
    }
    const saved_where_clause = mainGrid?.where_clause
    if (saved_where_clause) {
      updateWhereClauseForm(saved_where_clause)
    }
    setSelectAllRow(grid_settings?.select_all_records)
    setExpandAll(grid_settings?.expand_all)
  }, [])

  useEffect(() => {
    const initialButtonData = {
      live_refresh_button: mainGrid?.live_refresh === 1 ? true : false,
      inline_edit_button: mainGrid?.inline_edit_button === 1 ? true : false,
    }

    updateButtonData(initialButtonData)
    if (content?.grid_settings) {
      setShowAddFormOnEdit(content?.grid_settings?.show_add_form_on_edit)
    }
    set_group_by(content?.main_grid?.group_by)
  }, [])

  const toggleLiveRefresh = () => {
    updateButtonData({
      inline_edit_button: !buttonData.live_refresh_button ? false : buttonData.inline_edit_button,
      live_refresh_button: !buttonData.live_refresh_button,
    })
  }

  const toggleInlineEdit = () => {
    if (!buttonData.live_refresh_button) {
      updateButtonData({
        ...buttonData,
        inline_edit_button: !buttonData.inline_edit_button,
      })
    }
  }

  const handleSubmit = () => {
    const table_body = document.getElementById('grid_setting_row_body')
    const expand_table_body = document.getElementById('grid_setting_row_body_expand')
    let a, b
    if (table_body) {
      a = Array?.from(table_body?.children)
    }
    if (expand_table_body) {
      b = Array?.from(expand_table_body?.children)
    }

    let ids = a?.map((item) => item.id)
    let expand_id = b?.map((item) => item?.id)

    if (expand_table_body && expand_id) {
      ids = [...ids, ...expand_id]
    }

    const isIdAvailable = preferanceForm['id'] ? true : false
    if (isIdAvailable) {
      preferanceForm['id'] = {
        ...preferanceForm['id'],
        order: 0,
      }
    }

    ids?.map((id, index) => {
      if (isIdAvailable) {
        preferanceForm[id] = {
          ...preferanceForm[id],
          order: index + 1,
        }
      } else {
        preferanceForm[id] = {
          ...preferanceForm[id],
          order: index,
        }
      }
    })
    let payload = {
      live_refresh: buttonData?.live_refresh_button,
      inline_edit_button: buttonData?.inline_edit_button,
      grid_column_setting: JSON.stringify(preferanceForm),
      grid_button_setting: JSON.stringify({
        mainGrid: buttonForm,
        table: tableButtonForm,
      }),
      group_by_setting: groupBy,
      grid_id,
      select_all_records: selectAllRow,
      expand_all: expandAll,
      grid_expand: JSON.stringify(grid_expand) === '{}' ? null : { ...grid_expand, method: grid_expand?.name },
      filter_by,
    }

    if (whereClauseForm?.length > 0) {
      payload = {
        ...payload,
        where_clause: whereClauseForm,
      }
    }
    payload = {
      ...payload,
      where_clause: whereClauseForm,
      grid_add_form_style: widgetForm ? 'wizard' : 'simple',
      show_add_form_on_edit: showAddFormOnEdit,
      group_by,
    }
    const pageNameFormCoreData = getPageNameForCoreDataFromUrl()
    dispatch(setLoader(true))
    request('save-grid-setting', 'POST', payload).then((res) => {
      request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
        let pageData = {}
        pageData[pageNameFormCoreData] = data

        dispatch(updatePageDataRedux(pageData))
        dispatch(setLoader(false))
      })
    })
    // dispatch(
    //   submitRequest({
    //     url: 'save-grid-setting',
    //     data: {
    //       ...payload,
    //     },
    //   })
    // )
  }

  const updateGridColumnSettingTitle = (value, item) => {
    if (value === true) {
      for (let i in preferanceForm) {
        if (preferanceForm[i]?.title) {
          preferanceForm[i].title = false
        }
      }
      updatePreferanceForm({
        ...preferanceForm,
        [item.field_id]: {
          ...preferanceForm[item.field_id],
          title: value,
        },
      })
    } else {
      updatePreferanceForm({
        ...preferanceForm,
        [item.field_id]: {
          ...preferanceForm[item.field_id],
          title: value,
        },
      })
    }
  }
  const updateGroupBy = (value, field) => {
    const updated_groupBy = { ...groupBy }
    updated_groupBy[field] = value
    setGroupBy(updated_groupBy)
  }

  useEffect(() => {
    let default_grid_setting
    if (mainGrid?.grid_column_setting) {
      default_grid_setting = JSON.parse(mainGrid?.grid_column_setting)
    }
    let baseGridSetting = preferanceForm
    mainGrid?.component_call_cols.map((item) => {
      if (item.component_name === 'text_data_col')
        if (mainGrid?.grid_column_setting?.length > 2) {
          baseGridSetting[item.field_id] = {
            ...preferanceForm[item.field_id],
            name: cms(item.cms_key),
            title: default_grid_setting[item.field_id]?.title || preferanceForm[item.field_id]?.title,
            print: default_grid_setting[item.field_id]?.print || preferanceForm[item.field_id]?.print,
            export: default_grid_setting[item.field_id]?.export || preferanceForm[item.field_id]?.export,
          }
        } else {
          baseGridSetting[item.field_id] = {
            ...preferanceForm[item.field_id],
            name: cms(item.cms_key),
            title: preferanceForm[item.field_id]?.title,
            print: preferanceForm[item.field_id]?.print,
            export: preferanceForm[item.field_id]?.export,
          }
        }
    })
    if (mainGrid?.component_call_expand_fields && mainGrid?.component_call_expand_fields?.length > 0) {
      mainGrid?.component_call_expand_fields.map((item) => {
        if (mainGrid?.grid_column_setting?.length > 2) {
          baseGridSetting[item.field_id] = {
            ...preferanceForm[item.field_id],
            name: cms(item.cms_key),
            title: default_grid_setting[item.field_id]?.title || preferanceForm[item.field_id]?.title,
            print: default_grid_setting[item.field_id]?.print || preferanceForm[item.field_id]?.print,
            export: default_grid_setting[item.field_id]?.export || preferanceForm[item.field_id]?.export,
          }
        } else {
          baseGridSetting[item.field_id] = {
            ...preferanceForm[item.field_id],
            name: cms(item.cms_key),
            title: preferanceForm[item.field_id]?.title,
            print: preferanceForm[item.field_id]?.print,
            export: preferanceForm[item.field_id]?.export,
          }
        }
      })
    }
    set_grid_expand({
      name: mainGrid?.grid_expand_table?.[0]?.name,
      method: mainGrid?.grid_expand_table?.[0]?.name,
      columns: mainGrid?.grid_expand_table?.[0]?.component_call_cols,
    })
    set_filter_by(mainGrid?.grid_filter_cols)
    updatePreferanceForm(baseGridSetting)

    if (mainGrid?.group_by_setting) {
      setGroupBy(JSON.parse(mainGrid?.group_by_setting))
    }
  }, [])

  var row
  function start(e) {
    row = e.target
  }
  function dragover(e) {
    e.preventDefault()

    let children = Array.from(e.target.parentNode.parentNode.children)

    if (children.indexOf(e.target.parentNode) > children.indexOf(row)) e.target.parentNode.after(row)
    else e.target.parentNode.before(row)
  }

  const onSaveColumn = () => {
    const payload = columnForm?.filter((item) => item)
    if (payload?.length > 0) {
      const pageNameFormCoreData = getPageNameForCoreDataFromUrl()
      dispatch(setLoader(true))
      request('submit-grid-column', 'POST', {
        columnForm: payload,
        source_table,
        grid_id: mainGrid?.id,
        page_name: pageName,
        parent_page_name: parentPageName || window.location.hash.split('/')[1]?.split('-')[0],
        tab: true,
      }).then((res) => {
        request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[parentPageName] = data

          dispatch(updatePageDataRedux(pageData))
          dispatch(setLoader(false))
        })
      })
      // dispatch(
      //   submitRequest({
      //     url: 'submit-grid-column',
      //     data: {
      //       columnForm: payload,
      //       source_table,
      //       grid_id: mainGrid?.id,
      //       page_name: pageName,
      //       parent_page_name: window.location.hash.split('/')[1]?.split('-')[0],
      //       tab: true,
      //     },
      //   })
      // )
    }
    updateColumnForm(null)
    setAddColumnFormOpen(false)
  }

  const onSaveTab = () => {
    if (tabForm && tabForm?.length > 0) {
      const pageNameFormCoreData = getPageNameForCoreDataFromUrl()
      dispatch(setLoader(true))
      request('submit-page-tab', 'POST', {
        // data: formFieldOrder,
        grid_id: mainGrid?.id,
        grid_page: true,
        // form_name: formName,
        page_name: pageName,
        tabForm: tabForm,
        parent_page_name: parentPageName,
        // tab:true
      }).then((res) => {
        request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
          let pageData = {}
          pageData[parentPageName] = data

          dispatch(updatePageDataRedux(pageData))
          dispatch(setLoader(false))
        })
      })
      // dispatch(
      //   submitRequest({
      //     url: 'submit-page-tab',
      //     data: {
      //       // data: formFieldOrder,
      //       grid_id: mainGrid?.id,
      //       // form_name: formName,
      //       page_name: pageName,
      //       tabForm: tabForm,
      //       parent_page_name: parentPageName,
      //       // tab:true
      //     },
      //   })
      // )
    }
  }

  const isRepeatingForm = content?.type === 'REPEATING_FORM' ? true : false

  let repeatingFormLayout = {}

  if (mainGrid?.column_layout && mainGrid?.column_layout?.length > 0) {
    repeatingFormLayout['layout'] = mainGrid?.column_layout
  } else {
    repeatingFormLayout['layout'] = []
  }

  const fieldsToEditForRepeatingForm = []

  mainGrid?.component_call_cols
    ?.filter((field) => field?.component_name === 'text_data_col')
    ?.map((item, key) => {
      // if (item.field_setting && item.field_setting?.title) {

      if (fieldsToEditForRepeatingForm[0]) {
        fieldsToEditForRepeatingForm[0].push(item)
      } else {
        fieldsToEditForRepeatingForm[0] = [item]
      }
    })

  const onDeletePage = (pageData) => {
    let payload = {}
    if (currentPageData?.isMainPage) {
      payload = {
        page_name: gridData?.gridData?.pageName,
        parent_page_name: gridData?.gridData?.parentPageName,
        field_id: gridData?.gridData?.field_id,
        name: parentPageName,
      }
    } else {
      payload = {
        name: parentPageName,
      }
    }
    request(`/remove/page`, 'POST', { ...payload }).then((res) => {
      if (res.status === 200 || res.status === 202) {
        dispatch(removePageDataRedux(pageData?.name))
        dispatch(reduxLoad())
      } else {
        dispatch(setNotification({ type: 'error', message: 'Something went wrong' }))
      }
    })
  }
  const userPage = useSelector((state) => state.CMS.coreData?.page?.users)

  const [isCreatingSeeder, setIsCreatingSeeder] = useState(false)
  const onCreateSeeder = async () => {
    setIsCreatingSeeder(true)
    const payload = await generatePayloadForSeeder(currentPageData)
    console.log('create seeder payload::::::::', payload)
    request('create-seeder', 'POST', payload).then((res) => {
      if (res?.status === 200 || res?.status === 202) {
        dispatch(setNotification({ type: 'success', message: 'Seeder created successfully!' }))
      } else {
        dispatch(setNotification({ type: 'error', message: res?.meesage || 'something went wrong' }))
      }
    })
    setIsCreatingSeeder(false)

    // request('create-seeder','POST',userPage)
  }

  return (
    <>
      {(process.env.REACT_APP_ENV === 'dev' || true) && (
        <Button onClick={() => onCreateSeeder()} color='info' className='mr-3'>
          {isCreatingSeeder ? 'Creating seeder....' : 'Create Seeder'}
        </Button>
      )}

      <ListGroup>
        <Row>
          {currentPageData && currentPageData?.isRemovable && (
            <Col md={12}>
              <Button className='float-right' color='danger' onClick={() => onDeletePage(currentPageData)}>
                {/* <FontAwesomeIcon icon='fa-regular fa-trash-can' /> */}
                <IconSet icon='fa-regular fa-trash-can' color='light' /> Remove Page
              </Button>
            </Col>
          )}

          {checkCMSPermission('update_grid_setting') && (
            <>
              {' '}
              <Col md={12}>
                <ListGroupItem
                  style={{
                    padding: '0.5rem 0.5rem 0.5rem 1rem',
                    display: 'flex',
                    marginBottom: 7,
                    marginTop: 7,
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setGridSettingAccoridanOpen({
                      tableSetting: !gridSettingAccordianOpen.tableSetting,
                      tabSetting: false,
                      gridColors: false,
                      filterSetting: false,
                      gridFilterSetting: false,
                    })
                  }}>
                  <div> Table setting</div>
                </ListGroupItem>
              </Col>
              {gridSettingAccordianOpen?.tableSetting && (
                <Col md={12} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  <Row style={{ marginLeft: '0px' }}>
                    <Col md={3}>
                      <div style={{ marginBottom: '1rem' }}>Live refresh Button</div>
                      <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={toggleLiveRefresh}>
                        <div
                          className={cx('switch-animate', {
                            'switch-on': buttonData.live_refresh_button,
                            'switch-off': !buttonData.live_refresh_button,
                          })}>
                          <input type='checkbox' />
                          <span className='switch-left bg-success'>ON</span>
                          <label>&nbsp;</label>
                          <span className='switch-right bg-success'>OFF</span>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div style={{ marginBottom: '1rem' }}>Widget Form</div>
                      <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={() => setWidgetForm(!widgetForm)}>
                        <div
                          className={cx('switch-animate', {
                            'switch-on': widgetForm,
                            'switch-off': !widgetForm,
                          })}>
                          <input type='checkbox' />
                          <span className='switch-left bg-success'>ON</span>
                          <label>&nbsp;</label>
                          <span className='switch-right bg-success'>OFF</span>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div style={{ marginBottom: '1rem' }}>Show Add Form On Edit</div>
                      <div
                        className='switch has-switch mb-2 mr-2'
                        data-on-label='ON'
                        data-off-label='OFF'
                        onClick={() => setShowAddFormOnEdit(!showAddFormOnEdit)}>
                        <div
                          className={cx('switch-animate', {
                            'switch-on': showAddFormOnEdit,
                            'switch-off': !showAddFormOnEdit,
                          })}>
                          <input type='checkbox' />
                          <span className='switch-left bg-success'>ON</span>
                          <label>&nbsp;</label>
                          <span className='switch-right bg-success'>OFF</span>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div style={{ marginBottom: '1rem' }}>Select All Records</div>
                      <div
                        className='switch has-switch mb-2 mr-2'
                        data-on-label='ON'
                        data-off-label='OFF'
                        onClick={() => setSelectAllRow(!selectAllRow)}>
                        <div
                          className={cx('switch-animate', {
                            'switch-on': selectAllRow,
                            'switch-off': !selectAllRow,
                          })}>
                          <input type='checkbox' />
                          <span className='switch-left bg-success'>ON</span>
                          <label>&nbsp;</label>
                          <span className='switch-right bg-success'>OFF</span>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div style={{ marginBottom: '1rem' }}>Expand All Records</div>
                      <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={() => setExpandAll(!expandAll)}>
                        <div
                          className={cx('switch-animate', {
                            'switch-on': expandAll,
                            'switch-off': !expandAll,
                          })}>
                          <input type='checkbox' />
                          <span className='switch-left bg-success'>ON</span>
                          <label>&nbsp;</label>
                          <span className='switch-right bg-success'>OFF</span>
                        </div>
                      </div>
                    </Col>
                    <Col md={3}>
                      <div style={{ marginBottom: '1rem' }}>Group By</div>
                      <div className='switch has-switch mb-2 mr-2' data-on-label='ON' data-off-label='OFF' onClick={() => set_group_by(!group_by)}>
                        <div
                          className={cx('switch-animate', {
                            'switch-on': group_by,
                            'switch-off': !group_by,
                          })}>
                          <input type='checkbox' />
                          <span className='switch-left bg-success'>ON</span>
                          <label>&nbsp;</label>
                          <span className='switch-right bg-success'>OFF</span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Col md={12}>
                    <ListGroup>
                      <ListGroupItem
                        style={{
                          padding: '0.5rem 0.5rem 0.5rem 1rem',
                          display: 'flex',
                          marginBottom: 7,
                          marginTop: 7,
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          marginBottom: '1rem',
                        }}>
                        <div>Columns Setting</div>
                        <IconSet
                          icon={isColumnSettingOpen ? 'far fa-arrow-down' : 'far fa-arrow-right'}
                          color='primary'
                          style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                          onClick={() => setIsColumnSettingOpen(!isColumnSettingOpen)}
                        />
                      </ListGroupItem>

                      {isColumnSettingOpen && (
                        <>
                          <div className='float-right'>
                            <Button
                              color={addColumnFormOpen ? 'danger' : 'success'}
                              onClick={() => {
                                if (isRepeatingForm) {
                                  setAddColumnForRepeatingForm(!addColumnForRepeatingFormOpen)
                                } else {
                                  setAddColumnFormOpen(!addColumnFormOpen)
                                }
                              }}
                              className='btn m-1 float-right'>
                              {isRepeatingForm ? (
                                <div>Add/Edit Field</div>
                              ) : (
                                <IconSet icon={addColumnFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
                              )}
                            </Button>
                          </div>

                          <Modal
                            isOpen={addColumnForRepeatingFormOpen}
                            toggle={() => setAddColumnForRepeatingForm(!addColumnForRepeatingFormOpen)}
                            style={{ width: 1000 }}
                            scrollable={true}>
                            <ModalHeader>Edit Field</ModalHeader>
                            <ModalBody>
                              <RenderCommonFields
                                // fields={[]}
                                formName={''}
                                // pageName={''}
                                // source_table={mainGrid?.source_table}
                                isRepeatingForm={isRepeatingForm}
                                grid_id={mainGrid?.id}
                                form={repeatingFormLayout}
                                source_table={content?.source_table}
                                pageName={pageName}
                                fields={fieldsToEditForRepeatingForm}
                                activeTabData={activeTabData}
                                parentPageName={parentPageName}
                              />
                            </ModalBody>
                          </Modal>

                          {addColumnFormOpen && (
                            <AvForm style={{ marginBottom: '1rem' }}>
                              <EditColumn
                                form={{ table: content?.source_table }}
                                updateColumn={updateColumnForm}
                                onSaveColumn={onSaveColumn}
                                isGridSetting={true}
                                isRepeatingForm={isRepeatingForm}
                                mainGrid={content?.main_grid}
                              />
                            </AvForm>
                          )}

                          <table className='table'>
                            <thead>
                              <tr>
                                <th style={{ width: '1rem' }}></th>
                                <th>Name</th>
                                <th>Print</th>
                                <th>Export</th>
                                <th>Title</th>
                                <th></th>
                                {!isRepeatingForm && <th></th>}
                              </tr>
                            </thead>
                            <tbody id='grid_setting_row_body'>
                              {mainGrid?.component_call_cols.map((item, key) => {
                                if (item.component_name === 'text_data_col' || item?.component_name === 'aggregate_column') {
                                  return (
                                    <RenderColumnRow
                                      item={item}
                                      key={key}
                                      index={key}
                                      source_table={source_table}
                                      dragover={dragover}
                                      start={start}
                                      preferanceForm={preferanceForm}
                                      updatePreferanceForm={updatePreferanceForm}
                                      updateGridColumnSettingTitle={updateGridColumnSettingTitle}
                                      mainGrid={mainGrid}
                                      isButtonSettingOpen={isButtonSettingOpen}
                                      setIsButtonSettingOpen={setIsButtonSettingOpen}
                                      pageName={pageName}
                                      isRepeatingForm={isRepeatingForm}
                                      parentPageName={parentPageName}
                                      isAggregateColumn={item?.component_name === 'aggregate_column'}
                                    />
                                  )
                                }
                              })}
                            </tbody>
                          </table>
                          <hr></hr>
                          {mainGrid?.component_call_expand_fields && (
                            <>
                              <div>Expand columns</div>
                              <table className='table'>
                                <thead>
                                  <tr>
                                    <th style={{ width: '1rem' }}></th>
                                    <th>Name</th>
                                    <th>Print</th>
                                    <th>Export</th>

                                    <th></th>
                                  </tr>
                                </thead>
                                <tbody id='grid_setting_row_body_expand'>
                                  {mainGrid?.component_call_expand_fields &&
                                    mainGrid?.component_call_expand_fields.map((item, key) => {
                                      return (
                                        <tr
                                          key={key}
                                          draggable='true'
                                          id={item?.field_id}
                                          onDragStart={(e) => start(e)}
                                          onDragOver={(e) => dragover(e)}>
                                          <td>
                                            {' '}
                                            <i className='fa-sharp fa-solid fa-grid-horizontal' style={{ cursor: 'pointer' }}></i>
                                          </td>

                                          <td
                                            onClick={() => {
                                              if (editMode) {
                                                editCMS(item.cms_key)
                                              }
                                            }}>
                                            {' '}
                                            {cms(item.cms_key)}
                                          </td>
                                          <td>
                                            <Switch
                                              checked={preferanceForm[item.field_id]?.print}
                                              onChange={(value) =>
                                                updatePreferanceForm({
                                                  ...preferanceForm,
                                                  [item.field_id]: {
                                                    ...preferanceForm[item.field_id],
                                                    print: value,
                                                  },
                                                })
                                              }
                                              uncheckedIcon={<i className='fa-thin fa-xmark switch-icon'></i>}
                                              checkedIcon={<i className='fa-thin fa-check switch-icon'></i>}
                                              className='d-inline'
                                            />
                                          </td>
                                          <td>
                                            <Switch
                                              checked={preferanceForm[item.field_id]?.export}
                                              onChange={(value) =>
                                                updatePreferanceForm({
                                                  ...preferanceForm,
                                                  [item.field_id]: {
                                                    ...preferanceForm[item.field_id],
                                                    export: value,
                                                  },
                                                })
                                              }
                                              uncheckedIcon={<i className='fa-thin fa-xmark switch-icon'></i>}
                                              checkedIcon={<i className='fa-thin fa-check switch-icon'></i>}
                                              className='d-inline'
                                            />
                                          </td>

                                          <td>
                                            <Button
                                              className='btn-danger'
                                              onClick={() => {
                                                const pageNameFormCoreData = getPageNameForCoreDataFromUrl()
                                                dispatch(setLoader(true))
                                                request('remove-grid-column', 'POST', {
                                                  grid_id: mainGrid?.id,
                                                  field_id: item.field_id,
                                                  expand: true,
                                                }).then((res) => {
                                                  request(`get-page/${parentPageName}`, 'GET').then(({ data }) => {
                                                    let pageData = {}
                                                    pageData[parentPageName] = data

                                                    dispatch(updatePageDataRedux(pageData))
                                                    dispatch(setLoader(false))
                                                  })
                                                })
                                                // dispatch(
                                                //   submitRequest({
                                                //     url: 'remove-grid-column',
                                                //     data: {
                                                //       grid_id: mainGrid?.id,
                                                //       field_id: item.field_id,
                                                //       expand: true,
                                                //     },
                                                //   })
                                                // )
                                              }}>
                                              <IconSet icon='pe-7s-trash' color='light' />
                                            </Button>
                                          </td>
                                        </tr>
                                      )
                                    })}
                                </tbody>
                              </table>
                            </>
                          )}
                        </>
                      )}

                      <ExpandRowWithTable
                        content={content}
                        source_table={source_table}
                        pageName={pageName}
                        activeTabData={activeTabData}
                        parentPageName={parentPageName}
                        gridData={gridData}
                        grid_expand={grid_expand}
                        set_grid_expand={set_grid_expand}
                      />

                      <FilterBy filter_by={filter_by} set_filter_by={set_filter_by} source_table={source_table} />

                      <ListGroupItem
                        style={{
                          padding: '0.5rem 0.5rem 0.5rem 1rem',
                          marginBottom: 7,
                          marginTop: 7,
                          marginBottom: '1rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <div> Button Setting</div>

                        <IconSet
                          icon={isButtonSettingOpen ? 'far fa-arrow-down' : 'far fa-arrow-right'}
                          color='primary'
                          style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                          onClick={() => setIsButtonSettingOpen(!isButtonSettingOpen)}
                        />
                      </ListGroupItem>
                      {isButtonSettingOpen && (
                        <GridButtonSetting
                          buttons={gridButtonList}
                          buttonForm={buttonForm}
                          setButtonForm={setButtonForm}
                          setTableButtonForm={setTableButtonForm}
                          tableButtonForm={tableButtonForm}
                          tableButtonList={tableButtonList}
                          mainGrid={mainGrid}
                          content={content}
                        />
                      )}
                      <ListGroupItem
                        style={{
                          padding: '0.5rem 0.5rem 0.5rem 1rem',
                          marginBottom: 7,
                          marginTop: 7,
                          marginBottom: '1rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <div> Group Setting</div>

                        <IconSet
                          icon={isGridRowSettingOpen ? 'far fa-arrow-down' : 'far fa-arrow-right'}
                          color='primary'
                          style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                          onClick={() => setIsGridRowSettingOpen(!isGridRowSettingOpen)}
                        />
                      </ListGroupItem>
                      {isGridRowSettingOpen && (
                        <Col md={12}>
                          <RowGroupSetting mainGrid={mainGrid} onChange={(value, field) => updateGroupBy(value, field)} groupBy={groupBy} />
                        </Col>
                      )}
                      <ListGroupItem
                        style={{
                          padding: '0.5rem 0.5rem 0.5rem 1rem',
                          marginBottom: 7,
                          marginTop: 7,
                          marginBottom: '1rem',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}>
                        <div> Where Clause</div>

                        <IconSet
                          icon={whereClauseOpen ? 'far fa-arrow-down' : 'far fa-arrow-right'}
                          color='primary'
                          style={{ cursor: 'pointer', marginRight: '0.5rem' }}
                          onClick={() => setWhereClauseOpen(!whereClauseOpen)}
                        />
                      </ListGroupItem>
                      {whereClauseOpen && (
                        <Col md={12}>
                          <WhereClause source_table={source_table} onChange={updateWhereClauseForm} form={whereClauseForm} />
                        </Col>
                      )}
                    </ListGroup>
                  </Col>
                  <Col md={12}>
                    <Button onClick={() => handleSubmit()} color='primary'>
                      Save Grid Setting
                    </Button>
                  </Col>
                </Col>
              )}
            </>
          )}
          {content?.grid_settings?.display_report_type?.length !== 0 && (
            <Col md={12}>
              <ListGroupItem
                style={{
                  padding: '0.5rem 0.5rem 0.5rem 1rem',
                  display: 'flex',
                  marginBottom: 7,
                  marginTop: 7,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setGridSettingAccoridanOpen({
                    tableSetting: false,
                    tabSetting: false,
                    gridColors: false,
                    filterSetting: false,
                    gridFilterSetting: false,
                    scheduleEmailGridReport: !gridSettingAccordianOpen?.scheduleEmailGridReport,
                  })
                }}>
                <div> Schedule Email Grid Report</div>
              </ListGroupItem>
            </Col>
          )}

          {gridSettingAccordianOpen?.scheduleEmailGridReport && (
            <Col md={12}>
              {' '}
              <ScheduleEmailReportsAccordian content={content} parentPageName={parentPageName} />
            </Col>
          )}

          {checkCMSPermission('add_tab_in_grid') && (
            <>
              {' '}
              <Col md={12}>
                <ListGroupItem
                  style={{
                    padding: '0.5rem 0.5rem 0.5rem 1rem',
                    marginBottom: 7,
                    marginTop: 7,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setGridSettingAccoridanOpen({
                      tableSetting: false,
                      tabSetting: !gridSettingAccordianOpen.tabSetting,
                      gridColors: false,
                      filterSetting: false,
                      gridFilterSetting: false,
                    })
                  }}>
                  <div> Tab setting</div>
                </ListGroupItem>
              </Col>
              {gridSettingAccordianOpen?.tabSetting && (
                <Col md={12} style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                  <AvForm>
                    <Row style={{ margin: '0rem' }}>
                      <EditTab
                        form={{ table: content?.source_table }}
                        col={6}
                        updateTab={updateTabForm}
                        isTabPage={true}
                        onSaveTab={onSaveTab}
                        tabList={childTabs}
                        pageName={pageName}
                        parentPageName={parentPageName}
                      />
                    </Row>
                  </AvForm>
                </Col>
              )}
            </>
          )}

          {checkCMSPermission('grid_color_setting') && (
            <>
              {' '}
              <Col md={12}>
                <ListGroupItem
                  style={{
                    padding: '0.5rem 0.5rem 0.5rem 1rem',
                    marginBottom: 7,
                    marginTop: 7,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setGridSettingAccoridanOpen({
                      tableSetting: false,
                      tabSetting: false,
                      gridColors: !gridSettingAccordianOpen.gridColors,
                      filterSetting: false,
                      gridFilterSetting: false,
                    })
                  }}>
                  <div> Grid Color setting</div>
                </ListGroupItem>
              </Col>
              {gridSettingAccordianOpen?.gridColors && (
                <>
                  <Col md={6}>
                    <br />
                    <h5>Grid Colors</h5>
                  </Col>
                  <Col md={6}>
                    <Button color='success' onClick={() => setIsColorFormOpen(!isColorFormOpen)} className='btn m-1 float-right'>
                      <IconSet icon={isColorFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
                    </Button>
                  </Col>

                  {isColorFormOpen && (
                    <Col md={12}>
                      <AddColorGroup source_table={source_table} content={content} />
                    </Col>
                  )}
                  <Col md={12}>
                    <GridSettingTable content={content} source_table={source_table} pageName={pageName} parentPageName={parentPageName} />
                  </Col>
                </>
              )}
            </>
          )}

          {checkCMSPermission('filter_setting') && (
            <>
              <Col md={12}>
                <ListGroupItem
                  style={{
                    padding: '0.5rem 0.5rem 0.5rem 1rem',
                    marginBottom: 7,
                    marginTop: 7,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setGridSettingAccoridanOpen({
                      tableSetting: false,
                      tabSetting: false,
                      gridColors: false,
                      filterSetting: !gridSettingAccordianOpen.filterSetting,
                      gridFilterSetting: false,
                    })
                  }}>
                  <div> Filter In/Out setting</div>
                </ListGroupItem>
              </Col>
              {gridSettingAccordianOpen?.filterSetting && (
                <>
                  <Col md={6}>
                    <br />
                    <h5>Grid Filters</h5>
                  </Col>
                  <Col md={6}>
                    <Button color='success' onClick={() => setIsColorFormOpen(!isColorFormOpen)} className='btn m-1 float-right'>
                      <IconSet icon={isColorFormOpen ? 'fa-solid fa-xmark' : 'fa-solid fa-plus'} color='light' />
                    </Button>
                  </Col>

                  {isColorFormOpen && (
                    <Col md={12}>
                      <AddColorGroup source_table={source_table} content={content} isFilter={true} />
                    </Col>
                  )}

                  <Col md={12}>{<GridFilterTable content={content} source_table={source_table} pageName={pageName} />}</Col>
                </>
              )}
            </>
          )}
          <Col md={12}>
            <ListGroupItem
              style={{
                padding: '0.5rem 0.5rem 0.5rem 1rem',
                marginBottom: 7,
                marginTop: 7,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                cursor: 'pointer',
              }}
              onClick={() => {
                setGridSettingAccoridanOpen({
                  tableSetting: false,
                  tabSetting: false,
                  gridColors: false,
                  filterSetting: false,
                  gridFilterSetting: false,
                  copyTab: !gridSettingAccordianOpen?.copyTab,
                })
              }}>
              <div> Copy tab</div>
            </ListGroupItem>
          </Col>
          {gridSettingAccordianOpen?.copyTab && (
            <Col md={12}>
              {' '}
              <CopyTab source_table={source_table} content={content} pageName={pageName} parentPageName={parentPageName} />
            </Col>
          )}
        </Row>
      </ListGroup>
    </>
  )
}

export default GridSetting
